import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

import { connect } from 'react-redux';

import isEqual from 'react-fast-compare';

import playCardSound from '../../../../../sounds/card_played.wav';
import takingATrick from '../../../../../sounds/taking_a_trick.mp3';

import Render3PlayerCards from './Render3PlayerCards';
import Render4PlayerCards from './Render4PlayerCards';

const erci = ['♥-9', '♥-K', '♥-10', '♥-A'];
const kreisti = ['♣︎-9', '♣︎-K', '♣︎-10', '♣︎-A'];
const piki = ['♠︎-9', '♠︎-K', '♠︎-10', '♠︎-A'];
const trumpji = ['♦︎-7', '♦︎-8', '♦︎-9', '♦︎-K', '♦︎-10', '♦︎-A', '♦︎-J', '♥-J', '♠︎-J', '♣︎-J', '♦︎-Q', '♥-Q', '♠︎-Q', '♣︎-Q'];

class CardsOnTable extends Component {
  static propTypes = {
    currentTable: PropTypes.arrayOf(PropTypes.shape()),
    myPos: PropTypes.string,
    removeSelectedCard: PropTypes.func.isRequired,
    tableInProgress: PropTypes.func.isRequired,
    soundOn: PropTypes.bool,
    fastGame: PropTypes.bool,
    lightningGame: PropTypes.bool,
    playerCount: PropTypes.number,
    sittingOut: PropTypes.string,
    gameState: PropTypes.string,
    party: PropTypes.number,
    currentTurn: PropTypes.string,
    tableIsInProgress: PropTypes.bool,
    firstToGo: PropTypes.string,
  }

  static defaultProps = {
    currentTable: null,
    myPos: null,
    soundOn: false,
    fastGame: false,
    lightningGame: false,
    playerCount: null,
    sittingOut: null,
    gameState: null,
    party: null,
    currentTurn: null,
    tableIsInProgress: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      cardsAdded: {},
      remSelectedCard: false,
      animSpeed: 0.35,
      animSpeedSet: false,
      showCardsTimeout: 1.0,
      cardsRemAnimInProgress: false,
    };

    this.playCardAudio = new Audio(playCardSound);
    this.takingATrickAudio = new Audio(takingATrick);
  }

  componentDidMount() {
    const { currentTable } = this.props;


    if (currentTable) {
      this.setState({ currentTable: [...currentTable] });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { animSpeedSet, animSpeed } = this.state;
    if ((nextProps.fastGame || nextProps.lightningGame) && !animSpeedSet) {
      this.setState({ animSpeed: animSpeed - 0.03, animSpeedSet: true });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      currentTable, myPos, gameState, playerCount, sittingOut, firstToGo
    } = this.props;
    const { cardsAdded, currentTable: currentTableState } = this.state;

    if (!isEqual(nextState.cardsAdded, cardsAdded)) {
      return true;
    }

    if (!isEqual(nextProps.currentTable, currentTable)) {
      return true;
    }

    if (!isEqual(nextState.currentTable, currentTableState)) {
      return true;
    }

    if (!isEqual(nextProps.playerCount, playerCount)) {
      return true;
    }

    if (!isEqual(nextProps.sittingOut, sittingOut)) {
      return true;
    }

    if (myPos !== nextProps.myPos) {
      return true;
    }

    if (gameState !== nextProps.gameState) {
      return true;
    }

    if (firstToGo !== nextProps.firstToGo) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    const {
      currentTable: newTable, removeSelectedCard, tableInProgress, soundOn, party, gameState, myPos, updateCardsRemAnimInProgress,
    } = this.props;
    const { party: prevParty, gameState: prevGameState } = prevProps;

    console.log('testcurrentTable componentDidUpdate', newTable);

    if (gameState === 'results' && gameState !== prevGameState) {
      this.timeoutID = setTimeout(() => {
        gsap.set('.table-card', { clearProps: 'transform' });

        setTimeout(() => {
          gsap.set('.table-card', { opacity: 1 });
          gsap.set('.table-card-bg', { opacity: 1 });
        }, 250);

        gsap.set('.table-card-bg', { clearProps: 'transform' });

        tableInProgress(false);

        // Reset cardsAdded after a round is ended to clear data for next round
        this.setState({ currentTable: [], cardsRemAnimInProgress: false, cardsAdded: {} });
        updateCardsRemAnimInProgress(false);
      }, (this.state.animSpeed * 1000) + 500 + (this.state.showCardsTimeout * 1000));
    }

    const {
      currentTable, cardsAdded, remSelectedCard, cardsRemAnimInProgress,
    } = this.state;

    if (prevParty && prevParty !== party) {
      this.setState({ cardsAdded: {}, currentTable: [] });

      return;
    }

    if (remSelectedCard) {
      setTimeout(() => { removeSelectedCard(); }, 0); // To avoid blinking
      this.setState({ remSelectedCard: false });
    }

    if ((!newTable || newTable.length !== 3) && currentTable && currentTable.length > 2 && Object.keys(cardsAdded).length === 0) {
      if (cardsRemAnimInProgress) {
        return;
      }

      const cards = [];
      let posClassName;
      let strongestPlayer;

      if (currentTable && currentTable.length === 3 && currentTable[0] && currentTable[1] && currentTable[2]) {
        console.log('testcurrentTable all 3 cards', currentTable);

        for (let i = 0; i < 3; i += 1) {
          const { card, player } = currentTable[i];
          let type;
          let index;

          if (kreisti.indexOf(currentTable[i].card) !== -1) {
            type = 'kreists';
            index = kreisti.indexOf(currentTable[i].card);
          } else if (erci.indexOf(currentTable[i].card) !== -1) {
            type = 'ercs';
            index = erci.indexOf(currentTable[i].card);
          } else if (piki.indexOf(currentTable[i].card) !== -1) {
            type = 'pikis';
            index = piki.indexOf(currentTable[i].card);
          } else {
            type = 'trumpis';
            index = trumpji.indexOf(currentTable[i].card);
          }

          cards[i] = {
            card,
            player,
            type,
            index,
          };
        }
        let strongestCard = 0;

        for (let i = 1; i < 3; i += 1) {
          if (cards[strongestCard].type !== 'trumpis' && cards[i].type === 'trumpis') {
            strongestCard = i;
          } else if (cards[strongestCard].type === 'trumpis' && cards[i].type === 'trumpis') {
            if (cards[strongestCard].index < cards[i].index) {
              strongestCard = i;
            }
          } else if (cards[strongestCard].type === cards[i].type
          && cards[strongestCard].index < cards[i].index) {
            strongestCard = i;
          }
        }

        strongestPlayer = cards[strongestCard].player;

        console.log('cards', { cards });

        console.log('strongestCard', { strongestCard });
        if (soundOn && myPos === strongestPlayer) {
          console.log('play sound');
          const playPromise = this.takingATrickAudio.play();
          if (playPromise !== undefined) {
            playPromise
              .then(_ => {
                // Automatic playback started!
                // Show playing UI.
                console.log("audio played auto");
              })
              .catch(error => {
                // Auto-play was prevented
                // Show paused UI.
                console.log("playback prevented");
              });
          }
        }

        if (cards && cards.length > 0) {
          if (strongestCard === 0) {
            posClassName = 'player-left';
          } else if (strongestCard === 1) {
            posClassName = 'player-firstperson';
          } else if (strongestCard === 2) {
            posClassName = 'player-right';
          } else if (strongestCard === 3) {
            posClassName = 'player-top';
          }
        }
      }

      console.log('posClassName', { posClassName });

      let playerTurnElColl;
      if (posClassName) {
        playerTurnElColl = document.getElementsByClassName(`player-${strongestPlayer}`);
      } else {
        playerTurnElColl = document.getElementsByClassName('is-player-turn');
      }

      //  var playerTurnElColl = document.getElementsByClassName("is-player-turn");
      const tableCardElColl = document.getElementsByClassName('table-card');

      tableInProgress(true);

      const { showCardsTimeout, animSpeed } = this.state;

      if (playerTurnElColl && playerTurnElColl.length > 0 && tableCardElColl && tableCardElColl.length > 0) {
        const playerTurnEl = playerTurnElColl[0];
        const tableCardEl = tableCardElColl[0];

        const playerTurnElRect = playerTurnEl.getBoundingClientRect();
        const tableCardElRect = tableCardEl.getBoundingClientRect();

        const targetX = playerTurnElRect.left - tableCardElRect.left;
        const targetY = playerTurnElRect.top - tableCardElRect.top;

        gsap.to('.table-card', {
          x: targetX,
          y: targetY,
          delay: showCardsTimeout,
          duration: animSpeed,
          scale: 0.7,
          ease: 'none',
          opacity: 0.0,
          onComplete: () => {
            console.log('onComplete 2');
            gsap.set('.table-card', { clearProps: 'transform' });
          //  gsap.set('.table-card', { opacity: 1 });

            setTimeout(() => {
              gsap.set('.table-card', { opacity: 1 });
              gsap.set('.table-card-bg', { opacity: 1 });
            }, 250);

            gsap.set('.table-card-bg', { clearProps: 'transform' });
            gsap.set('.table-card-bg', { opacity: 1 });

            // TO FIX IF CAUSES OTHER ISSUES
            // Test to reset cardsAdded after each trick to prevent a card getting stuck in cardsAdded data and preventing table from clearing
            const { currentTable: currentTable2 } = this.props;

            const _cardsAdded = {};

            if (newTable && newTable.length) {
              console.log('test 3');

              for (const currentCard of currentTable2) {
                _cardsAdded[currentCard.card] = { ...currentCard };
              }
            }

            console.log('set _cardsAdded after removing last table', _cardsAdded);

            this.setState({ cardsAdded: _cardsAdded });

          /*  setTimeout(() => {
              if (playeCardsEl) {
                gsap.set(playeCardsEl, { clearProps: 'transform' });
                gsap.set(playeCardsEl, { display: 'flex' });
              } else {
              //  console.log(`set card: ${playeCardsEl.id} no longer has el`);
              }
            }, 1250);
            const { currentTable, cardsAdded } = this.state;
            const uppdCardsAdded = { ...cardsAdded };
            delete uppdCardsAdded[cardAddedKey];
            this.setState({ cardsAdded: uppdCardsAdded, remSelectedCard: true });
            if (currentTable && currentTable.length < 3) tableInProgress(false); */
          },
        });
      } else {
        gsap.to('.table-card', {
          y: -270, delay: showCardsTimeout, duration: 1, ease: 'none',
        });
      }

      this.setState({ cardsRemAnimInProgress: true });
      updateCardsRemAnimInProgress(true);

      this.timeoutID = setTimeout(() => {
        tableInProgress(false);
        this.setState({ currentTable: [], cardsRemAnimInProgress: false });
        updateCardsRemAnimInProgress(false);
      }, (animSpeed * 1000) + (showCardsTimeout * 1000) + 100);
    } else {
      console.log('CardsOnTable', { currentTable, cardsAdded });
      console.log(cardsAdded);
      //  console.log(tableInProgress);

      if (Object.keys(cardsAdded).length > 0) {
        // eslint-disable-next-line
        for (const cardAddedKey in cardsAdded) {
          console.log('cardsAdded cardAddedKey', cardAddedKey, cardsAdded[cardAddedKey]);

          if (cardsAdded[cardAddedKey].animating) {
            continue;
          }

          const playeCardsEl = document.getElementById(`hand-card-${cardAddedKey}`);
          const tagetTableCardEl = document.getElementById(`table-card-${cardAddedKey}`);

          const fallbackCardCol = document.getElementsByClassName(`card2-5`);

          if (playeCardsEl && tagetTableCardEl && false) { // First person
            console.log('From First person');
            tableInProgress(true);

            const playeCardsElRot = gsap.getProperty(playeCardsEl, 'rotate');

            //  gsap.set(playeCardsEl, { rotate: 0 });
            // gsap.set(tagetTableCardEl, {
            //    x: 0, y: 0, opacity: 1, scale: 1, rotate: 0,
            //  });

            const tagetTableCardRect = tagetTableCardEl.getBoundingClientRect();
            const animCardRect = playeCardsEl.getBoundingClientRect();

            const scaleX = tagetTableCardRect.width / animCardRect.width;
            const scaleY = tagetTableCardRect.height / animCardRect.height;

            // gsap.set(playeCardsEl, { rotate: playeCardsElRot });

            const targetX = tagetTableCardRect.left - animCardRect.left;
            const targetY = tagetTableCardRect.top - animCardRect.top;

            //  const targetX = -tagetTableCardRect.left;
            // const targetY = -tagetTableCardRect.top;

            if (soundOn) {
              console.log('play sound');
              const playPromise = this.playCardAudio.play();
              if (playPromise !== undefined) {
                playPromise
                  .then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    console.log("audio played auto");
                  })
                  .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    console.log("playback prevented");
                  });
              }
            }

            console.log('cardsData', { targetX, targetY, anLeft: animCardRect.left, anTop: animCardRect.top, targLeft: tagetTableCardRect.left, targTop: tagetTableCardRect.top });

            cardsAdded[cardAddedKey].animating = true;
            gsap.to(playeCardsEl, {
              x: targetX,
              y: targetY,
              //  border: '4px solid red',
              rotate: 0,
              duration: this.state.animSpeed,
              scaleX,
              scaleY,
              ease: 'sine.out',
              clearProps: 'transform',
              onComplete: () => {
                console.log('onComplete');
                //  gsap.set(playeCardsEl, { clearProps: 'transform' });
                //  gsap.set(playeCardsEl, { display: 'none' });

                setTimeout(() => {
                  if (playeCardsEl) {
                    gsap.set(playeCardsEl, { clearProps: 'transform' });
                    gsap.set(playeCardsEl, { display: 'flex' });
                  } else {
                    //  console.log(`set card: ${playeCardsEl.id} no longer has el`);
                  }
                }, 1250);
                const { currentTable, cardsAdded } = this.state;
                const uppdCardsAdded = { ...cardsAdded };
                delete uppdCardsAdded[cardAddedKey];
                this.setState({ cardsAdded: uppdCardsAdded, remSelectedCard: true });
                if (currentTable && currentTable.length < 3) tableInProgress(false);
              },
            });
          } else if ((playeCardsEl && tagetTableCardEl) || (fallbackCardCol && fallbackCardCol.length > 0 && tagetTableCardEl && myPos && cardsAdded[cardAddedKey].player && cardsAdded[cardAddedKey].player === myPos)) { // First person
            console.log('From first person 2', { tagetTableCardEl, playeCardsEl, fallbackCardCol });
            const playerAvatarEl = document.getElementById(`player-${cardsAdded[cardAddedKey].player}`);

            const fallbackCardEl = fallbackCardCol[0];

            console.log('fallbackCardEl', fallbackCardEl);

            if (playerAvatarEl) {
              console.log('has playerAvatarEl', { playerAvatarEl });

              tableInProgress(true);
              const tagetTableCardRect = tagetTableCardEl.getBoundingClientRect();
              const playerAvatarRect = playerAvatarEl.getBoundingClientRect();

              let animCardRect;
              let playeCardElementRotation;

              if (playeCardsEl) {
                animCardRect = playeCardsEl.getBoundingClientRect();
                playeCardElementRotation = gsap.getProperty(playeCardsEl, 'rotate');
              } else {
                animCardRect = fallbackCardEl.getBoundingClientRect();
                playeCardElementRotation = gsap.getProperty(fallbackCardEl, 'rotate');
              }

              // const animCardRect = playeCardsEl.getBoundingClientRect();

              // const playeCardElementRotation = gsap.getProperty(playeCardsEl, 'rotate');

              console.log('tagetTableCardRect', { tagetTableCardRect });

              const targetX = animCardRect.left - tagetTableCardRect.left;
              const targetY = animCardRect.top - tagetTableCardRect.top;

              //  const targetX = playerAvatarRect.left;
              //  const targetY = playerAvatarRect.top;

              console.log('targetX', {
                targetX, targetY, pLeft: playerAvatarRect.left, pTop: playerAvatarRect.top, tLeft: tagetTableCardRect.left, tTop: tagetTableCardRect.top,
              });
              console.log('playerAvatarRect', { playerAvatarRect });

              gsap.set(tagetTableCardEl, {
                x: targetX, y: targetY, visibility: 'visible', scale: 0.2, opacity: 1, rotate: playeCardElementRotation
              });

              //  gsap.set(playeCardsEl, { rotate: playeCardElementRotation });

              // if (soundOn) {
              //   console.log('play sound');
              //   const playPromise = this.playCardAudio.play();
              //   if (playPromise !== undefined) {
              //     playPromise
              //       .then(_ => {
              //         // Automatic playback started!
              //         // Show playing UI.
              //         console.log("audio played auto");
              //       })
              //       .catch(error => {
              //         // Auto-play was prevented
              //         // Show paused UI.
              //         console.log("playback prevented");
              //       });
              //   }
              // }

              console.log('this.state.animSpeed', { speed: this.state.animSpeed });

              cardsAdded[cardAddedKey].animating = true;
              gsap.to(tagetTableCardEl, {
                x: 0,
                y: 0,
                //  border: '4px solid red',
                duration: this.state.animSpeed,
                delay: 0.1,
                scale: 1,
                rotate: 0,
                ease: 'sine.out',
                onComplete: () => {
                  console.log('onComplete');
                  const { currentTable, cardsAdded } = this.state;
                  const uppdCardsAdded = { ...cardsAdded };
                  delete uppdCardsAdded[cardAddedKey];
                  this.setState({ cardsAdded: uppdCardsAdded });
                  if (currentTable && currentTable.length < 3) tableInProgress(false);
                },
              });
            } else {
              console.log('has no player avatar ');
              const uppdCardsAdded = { ...this.state.cardsAdded };
              delete uppdCardsAdded[cardAddedKey];
              this.setState({ cardsAdded: uppdCardsAdded });
            }
          } else if (tagetTableCardEl && cardsAdded[cardAddedKey].player !== myPos) { // From player on sides
            console.log('From player on sides', { tagetTableCardEl, playeCardsEl, cardAddedKey });
            const playerAvatarEl = document.getElementById(`player-${cardsAdded[cardAddedKey].player}`);

            if (playerAvatarEl) {
              console.log('has playerAvatarEl', { playerAvatarEl });

              tableInProgress(true);
              const tagetTableCardRect = tagetTableCardEl.getBoundingClientRect();
              const playerAvatarRect = playerAvatarEl.getBoundingClientRect();

              console.log('tagetTableCardRect', { tagetTableCardRect });

              const targetX = playerAvatarRect.left - tagetTableCardRect.left;
              const targetY = playerAvatarRect.top - tagetTableCardRect.top;

              //  const targetX = playerAvatarRect.left;
              //  const targetY = playerAvatarRect.top;

              console.log('targetX', {
                targetX, targetY, pLeft: playerAvatarRect.left, pTop: playerAvatarRect.top, tLeft: tagetTableCardRect.left, tTop: tagetTableCardRect.top,
              });
              console.log('playerAvatarRect', { playerAvatarRect });

              gsap.set(tagetTableCardEl, {
                x: targetX, y: targetY, visibility: 'visible', scale: 0.2, opacity: 1,
              });

              // if (soundOn) {
              //   console.log('play sound');
              //   const playPromise = this.playCardAudio.play();
              //   if (playPromise !== undefined) {
              //     playPromise
              //       .then(_ => {
              //         // Automatic playback started!
              //         // Show playing UI.
              //         console.log("audio played auto");
              //       })
              //       .catch(error => {
              //         // Auto-play was prevented
              //         // Show paused UI.
              //         console.log("playback prevented");
              //       });
              //   }
              // }

              console.log('this.state.animSpeed', { speed: this.state.animSpeed });

              cardsAdded[cardAddedKey].animating = true;
              gsap.to(tagetTableCardEl, {
                x: 0,
                y: 0,
                //  border: '4px solid red',
                duration: this.state.animSpeed,
                delay: 0.1,
                scale: 1,
                ease: 'sine.out',
                onComplete: () => {
                  console.log('onComplete');
                  const { currentTable, cardsAdded } = this.state;
                  const uppdCardsAdded = { ...cardsAdded };
                  delete uppdCardsAdded[cardAddedKey];
                  this.setState({ cardsAdded: uppdCardsAdded });
                  if (currentTable && currentTable.length < 3) tableInProgress(false);
                },
              });
            } else {
              console.log('has no player avatar ');
              const uppdCardsAdded = { ...this.state.cardsAdded };
              delete uppdCardsAdded[cardAddedKey];
              this.setState({ cardsAdded: uppdCardsAdded });
            }
          } else {
            console.log('testcurrentTable missing something', { tagetTableCardEl, playeCardsEl });
          }
        }
      }

      console.log('test 1');

      if (cardsRemAnimInProgress || (currentTable && currentTable.length > 2)) {
      //  setTimeout(() => { this.setState({ currentTable: [], cardsAdded: {} }) }, 100);
        return;
      }

      console.log('test 2');

      const _cardsAdded = cardsAdded;

      if (newTable && newTable.length) {
        console.log('test 3');
        for (const maybeNewCard of newTable) {
          let cardPresent = false;

          for (const currentCard of currentTable) {
            if (maybeNewCard && currentCard && maybeNewCard.card === currentCard.card) {
              cardPresent = true;
              break;
            }
          }

          if (!cardPresent && maybeNewCard) {
            _cardsAdded[maybeNewCard.card] = { ...maybeNewCard };
          }
        }
      }

      console.log('_cardsAdded', { _cardsAdded, newTable });

      if (newTable && newTable.length) {
        console.log('test 4');
        this.setState({ currentTable: [...newTable], cardsAdded: _cardsAdded });
      } else {
      //  this.setState({ currentTable: [], cardsAdded: _cardsAdded });
      }
    }

    // play put card sound whenever cards on table change
    if (!isEqual(prevProps.currentTable, this.props.currentTable)) {
      if (soundOn && this.playCardAudio) {
        this.playCardAudio.play();
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  render() {
    const {
      myPos,
      playerCount,
      sittingOut,
      currentTurn,
      tableIsInProgress,
      gameState,
      firstToGo,
    } = this.props;

    const { currentTable, cardsAdded } = this.state;

    console.log('currentTable', currentTable, sittingOut);

    if (!currentTable) {
      return null;
    }

    if (playerCount === 4) {
      return (
        <Render4PlayerCards
          myPos={myPos}
          currentTable={currentTable}
          currentTurn={currentTurn}
          cardsAdded={cardsAdded}
          sittingOut={sittingOut}
          tableIsInProgress={tableIsInProgress}
          gameState={gameState}
          firstToGo={firstToGo}
        />
      );
    }
    return (
      <Render3PlayerCards
        myPos={myPos}
        currentTable={currentTable}
        currentTurn={currentTurn}
        cardsAdded={cardsAdded}
        tableIsInProgress={tableIsInProgress}
        gameState={gameState}
        firstToGo={firstToGo}
      />
    );
  }
}

const mapStateToProps = state => ({
  fastGame: state.game.globalParams.fastGame,
  lightningGame: state.game.globalParams.lightningGame,
  gameState: state.game.globalParams.gameState,
  sittingOut: state.game.sittingOut,
  currentTable: state.game.currentTable,
  currentTurn: state.game.currentTurn,
  playerCount: state.game.players.player4 ? 4 : 3,
  party: state.game.globalParams.party,
  rPlayed: state.game.globalParams.rPlayed,
  firstToGo: state.game.firstToGo,
});
const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsOnTable);
