import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { composeInitialProps, useTranslation } from 'react-i18next';

import { useSelector, useReducer, useDispatch, shallowEqual, connect } from 'react-redux';

import Joyride, {
  CallBackProps, STATUS,
} from 'react-joyride';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Media,
} from 'reactstrap';

import { map, filter, size, isEqual, find, includes, } from 'lodash';

import CustomTooltip from './CustomTooltip';
import CustomModal from '../../../Components/Components/Modal';

import * as constants from '../../../../../../constants/constants';

import closeImg from '../../../../../../images/icons/close.png';
import hurmanImg from '../../../../../../images/redesign/tutorial/hurman.webp';
import arrowImg from '../../../../../../images/redesign/tutorial/arrow.png';
import { claimTutorialBonus } from '../../../../../../actions/member';
import { getNewTourStateLoad, startNewTour, stopTour, startNewTourMobile, startNewTourMobileIFrame, startNewTourMobileDesktop, getNewTourStateLoadMobile, getNewTourStateLoadMobileIFrame, getNewTourStateLoadMobileDesktop, getNewTourStateLoadMobilePortrait, startNewTourMobilePortrait  } from '../../../../../../actions/tour';
import { setExpandedLandscapeSidebar, setPrepareForJoyride } from '../../../../../../actions/state';
import config from '../../../../../../constants/config';
import { isMobileDevice } from '../../../../../../utils/deviceUtils';
import { isLandscape, isMobileScreenResolution } from '../../../../../../utils/screen';

const GlobalJoyrideStyles = ({runTour, offsetY}) => {
  useEffect(() => {
    const style = document.createElement('style');

      style.innerHTML = `
        //.react-joyride__overlay {
        //  position: fixed !important;
        //  height: auto !important;
        //  top: 0 !important;
        //  bottom: 0 !important;
        //  left: 0 !important;
        //  right: 0 !important;
        //}

        @media screen and (orientation : portrait) {
          .__floater {
            left: 0px !important;
            right: 0px !important;
          }
          .__floater__arrow {
            display: none !important;
          }
          ::-webkit-scrollbar { /* WebKit */
            display: none;
            width: 0;
            height: 0;
          }
          ::-webkit-scrollbar-thumb { /* WebKit */
            display: none;
            width: 0;
            height: 0;
          }
        }
      `;
      document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    const isPortraitMobile = window.matchMedia("(orientation: portrait)").matches;
    let interval;
  
    if (isPortraitMobile && runTour) {
      interval = setInterval(() => {
        const floaterElements = document.getElementsByClassName("__floater");
        const floaterBodyElements = document.getElementsByClassName("__floater__body");
    
        const floaterElement = floaterElements[0] || null;
        const floaterBodyElement = floaterBodyElements[0] || null;
    
        if (floaterElement) {
          const floaterElementStyle = window.getComputedStyle(floaterElement);
          const transform = floaterElementStyle.transform;
          const matrix = new DOMMatrixReadOnly(transform);
          floaterBodyElement.style.transform = `translate(${-matrix.m41}px, ${offsetY}px)`
        }
      }, 200);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [runTour, offsetY]);

  return null;
};

const JoyrideComponent = ({ run, toggle, closeJoyride, tutorialBonus, openMobileDropdownModal, collapseSidebarTab, setPrepareForJoyrideFunc, setExpandedLandscapeSidebarFunc, expandedLandscapeSidebar, }) => {
  const { t } = useTranslation('common');

  const dispatch = useDispatch();
  const tourState = useSelector(state => state.tour);
  const member = useSelector(state => state.member);
  const [tourRound, setTourRound] = useState([]);
  const [completeTourModal, setCompleteTourModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [firstTourModalOpen, setFirstTourModalOpen] = useState(false);
  const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    if (run) {
      setPrepareForJoyrideFunc(true); // close mobile landscape sidebar menu opened tabs with no content (More..., Money/Points items)
      try {
        if (!localStorage.getItem('joyrideActive')) {
          localStorage.setItem('joyrideActive', 'true');
        }
        if (isLandscape()) {
          if (config.isInAppFrame()) {
            dispatch(getNewTourStateLoadMobileIFrame());
          } else if (isMobileDevice()) {
            dispatch(getNewTourStateLoadMobile());
          } else {
            dispatch(getNewTourStateLoadMobileDesktop());
          }
        } else {
          dispatch(getNewTourStateLoadMobilePortrait());

        }

      } catch (err) {
        console.log(err);
      }
    }

    if (run && !tutorialBonus && !processing) {
      setFirstTourModalOpen(true);
    }

    if (run && tutorialBonus && !processing) {
      setProcessing(true);
    }

    if (run && processing) {
      if (isLandscape()) {
        if (config.isInAppFrame()) {
          dispatch(startNewTourMobileIFrame());
        } else if (isMobileDevice()) {
          dispatch(startNewTourMobile());
        } else {
          dispatch(startNewTourMobileDesktop());
        }
      } else {
        dispatch(startNewTourMobilePortrait());
      }

    }

    if (!run) {
      setFirstTourModalOpen(false);
      setProcessing(false);
      try {
        localStorage.removeItem('joyrideActive');
      } catch (err) {
        return null;
      }
    }
  }, [run, processing]);

  useEffect(() => {
    try {
      if (localStorage) {
        return () => {
          localStorage.removeItem('joyrideActive');
        };
      }

      return null;
    } catch (err) {
      return null;
    }
  }, []);

  useEffect(() => {
      if (tourState && run) {
        const pageStep = find(tourState.steps, (o, k) => { return k === 0; });
        if (size(pageStep) > 0) {
          //if (pageStep.tab === 'mobileSettings') {
          if (pageStep.tab === constants.MENU_NAVIGATION.myInfo || pageStep.tab === constants.MENU_NAVIGATION.tournaments) {
            const pageSubStep = find(tourState.steps, (o, k) => { return k === tourState.stepIndex; });
            console.log(pageSubStep, "check!!");
            toggle(pageSubStep?.tab || pageStep.tab);
          } else {
            toggle(pageStep.tab);
          }
        }
      }
  }, [tourState]);

  const handleJoyrideCallback2 = (data: CallBackProps) => {
    const { status, action, step, index } = data;
    // if (pageStep && pageStep.collapseTabBeforeStep) {
    //   collapseSidebarTab(step.collapseTabBeforeStep);
    // }

    console.log('handleJoyrideCallback', action, step, member, member.tutorialBonus);

    if (step) {
      console.log('step.expandedLandscapeSidebar', step.expandedLandscapeSidebar);
      if (step.expandedLandscapeSidebar) {
        setExpandedLandscapeSidebarFunc(true);
      } else {
        setExpandedLandscapeSidebarFunc(false);
      }

      if (step.scrollIntoViewElement) {
        const element = document.getElementById(step.scrollIntoViewElement);
        if (element) {
          element.scrollIntoView({ behavior: 'auto' });
        }
      }

      if (step.offsetY) {
        setOffsetY(step.offsetY);
      } else {
        setOffsetY(0);
      }
    }

    try {
      if (action && step && member && !member.tutorialBonus) {
        console.log("localStorage.getItem('allTours')", localStorage.getItem('allTours'));

        if (localStorage.getItem('allTours')) {
          let allTours = JSON.parse(localStorage.getItem('allTours'));
          const { completeTour } = allTours;
          const newVal = [...completeTour];

          console.log('includes(newVal, step.unit)', includes(newVal, step.unit));
          if (!includes(newVal, step.unit)) {
            newVal.push(step.unit);

            allTours = {
              ...allTours,
              completeTour: newVal,
            };
            console.log('handleJoyrideCallback allTOurs', { allTours });
            localStorage.removeItem('allTours');
            localStorage.setItem('allTours', JSON.stringify(allTours));
          }
        } else {
          let allTours = {
            completeTour: [],
          };
          const newVal = allTours.completeTour;
          newVal.push(step.unit);
          allTours = {
            ...allTours,
            completeTour: newVal,
          };
          console.log({ allTours }, "allTours empty");

          localStorage.setItem('allTours', JSON.stringify(allTours));
        }
      }
    } catch (err) {
      console.log(err);
    }

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED];

    if (finishedStatuses.includes(status) || action === 'close' || action === 'stop') {

      try {
        if (member && !member.tutorialBonus) {
          console.log("stop tour", member.tutorialBonus);

          if (localStorage.getItem('allTours') && JSON.parse(localStorage.getItem('allTours')).completeTour) {
            const allTours = JSON.parse(localStorage.getItem('allTours')).completeTour;
            allTours.sort((a, b) => { return a - b; });

            const tourAllSteps = config.isInAppFrame() ? constants.TOUR_NEW_ALL_STEPS_IFRAME : isMobileDevice() ? constants.TOUR_NEW_ALL_STEPS_MOBILE : constants.TOUR_NEW_ALL_STEPS_MOBILE_DESKTOP;

            console.log('tourAllSteps', tourAllSteps, allTours, isMobileDevice());

            if (isEqual(tourAllSteps, allTours)) {
              console.log("You got 3000000 bonus. WWW");

              setCompleteTourModal(true);
              dispatch(claimTutorialBonus());
              localStorage.removeItem('allTours');
            } else {
              localStorage.removeItem('allTours');
            }
          }
        } else if (member && member.tutorialBonus) {
          if (localStorage.getItem('allTours')) {
            localStorage.removeItem('allTours');
          }
        }
      } catch (err) {
        console.log(err);
      }
      // console.log("close tour");
      setExpandedLandscapeSidebarFunc(false);
      setProcessing(false);
      closeJoyride();
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, step, index } = data;

    console.log('handleJoyrideCallback', status, action, step);

    try {
      if (action && step && member && !member.tutorialBonus) {
        if (localStorage.getItem('allTours')) {
          let allTours = JSON.parse(localStorage.getItem('allTours'));
          const { completeTour } = allTours;
          const newVal = [...completeTour];
          if (!includes(newVal, step.unit)) {
            newVal.push(step.unit);

            allTours = {
              ...allTours,
              completeTour: newVal,
            };
            console.log({ allTours });
            localStorage.removeItem('allTours');
            localStorage.setItem('allTours', JSON.stringify(allTours));
          }
        } else {
          let allTours = {
            completeTour: [],
          };
          const newVal = allTours.completeTour;
          newVal.push(step.unit);
          allTours = {
            ...allTours,
            completeTour: newVal,
          };
          console.log({allTours}, "empty");
          localStorage.setItem('allTours', JSON.stringify(allTours));
        }
      }
    } catch (err) {
      console.log(err);
    }

    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED];

    console.log('actionaction', action);

    if (finishedStatuses.includes(status) || action === 'close' || action === 'stop') {
      try {
        console.log("stop tour", member.tutorialBonus);

        if (member && !member.tutorialBonus) {
          if (localStorage.getItem('allTours') && JSON.parse(localStorage.getItem('allTours')).completeTour) {
            const allTours = JSON.parse(localStorage.getItem('allTours')).completeTour;
            allTours.sort((a, b) => { return a - b; });
            if (isEqual(constants.TOUR_NEW_ALL_STEPS, allTours)) {
              console.log("You got 3000000 bonus. WWW");

              setCompleteTourModal(true);
              dispatch(claimTutorialBonus());
              localStorage.removeItem('allTours');
            } else {
              localStorage.removeItem('allTours');
            }
          }
        } else if (member && member.tutorialBonus) {
          if (localStorage.getItem('allTours')) {
            localStorage.removeItem('allTours');
          }
        }
      } catch (err) {
        console.log(err);
      }

      console.log("close tour");
      setProcessing(false);
      closeJoyride();
    }
  };

  const toggleCompleteTourModal = () => {
    setCompleteTourModal(false);
  };

  const closeJoyrideFunc = () => {
    closeJoyride();
    setProcessing(false);
  };

  const closeTour = () => {
    setProcessing(false);
    closeJoyride();
  };

  const continueTutorial = () => {
    setProcessing(true);
    setFirstTourModalOpen(false);
  };

  console.log('tourState', tourState, processing);

  return (
    <>
    { run &&
      <GlobalJoyrideStyles runTour={run} offsetY={offsetY} />
    }
      {
        tourState && processing && (
          <Joyride
            {...tourState}
            tooltipComponent={CustomTooltip}
            callback={handleJoyrideCallback}
            continuous
            showProgress
            showSkipButton
            scrollToFirstStep
            spotlightClicks
            spotlightPadding={0}
            disableAnimation={true} // Disable animations

            styles={{
              options: {
                zIndex: 10000,
                primaryColor: 'linear-gradient(180deg,#b4ec51,#429321)',
              },
            }}
          />
        )
      }

      <CustomModal
        isOpen={firstTourModalOpen}
        toggle={closeTour}
        size="md"
        title={t('menu.tutorial')}
        footer={(
          <Button className="modal-footer-button tutorial-result-button mb-3 tutorial-begin-modal-footer-button" color="secondary" onClick={continueTutorial}>
            {t('common.continue')}
          </Button>
        )}
        body={(
          <div className="d-flex align-items-center flex-column">
            <p className="tutorial-result-content">
              {t('bonusPage.gameGideBounsContent')}
            </p>
          </div>
        )}
      />
      <CustomModal
        isOpen={completeTourModal}
        toggle={toggleCompleteTourModal}
        size="md"
        verticalCentering
        largeText
        title={t('menu.tutorial')}
        footer={(
          <Button className="modal-footer-button tutorial-result-button mb-3" color="secondary" onClick={toggleCompleteTourModal}>
            {t('common.ok')}
          </Button>
        )}
        body={(
          <>
            {t('tutorial.earnedReward')}
          </>
        )}
      />
    </>
  );
};

JoyrideComponent.propsTypes = {
  run: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  closeJoyride: PropTypes.func.isRequired,
};

JoyrideComponent.defaultProps = {
  run: false,
};

const mapStateToProps = state => ({
  expandedLandscapeSidebar: state.state.expandedLandscapeSidebar || false,
});

const mapDispatchToProps = {
  setPrepareForJoyrideFunc: setPrepareForJoyride,
  setExpandedLandscapeSidebarFunc: setExpandedLandscapeSidebar,

};

export default connect(mapStateToProps, mapDispatchToProps)(JoyrideComponent);
