import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
// import ReactTableContainer from 'react-table-container';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

// import ScrollArea from 'react-scrollbar';

// import { Link } from 'react-router-dom';

import isEqual from 'react-fast-compare';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
// import ScrollAreaButtons from '../../UI/ScrollAreaButtons';

import { filter, map, size } from 'lodash';
import ScrollAreaWrapper from '../../UI/ScrollAreaWrapper';

import RoomsTableRender from './RoomsTableRender';
import RoomsTableJoinedRender from './RoomsTableJoinedRender';
import RoomsTableFooter from './RoomsTableFooter';

import roomsImg from '../../../../images/icons/search.png';
import closeImg from '../../../../images/icons/close.png';
// import keyImg from '../../../../images/icons/private.svg';

import playerJoinedSound from '../../../../sounds/player_joined.wav';
import buttonClickedSound from '../../../../sounds/click_feedback.flac';
import mobileCloseImg from '../../../../images/redesign/components/modal/mobileClose.png';

import {
//  joinRoom,
  leaveRoomMenu,
} from '../../../../actions/room';
import {
  getFriends,
  unBlockUser,
  clickCampaign,
  getIgnoredPlayers,
  getIgnoredByPlayers,
} from '../../../../actions/member';
import {
  FONT_MODE, FROUNT_SIDE_STATUS
} from '../../../../constants/constants';
import { getActiveRoomsCountOld, getRoomGameType } from '../../../../utils/roomUtils';
import config from '../../../../constants/config';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

const GameTypeMap = {
  P: 'P',
  PM: 'PM',
  G: 'G',
  MG: 'MG',
  Privāta: 'Privāta',
  Friends: 'Friends',
};

const GameBetMap = {
  // '1:1': '1:1',
  // '1:5': '1:5',
  '1:10': '1:10',
  '1:25': '1:25',
  '1:50': '1:50',
  '1:100': '1:100',
  '1:250': '1:250',
  '1:500': '1:500',
  '1:1000': '1:1000',
  '1:5000': '1:5000',
  '1:10000': '1:10000',
};

class RoomsTable extends React.Component {
  static propTypes = {
    rooms: PropTypes.shape(),
    joinedRooms: PropTypes.shape(),
    //  GameTypeMap: PropTypes.shape(),
    //  GameBetMap: PropTypes.shape(),
    joinRoom: PropTypes.func.isRequired,
    uid: PropTypes.string,
    level: PropTypes.number,
    activeTournament: PropTypes.string,
    finishedTournamentLastRound: PropTypes.bool,
    joinedRoom: PropTypes.shape(),
    privateRoomPassword: PropTypes.string,
    isFullscreen: PropTypes.bool,
    t: PropTypes.func.isRequired,
    leaveRoom: PropTypes.func.isRequired,
  //  userSettings: PropTypes.shape().isRequired,
    lvl: PropTypes.number,
    fontMode: PropTypes.string,
    joinRoomError: PropTypes.shape({}),
    tournaments: PropTypes.arrayOf(PropTypes.shape()),
    myTournamentsData: PropTypes.shape({}),
    offset: PropTypes.number,
    //  playButtonSound: PropTypes.func.isRequired,
    unBlockUserFunc: PropTypes.func.isRequired,
    resetError: PropTypes.func.isRequired,
    friends: PropTypes.shape(),
    fetchFriends: PropTypes.func.isRequired,
    fetchIgnoredPlayers: PropTypes.func.isRequired,
    fetchIgnoredByPlayers: PropTypes.func.isRequired,
    ignoredPlayers: PropTypes.shape(),
    ignoredByPlayers: PropTypes.shape(),
    showIgnoredRooms: PropTypes.bool,
    campaignData: PropTypes.shape(),
    i18n: PropTypes.shape(),
    devMode: PropTypes.bool,
    clickACampaign: PropTypes.func.isRequired,
  }

  static defaultProps = {
    uid: '',
    level: null,
    rooms: {},
    joinedRooms: {},
    activeTournament: null,
    finishedTournamentLastRound: null,
    joinedRoom: null,
    privateRoomPassword: null,
    isFullscreen: false,
    fontMode: FONT_MODE.normal,
    joinRoomError: null,
    tournaments: [],
    myTournamentsData: {},
    offset: 0,
    lvl: null,
    friends: {},
    campaignData: [],
    i18n: {},
    devMode: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      joinRoomClicked: false,
      tempTypeFilter: ["-"],
      tempBetFilter: ["-"],
      typeFilter: ["-"],
      betFilter: ["-"],
      rooms: [],
      joinedRoom: {},
      password: [],
      privateRoomConfirm: false,
      privateRoomConfirmError: '',
      privateRoomId: '',
      //  privateRoomPosition: '',
      prevRooms: {},
      gameTypeFilterOpen: false,
      gameBetFilterOpen: false,
      gameTypeFilterMap: null,
      gameBetFilterMap: null,
      scrollPos: 0,
      popoverOpen: {},
      errorModalIgnored: false,
      errorRoomId: '',
      errorPassword: '',
      blockedUId: '',
      tooltipId: null,
      bannerHeight: 120,
      campaignClosed: false,
      isOverflowingTypeFilter: false,
      isOverflowingBetFilter: false,
    };
    window.addEventListener('resize', this.handleResize);
    this.bannerRef = React.createRef(null);
    this.imageBannerRef = React.createRef(null);
    this.menuHeaderRef = React.createRef(null);
    //  this.joinRoomClicked = this.joinRoomClicked.bind(this);
    this.toggleGameTypeFilter = this.toggleGameTypeFilter.bind(this);
    this.toggleGameBetFilter = this.toggleGameBetFilter.bind(this);

    this.digit1 = React.createRef();
    this.digit2 = React.createRef();
    this.digit3 = React.createRef();
    this.digit4 = React.createRef();

    this.playerJoinedAudio = new Audio(playerJoinedSound);
    //  this.buttonClickedAudio = new Audio(buttonClickedSound);
    this.buttonClickedAudio = new Audio(buttonClickedSound);

    this.externalBannerRef = React.createRef(null);
    this.textRefTypeFilter = React.createRef(null);
    this.textRefBetFilter = React.createRef(null);


    // window.addEventListener("click", this.handleClickCampaignExternal2, false);
  }
  checkOverflowTypeFilter = () => {
    if (this.textRefTypeFilter.current) {
      const isOverflowingTypeFilter =
        this.textRefTypeFilter.current.scrollWidth > this.textRefTypeFilter.current.offsetWidth;
      this.setState({ isOverflowingTypeFilter });
    }
  };
  checkOverflowBetFilter = () => {
    if (this.textRefBetFilter.current) {
      const isOverflowingBetFilter =
        this.textRefBetFilter.current.scrollWidth > this.textRefBetFilter.current.offsetWidth;
      this.setState({ isOverflowingBetFilter });
    }
  };

  handleResize = () => {
    // const bannerHeight = this.bannerRef.current.innerHeight;
    console.log(this.bannerRef.current, this.imageBannerRef.current, "checkCurrent======" )
    if (this.bannerRef && this.bannerRef.current) {
      const { offsetHeight } = this.bannerRef.current;
      console.log(offsetHeight, "bannerHeight");
      this.setState({
        bannerHeight: offsetHeight || 0,
      });
    }

    this.checkOverflowTypeFilter();
    this.checkOverflowBetFilter();
  }



  componentDidMount() {
    const { t, rooms, joinedRooms, resetError, fetchFriends, setScreenView, fetchIgnoredPlayers, fetchIgnoredByPlayers, offset } = this.props;
    const {
      typeFilter,
      betFilter,
      gameTypeFilterMap,
      gameBetFilterMap,
    } = this.state;

    fetchIgnoredPlayers();
    fetchIgnoredByPlayers();
    /*
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        console.log('pushAd', { adsbygoogle });
        adsbygoogle.push({});
      } catch (e) {
        console.error(e);
      }
    }

    let timer = 0;

    let adInterval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      timer = timer + 1;
      if (timer === 0) {
        return null;
      }
      if (timer > 6) {
        console.log('clear adInterval timer');
        clearInterval(adInterval);
      } else if (window.adsbygoogle) {
        console.log('clear adInterval had ad');
        pushAd();
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(adInterval);
      }
    }, 300); */

   // return () => {
  //    clearInterval(adInterval)
  //  }

    // (window.adsbygoogle = window.adsbygoogle || []).push({});

    console.log('componentDidMount test 2');
    setScreenView('rooms');
    // setScreenView('rooms');
    this.handleResize();
    resetError();
    if (!gameTypeFilterMap || !gameBetFilterMap) {
      this.setState({ gameTypeFilterMap: { '-': t('common.all'), ...GameTypeMap }, gameBetFilterMap: { '-': t('common.all'), ...GameBetMap } });
    }

    const roomsArray = [];

    if (rooms) {
      this.setState({ prevRooms: { ...rooms } });

      Object.keys(rooms).map((key) => {
        roomsArray.push({
          filled: this._roomFilled(rooms[key]),
          key,
          ...rooms[key],
        });
        return null;
      });

      if (roomsArray && roomsArray.length > 0) {
        // let typeFilteredRooms = roomsArray;
        // let betFilteredRooms = [];

        // if (typeFilter === 'P') {
        //   typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'P' && !room.globalParams.smallGame);
        // } else if (typeFilter === 'PM') {
        //   typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'P' && room.globalParams.smallGame);
        // } else if (typeFilter === 'G') {
        //   typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'G' && !room.globalParams.smallGame);
        // } else if (typeFilter === 'MG') {
        //   typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.gameType === 'G' && room.globalParams.smallGame);
        // } else if (typeFilter === 'Privāta') {
        //   typeFilteredRooms = roomsArray.filter(room => room.globalParams && room.globalParams.privateRoom);
        // }

        // if (!betFilter || betFilter === '-') {
        //   betFilteredRooms = typeFilteredRooms;
        // } else {
        //   betFilteredRooms = typeFilteredRooms.filter(room => room.globalParams && room.globalParams.bet === betFilter);
        // }

        this.setState({ rooms: [...roomsArray] });
      }
    }

    if (rooms) {
      let joinedRoom;

      if (joinedRooms) {
        Object.keys(joinedRooms).map((joinedRoomKey) => {
          joinedRoom = { ...rooms[joinedRoomKey], key: joinedRoomKey } || null;
          return null;
        });
      }

    //  let lastPlayerChangeModalClosed = false;

     // if (joinedRoom && joinedRoom.playersList && Object.keys(joinedRoom.playersList).length <= 2 && joinedRoom.lastPlayerChange && (joinedRoom.lastPlayerChange + offset) < (Date.now() - (1000 * 60 * 5))) {
     //   lastPlayerChangeModalClosed = true;
     // }

    //  console.log('set initial lastPlayerChangeModalClosed to', lastPlayerChangeModalClosed);

     // this.setState({ joinedRoom, lastPlayerChangeModalClosed });
    }

    fetchFriends();

    try {
      window.addEventListener('resize', this.handleResize);
    } catch (err) {
      console.log(err);
    }


  }

  componentWillReceiveProps(nextProps) {
    const {
      t, rooms, joinedRooms, soundOn,
    } = nextProps;

    const {
      gameTypeFilterMap,
      gameBetFilterMap,
      privateRoomConfirm,
      typeFilter,
      betFilter
    } = this.state;

    if (!gameTypeFilterMap || !gameBetFilterMap) {
      this.setState({ gameTypeFilterMap: { '-': t('common.all'), ...GameTypeMap }, gameBetFilterMap: { '-': t('common.all'), ...GameBetMap } });
    }

    this.filterRooms(rooms, typeFilter, betFilter);

    if (rooms) {
      let joinedRoom;

      if (joinedRooms) {
        Object.keys(joinedRooms).map((joinedRoomKey) => {
          if (rooms[joinedRoomKey]) {
            joinedRoom = {
              filled: this._roomFilled(rooms[joinedRoomKey]),
              key: joinedRoomKey,
              ...rooms[joinedRoomKey],
            };
          }
          return null;
        });
      }

      if (soundOn) {
        const { joinedRoom: joinedRoomState } = this.state;
        if (joinedRoomState && joinedRoomState.playersList && joinedRoom && joinedRoom.playersList) {
          const prevPl = joinedRoomState.playersList.playerList;
          const newPl = joinedRoom.playersList.playerList;

          if (!prevPl && newPl) {
          //  this.playerJoinedAudio.play();
          } else if (prevPl && newPl && Object.keys(prevPl).length < Object.keys(newPl).length) {
          //  this.playerJoinedAudio.play();
          }
        }
      }

      this.setState({ joinedRoom });

      if (joinedRoom && privateRoomConfirm) {
        this.setState({
          privateRoomConfirm: false
        })
      }
    //  console.log(joinedRoom, "checkJoinRoom")
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { t, joinRoomError, joinedRooms, resetError, campaignData, loggedInUserDataFetched, adsHidden } = this.props;
    const { errorModalIgnored, modalType, bannerSet, privateRoomConfirm } = this.state;


    if (!isEqual(prevState.tempTypeFilter, this.state.tempTypeFilter)) {
      this.checkOverflowTypeFilter()
    }
    if (!isEqual(prevState.tempBetFilter, this.state.tempBetFilter)) {
      this.checkOverflowBetFilter()
    }

    // if ((joinRoomError && joinRoomError.error && size(prevProps.joinRoomError.error) === 0)) {
    if (joinRoomError && joinRoomError.error) {
      console.log('set error', joinRoomError.error);

      if (joinRoomError.error === 'multiAccount' && privateRoomConfirm) {
        this.closePrivateRoomEnter();
      }
      if (joinRoomError.error === 'you ignored' && !errorModalIgnored) {
        this.setState({
          errorModalIgnored: true, errorRoomId: joinRoomError?.roomId, errorPassword: joinRoomError?.password, blockedUId: joinRoomError?.uid, errorModalText: t('notifications:youIgnoredPlayer'), errorModalHeader: t('notifications:IgnoreHeader'),
        });
      }
    }

    /* let adsense = true;

    if (adsense) {
      const scriptTag = document.createElement("script");

      scriptTag.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1751018231962739';
      scriptTag.async = true;

      console.log('campaignData[0]?.adsense');

      scriptTag.addEventListener('load', () => {
        // this.setState({ bannerSet: true });
        console.log('adsense loaded');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });

      const bannerRef4 = document.getElementById('bannerRef2');

      if (bannerRef4 && campaignData[0]?.externalBanner) {
        bannerRef4.appendChild(scriptTag);
        // this.setState({ bannerSet: true });
      }
    } */

    if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
      if (!bannerSet && campaignData && campaignData[0] && campaignData[0]?.externalBanner) {
        const scriptTag = document.createElement("script");

        let targetLink2;

        if (campaignData[0]?.adFormMeasure) {
          // const targetLink2 = `${campaignData[0]?.externalBanner}?redirect=${campaignData[0]?.adFormMeasure};C=0`;

          targetLink2 = `${campaignData[0]?.externalBanner}${campaignData[0]?.adFormMeasure}`;

          // console.log('targetLink2', targetLink2);

          scriptTag.src = targetLink2;
          scriptTag.async = true;
        } else {
          scriptTag.src = campaignData[0]?.externalBanner;
          scriptTag.async = true;
        }

        // console.log('campaignData[0]?.externalBanner', campaignData[0]?.externalBanner);

        scriptTag.addEventListener('load', () => {
          const promise1 = new Promise((r) => setTimeout(r, 1000));
          // console.log('scriptTag load inputs 1');

          try {
            Promise.all([promise1]).then(() => {
              const inputs = document.getElementsByTagName('iframe');
              let bannerIframe = null;

              // console.log('scriptTag load inputs 2', inputs);

              // console.log('inputs.length', inputs.length);
              if (inputs) {
                for (var i = 0; i < inputs.length; i++) {
                  // console.log('input', inputs[i].clientHeight, inputs[i].clientWidth, inputs[i].offsetHeight);

                  if (inputs[i].clientHeight === 120 && inputs[i].clientWidth === 920) {
                    bannerIframe = inputs[i];
                  }
                }
              }
              // console.log('scriptTag load bannerIframe', bannerIframe);
            //  console.log('scriptTag load bannerIframe document', bannerIframe.document);
            //  console.log('scriptTag load bannerIframe contentDocument', bannerIframe.contentDocument);
              if (bannerIframe && bannerIframe.contentDocument) {
                // bannerIframe.document.addEventListener("click", this.handleClickCampaignExternal2);

                // console.log('bannerIframe.contentDocument', bannerIframe.contentDocument);

                bannerIframe.contentDocument.body.addEventListener('mousedown', this.handleClickCampaignExternal2);
              } else if (bannerIframe && bannerIframe.document) {
                // console.log('bannerIframe.document', bannerIframe.document);

                bannerIframe.document.addEventListener("click", this.handleClickCampaignExternal2);
              }
            });
          } catch (err) {
            console.log(err);
          }

          this.setState({ bannerSet: true });
        });

        const bannerRef3 = document.getElementById('bannerRef');

        if (bannerRef3 && campaignData[0]?.externalBanner) {
          bannerRef3.appendChild(scriptTag);
          this.setState({ bannerSet: true });
        }
      }
    }
  }

  componentWillUnmount() {
    if (this.timeoutID) clearTimeout(this.timeoutID);
    window.removeEventListener('resize', this.handleResize);

    // window.removeEventListener("click", this.handleClickCampaignExternal2, false);
  }

  _roomFilled = (room) => {
    if (room.playersList && room.globalParams) {
      if (!room.globalParams.fourPRoom
        && room.playersList.player1 && room.playersList.player1.uid
        && room.playersList.player2 && room.playersList.player2.uid
        && room.playersList.player3 && room.playersList.player3.uid) {
        return true;
      } if (room.globalParams.fourPRoom
      && room.playersList.player1 && room.playersList.player1.uid
      && room.playersList.player2 && room.playersList.player2.uid
      && room.playersList.player3 && room.playersList.player3.uid
      && room.playersList.player4 && room.playersList.player4.uid) {
        return true;
      }
      return false;
    }
    return false;
  }
  /*  room.playersList && room.globalParams && ((room.globalParams.fourPRoom
      && room.playersList.player1 && room.playersList.player1.uid
      && room.playersList.player2 && room.playersList.player2.uid
      && room.playersList.player3 && room.playersList.player3.uid
      && room.playersList.player4 && room.playersList.player4.uid) || (
    !room.globalParams.fourPRoom
      && room.playersList.player1 && room.playersList.player1.uid
      && room.playersList.player2 && room.playersList.player2.uid
      && room.playersList.player3 && room.playersList.player3.uid)
    }) */

filterRooms = (rooms, passedTypeFilter, passedBetFilter) => {
  const { friends } = this.props;
  const {
    rooms: lastRooms,
    prevRooms,
    privateRoomId,
  } = this.state;
  const friendsIds = size(friends) > 0 ? map(friends, (item, key) => { return size(item) > 0 ? item.uid : null; }) : [];

  if (Object.keys(rooms).length > 0 || Object.keys(prevRooms).length > 0) {
    if (privateRoomId) {
      const joinedPrivateRoom = rooms[privateRoomId];

      if (!joinedPrivateRoom) {
        this.setState({
          password: [], privateRoomConfirm: false, privateRoomId: '', privateRoomConfirmError: '',
        });
      }
    }

    const exRooms = {};
    const newRooms = { ...rooms };

    //  for (const roomKey in prevRooms) {

    Object.keys(prevRooms).map((roomKey) => {
      if (!rooms[roomKey]) {
        exRooms[roomKey] = prevRooms[roomKey];
        exRooms[roomKey].removal = true;

        this.timeoutID = setTimeout(() => {
          const { prevRooms: prevRooms2 } = this.state;
          delete prevRooms2[roomKey];

          const remainingRooms = lastRooms.filter(_room => _room.key !== roomKey);

          // >>>>>>>>>>>>>
          // this.setState({
          //   rooms: [...remainingRooms],
          //   prevRooms: { ...prevRooms2 },
          // });
        }, 2000);
      } else {
        exRooms[roomKey] = { ...rooms[roomKey], removal: this._roomFilled(rooms[roomKey]) };
      //  exRooms[roomKey].removal = this._roomFilled(rooms[roomKey]);
      }

      delete newRooms[roomKey];
      return null;
    });
    //  }

    const allRooms = { ...exRooms, ...newRooms };

    const roomsArray = [];

    Object.keys(allRooms).map((key) => {
      if (allRooms[key].filled === true) {
        return null;
      }

      roomsArray.push({
        filled: allRooms[key].removal || this._roomFilled(allRooms[key]),
        key,
        ...allRooms[key],
      });
      return null;
    });

    //  this.state.prevRooms = { ...allRooms };
    //  this.setState({ prevRooms: { ...allRooms } });

    if (!isEqual(prevRooms, allRooms)) {
      this.setState({ prevRooms: { ...allRooms } });
    }
  
    let filteredRooms = [...roomsArray];
    if (roomsArray && roomsArray.length > 0) {

      const allTypes = ["P", "PM", "G", "MG", "-"];
      const isPrivateFilterEnabled = size(passedTypeFilter) > 0 && passedTypeFilter.includes('Privāta');
      const isOnlyPrivateFilterEnabled = isPrivateFilterEnabled && passedTypeFilter.length === 1;
      let selectedFilter = [...passedTypeFilter];

      if (passedTypeFilter && passedTypeFilter.includes('Friends')) {
        selectedFilter.splice(selectedFilter.indexOf('Friends'), 1);
      }

      if (size(selectedFilter) > 0 && !selectedFilter.includes("-")) {
        
        filteredRooms = roomsArray.filter(room => selectedFilter.includes(getRoomGameType(room)) || isOnlyPrivateFilterEnabled);
        if (isPrivateFilterEnabled) {
          filteredRooms = filteredRooms.filter(room => room.globalParams && room.globalParams.privateRoom);
        }
      }

      if (passedTypeFilter && size(passedTypeFilter) > 0) {
        if (passedTypeFilter && passedTypeFilter.includes('Friends')) {
          filteredRooms = filter(filteredRooms, (item, key) => {
            if (item?.playersList) {
              return (item.playersList.player1 && friendsIds.includes(item.playersList?.player1?.uid)) || (item.playersList.player2 && friendsIds.includes(item.playersList.player2.uid)) || (item.playersList.player3 && friendsIds.includes(item.playersList.player3.uid));
            }
          });
        }
      }
      
      if (size(passedBetFilter) > 0 && !passedBetFilter.includes('-')) {
        filteredRooms = filteredRooms.filter(room => passedBetFilter.includes(room.globalParams?.bet));
      }
    }

    if (!isEqual(lastRooms, filteredRooms)) {
      this.setState({ rooms: [...filteredRooms] });
    }
  }
}

  filterType = (gameTypeKey) => {
    const { tempTypeFilter, betFilter } = this.state;
    this.playButtonSound();
    let showTypeFilter = [...tempTypeFilter];
  
    // gameTypeKey - selected game mode (P, PM, G, MG, - (ALL) )
    if (gameTypeKey === "-") {
      showTypeFilter = ["-"];
    } else {
      const index = tempTypeFilter.indexOf(gameTypeKey);

      if (index === -1) {
        // if selected mode is not in the array
        // delete incompatible filter elements and add current element
        if (gameTypeKey === "P" || gameTypeKey === "PM") {
          showTypeFilter = showTypeFilter.filter((gameType) => gameType !== "G" && gameType !== "MG")
          showTypeFilter.push(gameTypeKey);
        } else if (gameTypeKey === "G" || gameTypeKey === "MG") {
          showTypeFilter = showTypeFilter.filter((gameType) => gameType !== "P" && gameType !== "PM")
          showTypeFilter.push(gameTypeKey);
        } else {
          const indexOfPrivateType = showTypeFilter.indexOf(gameTypeKey)
          if (indexOfPrivateType !== -1) showTypeFilter.splice(indexOfPrivateType, 1)
          showTypeFilter.unshift(gameTypeKey);
        }

        const allGamesTypeIndex = showTypeFilter.indexOf("-");
        if (allGamesTypeIndex !== -1) showTypeFilter.splice(allGamesTypeIndex, 1);
      } else { // if it's in the array, remove it
        showTypeFilter.splice(index, 1);
        if (size(showTypeFilter) === 0) {
          showTypeFilter = ["-"];
        }
      }
    }

    this.setState({
      tempTypeFilter: showTypeFilter,
    });
  }

  filterBet = (betKey) => {
    const { level } = this.props;
    const { tempBetFilter, typeFilter } = this.state;
    this.playButtonSound();
    let showBetFilter = [...tempBetFilter];
    if (betKey === "-") {
      showBetFilter = ["-"];
    } else {
      const index = tempBetFilter.indexOf(betKey);

      if (index === -1) {
        showBetFilter.push(betKey);
        if (tempBetFilter.indexOf("-") !== -1) {
          showBetFilter.splice(tempBetFilter.indexOf("-"), 1);
        }
      } else {
        showBetFilter.splice(index, 1);
        if (size(showBetFilter) === 0) {
          showBetFilter = ["-"];
        }
      }
    }
    
    // if (level && level >= 3 && (size(GameBetMap) - 2 === size(showBetFilter))) {
    //   showBetFilter = ["-"];
    // } else if (level && level < 3 && size(GameBetMap) === size(showBetFilter)) {
    //   showBetFilter = ["-"];
    // }
    if (size(GameBetMap) === size(showBetFilter)) {
      showBetFilter = ["-"];
    }

    this.setState({
      tempBetFilter: showBetFilter,
    });
  }

  closePrivateRoomEnter = () => {
    this.setState({
      password: [], privateRoomConfirm: false, privateRoomId: '', privateRoomConfirmError: '',
    });
  }

  handleDelete = (e) => {
    const { password, privateRoomConfirm } = this.state;

    if (e.target) {
      const { value, name } = e.target;

      let newVal;

      if (e.keyCode === 8) {
        newVal = null;
      } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
        if (e.keyCode === 48 || e.keyCode === 96) {
          newVal = 0;
        } else if (e.keyCode === 49 || e.keyCode === 97) {
          newVal = 1;
        } else if (e.keyCode === 50 || e.keyCode === 98) {
          newVal = 2;
        } else if (e.keyCode === 51 || e.keyCode === 99) {
          newVal = 3;
        } else if (e.keyCode === 52 || e.keyCode === 100) {
          newVal = 4;
        } else if (e.keyCode === 53 || e.keyCode === 101) {
          newVal = 5;
        } else if (e.keyCode === 54 || e.keyCode === 102) {
          newVal = 6;
        } else if (e.keyCode === 55 || e.keyCode === 103) {
          newVal = 7;
        } else if (e.keyCode === 56 || e.keyCode === 104) {
          newVal = 8;
        } else if (e.keyCode === 57 || e.keyCode === 105) {
          newVal = 9;
        }
         newVal = value.charAt(value.length - 1);
      } else {
        return;
      }

      if (!newVal || !isNaN(newVal)) {
        if (newVal || newVal === 0) {
          password[name] = newVal.toString();
        } else {
          password[name] = null;
        }

        if (newVal || newVal === 0) {
          if (name === 0 || name === '0') {
            if (this.digit2) {
              this.digit2.current.focus();
            }
          }
          if (name === 1 || name === '1') {
            if (this.digit3) {
              this.digit3.current.focus();
            }
          }
          if (name === 2 || name === '2') {
            if (this.digit4) {
              this.digit4.current.focus();
            }
          }
        } else {
          if (name === 1 || name === '1') {
            if (this.digit1) {
              if (value || value === 0 || value === '0') {
                this.digit2.current.focus();
              } else {
                this.digit1.current.focus();
              }
            }
          }
          if (name === 2 || name === '2') {
            if (this.digit2) {
              if (value || value === 0 || value === '0') {
                this.digit3.current.focus();
              } else {
                this.digit2.current.focus();
              }
            }
          }
          if (name === 3 || name === '3') {
            if (this.digit3) {
              if (value || value === 0 || value === '0') {
                this.digit4.current.focus();
              } else {
                this.digit3.current.focus();
              }
            }
          }
        }

        this.setState({
          password,
        });

        if (privateRoomConfirm) {
          if (this.digit1.current && (!password[0] || password[0] === '')) {
            this.digit1.current.focus();
          } else if (password[0] && password[0] !== '' && password[1] && password[1] !== '' && password[2] && password[2] !== '' && password[3] && password[3] !== '') {
            this.joinPrivateRoomClicked(password);
            // this.setState({password: []});
          }
        }
      }
    }
  }

  joinPrivateRoomConfirm = (roomId) => {
    console.log("joinPrivateRoomConfirm: ", this.props);
    const { activeTournament, finishedTournamentLastRound, joinedRoom, joinedRooms } = this.props;
    const { joinRoomClicked } = this.state;

    if (joinRoomClicked || (activeTournament && !finishedTournamentLastRound) || joinedRoom) {
      return null;
    }

    this.setState({
      privateRoomConfirm: (!joinedRooms),
      privateRoomId: roomId,
      privateRoomConfirmError: '',
    });

    return null;
  }
  onPrivateRoomModalOpen = () => {
    if (this.digit1) {
      this.digit1.current.focus();
    }
  }

  joinPrivateRoomClicked = async () => {
    const {
      joinRoom, rooms, activeTournament, finishedTournamentLastRound, joinedRoom, t, joinRoomError, lvl,
    } = this.props;
    const { privateRoomId, password, joinRoomClicked } = this.state;

    if (rooms[privateRoomId].globalParams.proGame && lvl < 10) {
      this.setState({
        joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:needLevelForProJoin'), errorModalHeader: t('game:message'), privateRoomConfirm: false,
      });
      return null;
    }
    if (joinRoomClicked || (activeTournament && !finishedTournamentLastRound) || joinedRoom) {
      return null;
    }

    const checkTournament = await this.checkTournamentLine();
    if (checkTournament) {
      this.setState({
        joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
      });
      return null;
    }
console.log(password, "password: ")
    if (password && password.length === 4) {
      this.setState({ joinRoomClicked: true });

      const passString = password.join('');

      let fourPRoomVar = false;

      if (rooms[privateRoomId] && rooms[privateRoomId].globalParams) {
        fourPRoomVar = rooms[privateRoomId].globalParams.fourPRoom;
      }

      this.playButtonSound();

      await joinRoom({ roomId: privateRoomId, password: passString, fourPRoom: fourPRoomVar }).then((res) => {


        console.log(res, joinedRoom, joinRoomError, "privateStatus")
        this.setState({ joinRoomClicked: false });
        if (res && res.status === 'success') {
          this.setState({ privateRoomConfirm: false });
        } else if (res && res.status === 'error') {
          if (res.error === 'wrong password') {
            this.setState({ privateRoomConfirmError: t('roomsTable.wrongCode') });
          } else if (res.error === 'room closed') {
            this.setState({ privateRoomConfirmError: t('roomsTable.roomClosed') });
          } else if (res.error === 'position taken') {
            this.setState({ privateRoomConfirmError: t('roomsTable.placeTaken') });
          } else {
            this.setState({ privateRoomConfirmError: t('roomsTable.joinError') });
          }
        }
      }).catch(() => {
        this.setState({ joinRoomClicked: false });
      });

    //  setTimeout(() => {
    //    this.setState({ joinRoomClicked: false });
      //  this.closePrivateRoomPassword();
    //  }, 750);
    } else {
      console.log('no password');
    }

    return null;
  }

  showPrivateRoomPassword = () => {
    const { privateRoomPassword } = this.props;

    if (privateRoomPassword) {
      this.setState({
        showPrivateRoomPassword: true,
      });
    }
  }

  closePrivateRoomPassword = () => {
    this.setState({
      showPrivateRoomPassword: false,
    });
  }


  joinRoomClicked = async (roomId) => {
    const { joinRoom, rooms, activeTournament, finishedTournamentLastRound, t, lvl } = this.props;
    const { joinRoomClicked, joinedRoom } = this.state;

    if (rooms[roomId] && rooms[roomId].globalParams && rooms[roomId].globalParams.proGame && lvl < 10) {
      this.setState({
        joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:needLevelForProJoin'), errorModalHeader: t('game:message'),
      });
      return null;
    }

    if (joinRoomClicked || (activeTournament && !finishedTournamentLastRound) || joinedRoom) {
      return null;
    }

    const checkTournament = await this.checkTournamentLine();
    if (checkTournament) {
      this.setState({
        joinRoomClicked: false, errorModalOpen: true, errorModalText: t('notifications:inActiveTournament'), errorModalHeader: t('common:home.confirm'),
      });
      return null;
    }

    let betVar = null;
    let fourPRoomVar = false;
    if (rooms[roomId] && rooms[roomId].globalParams) {
      betVar = rooms[roomId].globalParams.bet;
      fourPRoomVar = rooms[roomId].globalParams.fourPRoom;
    }

  //  console.log('joinRoomClicked', { fourPRoomVar, room: rooms[roomId], betVar });

    this.setState({ joinRoomClicked: true });
    this.playButtonSound();

    await joinRoom({
      roomId, password: '', betVar, fourPRoom: fourPRoomVar,
    }).then(() => {
      this.setState({ joinRoomClicked: false });
    }).catch((err) => {
    //  console.log('join room response', { err });
      this.setState({ joinRoomClicked: false });
    });

    //  setTimeout(() => {
    //    this.setState({ joinRoomClicked: false });
    //  }, 750);

    return null;
  }

  toggleGameTypeFilter = () => {
    this.setState(prevState => ({
      gameTypeFilterOpen: !prevState.gameTypeFilterOpen,
      typeFilter: this.state.tempTypeFilter,
    }));
    this.filterRooms(this.props.rooms, this.state.tempTypeFilter, this.state.tempBetFilter);
  }

  toggleGameBetFilter = () => {
    this.setState(prevState => ({
      gameBetFilterOpen: !prevState.gameBetFilterOpen,
      betFilter: this.state.tempBetFilter,
    }));
    this.filterRooms(this.props.rooms, this.state.tempTypeFilter, this.state.tempBetFilter);
  }

  updateScrollPos = (val) => {
    this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  onHover = (id) => {
    const { activeTournament, finishedTournamentLastRound } = this.props;
    const { popoverOpen, tooltipId } = this.state;

    if (popoverOpen && !popoverOpen[id]) {
      this.setState(prevState => ({
        popoverOpen: {
          ...prevState.popoverOpen,
          [id]: true,
        },
        tooltipId: `join-room-button-${id}`,
      }));
    }
  }

  onHoverLeave = (id) => {
    this.setState(prevState => ({
    //  popoverOpen: null,
      popoverOpen: {
      },
      tooltipId: null,
    }));
  }

  playButtonSound = () => {
    const { soundOn, uid } = this.props;

  //  if (userSettings && uid) {
  //    const userSettings2 = userSettings[uid] || userSettings.default || {};

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(_ => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
      }
  //  }
  }

  checkTournamentLine = () => {
    const { tournaments, myTournamentsData, offset } = this.props;

    if (size(myTournamentsData) > 0) {
      if (size(tournaments) > 0) {

        if (myTournamentsData.tournamentId) {
          const curTournament = tournaments.find(item => item.id === myTournamentsData.tournamentId);
         
          if(curTournament && curTournament.currentState === 'registration') {
            if (curTournament && curTournament.startDate && curTournament.startDate - (1000 * 60 * 5) < (Date.now() + offset)) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  closeErrorModal = () => {
    this.setState({
      errorModalOpen: false, errorModalText: null, errorModalHeader: null, errorModalIgnored: false,
    });
  }

  closeJoinErrorModal = () => {
    const { resetError } = this.props;

    this.setState({
      errorModalText: null, errorModalHeader: null, errorModalIgnored: false,
    });
    resetError();
  }

  joinUnBlockFunc = () => {
    const { rooms, unBlockUserFunc } = this.props;
    const { errorRoomId, errorPassword, blockedUId } = this.state;

    if (blockedUId) {
      unBlockUserFunc(blockedUId).then(res => {
        this.closeErrorModal();
        if (res.status === FROUNT_SIDE_STATUS.success) {
          if (errorPassword) {
            this.joinPrivateRoomClicked();
          } else {
            this.joinRoomClicked(errorRoomId)
          }
        }
      });
    } else {
      if (errorPassword) {
        this.joinPrivateRoomClicked();
      } else {
        this.joinRoomClicked(errorRoomId)
      }
    }

    this.closeJoinErrorModal();
  }

  handleClickCampaign = (event, targetLink, adFormMeasure, campaignKey) => {
    const { clickACampaign, devMode } = this.props;

    console.log('handleClickCampaign');

    if (event.target === event.currentTarget) {
      console.log('parent clicked');
    }

    if (adFormMeasure) {
     // const targetLink2 = `${targetLink}?redirect=${adFormMeasure};C=0`;

    //  console.log('targetLink2 222', targetLink2);

    //  window.open(targetLink2, '_blank');
      clickACampaign(campaignKey, 'lobby', 'old', devMode);
    } else {
    //  window.open(targetLink, '_blank');
      clickACampaign(campaignKey, 'lobby', 'old', devMode);
    }
  }

  handleClickCampaignExternal2 = () => {
    const { clickACampaign, devMode, campaignData } = this.props;

    // console.log('handleClickCampaignExternal2');

    if (campaignData && campaignData[0] && campaignData[0]?.key) {
      clickACampaign(campaignData[0]?.key, 'lobby', 'old', devMode);
    }
  }

  handleChangeDigit = (e) => {
    const { password } = this.state;

    // console.log('handleChangeDigit', password);

    const newPassword = [...password];

    if (e.target) {
      const { value, name } = e.target;

      const newVal = value.charAt(value.length - 1);
      if (!isNaN(newVal) || !newVal) {
        newPassword[name] = newVal;

        if (newVal) {
          if (name === 0 || name === '0') {
            if (this.digit2) {
              this.digit2.current.focus();
            }
          }
          if (name === 1 || name === '1') {
            if (this.digit3) {
              this.digit3.current.focus();
            }
          }
          if (name === 2 || name === '2') {
            if (this.digit4) {
              this.digit4.current.focus();
            }
          }
        }

        this.setState({
          password: newPassword,
        });
      }
    }
  }

  handleDelete = (e) => {
    const { password } = this.state;
    if (e.target) {
      const { name } = e.target;
      
      if (e.key === 'Backspace' || e.key === 'Delete') {
        if ((name === 3 || name === '3') && !password[name]) {
          if (this.digit3) {
            this.digit3.current.focus();
          }
        }
        if ((name === 2 || name === '2') && !password[name]) {
          if (this.digit2) {
            this.digit2.current.focus();
          }
        }
        if ((name === 1 || name === '1') && !password[name]) {
          if (this.digit1) {
            this.digit1.current.focus();
          }
        }
      }
    }
  }

  handleClickCloseCampaign = () => {
    // console.log('handleClickCloseCampaign');
    this.setState({ campaignClosed: true });
  }

  setLastPlayerChangeModalClosed = () => {
    const { setLastPlayerChangeModalClosed } = this.props;

    setLastPlayerChangeModalClosed(true);

    // this.setState({ lastPlayerChangeModalClosed: true });
  }

  render() {
    const {
      uid,
      t,
      level,
      leaveRoom,
      privateRoomPassword,
      activeTournament,
      finishedTournamentLastRound,
      isFullscreen,
      fontMode,
      joinRoomError,
      campaignData,
      i18n,
      devMode,
      adsHidden,
      loggedInUserDataFetched,
      showIgnoredRooms,
      ignoredUsers,
      ignoredByUsers,
      offset,
      lastPlayerChangeModalClosed,
    } = this.props;

    const {
      joinRoomClicked,
      tempTypeFilter,
      typeFilter,
      tempBetFilter,
      betFilter,
      rooms,
      privateRoomConfirm,
      privateRoomConfirmError,
      password,
      joinedRoom,
      showPrivateRoomPassword,
      gameTypeFilterOpen,
      gameBetFilterOpen,
      gameTypeFilterMap,
      gameBetFilterMap,
      scrollPos,
      popoverOpen,
      errorModalOpen,
      errorModalText,
      errorModalHeader,
      errorModalIgnored,
      tooltipId,
      bannerHeight,
      campaignClosed,
      isOverflowingTypeFilter,
      isOverflowingBetFilter,
    } = this.state;
    let digitsArr = [];

    // console.log('finishedTournamentLastRound', finishedTournamentLastRound);

    if (showPrivateRoomPassword && privateRoomPassword) {
      digitsArr = privateRoomPassword.toString().split('').map(Number);
    }

  //  console.log("joinRoomError: ", campaignData)
    let campaignShow = false;

    if (loggedInUserDataFetched && (!adsHidden || adsHidden < Date.now())) {
      if (campaignData) {
        if (devMode && filter(campaignData.selectedEnvs, item => item.value === 'dev')[0]) {
          campaignShow = true;
        } else if (!devMode && filter(campaignData.selectedEnvs, item => item.value === 'prod')[0]) {
          campaignShow = true;
        }
        campaignShow = filter(campaignData[0]?.selectedDesigns, item => item?.value === 'old')[0] ? true : false;
      }
    }

    let roomsIgnoreFiltered = JSON.parse(JSON.stringify(rooms));

    if (!showIgnoredRooms && ignoredUsers && ignoredByUsers) {
      for (const room of rooms) {
        if (room.playersList && room.playersList.playerList) {
          const playerListKeys = Object.keys(room.playersList.playerList);
          for (const playerKey of playerListKeys) {
            if (ignoredUsers[playerKey] || ignoredByUsers[playerKey]) {
              roomsIgnoreFiltered = roomsIgnoreFiltered.filter((roomItem) => roomItem.key !== room.key);
              break;
            }
          }
        }
      }
    }

    return (
      <Fragment>
        <Helmet>
          <title>
            Zole - {t('route.roomsTable')}
          </title>
        </Helmet>
        <div className="rooms-table">
          <div className="rooms-table-header" ref={this.menuHeaderRef} style={{ minHeight: (!campaignClosed && campaignShow && campaignData && campaignData[0]) ? `${bannerHeight ? 115 + bannerHeight : 160}px` : 'unset' }} >
            <Row>
              <Col xs="5" sm="5">
                <Media src={roomsImg} className="rooms-table-header-image" />
                <div className="rooms-table-header-text">
                  {t('roomsTable.chooseRoom')}
                </div>
              </Col>
              <Col xs="12" sm="7">
                <Row>
                  <Col xs="4" sm="6" className="rooms-table-select-type">
                    {gameTypeFilterMap && (
                      <>
                        <Dropdown isOpen={gameTypeFilterOpen} toggle={this.toggleGameTypeFilter} id="game-type-filter-id">
                          <DropdownToggle caret>
                            <div className="item-wrapper" ref={this.textRefTypeFilter}>
                              {
                                tempTypeFilter && tempTypeFilter.map((item, idx) => (
                                  <span className="filter-room-type-menu">
                                    {item === '-'
                                      ? t('common.gameType')
                                      : (item === 'Privāta' 
                                        ? t('common.private')
                                        : (item === 'Friends'
                                          ? t('route.friends')
                                          : gameTypeFilterMap[item]))
                                    }
                                  </span>
                                ))
                              }
                            </div>
                          </DropdownToggle>
                          <DropdownMenu>
                            {Object.keys(gameTypeFilterMap).map(gtKey => (
                              <DropdownItem toggle={false} key={gtKey} className={tempTypeFilter.includes(gtKey) ? "filter-room-type-option" : ''} onClick={() => { this.filterType(gtKey); }}>
                                {gtKey === '-' ? t('common.all') : (gtKey === 'Privāta' ? t('common.private') : gtKey === 'Friends' ? t('route.friends') :  gameTypeFilterMap[gtKey])}
                              </DropdownItem>
                            ))}
                            <DropdownItem toggle={false} className={"filter-room-type-option-ok"} onClick={this.toggleGameTypeFilter}>
                              {t('common.ok')}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        { (isOverflowingTypeFilter) && (
                          <UncontrolledTooltip
                            className={`player-receivedGift-tooltip-old ${gameTypeFilterOpen && 'd-none'}`}
                            placement="top"
                            target="game-type-filter-id"
                            container={'div > div > div > div'}
                            // trigger="click" // use for debug only
                          >
                            <div className="player-receivedGift-tooltip-from" style={{wordWrap: 'normal'}}>
                              {
                                tempTypeFilter && tempTypeFilter.map((item, idx) => (
                                  <span className="filter-room-type-menu mr-2">
                                    {item === '-'
                                      ? t('common.gameType')
                                      : (item === 'Privāta' 
                                        ? t('common.private')
                                        : (item === 'Friends'
                                          ? t('route.friends')
                                          : gameTypeFilterMap[item]))
                                    }
                                  </span>
                                ))
                              }
                            </div>
                          </UncontrolledTooltip>
                        )}
                      </>
                    )}


                  </Col>
                  <Col xs="4" sm="6" className="rooms-table-select-bet">
                    {gameBetFilterMap && (
                      <>
                        <Dropdown isOpen={gameBetFilterOpen} toggle={this.toggleGameBetFilter} id="game-bet-filter-id">
                          <DropdownToggle caret>
                            <div className="item-wrapper" ref={this.textRefBetFilter}>
                              {
                                tempBetFilter && tempBetFilter.map((item, idx) => (
                                  <span className="filter-room-type-menu">{(item === '-' ? t('common.bet') : gameBetFilterMap[item])}</span>
                                ))
                              }
                            </div>
                          </DropdownToggle>
                          <DropdownMenu>
                            {Object.keys(gameBetFilterMap).map((bKey) => {
                              if (level && level >= 3 && (bKey === '1:1' || bKey === '1:5')) {
                                return null;
                              }
                              return (
                                <DropdownItem toggle={false} key={bKey} className={tempBetFilter.includes(bKey) ? "filter-room-type-option" : ''} onClick={() => { this.filterBet(bKey); }}>
                                  {bKey === '-' ? t('common.all') : gameBetFilterMap[bKey]}
                                </DropdownItem>
                              );
                            })}
                            <DropdownItem toggle={false} className={"filter-room-type-option-ok"} onClick={this.toggleGameBetFilter}>
                              {t('common.ok')}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        { (isOverflowingBetFilter) && (
                          <UncontrolledTooltip
                            className={`player-receivedGift-tooltip-old ${gameBetFilterOpen && 'd-none'}`}
                            placement="top"
                            target="game-bet-filter-id"
                            container={'div > div > div > div'}
                            // trigger="click" // use for debug only
                          >
                            <div className="player-receivedGift-tooltip-from" style={{wordWrap: 'normal'}}>
                              {
                                tempBetFilter && tempBetFilter.map((item, idx) => (
                                  (item === '-' ? t('common.bet') : gameBetFilterMap[item] + ' ')
                                ))
                              }
                            </div>
                          </UncontrolledTooltip>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>

            <div width="100%" style={{ width: '100%', cursor: 'pointer', position: 'relative' }}>
              {/* <ins className="adsbygoogle"
                style={{ display: 'block', maxHeight: '50px' }}
                data-ad-client="ca-pub-1751018231962739"
                data-ad-slot="8909520870"
                data-ad-format="auto"
                data-full-width-responsive="true">
              </ins> */}
              <div
                id="bannerRef"
                // style={{ overflow: 'hidden' }}
                style={{
                  overflow: 'hidden',
                  cursor: 'default',
                  height: (!campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner) ? '120px' : '0px',
                  display: (!campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner) ? 'block' : 'none',
                }}
              />
              {(!campaignClosed && campaignShow && campaignData && campaignData[0] && campaignData[0]?.externalBanner) ? (
                <Media className="lobby-banner-close" src={mobileCloseImg} alt="X" onClick={() => this.handleClickCloseCampaign()} />
              ) : (null)}
              {/* <div
                onClick={(e) => this.handleClickCampaignExternal(e, campaignData[0]?.key)}
                style={{ position: 'absolute', width: '100%', height: '0px', pointerEvents: 'none', overflow: 'visible', top: 0 }}
              /> */}
            </div>
            <div ref={this.bannerRef} className='mt-2' style={{ marginLeft: '-15px', marginRight: '0px' }} >
              {(!campaignClosed && campaignShow && campaignData && campaignData[0] && !campaignData[0]?.externalBanner) && (
              // campaignData[0].selectedDesigns?.old && filter(campaignData[0]?.selectedPlatforms, item => item.value.toLowerCase() === 'openRoom')[0] && (
                <a onClick={(e) => this.handleClickCampaign(e, campaignData[0]?.pageToOpen, campaignData[0]?.adFormMeasure, campaignData[0]?.key)} width="100%" style={{ width: '100%', cursor: 'pointer' }} >
                  <Media ref={this.imageBannerRef} style={{ objectFit: 'contain' }} src={i18n.language === 'lv' ? campaignData[0].imageUrl : i18n.language === 'en' ? campaignData[0].imageUrlEn : i18n.language === 'ru' ? campaignData[0].imageUrlRu : campaignData[0].imageUrl} width="100%" alt="" />
                </a>
              )}
              {(!campaignClosed && campaignShow && campaignData && campaignData[0] && !campaignData[0]?.externalBanner) ? (
                <Media className="game-banner-close" src={mobileCloseImg} alt="X" onClick={() => this.handleClickCloseCampaign()} />
              ) : (null)}
            </div>
          </div>
          <div className="rooms-table-wrapper">
            {getActiveRoomsCountOld(rooms) !== 0 ? (
              <div className="rooms-table-wrapper-inner old-design-scrollbar">
                <table className="rooms-table-table">
                  <colgroup>
                    <col span="1" className="" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th style={{ width: '7%' }} />
                      <th style={{ width: '5%' }} />
                      <th style={{ width: '5%' }} />
                      <th style={{ width: '4%' }} />
                      <th style={{ width: '4%' }} />
                      <th style={{ width: '4%' }} />
                      <th style={{ width: '4%' }} />
                      <th style={{ width: '67%' }} />
                    </tr>
                  </thead>
                  <tbody className="rooms-table-body">
                    <RoomsTableJoinedRender
                      rooms={roomsIgnoreFiltered}
                      joinedRoom={joinedRoom}
                      uid={uid}
                      leaveRoom={leaveRoom}
                      activeTournament={activeTournament}
                      finishedTournamentLastRound={finishedTournamentLastRound}
                      popoverOpen={popoverOpen}
                      joinRoomClicked={joinRoomClicked}
                    />
                    <RoomsTableRender
                      rooms={roomsIgnoreFiltered}
                      joinedRoom={joinedRoom}
                      uid={uid}
                      leaveRoom={leaveRoom}
                      activeTournament={activeTournament}
                      finishedTournamentLastRound={finishedTournamentLastRound}
                      popoverOpen={popoverOpen}
                      joinRoomClicked={joinRoomClicked}
                      onHover={this.onHover}
                      onHoverLeave={this.onHoverLeave}
                      joinRoomClickedFunc={this.joinRoomClicked}
                      joinPrivateRoomConfirm={this.joinPrivateRoomConfirm}
                      fontMode={fontMode}
                      tooltipId={tooltipId}
                    />
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="d-flex align-items-center w-100 h-100 rooms-table-empty-entries-old">{t('roomsTable.roomsTableEmptyEntries')}</div>
            )}

          </div>
          <RoomsTableFooter isFullscreen={isFullscreen} />

          {/*  {console.log('joinedRoom.lastPlayerChange', joinedRoom ? joinedRoom.lastPlayerChange : 'no joinedRoom', Date.now(), offset, joinedRoom ? (joinedRoom.lastPlayerChange - offset) : 'no joinedRoom')}

          {console.log(joinedRoom ? (joinedRoom.lastPlayerChange - offset) < (Date.now() - (1000 * 60 * 5)) : '')}
          {console.log('offset', offset, offset / 1000)} */}

          <Modal
            container={'div > div'}
            isOpen2={!lastPlayerChangeModalClosed && joinedRoom && joinedRoom.playersList && Object.keys(joinedRoom.playersList).length <= 2 && joinedRoom.lastPlayerChange && (joinedRoom.lastPlayerChange - offset) < (Date.now() - (1000 * 60 * 5))}
            isOpen={!lastPlayerChangeModalClosed && joinedRoom && joinedRoom.globalParams
              && (joinedRoom.globalParams && (joinedRoom.globalParams.bet === '1:250' || joinedRoom.globalParams.bet === '1:500' || joinedRoom.globalParams.bet === '1:1000' || joinedRoom.globalParams.bet === '1:5000' || joinedRoom.globalParams.bet === '1:10000'))
              && joinedRoom.playersList && Object.keys(joinedRoom.playersList).length <= 2 && joinedRoom.lastPlayerChange && (joinedRoom.lastPlayerChange - offset) < (Date.now() - (1000 * 60 * 5))}
            toggle={this.setLastPlayerChangeModalClosed}
            className={`notification old-modal root-font-${fontMode}`}
          >
            <ModalHeader
              className="notification-header"
              close={(
                <Media
                  src={closeImg}
                  className="notification-header-close"
                  alt="X"
                  onClick={this.setLastPlayerChangeModalClosed}
                />
              )}
            >
              {t('common:home.notifications')}
            </ModalHeader>
            <ModalBody className="notification-body">
              {t('common:roomsTable.noPlayerTimeoutModal')}
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button className="notification-footer-button" color="link" onClick={this.setLastPlayerChangeModalClosed}>
               {t('common.ok')}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal container={'div > div'} isOpen={privateRoomConfirm} toggle={() => this.closePrivateRoomEnter()} className={`notification old-modal root-font-${fontMode}`} onOpened={this.onPrivateRoomModalOpen}>
            <ModalHeader
              className="notification-header"
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.closePrivateRoomEnter()} />
            }
            >
              {t('roomsTable.enterCode')}
            </ModalHeader>
            <ModalBody className="notification-body">
              <Row>
                <Col>
                  {privateRoomConfirmError && (
                  <div className="room-password-error">
                    {privateRoomConfirmError}
                  </div>
                  )}
                </Col>
              </Row>
              <Input
                className="room-password-digit"
                type="text"
                name={0}
                id="digit1"
                value={password[0] || ''}
                onKeyUp={this.handleDelete}
                onChange={this.handleChangeDigit}
                innerRef={this.digit1}
              />
              <Input
                className="room-password-digit"
                type="text"
                name={1}
                id="digit2"
                value={password[1] || ''}
                onKeyUp={this.handleDelete}
                onChange={this.handleChangeDigit}
                innerRef={this.digit2}
              />
              <Input
                className="room-password-digit"
                type="text"
                name={2}
                id="digit3"
                value={password[2] || ''}
                onKeyUp={this.handleDelete}
                onChange={this.handleChangeDigit}
                innerRef={this.digit3}
              />
              <Input
                className="room-password-digit"
                type="text"
                name={3}
                id="digit4"
                value={password[3] || ''}
                onKeyUp={this.handleDelete}
                onChange={this.handleChangeDigit}
                innerRef={this.digit4}
              />
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button color="link" className="btn notification-footer-button" onClick={() => this.joinPrivateRoomClicked()}>
                { t('common.ok') }
              </Button>
            </ModalFooter>
          </Modal>

          <Modal container={'div > div'} isOpen={showPrivateRoomPassword} toggle={() => this.closePrivateRoomPassword()} className={`notification old-modal root-font-${fontMode}`}>
            <ModalHeader
              className="notification-header"
              close={
                <Media src={closeImg} className="notification-header-close" alt="X" onClick={() => this.closePrivateRoomPassword()} />
            }
            >
              {t('roomsTable.yourCode')}
            </ModalHeader>
            <ModalBody className="notification-body">
              {digitsArr.map(digit => (
                <div className="room-password-digit">
                  {digit}
                </div>
              ))}
            </ModalBody>
            <ModalFooter className="notification-footer">
              <Button color="link" className="btn notification-footer-button" onClick={() => this.closePrivateRoomPassword()}>
                {t('common.ok')}
              </Button>
            </ModalFooter>
          </Modal>

         

          <Modal
            container={'div > div'}
            isOpen={errorModalIgnored || errorModalOpen}
            toggle={this.closeJoinErrorModal}
            className={`notification old-modal root-font-${fontMode}`}
          >
            <ModalHeader
              className="notification-header"
              close={(
                <Media
                  src={closeImg}
                  className="notification-header-close"
                  alt="X"
                  onClick={this.closeJoinErrorModal}
                />
              )}
            >
              {errorModalHeader}
            </ModalHeader>
            <ModalBody className="notification-body">
              {errorModalText}
            </ModalBody>
            <ModalFooter className="notification-footer">
              {errorModalIgnored ? (
                <>
                  <Button className="notification-footer-button" color="link" onClick={this.joinUnBlockFunc} >
                    {t('ignoredUsers.unBlock')}
                  </Button>
                  <Button className="notification-footer-button" color="link" onClick={this.closeJoinErrorModal}>
                    {t('common.close')}
                  </Button>
                </>
              ) : (
                <Button className="notification-footer-button" color="link" onClick={this.closeErrorModal}>
                  {t('common.ok')}
                </Button>
              )}
            </ModalFooter>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  // userSettings: state.userSettings ? (state.userSettings[state.member.uid] || state.userSettings.default || {}) : {},
  soundOn: state.userSettings.soundOn,
  lvl: state.member.level,
  rooms: state.rooms.rooms || {},
  level: state.member.level,
  uid: state.member.uid || '',
  adsHidden: state.member.adsHidden || null,
  loggedInUserDataFetched: state.member.loggedInUserDataFetched || null,
  activeTournament: state.member.activeTournament || null,
  finishedTournamentLastRound: state.member.finishedTournamentLastRound || null,
  joinedRooms: state.member.joinedRooms || null,
  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : [],
  myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
  offset: state.member.offset || 0,
  joinRoomError: state.member.joinRoomError || null,
  friends: state.member.friends || {},
  ignoredUsers: state.member.ignoredUsers,
  ignoredByUsers: state.member.ignoredByUsers,
  showIgnoredRooms: state.member?.showIgnoredRooms || false,
});

const mapDispatchToProps = {
//  joinARoom: joinRoom,
  leaveRoom: leaveRoomMenu,
  unBlockUserFunc: unBlockUser,
  fetchFriends: getFriends,
  clickACampaign: clickCampaign,
  fetchIgnoredPlayers: getIgnoredPlayers,
  fetchIgnoredByPlayers: getIgnoredByPlayers,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'notifications'])(RoomsTable));
