import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import classNames from 'classnames'

import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Media from 'reactstrap/lib/Media';
import Popover from 'reactstrap/lib/Popover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import Spinner from 'reactstrap/lib/Spinner';
import Input from 'reactstrap/lib/Input';

import ScrollAreaWrapper from '../../../Components/ScrollAreaWrapper';

import MenuRow from '../../../Components/Components/MenuRow';

import Players from '../TournamentPlayers';
import CustomDate from '../../../Components/Components/CustomDate';

import CustomModal from '../../../Components/Components/Modal';
import TournamentTutorial from '../Components/TournamentTutorial';
import PopoverEndTimer from '../Components/PopoverEndTimer';
import TournamentTimer from '../Components/TournamentTimer';
import TournamentInfoMobile from '../Mobile/Components/TournamentInfo';

import coinImg from '../../../../../../images/redesign/common/coin.svg';

import tutorialImage from '../../../../../../images/icons/help2.svg';
import lightInfoImg from '../../../../../../images/redesign/light-mode/player/info.svg';

import infoImg from '../../../../../../images/icons/info_chats.svg';
import LandScapeLayoutMobileHeader from '../../Layout/Mobile/LandScapeLayoutMobileHeader';

import {
  getTournamentPlayers,
  getTournamentActiveRooms,
  joinTournament,
  leaveTournament,
  returnToTournament,
  pauseTournament,
} from '../../../../../../actions/tournaments';

import {
  FONT_MODE, MENU_NAVIGATION, SCREEN_MODE, CALC_TOURNAMENT_ROUND_LENGTH,
  PAYMENT_SOURCE,
} from '../../../../../../constants/constants';
import { getTournamentById, getTournamentPlayersAlias, getTournamentTitle, getTournamentType } from '../../../../../../utils/tournamentUtils';
import { TouchableWithoutFeedbackBase } from 'react-native';
import { setOpenTournamentsTutorial } from '../../../../../../actions/state';
import IconPopover from '../../../Components/Components/IconPopover';
import { TextInput } from '../../../Components/Components/TextInput';
import { addPaymentInfo } from '../../../../../../actions/shop';

// class TournamentTimer extends React.Component {
//   static propTypes = {
//     time: PropTypes.number,
//     offset: PropTypes.number,
//     t: PropTypes.func.isRequired,
//   }

//   static defaultProps = {
//     time: 0,
//     offset: 0,
//   }

//   constructor(props) {
//     super(props);
//     this.state = {
//       minutes: 0,
//       seconds: 0,
//     };
//   }

//   componentDidMount() {
//     const { time } = this.props;
//     this.intervalID = setInterval(() => {
//       const { offset } = this.props;

//       if (time) {
//         const gameLength = time - (Date.now() + offset);
//         const minutes = Math.floor((gameLength % (1000 * 60 * 60)) / (1000 * 60));
//         const seconds = Math.floor((gameLength % (1000 * 60)) / 1000);

//         if (minutes >= 0 && seconds >= 0) {
//           this.setState({
//             minutes: minutes < 10 ? `0${minutes}` : minutes,
//             seconds: seconds < 10 ? `0${seconds}` : seconds,
//           });
//         }
//       }
//     }, 1000);
//   }

//   componentWillUnmount() {
//     clearInterval(this.intervalID);
//   }

//   render() {
//     const { time, t } = this.props;
//     const { minutes, seconds } = this.state;

//     if (!time) return null;

//     return (
//       ` (${t('tournaments.newRoundIn')} ${minutes}:${seconds})`
//     );
//   }
// }

class Tournaments extends React.Component {
  static propTypes = {
    tournaments: PropTypes.arrayOf(PropTypes.shape()),
    tournamentPlayers: PropTypes.shape(),
    myTournamentsData: PropTypes.shape(),
    registeredTournament: PropTypes.shape(),
    offset: PropTypes.number,
    uid: PropTypes.string,
    //  member: PropTypes.shape(),
    //  i18n: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    joinTournamentFunc: PropTypes.func.isRequired,
    leaveTournamentFunc: PropTypes.func.isRequired,
    returnToTournamentFunc: PropTypes.func.isRequired,
    pauseTournamentFunc: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    fetchTournamentPlayers: PropTypes.func.isRequired,
    fetchTournamentActiveRooms: PropTypes.func.isRequired,
    fontMode: PropTypes.string,
    screenMode: PropTypes.string,
    name: PropTypes.string,
    setAddPaymentInfo: PropTypes.func.isRequired,
  }

  static defaultProps = {
    tournaments: [],
    tournamentPlayers: null,
    myTournamentsData: null,
    registeredTournament: null,
    uid: null,
    offset: 0,
    fontMode: FONT_MODE.normal,
    screenMode: SCREEN_MODE.normal,
    name: '',
  }

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      openJoinModal: false,
      tournamentId: '',
      scrollPos: 0,
      tournamentToJoin: null,
      tournamentToJoinFee: null,
      registerPopoverOpen: {},
      privateTournamentConfirm: false,
      privateTournamentConfirmError: '',
      tournamentConfirmError: '',
      privateTournamentId: '',
      password: [],
      lowBalanceError: false,
      showTournamentTutorial: false,
      tournamentInfoModalOpen: false,
      selectTournament: {},
      disableAutoJoin: false,
      leaveReason: '',
      curLength: 0,
    };

    this.digit1 = React.createRef();
    this.digit2 = React.createRef();
    this.digit3 = React.createRef();
    this.digit4 = React.createRef();
  }

  componentWillMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.openTournamentsTutorial) {
        this.toggleShowTournamentTutorial();
        this.props.setOpenTournamentsTutorialFunc(false);
    }
 }

  /* joinTournamentNotification = (tournamentId, fee) => {
    this.setState({
      openJoinModal: true,
      tournamentToJoin: tournamentId,
      tournamentToJoinFee: fee,
    });
  } */

  handleChangeDigit = (e) => {
    const { password } = this.state;

    if (e.target) {
      const { value, name } = e.target;

      const newVal = value.charAt(value.length - 1);

      if (!isNaN(newVal) || !newVal) {
        password[name] = newVal;

        if (newVal) {
          if (name === 0 || name === '0') {
            if (this.digit2) {
              this.digit2.current.focus();
            }
          }
          if (name === 1 || name === '1') {
            if (this.digit3) {
              this.digit3.current.focus();
            }
          }
          if (name === 2 || name === '2') {
            if (this.digit4) {
              this.digit4.current.focus();
            }
          }
        }

        this.setState({
          password,
        });
      }
    }
  }

  joinTournamentNotification = (tournamentId, fee) => {
    const { tournaments, registeredTournament, joinTournamentFunc } = this.props;

    const tournament = tournaments.find(element => element.id === tournamentId);

    if (tournament && !(tournament.registeredPlayers >= tournament.maxPlayers) && !registeredTournament) {
      joinTournamentFunc('init');

      this.setState({
        openJoinModal: true,
        tournamentToJoin: tournamentId,
        tournamentToJoinFee: fee,
      });
    }
  }

  toggleJoin = () => {
    this.setState(prevState => ({
      openJoinModal: !prevState.openJoinModal,
    }));
  }


  closePrivateTournamentEnter = () => {
    this.setState({
      password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
    });
  }

  joinPrivateTournamentConfirm = (tournamentId, fee) => {
    const { tournaments, registeredTournament, joinTournamentFunc } = this.props;

    const tournament = tournaments.find(element => element.id === tournamentId);

    if (tournament && !(tournament.registeredPlayers >= tournament.maxPlayers) && !registeredTournament) {
      joinTournamentFunc('init');

      this.setState({
        //  openJoinModal: true,
        tournamentToJoin: tournamentId,
        tournamentToJoinFee: fee,
        privateTournamentConfirm: true,
        privateTournamentId: tournamentId,
        privateTournamentConfirmError: '',
        disableAutoJoin: false,
      });
    }
  }


  joinPrivateTournamentClicked = () => {
    const { joinTournamentFunc, openModalNotif, modalTypeNotif, updateModalNotif } = this.props;
    const { privateTournamentId, password } = this.state;

    if (!privateTournamentId) {
      return null;
    }

    if (password && password.length === 4) {
      //  this.setState({ joinRoomClicked: true });

      const passString = password.join('');

      joinTournamentFunc(privateTournamentId, passString).then((res) => {
        if (res && res.status === 'success') {
          this.setState({
            password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'multiAccount') {
          if (!(openModalNotif && modalTypeNotif === 'multiAccountTournament')) {
            updateModalNotif(true, 'multiAccountTournament', null, null, null);
            this.setState({
              password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
            });
          }
        } else if (res.error === 'wrong password') {
          this.setState({ privateTournamentConfirmError: 'tournamentWrongPassword' }); // Nepareizs kods
        } else if (res.error === 'tournament already registered') {
          this.setState({
            password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'tournament max players') {
          this.setState({ privateTournamentConfirmError: 'tournamentFull' }); // Turnīrs pilns
        } else if (res.error === 'insuf bal tournament') {
          const { tournaments } = this.props;
          this.setState({ privateTournamentConfirmError: 'noBalanceTournament', lowBalanceError: true, selectTournament: { ...tournaments.filter(tournament => tournament.id === privateTournamentId)[0] }, disableAutoJoin: true });
        } else {
          this.setState({ privateTournamentConfirmError: 'tournamentError' }); // Radās kļūda pievienojoties, mēģini vēlreiz
        }
      });
    } else {
      console.log('no password');
    }

    return null;
  }

  /*  showPrivateTournamentPassword = () => {
    const { privateTournamentPassword } = this.props;

    if (privateTournamentPassword) {
      this.setState({
        showPrivateTournamentPassword: true,
      });
    }
  }

  closePrivateTournamentPassword = () => {
    this.setState({
      showPrivateTournamentPassword: false,
    });
  }  */

  joinTournament = (tournamentId) => {
    const { joinTournamentFunc, openModalNotif, modalTypeNotif, updateModalNotif } = this.props;

    if (tournamentId) {
      joinTournamentFunc(tournamentId).then((res) => {
        console.log(res);
        if (res && res.status === 'success') {
          //  this.setState({
          //    password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          //  });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'tournament already registered') {
          //  this.setState({
          //    password: [], privateTournamentConfirm: false, privateTournamentId: '', privateTournamentConfirmError: '',
          //  });

          setTimeout(() => {
            this.setState({
              openJoinModal: false,
              tournamentToJoin: '',
              tournamentToJoinFee: '',
            });
          }, 250);
        } else if (res.error === 'multiAccount') {
          if (!(openModalNotif && modalTypeNotif === 'multiAccountTournament')) {
            updateModalNotif(true, 'multiAccountTournament', null, null, null);
          }
        } else if (res.error === 'tournament max players') {
          this.setState({ tournamentConfirmError: 'tournamentFull' }); // Turnīrs pilns
        } else if (res.error === 'insuf bal tournament') {
          const { tournaments } = this.props;
          this.setState({ tournamentConfirmError: 'noBalanceTournament', lowBalanceError: true, selectTournament: { ...tournaments.filter(tournament => tournament.id === tournamentId)[0] } });
        } else {
          this.setState({ tournamentConfirmError: 'tournamentError' }); // Radās kļūda pievienojoties, mēģini vēlreiz
        }
      });
    }

    setTimeout(() => {
      this.setState({
        openJoinModal: false,
        tournamentToJoin: '',
        tournamentToJoinFee: '',
      });
    }, 250);
  }

  closeLowBalanceError = () => {
    this.setState({ lowBalanceError: false });
  }

  buyMoney = () => {
    const { changeTab, uid, name, setAddPaymentInfo } = this.props;

    setAddPaymentInfo({
      uid,
      name,
      source: PAYMENT_SOURCE.joinTournament,
    });
    changeTab(MENU_NAVIGATION.buyPageMoneyPacks);
  }

  openModal = (id) => {
    //  const { tournamentPlayers } = this.props;
    //  const leaderboard = tournamentPlayers[id];

    this.setState({
      tournamentId: id,
      openModal: true,
      //    leaderboard,
    });
  }

  toggle = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  }


  /*  toggleJoin = () => {
   this.setState(prevState => ({
     openJoinModal: !prevState.openJoinModal,
   }));
 }  */

  toggleLeave = () => {
    this.setState(prevState => ({
      openLeaveModal: !prevState.openLeaveModal,
    }));
  }

  leaveTournamentNotification = (tournamentId) => {
    this.setState({
      openLeaveModal: true,
      tournamentToLeave: tournamentId,
    });
  }

  leaveTournament = (tournamentId) => {
    const { leaveTournamentFunc } = this.props;
    const { leaveReason } = this.state;

    if (tournamentId) {
      leaveTournamentFunc(tournamentId, leaveReason);
    }

    setTimeout(() => {
      this.setState({
        openLeaveModal: false,
        tournamentToLeave: '',
        leaveReason: '',
      });
    }, 250);
  }

  fetchTournamentPlayers = (tournamentId) => {
    const { fetchTournamentPlayers } = this.props;

    if (tournamentId) {
      fetchTournamentPlayers(tournamentId).then(() => {
        this.setState({
          openModal: true,
          tournamentId: tournamentId,
        });
      });
    }
  }

  fetchTournamentActiveRooms = (tournamentId) => {
    const { fetchTournamentActiveRooms, tournaments } = this.props;
    const { popoverOpen } = this.state;

    if (popoverOpen && popoverOpen === tournamentId) {
      this.setState({ popoverOpen: null });
    } else if (tournamentId) {
      fetchTournamentActiveRooms(tournamentId).then((res) => {
        const tournament = tournaments.find(element => element.id === tournamentId);

        if (res && res.data) {
          const roomsCount = Object.keys(res.data).length;
          let partiesCount = 0;

          Object.keys(res.data).map((key) => {
            if (res.data[key] && res.data[key].party) {
              partiesCount += parseInt(res.data[key].party, 10);
            }
            return null;
          });

          console.log('roomsCount data', { roomsCount, partiesCount });

          console.log('tournament', { tournament });

          const { roundLength, gameStartTime, fastGame, lightningGame } = tournament;
          let { roundLengthTime } = tournament;
          const averageParties = partiesCount / roomsCount;
          const averagePartiesLeft = Math.round(parseInt(roundLength, 10) - averageParties);

          console.log('gameStartTime', gameStartTime);

          let speed = 'normal';

          if (fastGame) {
            speed = 'atra';
          } else if (lightningGame) {
            speed = 'lightning';
          }

          if (!roundLengthTime) {
            roundLengthTime = CALC_TOURNAMENT_ROUND_LENGTH(roundLength, speed);
          }

          let gameEndTime = gameStartTime + (roundLengthTime * 60 * 1000);

          /* if (roundLength > 24) {
            gameEndTime = gameStartTime + (60 * 60 * 1000);
          } else if (roundLength > 12) {
            gameEndTime = gameStartTime + (40 * 60 * 1000);
          } */

          console.log('averagePartiesLeft', { averagePartiesLeft });

          this.setState({ popoverOpen: tournamentId, popoverRoomsCount: roomsCount, popoverEndTime: gameEndTime, popoverPartiesCount: roomsCount > 0 ? averagePartiesLeft : 0 });
        }
      });
    }
  }

  togglePopover = () => {
    this.setState({ popoverOpen: null });
  }

  updateScrollPos = (val) => {
    if (val.topPosition || val.topPosition === 0) {
      this.setState({ scrollPos: val.topPosition || 0 });
    }
    //  this.setState({ scrollPos: val.topPosition || 0 });
  }

  scroll = (newVal) => {
    this.tableScrollbar.scrollYTo(newVal);
  }

  onRegisterHover = (id) => {
    const { registerPopoverOpen } = this.state;
    if (registerPopoverOpen && !registerPopoverOpen[id]) {
      this.setState(prevState => ({
        registerPopoverOpen: {
          ...prevState.registerPopoverOpen,
          [id]: true,
        },
      }));
    }
  }

  onRegisterHoverLeave = (id) => {
    this.setState(prevState => ({
      registerPopoverOpen: {
        ...prevState.registerPopoverOpen,
        [id]: false,
      },
    }));
    //  this.setState({
    //    registerPopoverOpen: false,
    //  })
  }

  toggleShowTournamentTutorial = () => {
    const { showTournamentTutorial } = this.state;

    if (!showTournamentTutorial) {
      ReactGA.event({
        category: 'Menu',
        action: 'Tournaments Help',
      });
    }
    this.setState(prevState => ({ showTournamentTutorial: !prevState.showTournamentTutorial }));
  }

  renderTournamentState = (t, state) => (
    <div className={`tournament-state tournament-state-${state}`}>
      {t(`tournaments.${state}`)}
    </div>
  )

  openTournamentInfoModal = (selectId) => {
    const { tournaments } = this.props;

    this.setState({
      tournamentInfoModalOpen: true,
      selectTournament: { ...tournaments.filter(tournament => tournament.id === selectId)[0] }
    })
  }

  closeTournamentInfoModal = () => {
    this.setState({
      tournamentInfoModalOpen: false,
    })
  }

  handleLeaveReason = (value) => {
    this.setState({ leaveReason: value, curLength: value.length });
  }

  render() {
    const {
      t,
      tournaments,
      myTournamentsData,
      //  member,
      registeredTournament,
      uid,
      offset,
      changeTab,
      tournamentPlayers,
      returnToTournamentFunc,
      pauseTournamentFunc,
      fontMode,
      screenMode,
      toggle,
      hasActiveRegistration,
      activeTab,
      achievementsNotification,
      handleClickStart,
      OpenMobileDropdownModal,
      showUserSettingsModal
      //  privateTournamentPassword,
    } = this.props;

    const {
      openModal,
      tournamentId,
      tournamentToJoin,
      tournamentToJoinFee,
      openJoinModal,
      tournamentToLeave,
      openLeaveModal,
      scrollPos,
      registerPopoverOpen,
      privateTournamentConfirm,
      privateTournamentConfirmError,
      tournamentConfirmError,
      password,
      popoverOpen,
      popoverRoomsCount,
      popoverPartiesCount,
      //  showPrivateTournamentPassword,
      lowBalanceError,
      showTournamentTutorial,
      popoverEndTime,
      selectTournament,
      tournamentInfoModalOpen,
      disableAutoJoin,
      leaveReason,
      curLength,
    } = this.state;

    console.log('myTournamentsData', { myTournamentsData });
    console.log('tournaments', { tournaments });

    if (privateTournamentConfirm) {
      if (this.digit1.current && (!password[0] || password[0] === '')) {
        this.digit1.current.focus();
      } else if (password[0] && password[0] !== '' && password[1] && password[1] !== '' && password[2] && password[2] !== '' && password[3] && password[3] !== '' && !disableAutoJoin) {
        this.joinPrivateTournamentClicked();
      }
    }

    return (
      <>
        <Helmet>
          <title>
            Zole - {t('route.tournaments')}
          </title>
        </Helmet>
        <div className="layout-mobile-body layout-mobile-body-tournaments-tab layout-mobile-body-tournaments-page-plan">
          <div className="layout-mobile-body-top">
            {/* <Row className="layout-mobile-subheader d-none"> */}
              {/* <div className='tournament-subheader-wrapper'>
                <div className="layout-subheader-link">
                  <Button color="link" className="layout-subheader-link-text active-tournaments-button" onClick={() => changeTab('6')}>
                    {t('tournaments.activeTournaments')}
                  </Button>
                </div>
                <div className="layout-subheader-link">
                  <Button color="link" className="layout-subheader-link-text future-tournaments-button layout-subheader-link-text-active future-tournaments-button-joyride-target" onClick={() => changeTab('16')}>
                    {t('tournaments.futureTournaments')}
                  </Button>
                </div>
                <div className="layout-subheader-link">
                  <Button color="link" className="layout-subheader-link-text past-tournaments-button" onClick={() => changeTab('7')}>
                    {t('tournaments.tournamentHistory')}
                  </Button>
                </div>
                <div className="layout-subheader-link">
                    <div className='tournament-tutorial-button-wrapper-menu'>
                        <Media className="tournament-header-tutorial-menu" style={{}} src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.toggleShowTournamentTutorial()} />
                    </div>
                </div>
              </div> */}
              {/* <div className='tournament-tutorial-button-wrapper'>
                <Media className="tournament-header-tutorial" src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.toggleShowTournamentTutorial()} />
              </div> */}
            {/* </Row> */}

          </div>

          <div className="layout-mobile-body-main">
            <div class="layout-mobile-body-main-title">{t('tournaments.futureTournaments')}</div>

            {(tournaments && tournaments.filter(tournament => tournament.currentState !== 'play' && tournament.currentState !== 'pause').length > 0) ? (
              <div
                className="tournament-scrollarea"
                contentClassName="tournament-scrollarea-body"
                show
                rightOffset={0}
                topOffset={0}
                bottomOffset={40}
              >

                { tournaments.filter(tournament => tournament.currentState !== 'play' && tournament.currentState !== 'pause').reverse().map((tournament) => {
                  const { startDate, registerTime } = tournament;
                  let registerDate;
                  if (startDate && registerTime) {
                    registerDate = startDate - (registerTime * 1000 * 60);
                  }

                  return (
                    <MenuRow key={tournament.id}>
                      <Row key={tournament.id} className="tournament-table-row tournament-table-row-main">
                        <div className='active-tournaments'>
                          <div className='active-tournaments-info'>
                            <div className='active-tournaments-info-section'>
                              <div className='active-tournaments-info-section-title'>
                                <div className='active-tournaments-info-section-title-wrapper'>
                                  <Media src={screenMode === SCREEN_MODE.light ? lightInfoImg : tutorialImage} onClick={() => this.openTournamentInfoModal(tournament.id)} className='active-tournaments-info-section-title-left-img' alt="x" />
                                  <div className='active-tournaments-info-section-inner-wrapper'>
                                    <div className='first-line'>
                                      <span className='active-tournaments-info-section-title-left-title'>{getTournamentTitle(tournament)}</span>
                                      {
                                        registeredTournament === tournament.id ? (
                                          <div className='active-tournaments-info-section-title-left-title-status confirmed-status'>{`${t('tournaments.registration')} `}</div>
                                        ) : (
                                          <div className={`active-tournaments-info-section-title-left-title-status ${tournament.currentState}-status`}>{t(`tournaments.${tournament.currentState}`)}</div>
                                        )
                                      }
                                    </div>
                                    <div className='second-line'>
                                      <span className='active-tournaments-info-section-title-left-private'>
                                        {getTournamentType(tournament, t)}
                                      </span>
                                      <div className='active-tournaments-info-section-time-rounds-wrapper'>
                                        <span className='active-tournaments-info-section-time-rounds'>{`${t('tournaments.rounds')}:`}</span>
                                        <span className='active-tournaments-info-section-time-roundsValue'>{tournament.rounds}</span>
                                      </div>
                                      <div className='active-tournaments-info-section-time-wrapper'>
                                        <span className='active-tournaments-info-section-time-label'>{`${t('tournaments.beginning')}:`}</span>
                                        <span className='active-tournaments-info-section-time-content'><CustomDate format="DD.MM.YYYY hh:mm" date={startDate} /></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {tournament.currentState && tournament.currentState === 'registration' && (
                              <div className='active-tournaments-info-button'>
                                <Fragment>
                                  <Button color="link" className="layout-default-button" onClick={() => this.fetchTournamentPlayers(tournament.id)}>
                                    {t('tournaments.players')}
                                  </Button>
                                  {registeredTournament === tournament.id ? (
                                    myTournamentsData && tournament.currentState === 'registration' && tournament.startDate && (tournament.startDate - (1000 * 30) < Date.now()) ? (
                                      <Fragment>
                                        {/* {myTournamentsData.paused ? (
                                          <Button color="link" disabled={tournament.rounds === tournament.currentRound} id="layout-highlighted-button" className="tournament-table-button" onClick={(e) => { returnToTournamentFunc(tournament.id); e.target.blur(); }}>
                                            {t('tournaments.return')}
                                          </Button>
                                        ) : (
                                          // <></>
                                          <Button color="link" disabled={tournament.rounds === tournament.currentRound} id="layout-highlighted-button" className="tournament-table-button" onClick={(e) => { pauseTournamentFunc(tournament.id); e.target.blur(); }}>
                                            {t('tournaments.pause')}
                                          </Button>
                                        )} */}
                                      </Fragment>
                                    ) : (
                                      <Button color="link" className="layout-highlighted-button" onClick={() => this.leaveTournamentNotification(tournament.id)}>
                                        {t('tournaments.leave')}
                                      </Button>
                                    )
                                  ) : (
                                    <Fragment>
                                      <div
                                        id={`tournament-popover-mobile-${tournament.id}`}
                                        onMouseEnter={() => this.onRegisterHover(tournament.id)}
                                        onMouseLeave={() => this.onRegisterHoverLeave(tournament.id)}
                                      >
                                        {tournament.privateTournament ? (
                                          <Button
                                            color="link"
                                            className={`layout-highlighted-button ${((tournament.registeredPlayers >= tournament.maxPlayers) || registeredTournament) && 'disabled'}`}
                                            onClick={() => this.joinPrivateTournamentConfirm(tournament.id, tournament.entryFee)}
                                          >
                                            {t('tournaments.register')}
                                          </Button>
                                        ) : (
                                          <Button
                                            color="link"
                                            className={`layout-highlighted-button ${((tournament.registeredPlayers >= tournament.maxPlayers) || registeredTournament) && 'disabled'}`}
                                            onClick={() => this.joinTournamentNotification(tournament.id, tournament.entryFee)}
                                          >
                                            {t('tournaments.register')}
                                          </Button>
                                        )}
                                      </div>
                                      {((tournament.registeredPlayers && tournament.maxPlayers && parseInt(tournament.registeredPlayers) >= parseInt(tournament.maxPlayers)) || registeredTournament) && (
                                        <IconPopover
                                          text={
                                            registeredTournament
                                            ? t('tournaments.alreadyRegisteredAnotherTournament')
                                            : (tournament.registeredPlayers && tournament.maxPlayers && parseInt(tournament.registeredPlayers) >= parseInt(tournament.maxPlayers))
                                              ? t('tournaments.tournamentMaxPlayers2')
                                              : ''}
                                          popoverOpen={registerPopoverOpen[tournament.id]}
                                          targetId={`tournament-popover-mobile-${tournament.id}`}
                                          placement="right"
                                          mobileEnable={true}
                                        />
                                      )}
                                    </Fragment>
                                  )}
                                </Fragment>
                              </div>
                            )}
                          </div>
                          <div className={
                            classNames('active-tournaments-sponsor', {
                              'active-tournaments-sponsor-two': tournament?.sponsorImg && tournament?.prizeImg
                            })
                          }>
                            {
                              tournament?.sponsorImg && (
                                <div className={
                                  classNames('active-tournaments-sponsor-section', {
                                    'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                  })
                                }>
                                  <span>{t('tournaments.sponsorTournaments')}</span>
                                  <Media src={tournament?.sponsorImg} alt="x" />
                                </div>
                              )
                            }
                            {
                              tournament?.prizeImg && (
                                <div className={
                                  classNames('active-tournaments-sponsor-section', {
                                    'active-tournaments-sponsor-section-two': tournament?.sponsorImg && tournament?.prizeImg
                                  })
                                }>
                                  <span>{t('tournaments.mainPrize')}</span>
                                  <Media src={tournament?.prizeImg} alt="x" />
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </Row>
                    </MenuRow>
                  );
                })}
              </div>
            ) : (<div className="text-center future-tournaments-empty-entries">{t('tournaments.futureTournamentsEmptyEntries')}</div>)}

          </div>
        </div>

        {console.log('showTournamentTutorial', { showTournamentTutorial })}

        <TournamentTutorial showTournamentTutorial={showTournamentTutorial} toggleShowTournamentTutorial={this.toggleShowTournamentTutorial} />

        <CustomModal
          isOpen={tournamentInfoModalOpen}
          toggle={this.closeTournamentInfoModal}
          addClassName="tournaments-info-modal"
          size="lg"
          title={getTournamentTitle(selectTournament)}
          footer={
            <Button color="link" className="btn notification-footer-button" onClick={this.closeTournamentInfoModal}>
              {t('common.close')}
            </Button>
          }
          body={<TournamentInfoMobile t={t} selectTournament={selectTournament} />}
        />

        {uid && tournamentPlayers && openModal && getTournamentPlayersAlias(getTournamentById(tournaments, tournamentId)) !== 'complete' ? (
          <>
            <CustomModal
              isOpen={openModal}
              toggle={this.toggle}
              size="lg"
              addClassName="tournament-top-modal"
              title={t('tournaments.participants')/*t('tournaments.top')*/}
              footer={
                <Button color="link" className="btn notification-footer-button" onClick={this.toggle}>
                  {t('common.close')}
                </Button>
              }
              body={<Players tournament={getTournamentById(tournaments, tournamentId)} players={tournamentPlayers} tournamentId={tournamentId} uid={uid} screenMode={screenMode} />}
            />
          </>
        ) : (null)}

        <CustomModal
          isOpen={privateTournamentConfirm}
          toggle={this.closePrivateTournamentEnter}
          size="lg"
          largeText
          addClassName="tournaments-join-private-password-modal"
          title={t('tournaments.joinTournament')}
          footer={(
            <>
              <Button color="link" className="btn notification-footer-button" onClick={() => this.joinPrivateTournamentClicked()}>
                {t('tournaments.register')}
              </Button>
              <Button color="link" className="btn notification-footer-button" onClick={this.closePrivateTournamentEnter}>
                {t('common.close')}
              </Button>
            </>
          )}
          body={(
            <>
              <div className="text-center">
                {t('tournaments.joinTournamentMessage', { entryFee: tournamentToJoinFee })}
                <Media className="tournaments-join-message-image" src={coinImg} />
                <br />
                {t('tournaments.enterCode')}
              </div>
              <Row>
                <Col>
                  {privateTournamentConfirmError && (
                    <div className="room-password-error">
                      {t(`notifications:${privateTournamentConfirmError}`)}
                    </div>
                  )}
                </Col>
              </Row>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15}}>
                <Input
                  className="room-password-digit"
                  type="text"
                  name={0}
                  id="digit1"
                  value={password[0] || ''}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit1/*(el) => { this.digit1 = el; }*/}
                />
                <Input
                  className="room-password-digit"
                  type="text"
                  name={1}
                  id="digit2"
                  value={password[1] || ''}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit2}
                />
                <Input
                  className="room-password-digit"
                  type="text"
                  name={2}
                  id="digit3"
                  value={password[2] || ''}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit3}
                />
                <Input
                  className="room-password-digit"
                  type="text"
                  name={3}
                  id="digit4"
                  value={password[3] || ''}
                  onChange={this.handleChangeDigit}
                  innerRef={this.digit4}
                />
              </div>
            </>
          )}
        />

        <CustomModal
          isOpen={openJoinModal}
          toggle={this.toggleJoin}
          size="lg"
          verticalCentering
          largeText
          title={t('tournaments.joinTournament')}
          footer={(
            <>
              <Button color="link" className="modal-footer-button" onClick={() => this.joinTournament(tournamentToJoin)}>
                {t('tournaments.register')}
              </Button>
              <Button color="link" className="modal-footer-button" onClick={this.toggleJoin}>{t('tournaments.close')}</Button>
            </>
          )}
          body={(
            <>
              {tournamentConfirmError && (
                <Row>
                  <Col>
                    <div className="room-password-error text-center">
                      {t(`notifications:${tournamentConfirmError}`)}
                    </div>
                  </Col>
                </Row>
              )}

              <div className="text-center">
                {t('tournaments.joinTournamentMessage', { entryFee: tournamentToJoinFee })}
                <Media className="tournaments-join-message-image" src={coinImg} />
              </div>
            </>
          )}
        />

        <CustomModal
          isOpen={openLeaveModal}
          toggle={this.toggleLeave}
          size="lg"
          largeText
          verticalCentering
          title={t('tournaments.leaveTournament')}
          footer={(
            <>
              <Button color="link" className="modal-footer-button" onClick={() => this.leaveTournament(tournamentToLeave)}>
                {t('tournaments.leave')}
              </Button>
              <Button color="link" className="modal-footer-button" onClick={this.toggleLeave}>{t('tournaments.close')}</Button>
            </>
          )}
          body={
            <div>
              {t('tournaments.leaveTournamentBody')}
              <p className="text-center mb-1">{t('tournaments.leaveTournamentWarning')}</p>
              <div className="d-block w-100 text-center">
                {t('tournaments.leaveTournamentReason')}
                <TextInput
                  contentEditable={true}
                  width='100%'
                  type="textarea"
                  initialValue={leaveReason}
                  onChange={(value, type) =>
                    this.handleLeaveReason(value, type)
                  }
                  maxLength={500}
                  curLength={curLength}
                  customHeight="auto"
                  rows="3"
                />
              </div>
            </div>
          }
        />

        <CustomModal
          isOpen={lowBalanceError}
          toggle={this.closeLowBalanceError}
          size="lg"
          verticalCentering
          largeText
          title={t('common:home.notifications')}
          footer={
            <Fragment>
              <Button className="btn notification-footer-button" color="link" onClick={this.buyMoney}>{t('notifications:buyMoney')}</Button>
              <Button className="btn notification-footer-button" color="link" onClick={this.closeLowBalanceError}>{t('notifications:close')}</Button>
            </Fragment>
          }
          body={
            <div className='text-center'>
              {t('notifications:noMoneyTournamentDescription',
                {
                  feeMaxLoss: selectTournament && selectTournament.bet && selectTournament?.entryFee + 16 * parseInt(selectTournament.bet.split(':')[1]),
                  bet: selectTournament?.bet, fee: selectTournament?.entryFee
                })}
            </div>
          }
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  registeredTournament: state.member.registeredTournament,
  uid: state.member.uid || '',
  offset: state.member.offset || 0,
  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : {},
  myTournamentsData: (state.tournaments && state.tournaments.myTournamentsData) ? state.tournaments.myTournamentsData : {},
  tournamentPlayers: (state.tournaments && state.tournaments.tournamentPlayers) ? state.tournaments.tournamentPlayers : {},
  openTournamentsTutorial: state.state.openTournamentsTutorial || false,
  name: state.member.name,
});

const mapDispatchToProps = {
  pauseTournamentFunc: pauseTournament,
  returnToTournamentFunc: returnToTournament,
  leaveTournamentFunc: leaveTournament,
  joinTournamentFunc: joinTournament,
  fetchTournamentPlayers: getTournamentPlayers,
  fetchTournamentActiveRooms: getTournamentActiveRooms,
  setOpenTournamentsTutorialFunc: setOpenTournamentsTutorial,
  setAddPaymentInfo: addPaymentInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(['common', 'notifications'])(Tournaments));
