// import { errorMessages } from '../constants/messages';
import {
  map, size, forEach, filter, find, isEqual,
} from 'lodash'; // , orderBy, keyBy, sortBy, get

import axios from 'axios';
import i18n from 'i18next';

import moment from 'moment';
import {
  Firebase,
  FirebaseRef,
  AdminLogsRef,
  RoomsRef,
  UserStatsRef,
  TournamentsRef,
  LeaderboardRef,
  StatusRef,
  FirebaseStorage,
} from '../lib/firebase';
import {
  isAndroid,
  isIOS,
  isIOS13,
  isTablet,
  isMobile,
  isDesktop,
  browserName,
  browserVersion,
  osName,
  osVersion,
  mobileModel
} from 'react-device-detect';
import { errorMessages } from '../constants/messages';
import * as statisticsConst from '../constants/statisticsTypes';
import * as constants from '../constants/constants';
import config from '../constants/config';
import { getDbRoomGameType } from '../utils/roomUtils';
import { toUrlString } from '../utils/stringUtils';

let chatRef = null;

function dateFormat(formatData) {
  return moment(formatData).format('DD-MM-YYYY HH:MM:SS');
}

/*
function dateFormatMoment(formatData, dateType) {
  return moment(formatData).format(dateType);
} */

export function getmissedTurnsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child('missedTurnsCount').once('value', (snapshot) => {
    const array = [];

    snapshot.forEach((childSnapshot) => {
      const val = childSnapshot.val();
      const { key } = childSnapshot;
      array.push({ key, ...val });
    });

    array.sort((a, b) => b.count - a.count);

    return resolve(dispatch({ type: 'MISSED_TURNS_COUNT', data: array }));
  })).catch((err) => { console.log(err.message); });
}

export function getProfanitiesList() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('profanitiesList').once('value', (snapshot) => {
    const profanitiesList = snapshot.val() || {};

    return resolve(dispatch({ type: 'PROFANITIES_LIST', data: profanitiesList }));
  })).catch((err) => { console.log(err.message); });
}

export function addProfanity(word, exact) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve) => {
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'addProfanity',
      adminId: UID,
      adminEmail,
      adminName,
      data: word,
      date: Firebase.database.ServerValue.TIMESTAMP,
    });

    return FirebaseRef.child(`profanitiesList/${word}`).set({ word, count: 0, exact: exact ? true : false }).then(() => resolve());
  });
}


export function deleteProfanity(word) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve) => {
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'deleteProfanity',
      adminId: UID,
      adminEmail,
      adminName,
      data: word,
      date: Firebase.database.ServerValue.TIMESTAMP,
    });

    FirebaseRef.child(`profanitiesList/${word}`).remove().then(() => resolve());
  });
}

export function getmissedTurnsData(userId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise(resolve => AdminLogsRef().child(`missedTurnsData/${userId}`).once('value', (snapshot) => {
    const missedTurnsData = snapshot.val() || {};

    return resolve(dispatch({ type: 'MISSED_TURNS_DATA', data: missedTurnsData }));
  })).catch((err) => { console.log(err.message); });
}

export function getUserCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/userCount')
    .on('value', (snapshot) => {
      const userCount = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_USER_COUNT', data: userCount }));
    })).catch((err) => { console.log(err.message); });
}

export function getUsersRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('users fetch getUsersRange', start, end);

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('users').off();

    FirebaseRef.child('users')
      .orderByChild('userIndex')
      .startAt(start)
      .endAt(end)
      .on('child_changed', (snapshot) => {
        const user = snapshot.val() || {};
        const { key } = snapshot;

        console.log('getUsersRange change', key, user);

        dispatch({ type: 'ADMIN_USER_REPLACE', data: { user, key } });
      });

    FirebaseRef.child('users')
      .orderByChild('userIndex')
      .startAt(start)
      .endAt(end)
      .once('value', (snapshot) => {
        const users = snapshot.val() || {};

        return resolve(dispatch({ type: 'ADMIN_USERS_REPLACE', data: users }));
      });
  }).catch((err) => { console.log(err.message); });
}


// export function getFilteredUsers(filterValue, filterType) {
//   if (Firebase === null) return () => new Promise(resolve => resolve());

//   if (!filterType || !filterValue) return dispatch => new Promise(resolve => resolve(dispatch({ type: 'ADMIN_FILTERED_USERS', data: null })));
//   if (filterType === constants.USERNAME_LOWER_UPPER.lowerCaseName || filterType === constants.USERNAME_LOWER_UPPER.lowerCaseLastName) {
//     return dispatch => new Promise(resolve => FirebaseRef.child('users')
//       .orderByChild(filterType)
//       .startAt(filterValue.toLowerCase())
//       .endAt(`${filterValue.toLowerCase()}\uf8ff`)
//       .once('value', (snapshot) => {
//         const data = snapshot.val() || {};

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_USERS', data }));
//       })).catch((err) => { console.log(err.message); });
//   }


//   if (filterType === 'uid') {
//     return dispatch => new Promise(resolve => FirebaseRef.child('users')
//       .orderByKey()
//       .equalTo(filterValue.toString())
//       .limitToFirst(1)
//       .once('value', (snapshot) => {
//         const data = snapshot.val() || {};

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_USERS', data }));
//       })).catch((err) => { console.log(err.message); });
//   }

//   if (filterType === 'email') {
//     return dispatch => new Promise(resolve => FirebaseRef.child('users')
//       .orderByChild(filterType)
//       .startAt(filterValue)
//       .endAt(`${filterValue}\uf8ff`)
//       .once('value', (snapshot) => {
//         const data = snapshot.val() || {};

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_USERS', data }));
//       })).catch((err) => { console.log(err.message); });
//   }

//   return new Promise(resolve => resolve());
// }

export function getFilteredUsers(
  filterValue, filterType, filterRegMethod, filterPlatform, filterLanguage, filterDesign,
  filterTheme, filterBirthday, filterProfilePhoto, filterSound, filterAutoJoin,
  filterFont, filterGameSetting, filterVerified, filterRegEnabled, filterRegStartDate, filterRegEndDate,
  filterLastLoginEnabled, filterLastLoginStartDate, filterLastLoginEndDate, filterBalanceEnabled,
  filterBalanceFrom, filterBalanceTo, filterPointsEnabled, filterPointsFrom, filterPointsTo,
  filterPlayedGamesEnabled, filterPlayedGamesFrom, filterPlayedGamesTo, filterLevelEnabled,
  filterLevelFrom, filterLevelTo, filterPurchasesEnabled, filterPurchasesFrom, filterPurchasesTo,
  filterSpentEnabled, filterSpentFrom, filterSpentTo, filterAchievementsEnabled,
  filterAchievementsStartDate, filterAchievementsEndDate, filterRewardsEnabled,
  filterRewardsFrom, filterRewardsTo, filterTutorialBonus, filterIOSAppBonus, filterAndroidAppBonus,
  filterOnlyBuyers, filterOnlyOnlinePlayers,
) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  // if (!filterValue && !filterRegMethod && !filterPlatform && !filterSound && !filterLanguage
  //   && !filterDesign && !filterTheme && !filterBirthday && !filterProfilePhoto && !filterFont
  //   && !filterAutoJoin && !filterSound && !filterGameSetting && !filterVerified && !filterRegEnabled
  //   && !filterLastLoginEnabled && !filterBalanceEnabled && !filterPointsEnabled && !filterPlayedGamesEnabled
  //   && !filterLevelEnabled && !filterPurchasesEnabled && !filterSpentEnabled && !filterAchievementsEnabled
  //   && !filterRewardsEnabled && !filterTutorialBonus && !filterAndroidAppBonus && !filterIOSAppBonus
  //   && !filterOnlyBuyers && !filterOnlyOnlinePlayers
  // ) {
  //   return dispatch => new Promise(resolve =>
  //     resolve(dispatch({ type: 'ADMIN_FILTERED_USERS', data: null }))
  //   );
  // }

  const applyFilters = async (data) => {
    if (filterPurchasesEnabled || filterSpentEnabled || filterOnlyBuyers) {
      try {
        const productPrices = new Map(constants.SHOP_PRODUCT_TYPES.map(product => [product.id, product.realPrice]));

        const paymentsSnapshot = await FirebaseRef.child('completedPayments').once('value');
        const completedPayments = paymentsSnapshot.val() || {};

        const userStats = Object.values(completedPayments)
          .filter(payment => payment.status === "completed")
          .reduce((acc, payment) => {
            const uid = payment.userUid;
            if (!acc[uid]) acc[uid] = { count: 0, spent: 0 };
            acc[uid].count++;
            acc[uid].spent += productPrices.get(payment.productNr) || 0;
            return acc;
          }, {});


        data = Object.fromEntries(
          Object.entries(data).filter(([uid]) => {
            const stats = userStats[uid] || { count: 0, spent: 0 };

            if (filterOnlyBuyers && stats.count === 0) {
              return false;
            }

            if (filterPurchasesEnabled && (stats.count < filterPurchasesFrom || stats.count > filterPurchasesTo)) {
              return false;
            }

            if (filterSpentEnabled && (stats.spent < filterSpentFrom || stats.spent > filterSpentTo)) {
              return false;
            }

            return true;
          })
        );

      } catch (err) {
        console.log("err loading completed payments:", err);
      }
    }

    let onlinePlayers = {};
    if (filterOnlyOnlinePlayers) {
      try {
        const onlinePlayersSnapshot = await StatusRef.child('status').once('value');
        onlinePlayers = onlinePlayersSnapshot.val() || {};
      } catch (err) {
        console.log("err loading completed online players:", err);
      }
    }

    const entries = await Promise.all(
      Object.entries(data).map(async ([uid, user]) => {
        let includeItem = true;

        if (filterOnlyOnlinePlayers && !onlinePlayers[user.uid]) includeItem = false;

        if (filterRegMethod && user.socProvider !== filterRegMethod) includeItem = false;
        if (filterPlatform && user.lastPlatform !== filterPlatform) includeItem = false;
        if (filterLanguage && user.lastLanguage !== filterLanguage) includeItem = false;
        if (filterDesign === "new" && !user.newDesign || filterDesign === "old" && user.newDesign) includeItem = false;
        if (filterTheme && user.screenMode !== filterTheme) includeItem = false;
        if (filterBirthday === "yes" && !user.birthday || filterBirthday === "no" && user.birthday) includeItem = false;
        if (filterProfilePhoto === "yes" && !user.photo || filterProfilePhoto === "no" && user.photo) includeItem = false;

        if (filterFont) {
          if (user.fontMode) {
            if (user.fontMode !== filterFont) includeItem = false;
          } else {
            if (filterFont !== "normal") includeItem = false;
          }
        }

        if (filterAutoJoin === "yes" && !user.autoMergeRooms || filterAutoJoin === "no" && user.autoMergeRooms) includeItem = false;

        if (filterSound || filterGameSetting && filterGameSetting !== "autoPlay" && filterGameSetting !== "showHint") {
          try {
            const userSettingsSnapshot = await FirebaseRef.child(`usersSettings/${uid}`).once('value');
            const userSettings = userSettingsSnapshot.val() || null;

            if (filterSound === "yes") {
              if (userSettings && userSettings.userSettings && userSettings.userSettings.soundOn === false) includeItem = false;
            } else if (filterSound === "no") {
              if (!userSettings || !userSettings.userSettings || userSettings.userSettings.soundOn === true) includeItem = false;
            }

            if (filterGameSetting === "leavingRoomBeforeEnd") {
              if (userSettings && userSettings.notificationSettings && userSettings.notificationSettings.leaveRoom === false) {
                includeItem = false;
              }
            }
            else if (filterGameSetting === "choosingLastRound") {
              if (userSettings && userSettings.notificationSettings && userSettings.notificationSettings.lastRound === false) {
                includeItem = false;
              }
            }
            else if (filterGameSetting === "choosingFold") {
              if (userSettings && userSettings.notificationSettings && userSettings.notificationSettings.quitRound === false) {
                includeItem = false;
              }
            }
            else if (filterGameSetting === "ignoringPlayer") {
              if (userSettings && userSettings.notificationSettings && userSettings.notificationSettings.ignorePlayer === false) {
                includeItem = false;
              }
            }
            else if (filterGameSetting === "leavingGameAccount") {
              if (userSettings && userSettings.notificationSettings && userSettings.notificationSettings.quitAccount === false) {
                includeItem = false;
              }
            }
            else if (filterGameSetting === "purchasingGift") {
              if (userSettings && userSettings.notificationSettings && userSettings.notificationSettings.purchaseGift === false) {
                includeItem = false;
              }
            }
            else if (filterGameSetting === "removingGiftFromProfile") {
              if (userSettings && userSettings.notificationSettings && userSettings.notificationSettings.removeGift === false) {
                includeItem = false;
              }
            }
            else if (filterGameSetting === "showResultsAfterEachRound") {
              if (userSettings && userSettings.notificationSettings && userSettings.notificationSettings.showAfterRound === false) {
                includeItem = false;
              }
            }
            else if (filterGameSetting === "showResultsAtTheEndOfRoom") {
              if (userSettings && userSettings.notificationSettings && userSettings.notificationSettings.showAtRoomEnd === false) {
                includeItem = false;
              }
            }

            else if (filterGameSetting === "doNotShowAnyConfirmationWindow") {
              if (userSettings && userSettings.notificationSettings) {
                if ((userSettings.notificationSettings.leaveRoom
                  || userSettings.notificationSettings.lastRound
                  || userSettings.notificationSettings.quitRound
                  || userSettings.notificationSettings.ignorePlayer
                  || userSettings.notificationSettings.quitAccount
                  || userSettings.notificationSettings.purchaseGift
                  || userSettings.notificationSettings.removeGift
                  || userSettings.notificationSettings.showAfterRound
                  || userSettings.notificationSettings.showAtRoomEnd)
                ) includeItem = false;
              } else {
                includeItem = false;
              }
            }

            if (filterGameSetting === "cardPlayWithOneClick" && userSettings.gameSettings.doubleClickPlay) includeItem = false;

          } catch (err) {
            console.error(`sound fetch err ${uid}:`, err);
            includeItem = false;
          }
        }

        if (filterGameSetting === "autoPlay" && user.disableAutoPlay) includeItem = false;
        if (filterGameSetting === "showHint" && user.showHint) includeItem = false;

        if (filterVerified === "true" && !user.verifiedEmail || filterVerified === "false" && user.verifiedEmail) includeItem = false;

        if (filterRegEnabled && !(user.dateCreated >= filterRegStartDate && user.dateCreated <= filterRegEndDate)) includeItem = false;
        if (filterLastLoginEnabled && !(user.lastLogin >= filterLastLoginStartDate && user.lastLogin <= filterLastLoginEndDate)) includeItem = false;
        if (filterBalanceEnabled && !(user.bal >= filterBalanceFrom && user.bal <= filterBalanceTo)) includeItem = false;
        if (filterPointsEnabled && !(user.totalPnts >= filterPointsFrom && user.totalPnts <= filterPointsTo)) includeItem = false;
        if (filterPlayedGamesEnabled && !(user.gPlayed >= filterPlayedGamesFrom && user.gPlayed <= filterPlayedGamesTo)) includeItem = false;
        if (filterLevelEnabled && !(user.lvl >= filterLevelFrom && user.lvl <= filterLevelTo)) includeItem = false;

        if (filterAchievementsEnabled) {
          let claimedAchievementsHistory = {};
          try {
            const claimedAchievementsHistorySnapshot = await FirebaseRef.child(`claimedAchievementsHistory/${uid}`).once('value');
            claimedAchievementsHistory = claimedAchievementsHistorySnapshot.val() || {};

            const count = Object.values(claimedAchievementsHistory).filter(achievement => achievement.time >= filterAchievementsStartDate && achievement.time <= filterAchievementsEndDate).length;
            if (count === 0) includeItem = false;

          } catch (err) {
            console.error("err loading completed claimedAchievementsHistory:", err, uid);
            includeItem = false;
          }
        }
        if (filterRewardsEnabled) {
          let claimedRewards = {};
          try {
            const claimedRewardsSnapshot = await FirebaseRef.child(`claimedRewards/${uid}`).once('value');
            claimedRewards = claimedRewardsSnapshot.val() || {};

            const count = Object.keys(claimedRewards).length;

            if (!(count >= filterRewardsFrom && count <= filterRewardsTo)) includeItem = false;

          } catch (err) {
            console.error("err loading completed claimedRewards:", err, uid);
            includeItem = false;
          }
        }
        if (filterTutorialBonus === "yes" && !user.tutorialBonus || filterTutorialBonus === "no" && user.tutorialBonus) includeItem = false;
        if (filterAndroidAppBonus === "yes" && !user.AndroidBonus || filterAndroidAppBonus === "no" && user.AndroidBonus) includeItem = false;
        if (filterIOSAppBonus === "yes" && !user.IOSBonus || filterIOSAppBonus === "no" && user.IOSBonus) includeItem = false;

        return includeItem ? [uid, user] : null;
      })
    );

    const sortedEntries = Object.fromEntries(
      entries.filter(Boolean)
        .sort(([, userA], [, userB]) => userB.lastLogin - userA.lastLogin)
    );

    return sortedEntries;
  };

  const fetchFilteredData = (query) => {

    return query.once('value')
      .then(snapshot => {
        let data = snapshot.val() || {};
        return applyFilters(data);
      })
      .catch(error => {
        console.log("err fetchFilteredData:", error);
        return {};
      });
  };

  const dispatchFilteredData = (query, dispatch) => {
    return fetchFilteredData(query)
      .then(data => dispatch({ type: 'ADMIN_FILTERED_USERS', data }))
      .catch(err => console.log(err.message));
  };

  return dispatch => {
    let query;

    if (!filterValue) {
      query = FirebaseRef.child('users').orderByChild('lastLogin').limitToLast(20000);
    } else if (filterType === constants.USERNAME_LOWER_UPPER.lowerCaseName || filterType === constants.USERNAME_LOWER_UPPER.lowerCaseLastName) {
      query = FirebaseRef.child('users').orderByChild(filterType).startAt(filterValue.toLowerCase()).endAt(`${filterValue.toLowerCase()}\uf8ff`);
    } else if (filterType === 'uid') {
      query = FirebaseRef.child('users').orderByKey().equalTo(filterValue.toString()).limitToFirst(1);
    } else if (filterType === 'email') {
      query = FirebaseRef.child('users').orderByChild(filterType).startAt(filterValue).endAt(`${filterValue}\uf8ff`);
    } else if (filterType === 'lastIp') {
      return FirebaseRef.child('usersLastIp').orderByValue().equalTo(filterValue).once('value')
        .then(snapshot => {const matchingKeys = Object.keys(snapshot.val() || {});
          if (matchingKeys.length === 0) {
            return dispatch({ type: 'ADMIN_FILTERED_USERS', data: {} });
          }

          const promises = matchingKeys.map(key => FirebaseRef.child('users').child(key).once('value'));
          return Promise.all(promises).then(snapshots => {
            let data = {};
            snapshots.forEach(snap => {
              if (snap.exists()) {
                data[snap.key] = snap.val();
              }
            });
            return applyFilters(data).then(filteredData =>
              dispatch({ type: 'ADMIN_FILTERED_USERS', data: filteredData })
            );
          });
        })
        .catch(err => console.log(err.message));
    }

    return dispatchFilteredData(query, dispatch);
  };

}


export function getNonVerifiedUsers(filterValue, filterType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  console.log('users fetch getNonVerifiedUsers', filterValue, filterType);

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .orderByChild('verifiedEmail')
    .equalTo(false)
    // .limitToFirst(300)
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};
      const array = [];

      console.log('data', data);

      Object.keys(data).map((key) => {
        if (filterValue && filterType) {
          if (filterType === 'lowerCaseName') {
            if (data[key].lowerCaseName && data[key].lowerCaseName.includes(filterValue.toLowerCase())) {
              array.push({ key, ...data[key] });
            }
          } else if (filterType === 'lowerCaseLastName') {
            if (data[key].lowerCaseLastName && data[key].lowerCaseLastName.includes(filterValue.toLowerCase())) {
              array.push({ key, ...data[key] });
            }
          } else if (filterType === 'uid') {
            if (key && key.includes(filterValue.toLowerCase())) {
              array.push({ key, ...data[key] });
            }
          } else if (filterType === 'email') {
            if (data[key].email && data[key].email.includes(filterValue.toLowerCase())) {
              array.push({ key, ...data[key] });
            }
          }
        } else {
          array.push({ key, ...data[key] });
        }
        return null;
      });

      array.sort((a, b) => (b.lastLogin - a.lastLogin));

      // show only users from social networks, who have set contactEmail, but have not verified it
      const filteredArray = array.filter(user => (
        (!['draugiem', 'inbox', 'facebook'].includes(user.socProvider) ||
        (user.contactEmail && user.contactEmail !== constants.NO_EMAIL_USER && !user.contactEmail.endsWith('@openid.inbox.lv') && !user.emailVerified)) &&
        user.verifiedEmailSent
      ));

      return resolve(dispatch({ type: 'ADMIN_NONVERIFIED_REPLACE', data: filteredArray }));
    })).catch((err) => { console.log(err.message); });
}

export function getAutoCompensations() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child('autoCompensations')
    .once('value', (snapshot) => {
      const autoCompensations = snapshot.val() || {};

      const sortedCompensations = Object.fromEntries(
        Object.entries(autoCompensations)
          .sort(
            ([, a], [, b]) =>
              b.lastDate - a.lastDate
          )
      );

      return resolve(dispatch({ type: 'ADMIN_AUTO_COMPENSATIONS_REPLACE', data: sortedCompensations }));
    })).catch((err) => { console.log(err.message); });
}

export function disableAutoCompensation(uid, autoCompensation, name) {
  return () => new Promise((resolve, reject) => {

    if (!uid) return reject({ code: 'Missing UID' });

    const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
    const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
    const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;


    const disableAutoCompensationFunction = Firebase.app().functions('europe-west1').httpsCallable('disableAutoCompensation');

    disableAutoCompensationFunction({uid, autoCompensation}).then((res) => {
      if (res.data.status === 'error') {
        return resolve({ status: constants.SERVER_STATUS_CODE.error, message: 'error' });
      } else {

        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'disableAutoCompensation',
          adminId: UID,
          adminEmail,
          adminName,
          userId: uid.toString(),
          userName: name || null,
          data: {
            disabled: !!autoCompensation,
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });

        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      }

    }).catch(e => {
      console.log(e, "disable auto compensation error")
      return reject({ code: e.code });
    });
  });
}

export function getRoomLogCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/roomsPlayed')
    .on('value', (snapshot) => {
      const roomsPlayed = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ADMIN_ROOM_LOGS_COUNT', data: roomsPlayed }));
    })).catch((err) => { console.log(err.message); });
}

export function getRoomLogCount2() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/roomsPlayed2')
    .on('value', (snapshot) => {
      const roomsPlayed = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ADMIN_ROOM_LOGS_COUNT_2', data: roomsPlayed }));
    })).catch((err) => { console.log(err.message); });
}


export function getRoomLogsRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child('adminLogs/roomIds')
    .orderByChild('index')
    .startAt(start)
    .endAt(end)
    .once('value', async (snapshot) => {
      const roomLogs = snapshot.val() || {};
      forEach(roomLogs, async (item, key) => {
        const promise1 = await AdminLogsRef().child(`adminLogs/rooms/${key}`).once('value');
        roomLogs[key] = { ...roomLogs[key], partyCount: size(promise1.val()) - 1 };
      });
      console.log(roomLogs, "check===================")

      return resolve(dispatch({ type: 'ADMIN_ROOM_LOGS_REPLACE', data: roomLogs }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}


// export function getFilteredRoomLogs(filterValue, filterType, filterMinValue, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, filterByTimeBetween, filterByTimeBetweenTime, showBotRoom, startDate, endDate) {
//   if (Firebase === null) return () => new Promise(resolve => resolve());
//   console.log('getFilteredRoomLogs', { filterValue, filterType, filterMinValue, filterSpeed, filterBet, filterAmountOfPlayer, filterCloseReason, filterByTimeBetween, filterByTimeBetweenTime, showBotRoom, startDate, endDate });


//   if (!filterType || (!filterValue && !filterMinValue && !startDate && !endDate && !filterSpeed && !filterBet && !filterAmountOfPlayer && !(filterByTimeBetween !== '' && filterByTimeBetweenTime) && filterType !== constants.MISSEDTURN && !filterCloseReason && !showBotRoom)) return dispatch => new Promise(resolve => resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: null })));

//   if (filterByTimeBetween !== '' && filterByTimeBetweenTime) {
//     console.log('filter by time between', filterByTimeBetween, filterByTimeBetweenTime, (parseInt(filterByTimeBetweenTime, 10) * (1000 * 60)));

//     return dispatch => new Promise((resolve) => {
//       AdminLogsRef().child('adminLogs/roomIds')
//         .orderByChild(filterByTimeBetween)
//         .startAt(parseInt(filterByTimeBetweenTime, 10) * (1000 * 60))
//         .limitToLast(3000)
//         .once('value', async (snapshot) => {
//           const data = snapshot.val() || {};

//           console.log('test 2 filterByTimeBetween', data);

//           let updatedLogs = [];
//           if (Object.keys(data).length > 0) {
//             Object.entries(data).map(([key, item]) => {
//               if (item.date >= startDate && item.date <= endDate) {
//                 updatedLogs.push({
//                   ...item,
//                   key,
//                 });
//               }
//             });

//             updatedLogs.reverse();

//             console.log('updatedLogs', updatedLogs);
//           }

//           return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs }));
//         });
//     });
//   }


//   if (filterCloseReason !== '') {
//     if ([constants.LASTROUND, constants.LeftBeforeStart, constants.GAMEERROR, filterCloseReason === constants.LEFTROOM].includes(filterCloseReason)) { //  || filterCloseReason === constants.GAMEERROR || filterCloseReason === constants.LEFTROOM
//       return dispatch => new Promise((resolve) => {
//         AdminLogsRef().child('adminLogs/roomIds')
//           .orderByChild('closeReason')
//           .equalTo(filterCloseReason)
//           .limitToLast(35000)
//           .once('value', async (snapshot) => {
//             const data = snapshot.val() || {};

//             let updatedLogs = [];
//             if (Object.keys(data).length > 0) {
//               const promises = Object.entries(data).map(async ([key, item]) => {
//                 if (item.closeReason === filterCloseReason && item.date >= startDate && item.date <= endDate) {

//                   updatedLogs.push({
//                     ...item,
//                     key,
//                   });
//                 }
//               });

//               updatedLogs.reverse();

//               await Promise.all(promises);
//             }

//             return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs }));
//           });
//       });
//     }
//     const selectFilterCloseReason = constants.closeReason.filter(item => filterCloseReason === item.id).reduce((obj, key) => {
//       const objCopy = { ...obj };
//       objCopy[key] = constants.closeReason[key];
//       return objCopy;
//     });
//     return dispatch => new Promise(resolve => {
//       AdminLogsRef().child('adminLogs/roomIds')
//       .orderByChild('date')
//       .startAt(startDate)
//         .endAt(endDate)
//         .limitToLast(35000)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             if ((item.closeReason === selectFilterCloseReason.filterType && (!selectFilterCloseReason.isOnline && (data[key].isOnline === selectFilterCloseReason.isOnline || !data[key].isOnline)) && (!selectFilterCloseReason.triedToMove && (data[key].triedToMove === selectFilterCloseReason.triedToMove || !data[key].triedToMove))) || (item.closeReason === selectFilterCloseReason.filterType && (selectFilterCloseReason.isOnline && data[key].isOnline === selectFilterCloseReason.isOnline) && (selectFilterCloseReason.triedToMove && data[key].triedToMove === selectFilterCloseReason.triedToMove))) {

//               updatedLogs.push({
//                   ...item,
//                   key,
//               });
//             }
//           });

//           await Promise.all(promises);
//         }

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })
//     })
//   }

//   if (filterMinValue !== '') {
//     return dispatch => new Promise(resolve => {
//       AdminLogsRef().child('adminLogs/roomIds')
//       .orderByChild('date')
//       .startAt(startDate)
//         .endAt(endDate)
//         .limitToLast(35000)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             if (data[key]?.party >= parseInt(filterMinValue, 10)) {

//               updatedLogs.push({
//                   ...item,
//                   key,
//               });
//             }
//           });

//           await Promise.all(promises);
//         }

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })
//     })
//   }

//   if (filterSpeed !== '') {
//     return dispatch => new Promise(resolve => {
//       AdminLogsRef().child('adminLogs/roomIds')
//       .orderByChild('date')
//       .startAt(startDate)
//         .endAt(endDate)
//         .limitToLast(35000)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             if (data[key]?.speed === filterSpeed) {

//               updatedLogs.push({
//                   ...item,
//                   key,
//               });
//             }
//           });

//           await Promise.all(promises);
//         }
//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })
//     })
//   }

//   if (filterBet !== '') {
//     return dispatch => new Promise(resolve => {
//       AdminLogsRef().child('adminLogs/roomIds')
//       .orderByChild('date')
//       .startAt(startDate)
//         .endAt(endDate)
//         .limitToLast(35000)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             if (data[key]?.bet === filterBet) {

//               updatedLogs.push({
//                   ...item,
//                   key,
//               });
//             }
//           });

//           await Promise.all(promises);
//         }

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })
//     })
//   }

//   if (filterAmountOfPlayer !== '') {
//     console.log('has filterAmountOfPlayer');

//     if (filterAmountOfPlayer === 'true') {
//       return dispatch => new Promise(resolve => {
//         AdminLogsRef().child('adminLogs/roomIds')
//         .orderByChild('date')
//         .startAt(startDate)
//           .endAt(endDate)
//           .limitToLast(35000)
//         .once('value', async (snapshot) => {
//           const data = snapshot.val() || {};

//           let updatedLogs = [];
//           if (Object.keys(data).length > 0) {
//             const promises = Object.entries(data).map(async ([key, item]) => {
//               if (data[key]?.fourPRoom) {

//                 updatedLogs.push({
//                     ...item,
//                     key,
//                 });
//               }
//             });

//             await Promise.all(promises);
//           }

//           return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//         })
//       })
//     }

//     return dispatch => new Promise(resolve => {
//       AdminLogsRef().child('adminLogs/roomIds')
//       .orderByChild('date')
//       .startAt(startDate)
//       .endAt(endDate)
//         .limitToLast(35000)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         console.log('data', data);

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             if (!data[key]?.fourPRoom) {

//               updatedLogs.push({
//                   ...item,
//                   key,
//               });
//             }
//           });

//           await Promise.all(promises);
//         }

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })
//     })
//   }

//   console.log('adminLogs/roomIds test 2', { filterType, filterValue, startDate, endDate });

//   if (filterType === constants.ROOMID && !filterValue && startDate && endDate) {
//     return dispatch => new Promise(resolve => AdminLogsRef().child('adminLogs/roomIds')
//       .orderByChild('date')
//       .startAt(startDate)
//       .endAt(endDate)
//       .limitToLast(35000)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             updatedLogs.push({
//               ...item,
//               key,
//             });
//           });

//           await Promise.all(promises);
//         }

//         console.log('updatedLogs 1', updatedLogs);

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })).catch((err) => { console.log(err.message); });
//   }

//   if (filterType === constants.ROOMID) {
//     return dispatch => new Promise(resolve => AdminLogsRef().child('adminLogs/roomIds')
//       .orderByKey()
//       .startAt(filterValue.trim())
//       .endAt(`${filterValue.trim()}\uf8ff`)
//       .limitToLast(2500)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             updatedLogs.push({
//               ...item,
//               key,
//             });
//           });

//           await Promise.all(promises);
//         }

//         console.log('updatedLogs 2', updatedLogs);

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })).catch((err) => { console.log(err.message); });
//   }

//   if (filterType === constants.USERID && startDate && endDate) {
//     return dispatch => new Promise(resolve => AdminLogsRef().child(`adminLogs/playerRooms/${filterValue}`)
//       .orderByChild('date')
//       .startAt(startDate)
//       .endAt(endDate)
//       .limitToLast(2500)
//         .once('value', async (snapshot) => {
//           const data = snapshot.val() || {};
//           console.log(data, "checkRoomId=");


//           let updatedLogs = [];
//           if (Object.keys(data).length > 0) {
//             const promises = Object.entries(data).map(async ([key, item]) => {
//               updatedLogs.push({
//                 ...item,
//                 key,
//               });
//             });

//             await Promise.all(promises);
//           }

//           return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//         })).catch((err) => { console.log(err.message); });
//   }

//   if (filterType === constants.USERID) {
//     return dispatch => new Promise(resolve => AdminLogsRef().child(`adminLogs/playerRooms/${filterValue}`).limitToLast(1000)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};
//         console.log(data, "checkRoomId=");

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             updatedLogs.push({
//               ...item,
//               key,
//             });
//           });

//           await Promise.all(promises);
//         }

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })).catch((err) => { console.log(err.message); });
//   }

//   if (showBotRoom) {
//     return dispatch => new Promise(resolve => {
//       AdminLogsRef().child('adminLogs/roomIds')
//       .orderByChild('date')
//       .startAt(startDate)
//       .endAt(endDate)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             if (data[key]?.hasBots) {

//               updatedLogs.push({
//                   ...item,
//                   key,
//               });
//             }
//           });

//           await Promise.all(promises);
//         }

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })
//     })
//   }

//   if (filterType === constants.MISSEDTURN) {
//     return dispatch => new Promise(resolve => {
//       AdminLogsRef().child('adminLogs/roomIds')
//       .orderByChild('date')
//       .startAt(startDate)
//       .endAt(endDate)
//       .once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           const promises = Object.entries(data).map(async ([key, item]) => {
//             if (data[key]?.closeReason === constants.MISSEDTURN) {

//               updatedLogs.push({
//                   ...item,
//                   key,
//               });
//             }
//           });

//           await Promise.all(promises);
//         }

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       })
//     })
//   }
//   if (startDate && endDate) {
//     return dispatch => new Promise(resolve => {
//       let query = AdminLogsRef().child('adminLogs/roomIds')
//         .orderByChild(filterType)
//         .startAt(filterValue.trim())
//         .endAt(`${filterValue.trim()}\uf8ff`)
//         .limitToLast(3000);

//       query.once('value', async (snapshot) => {
//         const data = snapshot.val() || {};

//         console.log('datadatadata', data, filterType, filterValue);

//         let updatedLogs = [];
//         if (Object.keys(data).length > 0) {
//           updatedLogs = Object.entries(data)
//             .map(([key, item]) => ({ ...item, key }))
//             .filter(item => item.date >= startDate && item.date <= endDate);
//         }

//         return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//       });
//     }).catch((err) => {
//       console.log(err.message);
//     });
//   }
//   return dispatch => new Promise(resolve => AdminLogsRef().child('adminLogs/roomIds')
//     .orderByChild(filterType)
//     .startAt(filterValue.trim())
//     .endAt(`${filterValue.trim()}\uf8ff`)
//     .limitToLast(3000)
//     .once('value', async (snapshot) => {
//       const data = snapshot.val() || {};

//       console.log('datadatadata', data, filterType, filterValue);

//       let updatedLogs = [];
//       if (Object.keys(data).length > 0) {
//         const promises = Object.entries(data).map(async ([key, item]) => {
//           updatedLogs.push({
//             ...item,
//             key,
//           });
//         });

//         await Promise.all(promises);
//       }

//       return resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: updatedLogs.reverse() }));
//     })).catch((err) => { console.log(err.message); });
// }

export function getFilteredRoomLogs(
  filterValue,
  filterType,
  filterMinValue,
  filterPlatform,
  filterSpeed,
  filterGames,
  filterBet,
  filterGameType,
  filterAmountOfPlayer,
  filterCloseReason,
  filterByTimeBetween,
  filterByTimeBetweenTime,
  filterBots,
  filterTournament,
  showActiveRooms,
  showMergedRooms,
  showAutoPlayRooms,
  excludeLeftBeforeStartRooms,
  startDate,
  endDate,
  winPoints,
  winCoins,
  lossPoints,
  lossCoins,
) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  if (
    !filterType &&
    !filterValue &&
    (!filterMinValue || filterMinValue.length === 0) &&
    !startDate &&
    !endDate &&
    !filterSpeed &&
    !filterGames &&
    (!filterBet || filterBet.length === 0) &&
    (!filterGameType || filterGameType.length === 0) &&
    (!filterPlatform || filterPlatform.length === 0) &&
    !filterAmountOfPlayer &&
    !filterCloseReason &&
    !filterBots &&
    !filterTournament &&
    !showActiveRooms &&
    !showMergedRooms &&
    !showAutoPlayRooms &&
    !excludeLeftBeforeStartRooms &&
    !winPoints &&
    !winCoins &&
    !lossPoints &&
    !lossCoins
  ) {
    return dispatch =>
      new Promise(resolve =>
        resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: null }))
      );
  }

  return dispatch =>
    new Promise(async resolve => {
      try {
        const activeRoomsSnapshot = await FirebaseRef.child('activeRooms').once('value');
        const activeRooms = activeRoomsSnapshot.val() || {};
        const activeRoomIds = Object.keys(activeRooms);

        let query;
        let queries = [];
        if (filterType === constants.LASTIP && filterValue) {
          const lastIpSnapshot = await FirebaseRef.child('usersLastIp').once('value');
          const lastIpData = lastIpSnapshot.val() || {};
          const userIdsWithSpecificIp = Object.keys(lastIpData).filter(userId => lastIpData[userId] === filterValue);

          if (!showActiveRooms) {
            for (const userId of userIdsWithSpecificIp) {
              queries.push(AdminLogsRef().child(`adminLogs/playerRooms/${userId}`).orderByChild('date').startAt(startDate).endAt(endDate).limitToLast(35000));
            }
          } else {
            for (const userId of userIdsWithSpecificIp) {
              queries.push(AdminLogsRef().child(`adminLogs/playerRooms/${userId}`).orderByChild('date').limitToLast(35000));
            }
          }
        } else {
          if (!showActiveRooms) {
            query = AdminLogsRef().child(`adminLogs/roomIds`).orderByChild('date').startAt(startDate).endAt(endDate).limitToLast(35000);
          } else {
            query = AdminLogsRef().child(`adminLogs/roomIds`).orderByChild('date').limitToLast(35000);
          }

          if (filterType === constants.ROOMID && filterValue) {
            query = AdminLogsRef().child(`adminLogs/roomIds/${filterValue}`);
          }

          if (filterType === constants.TOURNAMENTID && filterValue) {
            query = AdminLogsRef().child('adminLogs/roomIds').orderByChild('tournamentId').equalTo(filterValue).limitToLast(35000);
          }

          if (filterType === constants.USERID && filterValue) {
            if (!showActiveRooms) {
              query = AdminLogsRef().child(`adminLogs/playerRooms/${filterValue}`).orderByChild('date').startAt(startDate).endAt(endDate).limitToLast(35000);
            } else {
              query = AdminLogsRef().child(`adminLogs/playerRooms/${filterValue}`).orderByChild('date').limitToLast(35000);
            }
          }
        }

        const filterByTimeBetweenTimeMs = parseInt(filterByTimeBetweenTime, 10) * (1000 * 60);

        const selectFilterCloseReason = filterCloseReason
          ? constants.closeReason.filter(item => filterCloseReason === item.id).reduce((obj, key) => {
                const objCopy = { ...obj };
                objCopy[key] = constants.closeReason[key];
                return objCopy;
          }) : null;

        const roomsPlayedSnapshot = await FirebaseRef.child('statistics/roomsPlayed').once('value');
        const roomsPlayedLastIndexSnapshot = await FirebaseRef.child('statistics/roomsPlayedLastIndex').once('value');
        const roomsPlayed = roomsPlayedSnapshot.val() || 0;
        const roomsPlayedLastIndex = roomsPlayedLastIndexSnapshot.val() || 0;

        const totalRoomCount = roomsPlayed - roomsPlayedLastIndex;


        let data = {};

        if (filterType === constants.LASTIP && filterValue) {
          for (const lastIpQuery of queries) {
            const lastIpQuerySnapshot = await lastIpQuery.once('value');
            const lastIpQueryData = lastIpQuerySnapshot.val() || {};

            data = { ...data, ...lastIpQueryData}
          }

        } else {
          const querySnapshot = await query.once('value');
          const queryData = querySnapshot.val() || {};

          if (filterType === constants.ROOMID && filterValue) {
            data = queryData ? { [filterValue]: queryData } : {};
          } else {
            data = queryData || {};
          }
        }

        let updatedLogs = [];

        if (Object.keys(data).length > 0) {
          const promises = Object.entries(data).map(async ([key, item]) => {
            let includeItem = true;

            if (showActiveRooms && !activeRoomIds.includes(key)) {
              includeItem = false;
            }

            if ((filterType === constants.USERID || filterType === constants.LASTIP) && filterValue) {
              const roomSnapshot = await AdminLogsRef().child(`adminLogs/roomIds/${key}`).once('value');
              const roomData = roomSnapshot.val();

              if (!roomData) includeItem = false;
              if (filterBots && (!!roomData.hasBots).toString() !== filterBots) includeItem = false;
              if (filterByTimeBetween !== '' && filterByTimeBetweenTime && (!roomData[filterByTimeBetween] || roomData[filterByTimeBetween] < filterByTimeBetweenTimeMs)) includeItem = false;
              if (filterAmountOfPlayer && filterAmountOfPlayer === '2players' && !roomData.studentRoom) includeItem = false;
              if (filterAmountOfPlayer && filterAmountOfPlayer === '4players' && !roomData.fourPRoom) includeItem = false;
              if (filterAmountOfPlayer && filterAmountOfPlayer === '3players' && roomData.fourPRoom) includeItem = false;
              if (filterTournament && (!!roomData.tournamentId).toString() !== filterTournament) includeItem = false;
              if (showAutoPlayRooms && !roomData.hadAutomatedPlay) includeItem = false;
              if (showMergedRooms && !roomData.hasAutoMerge) includeItem = false;
            } else {
              if (filterBots && (!!item.hasBots).toString() !== filterBots) includeItem = false;
              if (filterByTimeBetween !== '' && filterByTimeBetweenTime && (!item[filterByTimeBetween] || item[filterByTimeBetween] < filterByTimeBetweenTimeMs)) includeItem = false;
              if (filterAmountOfPlayer && filterAmountOfPlayer === '2players' && !item.studentRoom) includeItem = false;
              if (filterAmountOfPlayer && filterAmountOfPlayer === '4players' && !item.fourPRoom) includeItem = false;
              if (filterAmountOfPlayer && filterAmountOfPlayer === '3players' && item.fourPRoom) includeItem = false;
              if (filterTournament && (!!item.tournamentId).toString() !== filterTournament) includeItem = false;
              if (showAutoPlayRooms && !item.hadAutomatedPlay) includeItem = false;
              if (showMergedRooms && !item.hasAutoMerge) includeItem = false;
            }

            if (includeItem) {
              if (filterCloseReason && selectFilterCloseReason &&
                ((item.closeReason !== selectFilterCloseReason.id &&
                  (item.closeReason !== selectFilterCloseReason.filterType || item.triedToMove !== selectFilterCloseReason.triedToMove || item.isOnline !== selectFilterCloseReason.isOnline)
                ) || !item.closeReason)
              ) includeItem = false;

              if (excludeLeftBeforeStartRooms && item.closeReason === "leftBeforeStart") includeItem = false;
              if (filterMinValue && filterMinValue.length !== 0 && !filterMinValue.includes(item.minGames.toString())) includeItem = false;
              if (filterSpeed && item.speed !== filterSpeed) includeItem = false;
              if (filterBet && filterBet.length !== 0 && !filterBet.includes(item.bet)) includeItem = false;
              if (item.date < startDate || item.date > endDate) includeItem = false;

              if (filterPlatform && filterPlatform.length !== 0) {
                if (item.envInRoom) {
                  for (const filterPlatformPart of filterPlatform) {
                    if (!item.envInRoom[filterPlatformPart]) {
                      includeItem = false;
                      break;
                    }
                  }
                } else {
                  includeItem = false;
                }
              }

              if (filterGames && (!item.party || item.party < parseInt(filterGames, 10))) includeItem = false;

              if (filterGameType && filterGameType.length !== 0 && !filterGameType.includes(getDbRoomGameType(item))) includeItem = false;


              if (winPoints || winCoins || lossPoints || lossCoins) {
                try {
                  const roomPointsSnapshot = await RoomsRef.child(`roomsPoints/${key}/`).once('value');
                  const pointsData = roomPointsSnapshot.val() || {};

                  if (pointsData && pointsData.points) {
                    if (winPoints) {
                      if (pointsData.points.total) {
                        const hasValue = Object.values(pointsData.points.total).some(points => points >= winPoints);
                        if (!hasValue) includeItem = false;
                      }
                    }
                    if (winCoins) {
                      if (pointsData.points.bal) {
                        const hasValue = Object.values(pointsData.points.bal).some(points => points >= winCoins);
                        if (!hasValue) includeItem = false;
                      }
                    }
                    if (lossPoints) {
                      if (pointsData.points.total) {
                        const hasValue = Object.values(pointsData.points.total).some(points => points <= -lossPoints);
                        if (!hasValue) includeItem = false;
                      }
                    }
                    if (lossCoins) {
                      if (pointsData.points.bal) {
                        const hasValue = Object.values(pointsData.points.bal).some(points => points <= -lossCoins);
                        if (!hasValue) includeItem = false;
                      }
                    }
                  } else {
                    includeItem = false;
                  }
                } catch (error) {
                  console.log('roomsPoints err:', error);
                  includeItem = false;
                }
              }
            }

            if (includeItem) {
              updatedLogs.push({ ...item, key });
            }
          });

          await Promise.all(promises);
        }

        updatedLogs.reverse();
        return resolve( dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: { logs: updatedLogs, totalRoomCount } }));

      } catch (err) {
        console.log('Error:', err.message);
        resolve(dispatch({ type: 'ADMIN_FILTERED_ROOM_LOGS', data: { logs: [], totalRoomCount: 0 } }));
      }
    });
}

export function getMissedRooms(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!start || !end) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => AdminLogsRef().child('missedRoomsData')
    .orderByChild('date')
    .startAt(start)
    .endAt(end)
    .once('value', (snapshot) => {
      const roomLogs = snapshot.val() || {};

      return resolve(roomLogs);
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function getSuspiciousPlayers2() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child('roomsTogether/suspiciousPlayers')
    .once('value', (snapshot) => {
      const suspiciousPlayers = snapshot.val() || {};

      return resolve(dispatch({ type: 'SUSPICIOUS_PLAYERS', data: suspiciousPlayers }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function getSuspiciousPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child('roomsTogether2/players').orderByChild('count').startAt(5)
    .once('value', (snapshot) => {
      const suspiciousPlayers = snapshot.val() || {};

      return resolve(dispatch({ type: 'SUSPICIOUS_PLAYERS', data: suspiciousPlayers }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function getSuspiciousPlayersRooms(player1, player2) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child(`roomsTogether2/playerRooms/${player1}--${player2}`)
    .once('value', (snapshot) => {
      const rooms = snapshot.val() || {};

      console.log('rooms', rooms);

      return resolve(dispatch({ type: 'SUSPICIOUS_PLAYERS_ROOMS', data: rooms }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function getFilteredTransactions(provider, purchasedItem, dateFrom, dateTo, show, UUID, devMode, source) {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  const startAt = new Date(dateFrom).setHours(0, 0, 0, 0);
  const endAt = new Date(dateTo).setHours(23, 59, 59, 59);
  return dispatch => new Promise((resolve) => {
    if (dateFrom != null && dateTo != null) {
      FirebaseRef.child(show)
        .orderByChild('dateInitiated')
        .startAt(startAt)
        .endAt(endAt)
        .once('value', (snapshot) => {
        let data = snapshot.val() || {};
          if (UUID !== '') {
            data = Object.keys(data).filter(key => data[key].userUid === UUID).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (provider !== 'all') {
            console.log('data 2', data);

            data = Object.keys(data).filter(key => data[key].provider === provider).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (purchasedItem != null) {
            const purchasedItemArray = [];
            Object.keys(purchasedItem).map((key1) => {
              purchasedItemArray.push(purchasedItem[key1].value);
              return null;
            });
            data = Object.keys(data).filter(key => purchasedItemArray.includes(data[key].productNr ? data[key].productNr.toString() : '') === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }
          if (source !== 'all') {
            console.log('data 2', data);

            data = Object.keys(data).filter(key => data[key]?.paymentInfo?.source === source).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }

          if (devMode) {
            data = Object.keys(data).filter(key => data[key].devMode === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          } else {
            data = Object.keys(data).filter(key => !data[key].devMode)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }
          console.log('data check devMode', data, devMode);
          return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data }));
        });
    } else if (dateFrom != null && dateTo == null) {
      FirebaseRef.child(show)
        .orderByChild('dateInitiated')
        .startAt(startAt)
        .once('value', (snapshot) => {
          let data = snapshot.val() || {};
          if (UUID !== '') {
            data = Object.keys(data).filter(key => data[key].userUid === UUID).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (provider !== 'all') {
            data = Object.keys(data).filter(key => data[key].provider === provider).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (purchasedItem != null) {
            const purchasedItemArray = [];
            Object.keys(purchasedItem).map((key1) => {
              purchasedItemArray.push(purchasedItem[key1].value);
              return null;
            });
            data = Object.keys(data).filter(key => purchasedItemArray.includes(data[key].productNr ? data[key].productNr.toString() : '') === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }

          if (devMode) {
            data = Object.keys(data).filter(key => data[key].devMode === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          } else {
            data = Object.keys(data).filter(key => !data[key].devMode)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }
          console.log('data', data);

          return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data }));
        });
    } else if (dateFrom == null && dateTo != null) {
      FirebaseRef.child(show)
        .orderByChild('dateInitiated')
        .endAt(endAt)
        .once('value', (snapshot) => {
          let data = snapshot.val() || {};
          if (UUID !== '') {
            data = Object.keys(data).filter(key => data[key].userUid === UUID).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (provider !== 'all') {
            data = Object.keys(data).filter(key => data[key].provider === provider).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (purchasedItem != null) {
            const purchasedItemArray = [];
            Object.keys(purchasedItem).map((key1) => {
              purchasedItemArray.push(purchasedItem[key1].value);
              return null;
            });
            data = Object.keys(data).filter(key => purchasedItemArray.includes(data[key].productNr ? data[key].productNr.toString() : '') === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }

          if (devMode) {
            data = Object.keys(data).filter(key => data[key].devMode === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          } else {
            data = Object.keys(data).filter(key => !data[key].devMode)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }
          console.log('dataaaa', data);

          return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data }));
        });
    } else {
      FirebaseRef.child(show)
        .orderByChild('dateInitiated')
        .once('value', (snapshot) => {
          let data = snapshot.val() || {};
          if (UUID !== '') {
            data = Object.keys(data).filter(key => data[key].userUid === UUID).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (provider !== 'all') {
            console.log('data', data);
            data = Object.keys(data).filter(key => data[key].provider === provider).reduce((obj, key) => {
              obj[key] = data[key];
              return obj;
            }, {});
          }
          if (purchasedItem != null) {
            const purchasedItemArray = [];
            Object.keys(purchasedItem).map((key1) => {
              purchasedItemArray.push(purchasedItem[key1].value);
              return null;
            });
            data = Object.keys(data).filter(key => purchasedItemArray.includes(data[key].productNr ? data[key].productNr.toString() : '') === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }

          if (devMode) {
            data = Object.keys(data).filter(key => data[key].devMode === true)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          } else {
            data = Object.keys(data).filter(key => !data[key].devMode)
              .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
              }, {});
          }
          console.log('data', data);

          return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data }));
        });
    }
  }).catch((err) => { console.log(err.message); });
}

export function getUserName(userId) {
  if (!Firebase) {
    return Promise.resolve({ status: "error", name: "" });
  }

  return new Promise((resolve, reject) => {
    FirebaseRef.child('users')
      .orderByChild('uid')
      .equalTo(userId)
      .once('value')
      .then(snapshot => {
        const userData = snapshot.val();
        if (userData) {
          const user = Object.values(userData)[0];

          resolve({ status: "success", name: user.name });
        } else {
          resolve({ status: "error", name: "" });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}


export function getPaymentsRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('initiatedPayments')
    .orderByChild('index')
    .startAt(start)
    .endAt(end)
    .once('value', (snapshot) => {
      const initiatedPayments = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data: initiatedPayments }));
    })).catch((err) => { console.log(err.message); });
}


export function getCompletedPaymentsRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('completedPayments')
    .orderByChild('index')
    .startAt(start)
    .endAt(end)
    .once('value', (snapshot) => {
      const completedPayments = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data: completedPayments }));
    })).catch((err) => { console.log(err.message); });
}

export function getInitiatedPaymentsRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('incompletePayments')
    .orderByChild('index')
    .startAt(start)
    .endAt(end)
    .once('value', (snapshot) => {
      const initiatedPayments = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data: initiatedPayments }));
    })).catch((err) => { console.log(err.message); });
}

export function getCancelledPaymentsRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('failedPayments')
    .orderByChild('index')
    .startAt(start)
    .endAt(end)
    .once('value', (snapshot) => {
      const failedPayments = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_PAYMENTS_REPLACE', data: failedPayments }));
    })).catch((err) => { console.log(err.message); });
}

export function getPaymentsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('initiatedPaymentsCount/count')
      .on('value', (snapshot) => {
        const paymentsCount = snapshot.val() || 0;
        dispatch({ type: 'ADMIN_PAYMENTS_COUNT', data: paymentsCount });

        FirebaseRef.child('initiatedPaymentsRemovedCount/count')
          .on('value', (snapshot2) => {
            const removedPaymentsCount = snapshot2.val() || 0;

            return resolve(dispatch({ type: 'ADMIN_REMOVED_PAYMENTS_COUNT', data: removedPaymentsCount }));
          });
      });
  }).catch((err) => { console.log(err.message); });
}


export function getCompletedPaymentsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('completedPaymentsCount/count')
    .once('value', (snapshot) => {
      const paymentsCount = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ADMIN_COMPLETED_PAYMENTS_COUNT', data: paymentsCount }));
    })).catch((err) => { console.log(err.message); });
}

export function getInitiatedPaymentsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('incompletePaymentsCount/count')
    .once('value', (snapshot) => {
      const paymentsCount = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ADMIN_INITIATED_PAYMENTS_COUNT', data: paymentsCount }));
    })).catch((err) => { console.log(err.message); });
}

export function getCancelledPaymentsCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('incompletePaymentsCount/count')
    .once('value', (snapshot) => {
      const paymentsCount = snapshot.val() || 0;

      return resolve(dispatch({ type: 'ADMIN_INITIATED_PAYMENTS_COUNT', data: paymentsCount }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllUsers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('getAllUsers');

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_USERS_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}


// export function getUserMessages(uid) {
//   if (Firebase === null) return () => new Promise(resolve => resolve());

//   const UID = (
//     FirebaseRef
//     && Firebase
//     && Firebase.auth()
//     && Firebase.auth().currentUser
//     && Firebase.auth().currentUser.uid
//   ) ? Firebase.auth().currentUser.uid : null;

//   if (!UID) return () => new Promise(resolve => resolve());

//   return dispatch => new Promise(resolve => FirebaseRef.child(`supportChat/messages/${uid.toString()}`)
//     .limitToLast(100)
//     .on('value', (snapshot) => {
//       const messages = snapshot.val() || {};

//       resolve(dispatch({
//         type: 'USER_CHATS',
//         data: messages,
//       }));
//     }));
// }
export function getUserMessages(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => {
    const messagesRef = FirebaseRef.child(`supportChat/messages/${uid.toString()}`).limitToLast(100);
    const languageRef = FirebaseRef.child(`users/${uid.toString()}/lastLanguage`);

    Promise.all([
      messagesRef.once('value'),
      languageRef.once('value')
    ]).then(([messagesSnapshot, languageSnapshot]) => {
      const messages = messagesSnapshot.val() || {};
      const lastLanguage = languageSnapshot.val() || 'en';

      resolve(dispatch({
        type: 'USER_CHATS',
        data: {
          messages,
          lastLanguage,
        },
      }));
    }).catch(error => {
      console.log("Error fetching messages or language:", error);
      resolve();
    });
  });
}

export function cancelUserMessages(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`supportChat/messages/${uid.toString()}`).off();
    return resolve(dispatch({
      type: 'USER_CHATS',
      data: {},
    }));
  });
}

export function getActiveReadMessages() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('supportChat/activeChats')
      .orderByChild('responded').equalTo(true).once('value', (readChatsSnapshot) => {
        const readChats = readChatsSnapshot.val() || {};

        console.log('activeChats readChats', readChats);

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(true).on('child_added', (unreadChatsSnapshot) => {
            const unreadChats = unreadChatsSnapshot.val() || {};
            const { key } = unreadChatsSnapshot;

            dispatch({
              type: 'ACTIVE_READ_MESSAGE_CHANGE',
              data: unreadChats,
              key,
            });
          });

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(true).on('child_changed', (unreadChatsSnapshot) => {
            const unreadChats = unreadChatsSnapshot.val() || {};
            const { key } = unreadChatsSnapshot;

            dispatch({
              type: 'ACTIVE_READ_MESSAGE_CHANGE',
              data: unreadChats,
              key,
            });
          });

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(true).on('child_removed', (unreadChatsSnapshot) => {
            const { key } = unreadChatsSnapshot;

            dispatch({
              type: 'ACTIVE_READ_MESSAGES_REMOVE',
              key,
            });
          });

        resolve(dispatch({
          type: 'ACTIVE_READ_MESSAGES',
          data: readChats,
        }));
      });
  });
}

export function getActiveUnreadMessages() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('supportChat/activeChats')
      .orderByChild('responded').equalTo(false).once('value', (unreadChatsSnapshot) => {
        const unreadChats = unreadChatsSnapshot.val() || {};

        console.log('activeChats unreadChats', unreadChats);

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(false).on('child_added', (unreadChatsChangeSnapshot) => {
            const unreadChatsChange = unreadChatsChangeSnapshot.val() || {};
            const { key } = unreadChatsChangeSnapshot;

            dispatch({
              type: 'ACTIVE_UNREAD_MESSAGE_CHANGE',
              data: unreadChatsChange,
              key,
            });
          });

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(false).on('child_changed', (unreadChatsChangeSnapshot) => {
            const unreadChatsChange = unreadChatsChangeSnapshot.val() || {};
            const { key } = unreadChatsChangeSnapshot;

            dispatch({
              type: 'ACTIVE_UNREAD_MESSAGE_CHANGE',
              data: unreadChatsChange,
              key,
            });
          });

        FirebaseRef.child('supportChat/activeChats')
          .orderByChild('responded').equalTo(false).on('child_removed', (unreadChatsChangeSnapshot) => {
            const { key } = unreadChatsChangeSnapshot;

            dispatch({
              type: 'ACTIVE_UNREAD_MESSAGES_REMOVE',
              key,
            });
          });

        resolve(dispatch({
          type: 'ACTIVE_UNREAD_MESSAGES',
          data: unreadChats,
        }));
      });
  });
}

export function getActiveMessages2() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('supportChat/activeChats')
    .orderByChild('active').equalTo(true).on('value', (snapshot) => {
      const activeChats = snapshot.val() || {};

      console.log('activeChats', activeChats);

      resolve(dispatch({
        type: 'ACTIVE_MESSAGES',
        data: activeChats,
      }));
    }));
}

export function updateAvatarFileUpload(fileUpload, editUserId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = editUserId;

  if (fileUpload !== '') {
    const baseData = fileUpload.toString();

    return () => new Promise((resolve) => {
      console.log('called here@@@@@@@@@@@@@@@@@', UID, baseData.replace(/^data:image\/[a-z]+;base64,/, ''));
      const uploadTask = FirebaseStorage.ref(`/userAvatars/${UID}`).child(`${UID}-${(Date.now().toString())}`)
        .putString(baseData.replace(/^data:image\/[a-z]+;base64,/, ''), 'base64', { contentType: 'image/jpg' });

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            resolve({ status: 'success', url: downloadURL });
          });
        });
    });
  }
  return () => new Promise(resolve => resolve());
}

export function supportMessageFileUpload(fileUpload) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (fileUpload !== '') {
    return () => new Promise((resolve) => {
      const uploadTask = FirebaseStorage.ref(`/attachFiles/${(Date.now().toString())}`)
        .put(fileUpload);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            resolve({ status: 'success', url: downloadURL });
          });
        });
    });
  }
  return () => new Promise(resolve => resolve());
}

export async function supportFileUpload(fileUpload) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    if (fileUpload) {
      const uploadTask = FirebaseStorage.ref(`/attachFiles/${(Date.now().toString())}`)
        .put(fileUpload);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            return resolve({ status: 'success', url: downloadURL });
          });
        });
    } else {
      return resolve({ status: 'error' });
    }
  });
}

export function answerSupportMessage({
  uid, message, fileUploadUrl, fileName, screen, isPenaltyChecked,
}) {
  console.log('answerSupportMessage', isPenaltyChecked);

  let screen2 = 'dWeb'
  if (window.innerWidth <= 1280 && window.innerWidth > 832) {
    screen2 = 'tablet'
  } else if (window.innerWidth <= 832) {
    screen2 = 'mWeb'
  }

  if (!uid) return () => new Promise(resolve => resolve());

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  console.log('Firebase.auth().currentUser', { currentUser: Firebase.auth().currentUser });

  let deviceInfo = screen2 || constants.DWEB_PLATFORM;
  let env = screen2 || constants.DWEB_PLATFORM;

  if (screen2.toLowerCase() === 'android' || screen2.toLowerCase() === 'ios') {
    env = constants.DWEB_PLATFORM;
  } else if (screen2.toLowerCase() === constants.TABLET_PLATFORM) {
    env = constants.DWEB_PLATFORM;
  } else {
    env = screen2;
  }

  if (screen2.toLowerCase() === 'draugiem' || screen2.toLowerCase() === 'facebook' || screen2.toLowerCase() === 'inbox') {
    deviceInfo = constants.DWEB_PLATFORM;
  }

  const isInDraugiemAppFrame = config.isInDraugiemAppFrame();
  const isInFacebooxAppFrame = config.isInFacebookAppFrame();
  const isInInboxAppFrame = config.isInInboxAppFrame();

  if (isTablet) {
    deviceInfo = constants.TABLET_PLATFORM;
  } else if (isIOS) {
    deviceInfo = constants.IOS_PLATFORM.toLowerCase();
  } else if (isAndroid && !isDesktop) {
    deviceInfo = constants.ANDROID_PLATFORM;
  } else if (isInDraugiemAppFrame) {
    env = constants.DRAUGIEM_PLATFORM;
  } else if (isInFacebooxAppFrame) {
    env = constants.FACEBOOK_PLATFORM;
  } else if (isInInboxAppFrame) {
    env = constants.INBOX_PLATFORM;
  }
  let additionalData = {};

  if (deviceInfo === 'android' || deviceInfo === 'ios') {
    additionalData = {
      ...additionalData,
      appVersion: osVersion || '',
      osVersion: `${osName || ''} - ${mobileModel || ''}`,
    };
  }

  return () => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
    const name = snapshot.val() || null;
    FirebaseRef.child(`supportChat/messages/${uid.toString()}`).push({
      message: !message ? '' : message,
      date: Firebase.database.ServerValue.TIMESTAMP,
      fileUpload: fileUploadUrl || null,
      fileName: fileName || null,
      env,
      device: deviceInfo,
    }).then(() => {
      FirebaseRef.child(`supportChat/activeChats/${uid.toString()}`).update({
        //  active: true,
        name: name || null,
        responded: true,
        autoRespond: false,
        lastResponse: Firebase.database.ServerValue.TIMESTAMP,
        read: false,
      });

      if (isPenaltyChecked) {
        console.log('isPenaltyChecked', uid);
        FirebaseRef.child(`users/${uid.toString()}/bal`)
          .transaction((bal) => (bal || 0) - 300).then(async (transactionRes) => {
            if (transactionRes.committed) {
              const newBal = transactionRes.snapshot.val();

              console.log('isPenaltyChecked newBal', newBal);

              UserStatsRef().child(`userBalHistory/${uid.toString()}`).push({
                time: Date.now(),
                type: 'profanityPenalty',
                change: -300,
                old: newBal + 300,
                new: newBal,
              });
            }
            return null;
          });
      }

      FirebaseRef.child(`supportChat/messagesCount/${uid.toString()}`).remove();

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'sendMessage',
        message: !message ? '' : message,
        adminId: UID,
        adminEmail,
        adminName,
        userId: uid.toString(),
        userName: name || null,
        date: Firebase.database.ServerValue.TIMESTAMP,
        fileUpload: fileUploadUrl || null,
        fileName: fileName || null,
        env,
        device: deviceInfo,
      });

      resolve({ status: 'success' });
    }).catch((err) => {
      resolve({ status: 'error', mesage: err });
    });
  }));
}


export function setSupportMessageAsResponded({ uid }) {
  if (!uid) return () => new Promise(resolve => resolve());

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
    const name = snapshot.val() || null;

    console.log('name', { name });

    FirebaseRef.child(`supportChat/activeChats/${uid.toString()}`).update({
      responded: true,
      autoRespond: false,
    }).then(() => {
      console.log('test2');

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'setMessageAsRead',
        adminId: UID,
        adminEmail,
        adminName,
        userId: uid.toString(),
        userName: name || null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      resolve({ status: 'success' });
    }).catch((err) => {
      console.log(err);
      resolve({ status: 'error', mesage: err });
    });
  }));
}

export function messageAll(message, filterPlayers, messageLvInput, messageEnInput, messageRuInput, languageType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    console.log(message, 'ALL', filterPlayers, 'filter', messageLvInput, 'LV', messageEnInput, 'EN', messageRuInput, 'RU', languageType, 'admin check');

    const messageAllFunction = Firebase.app().functions().httpsCallable('messageAll');

    messageAllFunction({
      message,
      filterPlayers,
      messageLvInput,
      messageEnInput,
      messageRuInput,
      languageType,
    }).then((res) => {
      if (res.data && res.data.status === constants.SERVER_STATUS_CODE.success) {
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      }
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    }).catch(err => resolve({ status: constants.SERVER_STATUS_CODE.error, message: err.code }));
  });
}


export function getUsersCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('users_count')
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'USERS_COUNT_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}


export function getAllVipUsers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('users fetch getAllVipUsers');

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .orderByChild('vip')
    .equalTo(true)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_VIP_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}


export function getAllBans() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('bans')
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      const array = [];
      map(data, (item, key) => {
        array.push({ key, ...data[key] });
        return null;
      });
      array.sort((a, b) => b.endDate - a.endDate);

      return resolve(dispatch({ type: 'ADMIN_BANS2_REPLACE', array }));
    })).catch((err) => { console.log(err.message); });
}


export function getBansRange(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('bans')
    .orderByChild('endDate')
    .limitToLast(end)
    .on('value', (snapshot) => {
      const bans = snapshot.val() || {};
      const array = [];
      map(bans, (item, key) => {
        array.push({ key, ...bans[key] });
        return null;
      });
      array.sort((a, b) => b.endDate - a.endDate);
      return resolve(dispatch({ type: 'ADMIN_BANS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}


export function getBansCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/bansCount')
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'BANS_COUNT_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllTransactions() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('transactions')
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_TRANSACTIONS_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllTournaments() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => TournamentsRef().child('newTournamentsList')
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      console.log('getAllTournaments', data);

      console.log('test data', data['-O73LC9o7SMN3ZldKvk5']);

      return resolve(dispatch({ type: 'ADMIN_TOURNAMENTS_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getClaimedPlayers(code) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child(`claimedGiftCodes/${code}`)
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_CLAIMEDPLAYERS_REPLACE', data }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllGiftCodes() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('giftCodes')
    .on('value', (snapshot) => {
      const data = snapshot.val() || {};
      const array = [];

      Object.keys(data).map((key) => {
        array.push({ key, ...data[key] });
        return null;
      });

      array.sort((a, b) => (a.expires - b.expires));
      return resolve(dispatch({ type: 'ADMIN_GIFTCODES_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function getTournamentPlayers(tournamentId, tournamentState) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const promise1 = TournamentsRef().child(`newTournaments/${tournamentId}/players`).once('value');
    const promise2 = TournamentsRef().child(`newTournaments/${tournamentId}/playersStaticData`).once('value');

    Promise.all([promise1, promise2]).then((promisesRes) => {
      let playersData;
      let playersStaticData;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          playersData = promiseRes.val() || {};
        } else if (index === 1) {
          playersStaticData = promiseRes.val() || {};
        }
        return null;
      });

      const players = {};
      const playersArr = [];

      if (playersStaticData) {
        Object.keys(playersStaticData).map((key) => {
          players[key] = { ...playersData[key], ...playersStaticData[key] };
          playersArr.push({ key, ...playersData[key], ...playersStaticData[key] });
          return null;
        });
      }

      console.log(players, "tournamentsPlayers playersArr", playersArr);

      //closed
      if (tournamentState === 'closed') {
        playersArr.sort((a, b) => (a.finalPosition - b.finalPosition));

        playersArr.sort((a, b) => {
          const aMainPoints = a.tournamentPoints || 0;
          const bMainPoints = b.tournamentPoints || 0;

          const aTotalRoomPoints = a.totalRoomPoints || 0;
          const bTotalRoomPoints = b.totalRoomPoints || 0;

          if (bMainPoints === aMainPoints) {
            return bTotalRoomPoints - aTotalRoomPoints;
          }
          return bMainPoints - aMainPoints;
        });
      } else {
        playersArr.sort((a, b) => (a.joinTime - b.joinTime));
      }

      const players2 = {};

      playersArr.map((player) => {
        players2[player.key] = { ...player };
      });
      console.log('players2', players2);

      return resolve(dispatch({ type: 'TOURNAMENT_PLAYERS', data: { tournamentPlayers: players, tournamentPlayersArr: playersArr, tournamentId } }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function getTournamentUsers(startDate, endDate, filterType, filterValue) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    TournamentsRef().child(`newTournaments`)
      .orderByChild('params/startDate')
      .startAt(startDate)
      .endAt(endDate)
      .once('value', async (snapshot) => {
        const newTournaments = snapshot.val() || {};
        const newTournamentsKeys = Object.keys(newTournaments);

        let tempTournaments = {};
        let tempUsers = {};
        let userPromises = [];
        let uidsToFetch = new Set();

        for (const key of newTournamentsKeys) {
          if (newTournaments[key].playersStaticData) {
            const playersStaticDataKeys = Object.keys(newTournaments[key].playersStaticData);

            tempTournaments[key] = { ...newTournaments[key].params, players: playersStaticDataKeys };

            for (const playerKey of playersStaticDataKeys) {
              const playerStaticData = newTournaments[key].playersStaticData[playerKey];

              if (filterValue !== '') {
                if (filterType === 'lowerCaseName' && !playerStaticData.name.toLowerCase().includes(filterValue.toLowerCase())) continue;
                if (filterType === 'uid' && playerStaticData.uid !== filterValue) continue;
                if (filterType === 'lastIp') {
                  uidsToFetch.add(playerStaticData.uid);
                }
              }

              if (!tempUsers[playerKey]) {
                tempUsers[playerKey] = {
                  tPlayed: 0,
                  tTotalPnts: 0,
                  tTotalBal: 0,
                };
              }

              tempUsers[playerKey].tPlayed += 1;
              tempUsers[playerKey].tTotalPnts += (playerStaticData.initalPoints && playerStaticData.endPoints)
                ? playerStaticData.endPoints - playerStaticData.initalPoints
                : 0;
              tempUsers[playerKey].tTotalBal += (playerStaticData.initialBal && playerStaticData.endBal)
                ? playerStaticData.endBal - playerStaticData.initialBal
                : 0;

              userPromises.push(
                FirebaseRef.child(`users/${playerKey}`).once('value').then(userSnapshot => {
                  const userData = userSnapshot.val() || {};

                  if (filterValue !== '') {
                    if (filterType === 'lowerCaseLastName' && !userData.lastName?.toLowerCase().includes(filterValue.toLowerCase())) {
                      delete tempUsers[playerKey];
                      return;
                    }
                    if (filterType === 'email' && userData.email?.toLowerCase() !== filterValue.toLowerCase() && userData.contactEmail?.toLowerCase() !== filterValue.toLowerCase()) {
                      delete tempUsers[playerKey];
                      return;
                    }
                  }

                  tempUsers[playerKey] = {
                    ...tempUsers[playerKey],
                    name: playerStaticData.name,
                    uid: playerStaticData.uid,
                    lvl: userData.lvl || 0,
                    bal: userData.bal || 0,
                    totalPnts: userData.totalPnts || 0,
                    gPlayed: userData.gPlayed || 0,
                    lastName: userData.lastName || null,
                    email: userData.email || null,
                  };
                }).catch(err => {
                  console.log(err.message);
                })
              );
            }
          }
        }

        let lastIpData = {};

        if (filterType === 'lastIp' && filterValue !== '') {
          if (uidsToFetch.size > 0) {
            await FirebaseRef.child('usersLastIp').once('value').then(snapshot => {
              const allIps = snapshot.val() || {};
              lastIpData = Object.fromEntries(
                Array.from(uidsToFetch).map(uid => [uid, allIps[uid] || null])
              );
            }).catch(err => {
              console.log(err.message);
            });
          }

          tempUsers = Object.fromEntries(
            Object.entries(tempUsers).filter(([playerKey, user]) => {
              const lastIp = lastIpData[user.uid];
              return lastIp === filterValue;
            })
          );

        }

        let totalUserCount = 0;

        await TournamentsRef().child('allTimeTournamentPlayersCount').once('value').then(countSnapshot => {
          totalUserCount = countSnapshot.val() || 0;
        }).catch(err => {
          console.log(err.message);
        });

        await Promise.all(userPromises);

        return resolve(dispatch({ type: 'TOURNAMENT_USERS', data: { users: tempUsers, userTournaments: tempTournaments, totalUserCount } }));
      }).catch((err) => {
        console.log(err.message);
        return resolve();
      });
  }).catch((err) => { console.log(err.message); });
}

export function getTournamentPlayerHistory(tournamentId, playerId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    TournamentsRef().child(`tournamentPlayerHistory/${tournamentId}/players/${playerId}`).once('value', (snapshot) => {
      const playerHistory = snapshot.val() || null;

      return resolve(dispatch({ type: 'TOURNAMENT_PLAYER_HISTORY', data: playerHistory }));
    }).catch((err) => {
      console.log(err);
      return resolve();
    });
  }).catch((err) => { console.log(err.message); });
}

export function getTournamentResults(tournamentId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const promise1 = TournamentsRef().child(`newTournaments/${tournamentId}/rounds`).once('value');

    Promise.all([promise1]).then((promisesRes) => {
      let rounds;

      promisesRes.map((promiseRes, index) => {
        if (index === 0) {
          rounds = promiseRes.val() || {};
        }
        return null;
      });

      return resolve(dispatch({ type: 'TOURNAMENT_RESULTS', data: { rounds, tournamentId } }));
    });
  }).catch((err) => { console.log(err.message); });

  // return dispatch => new Promise((resolve) => {
  //   const promise1 = AdminLogsRef().child(`adminLogs/rooms/${tournamentId}`).once('value');
  //   const promise2 = RoomsRef.child(`roomsPoints/${tournamentId}/points`).once('value');

  //   return Promise.all([promise1, promise2]).then((promisesRes) => {
  //     let data;
  //     let roomsPoints;
  //     promisesRes.map((resp, index) => {
  //       if (index === 0) {
  //         data = resp.val();
  //       } else if (index === 1) {
  //         roomsPoints = resp.val();
  //       }
  //       return null;
  //     });
  //     console.log(data, roomsPoints, "tournamentsPoints")

  //     return resolve(dispatch({
  //       type: 'TOURNAMENT_RESULTS', data: { data, tournamentId }, roomsPoints
  //     }));
  //   });
  // });
}


export function forceCloseTournament(tournamentId) {
  if (Firebase === null || !tournamentId) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return () => new Promise(resolve => resolve());

  // return () => new Promise((resolve) => {

  return () => new Promise(resolve => FirebaseRef.child('events').orderByChild('descriptionTournamentId').equalTo(tournamentId).limitToLast(1).once('value', async (snapshot) => {
    const eventData = snapshot.val() || null;

    console.log('eventData', eventData);

    const eventKey = (eventData && Object.keys(eventData).length > 0) ? Object.keys(eventData)[0] : null;

    console.log('already has an event, update exisitng one', eventKey);

    if (eventKey) {
      const deleteEventFunction = Firebase.app().functions().httpsCallable('deleteEvent');

      console.log('call delete banner');

      await deleteEventFunction({
        eventId: eventKey,
      });
    }

    const forceCloseTournamentFunction = Firebase.app().functions().httpsCallable('forceCloseTournament');

    forceCloseTournamentFunction({
      tournamentId,
    }).then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'forceCloseTournament',
        adminId: UID,
        adminEmail,
        adminName,
        tournamentId,
        data: null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      return resolve();
    });
  }));
}


export function deleteUser(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  /*
  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  */

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const deleteUserFunction = Firebase.app().functions().httpsCallable('deleteUser');

      FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
        const name = snapshot.val() || {};
        if (name) {
          deleteUserFunction({
            uid: uid.toString(),
          }).then((result) => {
            resolve(result);
          });
        }
      });
    }
  }));
}

export function manualyVerifyUser(uid, lang) {
  console.log('manualyVerifyUser', uid, lang);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    const sendVerificationEmailFunction = Firebase.app().functions().httpsCallable('sendVerificationEmail2');

    sendVerificationEmailFunction({
      uid: uid.toString(),
      lang,
    }).then((result) => {
      console.log('sendVerificationEmailFunction result', result);
      if (result.data.status === 'success') {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'sendVerifiedEmail',
          adminId: UID,
          adminEmail,
          adminName,
          uid,
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
      }

      dispatch({ type: 'SEND_NON_VERIFIED_PLAYER', data: { status: 'success', uid } });
      resolve({ status: 'success' });
    });
  });
}

export function toggleLogRocket(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }

  const adminId = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/enableLogRocket`)
    .once('value', (userSnapshot) => {
      const enableLogRocket = userSnapshot.val() || false;

      FirebaseRef.child(`users/${uid.toString()}`).update({
        enableLogRocket: !enableLogRocket,
      })
        .then(() => {
          dispatch({ type: 'CHANGE_LOGROCKET', data: { status: 'success', enabled: !enableLogRocket, uid } });

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: !enableLogRocket ? 'enableLogrocket' : 'disableLogrocket',
            adminId,
            adminEmail,
            adminName,
            data: { uid },
            userId: uid,
            date: Firebase.database.ServerValue.TIMESTAMP,
          });

          return resolve({ status: 'success', enabled: !enableLogRocket });
        })
        .catch(err => resolve({ status: 'error', message: err }));
    }));
}

export function toggleSmartlook(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }

  return dispatch => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/enableSmartlook`)
    .once('value', (userSnapshot) => {
      const enableSmartlook = userSnapshot.val() || false;

      FirebaseRef.child(`users/${uid.toString()}`).update({
        enableSmartlook: !enableSmartlook,
      })
        .then(() => {
          dispatch({ type: 'CHANGE_SMARTLOOK', data: { status: 'success', enabled: !enableSmartlook, uid } });

          return resolve({ status: 'success', enabled: !enableSmartlook });
        })
        .catch(err => resolve({ status: 'error', message: err }));
    }));
}


export function resetActiveRoom(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }

  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${uid.toString()}`).update({
      activeRoom: null,
      joinedRooms: null,
    })
      .then(() => resolve({ status: 'success' }))
      .catch(err => resolve({ status: 'error', message: err }));
  });
}


export function blockUser(uid, endDate, reason) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const endDate2 = new Date(endDate);

  const endDateParsed = Date.parse(endDate2);

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }
  if (!reason) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav iemesla' })); }
  if (!endDateParsed || !(endDate2 instanceof Date)) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav beigu laika' })); }

  const blockUserFunction = Firebase.app().functions().httpsCallable('blockUser');

  return dispatch => new Promise(resolve => blockUserFunction({
    uid,
    endDate: endDateParsed,
    reason,
  }).then(() => {
    dispatch({ type: 'CHANGE_BLOCK', data: { status: 'success', blocked: true, uid } });

    return resolve({ status: 'success' });
  }).catch((err) => {
    console.log(err);
    return resolve({ status: 'success' });
  }));
}


export function unblockUser(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }

  const unblockUserFunction = Firebase.app().functions().httpsCallable('unblockUser');

  return dispatch => new Promise(resolve => unblockUserFunction({
    uid,
  }).then(() => {
    dispatch({ type: 'CHANGE_BLOCK', data: { status: 'success', blocked: false, uid } });

    return resolve({ status: 'success' });
  }).catch((err) => {
    console.log(err);
    return resolve({ status: 'success' });
  }));
}


export function editUser(uid, bal, lvl, gPlayed, totalPnts, firstName, lastName, photo, updateFlag, filterValue, filterType, name, lowerCaseName, lowerCaseLastName) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!lvl && lvl !== 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav līmeņa' })); }
  if (!gPlayed && gPlayed !== 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav izspēlēto spēļu' })); }
  if (Number.isNaN(Number(lvl)) || lvl < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Līmenim jābūt pozitīvam skaitlim' })); }
  if (Number.isNaN(Number(gPlayed)) || gPlayed < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Izspēlētajām spēlēm jābūt pozitīvam skaitlim' })); }

  if (!firstName) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingFirstName })); }
  if (!lastName) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLastName })); }

  return () => new Promise((resolve) => {
    const promise1 = FirebaseRef.child(`users/${uid.toString()}/lvl`).once('value');
    const promise2 = FirebaseRef.child(`users/${uid.toString()}/gPlayed`).once('value');
    const promise3 = FirebaseRef.child(`users/${uid.toString()}/name`).once('value');

    Promise.all([promise1, promise2, promise3]).then((promiseRes) => {
      let userOldLvl;
      let userOldGPlayed;
      let oldName;
      promiseRes.map((res2) => {
        console.log(res2.key, '-----');

        if (res2) {
          if (res2.key === 'lvl') {
            userOldLvl = res2.val() || null;
          } else if (res2.key === 'gPlayed') {
            userOldGPlayed = res2.val() || null;
          } else if (res2.key === 'name') {
            oldName = res2.val() || null;
          }
        }
        return null;
      });

      FirebaseRef.child(`users/${uid.toString()}`).update({
        lvl,
        gPlayed,
        firstName,
        lastName,
        photo: photo || null,
        name,
        lowerCaseName,
        lowerCaseLastName,
      }).then(() => {
        console.log('reset user photo in leaderboard');

        LeaderboardRef.child(`leaderboard/allTime/${uid.toString()}/photo`).set(photo || null);
        LeaderboardRef.child(`leaderboard/daily/${uid.toString()}/photo`).set(photo || null);
        LeaderboardRef.child(`leaderboard/week/${uid.toString()}/photo`).set(photo || null);
        LeaderboardRef.child(`leaderboard/month/${uid.toString()}/photo`).set(photo || null);
        LeaderboardRef.child(`leaderboard/year/${uid.toString()}/photo`).set(photo || null);

        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'editUser',
          adminId: UID,
          adminEmail,
          adminName,
          userId: uid.toString(),
          userName: name || null,
          data: {
            lvl,
            gPlayed,
            oldLvl: userOldLvl || null,
            oldGPLayed: userOldGPlayed || null,
            oldName,
            photo,
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });

        resolve({ status: 'success' });
      }).catch((err) => {
        console.log(err);
        resolve({ status: 'error', message: 'Neizdevās labot' });
      });
    }).catch((err) => {
      console.log(err);
      resolve({ status: 'error', message: 'Neizdevās labot' });
    });
  });
}

export function addBalance(uid, balanceChange) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('addBalance', uid, balanceChange);

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!balanceChange && balanceChange !== 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav bilances' })); }

  return () => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
    const name = snapshot.val() || null;

    FirebaseRef.child(`users/${uid.toString()}/bal`)
      .transaction(bal => (parseInt(bal, 10) || 0) + parseInt(balanceChange, 10)).then((transactionRes) => {
        if (transactionRes.committed) {
          const newBal = transactionRes.snapshot.val();

          UserStatsRef().child('statistics/money').update({ admin: Firebase.database.ServerValue.increment(parseInt(balanceChange, 10)) });

          if (parseInt(balanceChange, 10) < 0) {
            UserStatsRef().child('statistics/money').update({ adminRemoved: Firebase.database.ServerValue.increment(parseInt(balanceChange, 10)) });
          } else {
            UserStatsRef().child('statistics/money').update({ adminAdded: Firebase.database.ServerValue.increment(parseInt(balanceChange, 10)) });
          }

        //  LeaderboardRef.child(`leaderboard/allTime/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`leaderboard/daily/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`leaderboard/week/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`leaderboard/month/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`leaderboard/year/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));

        //  LeaderboardRef.child(`leaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`dailyLeaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`weekLeaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`monthLeaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));
          LeaderboardRef.child(`yearLeaderboardPoints/${uid.toString()}/bal`).set(Firebase.database.ServerValue.increment(parseInt(-balanceChange, 10)));

          UserStatsRef().child(`userBalHistory/${uid.toString()}`).push({
            time: Date.now(),
            type: 'adminChange',
            change: balanceChange,
            old: newBal - parseInt(balanceChange, 10),
            new: newBal,
          });

          console.log('test2');

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'addUserBalance',
            adminId: UID,
            adminEmail,
            adminName,
            userId: uid.toString(),
            userName: name || null,
            data: {
              old: newBal - parseInt(balanceChange, 10),
              new: newBal,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }
        resolve({ status: 'success' });
      }).catch((err) => {
        console.log(err);
        resolve({ status: 'error', message: 'Neizdevās labot' });
      });
  }));
}


export function addPoints(uid, totalPntsChange) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!totalPntsChange && totalPntsChange !== 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav bilances' })); }

  return () => new Promise(resolve => FirebaseRef.child(`users/${uid.toString()}/name`).once('value', (snapshot) => {
    const name = snapshot.val() || null;

    FirebaseRef.child(`users/${uid.toString()}/totalPnts`)
      .transaction(totalPnts => (parseInt(totalPnts, 10) || 0) + parseInt(totalPntsChange, 10)).then((transactionRes) => {
        if (transactionRes.committed) {
          const newPoints = transactionRes.snapshot.val();

          UserStatsRef().child(`userPointsHistory/${uid.toString()}`).push({
            time: Date.now(),
            type: 'adminChange',
            change: totalPntsChange,
            old: newPoints - parseInt(totalPntsChange, 10),
            new: newPoints,
          });

        //  LeaderboardRef.child(`leaderboard/allTime/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`leaderboard/daily/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`leaderboard/week/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`leaderboard/month/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`leaderboard/year/${uid.toString()}/totalPnts`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));

        //  LeaderboardRef.child(`leaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`dailyLeaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`weekLeaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`monthLeaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));
          LeaderboardRef.child(`yearLeaderboardPoints/${uid.toString()}/tP`).set(Firebase.database.ServerValue.increment(parseInt(-totalPntsChange, 10)));

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'addUserPoints',
            adminId: UID,
            adminEmail,
            adminName,
            userId: uid.toString(),
            userName: name || null,
            data: {
              old: newPoints - parseInt(totalPntsChange, 10),
              new: newPoints,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }
        resolve({ status: 'success' });
      }).catch(() => {
        resolve({ status: 'error', message: 'Neizdevās labot' });
      });
  }));
}

export function addGiftCode(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    code, expires, playerLimit, value,
  } = data;

  const expiresParsed = Date.parse(expires);
  const curDateParsed = Date.parse(new Date());
  const pattern = /^[a-zA-Z0-9]*$/;

  if (!code) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingGiftCode })); }
  if (code.match(pattern) === null) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.giftCodePatternFailed })); }
  if (code.length > 10 || code.length < 4) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.giftCodeLongFailed })); }
  if (!expiresParsed) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingExpire })); }
  if (!playerLimit || playerLimit < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPlayerLimit })); }
  if (!value || value < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue })); }

  return () => new Promise((resolve) => {
    FirebaseRef.child(`giftCodes/${code.toLowerCase()}`)
      .once('value', (giftCodeSnapshot) => {
        const giftCode = giftCodeSnapshot.val() || {};
        if (!giftCode.code) {
          const createGiftCodeFunction = Firebase.app().functions().httpsCallable('createGiftCode');

          return createGiftCodeFunction({
            code: code.toLowerCase(),
            expires: expiresParsed,
            playerLimit,
            value,
          }).then((result) => {
            if (result.data.status) {
              AdminLogsRef().child('adminLogs/adminActions').push({
                type: 'createGiftCode',
                adminId: UID,
                adminEmail,
                adminName,
                data: {
                  code: code.toLowerCase(),
                  expires: dateFormat(expires),
                  playerLimit,
                  value,
                },
                date: Firebase.database.ServerValue.TIMESTAMP,
              });

              return resolve({ status: 'success' });
            }
            return resolve({ status: 'error', message: result.data.error });
          }).catch(err => resolve({ status: 'error', message: err.message }));
        }

        if (giftCode.code === code.toLowerCase() && giftCode.expires < curDateParsed) {
          const createGiftCodeFunction = Firebase.app().functions().httpsCallable('createGiftCode');

          return createGiftCodeFunction({
            code: code.toLowerCase(),
            expires: expiresParsed,
            playerLimit,
            value,
          }).then((result) => {
            if (result.data.status) {
              AdminLogsRef().child('adminLogs/adminActions').push({
                type: 'createGiftCode',
                adminId: UID,
                adminEmail,
                adminName,
                data: {
                  code: code.toLowerCase(),
                  expires: dateFormat(expires),
                  playerLimit,
                  value,
                },
                date: Firebase.database.ServerValue.TIMESTAMP,
              });

              return resolve({ status: 'success' });
            }
            return resolve({ status: 'error', message: result.data.error });
          }).catch(err => resolve({ status: 'error', message: err.message }));
        }

        return resolve({ status: 'error', message: 'codeExist' });
      }).catch(err => resolve({ status: 'error', error: err }));
  });
}

export function editGiftCode({ data, oldData }) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  const {
    code, expires, playerLimit, value,
  } = data;

  const expiresParsed = Date.parse(expires);
  const pattern = /^[a-zA-Z0-9]*$/;
  const curDateParsed = Date.parse(new Date());

  if (!code) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingGiftCode })); }
  if (code.match(pattern) === null) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.giftCodePatternFailed })); }
  if (code.length > 10 || code.length < 3) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.giftCodeLongFailed })); }
  if (!expiresParsed) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingExpire })); }
  if (!playerLimit) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPlayerLimit })); }
  if (!value) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue })); }

  const dataToEdit = {
    code,
    expires: dateFormat(expires),
    playerLimit,
    value,
  };
  return () => new Promise((resolve) => {
    if (code !== oldData.code) {
      FirebaseRef.child(`giftCodes/${code.toLowerCase()}`)
        .once('value', (giftCodeSnapshot) => {
          const giftCode = giftCodeSnapshot.val() || {};
          if (!giftCode.code) {
            const editGiftCodeFunction = Firebase.app().functions().httpsCallable('editGiftCode');

            return editGiftCodeFunction({
              code: code.toLowerCase(),
              expires: expiresParsed,
              playerLimit,
              value,
            }).then((result) => {
              if (result.data.status === 'success') {
                if (dataToEdit.code === oldData.code) {
                  const changesObj = {};

                  Object.keys(dataToEdit).map((key) => {
                    if (oldData[key] !== dataToEdit[key]) {
                      changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
                    }
                    return null;
                  });

                  let changedlog = '';

                  Object.keys(changesObj).map((key, index) => {
                    changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

                    if ((index + 1) !== Object.keys(changesObj).length) {
                      changedlog += ', ';
                    }
                    return null;
                  });

                  AdminLogsRef().child('adminLogs/adminActions').push({
                    type: 'editGiftCode',
                    adminId: UID,
                    adminEmail,
                    adminName,
                    giftCodeId: code.toLowerCase(),
                    data: {
                      ...dataToEdit,
                      changedlog,
                    },
                    date: Firebase.database.ServerValue.TIMESTAMP,
                  });
                } else {
                  AdminLogsRef().child('adminLogs/adminActions').push({
                    type: 'createGiftCode',
                    adminId: UID,
                    adminEmail,
                    adminName,
                    data: {
                      code: code.toLowerCase(),
                      expires: dateFormat(expires),
                      playerLimit,
                      value,
                    },
                    date: Firebase.database.ServerValue.TIMESTAMP,
                  });
                }
                return resolve({ status: 'success' });
              }
              return resolve({ status: 'error', message: result.data.error });
            }).catch(err => resolve({ status: 'error', message: err.message }));
          }

          if (giftCode.code === code.toLowerCase() && giftCode.expires < curDateParsed) {
            const editGiftCodeFunction = Firebase.app().functions().httpsCallable('editGiftCode');

            return editGiftCodeFunction({
              code: code.toLowerCase(),
              expires: expiresParsed,
              playerLimit,
              value,
            }).then((result) => {
              if (result.data.status === 'success') {
                if (dataToEdit.code === oldData.code) {
                  const changesObj = {};

                  Object.keys(dataToEdit).map((key) => {
                    if (oldData[key] !== dataToEdit[key]) {
                      changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
                    }
                    return null;
                  });

                  let changedlog = '';

                  Object.keys(changesObj).map((key, index) => {
                    changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

                    if ((index + 1) !== Object.keys(changesObj).length) {
                      changedlog += ', ';
                    }
                    return null;
                  });

                  AdminLogsRef().child('adminLogs/adminActions').push({
                    type: 'editGiftCode',
                    adminId: UID,
                    adminEmail,
                    adminName,
                    giftCodeId: code.toLowerCase(),
                    data: {
                      ...dataToEdit,
                      changedlog,
                    },
                    date: Firebase.database.ServerValue.TIMESTAMP,
                  });
                } else {
                  AdminLogsRef().child('adminLogs/adminActions').push({
                    type: 'createGiftCode',
                    adminId: UID,
                    adminEmail,
                    adminName,
                    data: {
                      code: code.toLowerCase(),
                      expires: dateFormat(expires),
                      playerLimit,
                      value,
                    },
                    date: Firebase.database.ServerValue.TIMESTAMP,
                  });
                }
                return resolve({ status: 'success' });
              }
              return resolve({ status: 'error', message: result.data.error });
            }).catch(err => resolve({ status: 'error', message: err.message }));
          }

          return resolve({ status: 'error', message: 'codeExist' });
        }).catch(err => resolve({ status: 'error', error: err }));
    } else {
      const editGiftCodeFunction = Firebase.app().functions().httpsCallable('editGiftCode');
      editGiftCodeFunction({
        code: code.toLowerCase(),
        expires: expiresParsed,
        playerLimit,
        value,
      }).then((result) => {
        if (result.data.status === 'success') {
          if (dataToEdit.code === oldData.code) {
            const changesObj = {};
            Object.keys(dataToEdit).map((key) => {
              if (oldData[key] !== dataToEdit[key]) {
                changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
              }
              return null;
            });
            let changedlog = '';
            Object.keys(changesObj).map((key, index) => {
              changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

              if ((index + 1) !== Object.keys(changesObj).length) {
                changedlog += ', ';
              }
              return null;
            });
            AdminLogsRef().child('adminLogs/adminActions').push({
              type: 'editGiftCode',
              adminId: UID,
              adminEmail,
              adminName,
              giftCodeId: code.toLowerCase(),
              data: {
                ...dataToEdit,
                changedlog,
              },
              date: Firebase.database.ServerValue.TIMESTAMP,
            });
          } else {
            AdminLogsRef().child('adminLogs/adminActions').push({
              type: 'createGiftCode',
              adminId: UID,
              adminEmail,
              adminName,
              data: {
                code: code.toLowerCase(),
                expires: expiresParsed,
                playerLimit,
                value,
              },
              date: Firebase.database.ServerValue.TIMESTAMP,
            });
          }
          return resolve({ status: 'success' });
        }
        return resolve({ status: 'error', message: result.data.error });
      }).catch(err => resolve({ status: 'error', message: err.message }));
    }
  });
}

export function deleteGiftCode(code) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  if (!code) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingGiftCode })); }

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const deleteGiftCodeFunction = Firebase.app().functions().httpsCallable('deleteGiftCode');

      deleteGiftCodeFunction({
        code: code.toString(),
      }).then((result) => {
        if (result.data.status === 'success') {
          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'deleteGiftCode',
            adminId: UID,
            adminEmail,
            adminName,
            code,
            data: null,
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
          resolve({ status: 'success' });
        } else {
          resolve({ status: 'error', message: result.data.error });
        }
      })
        .catch(err => resolve({ status: 'error', message: err.message }));
    }
  }));
}

export function saveEmailTemplate(name, design, html) {
  console.log('saveEmailTemplate', { name, design, html });

  FirebaseRef.child('emailTemplates').push({
    name,
    design,
    html,
    date: Firebase.database.ServerValue.TIMESTAMP,
  });
}


export function getTemplateData() {
  return dispatch => new Promise((resolve) => {
    console.log('getTemplateData');

    FirebaseRef.child('eTemplates/verifyEmail').once('value', (snapshot) => {
      const verifyEmail = snapshot.val() || null;

      console.log('eTemplates/verifyEmail data', { verifyEmail });

      FirebaseRef.child('eTemplates/resetPassword').once('value', (snapshot2) => {
        const resetPassword = snapshot2.val() || null;

        console.log('eTemplates/resetPassword data', { resetPassword });


        FirebaseRef.child('eTemplates/resetEmail').once('value', (snapshot3) => {
          const resetEmail = snapshot3.val() || null;

          console.log('eTemplates/resetEmail data', { resetEmail });

          dispatch({
            type: 'TEMPLATE_DATA',
            data: { verifyEmail, resetEmail, resetPassword },
          });

          return resolve({ verifyEmail, resetEmail, resetPassword });
        }, (err) => { console.log(err); return resolve(); });
      }, (err) => { console.log(err); return resolve(); });
    }, (err) => { console.log(err); return resolve(); });
  });
}


export function updateTemplateData(type, data) {
  return () => new Promise((resolve) => {
    console.log('updateTemplateData', { type, data });

    if (type === 'verifyEmail') {
      FirebaseRef.child('eTemplates/verifyEmail').set(data);
    } else if (type === 'resetEmail') {
      FirebaseRef.child('eTemplates/resetEmail').set(data);
    } else if (type === 'resetPassword') {
      FirebaseRef.child('eTemplates/resetPassword').set(data);
    }
    return resolve();
  });
}

export function addNewEvent(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, pageToOpen, showInBanner, devMode, bannerImageType, externalLink,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl && !bannerImageType) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }

  return () => new Promise((resolve) => {
    const createEventFunction = Firebase.app().functions().httpsCallable('createEvent');

    createEventFunction({
      startDate: startParsedDate,
      endDate: endParsedDate,
      titleEN: enTitle,
      titleLV: lvTitle,
      titleRU: ruTitle,
      descriptionEN: enDescription,
      descriptionLV: lvDescription,
      descriptionRU: ruDescription,
      imageUrl,
      pageToOpen,
      showInBanner,
      devMode,
      bannerImageType,
      externalLink,
    }).then((result) => {
      if (result.data.status === 'success') {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'createNewEvent',
          adminId: UID,
          adminEmail,
          adminName,
          data: {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            imageUrl,
            pageToOpen,
            showInBanner,
            devMode,
            externalLink,
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });

        return resolve({ status: 'success' });
      }
      return resolve({ status: 'error', message: result.data.error });
    }).catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function editSelectEvent(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    selectEventId, startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, pageToOpen, showInBanner, oldData, devMode, bannerImageType, externalLink,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!selectEventId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl && !bannerImageType) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  console.log(selectEventId, 'devmode action');
  return () => new Promise((resolve) => {
    const editEventFunction = Firebase.app().functions().httpsCallable('editEvent');

    editEventFunction({
      eventId: selectEventId.toString(),
      startDate: startParsedDate,
      endDate: endParsedDate,
      titleEN: enTitle,
      titleLV: lvTitle,
      titleRU: ruTitle,
      descriptionEN: enDescription,
      descriptionLV: lvDescription,
      descriptionRU: ruDescription,
      imageUrl,
      pageToOpen,
      showInBanner,
      devMode,
      bannerImageType,
      externalLink,
    }).then((result) => {
      if (result.data.status === 'success') {
        if (startParsedDate !== Date.parse(oldData.startDate) || endParsedDate !== Date.parse(oldData.endDate) || enTitle !== oldData.titleEN || enDescription !== oldData.descriptionEN || pageToOpen !== oldData.pageToOpen || showInBanner !== oldData.showInBanner || devMode !== oldData.devMode) {
          const dataToEdit = {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            imageUrl,
            pageToOpen,
            showInBanner,
            devMode,
            externalLink,
          };
          const changesObj = {};
          Object.keys(dataToEdit).map((key) => {
            if (oldData[key] !== dataToEdit[key]) {
              changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
            }
            return null;
          });

          let changedlog = '';
          Object.keys(changesObj).map((key, index) => {
            changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

            if ((index + 1) !== Object.keys(changesObj).length) {
              changedlog += ', ';
            }
            return null;
          });

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'editSelectEvent',
            adminId: UID,
            adminEmail,
            adminName,
            eventId: selectEventId,
            data: {
              ...dataToEdit,
              changedlog,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }

        return resolve({ status: 'success' });
      }
      return resolve({ status: 'error', message: result.data.error });
    }).catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function getAllEvents(filterType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());

  FirebaseRef.child('events').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('events')
    .on('value', (snapshot) => {
      const allEvents = snapshot.val() || {};
      const array = [];
      Object.keys(allEvents).map((key) => {
        if (filterType === 'PAST') {
          if (allEvents[key].endDate < filterDate) {
            array.push({ key, ...allEvents[key] });
            // return null;
          }
        } else if (filterType === 'CURRENT') {
          if (allEvents[key].startDate <= filterDate && allEvents[key].endDate >= filterDate) {
            array.push({ key, ...allEvents[key] });
            // return null;
          }
        } else if (filterType === 'PLAN') {
          if (allEvents[key].startDate > filterDate) {
            array.push({ key, ...allEvents[key] });
            // return null;
          }
        } else {
          array.push({ key, ...allEvents[key] });
          // return null;
        }
        return null;
      });

      array.sort((a, b) => (b.endDate - a.endDate));
      return resolve(dispatch({ type: 'ADMIN_EVENTS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function deleteEvent(selectEventId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const deleteEventFunction = Firebase.app().functions().httpsCallable('deleteEvent');

      deleteEventFunction({
        eventId: selectEventId.toString(),
      }).then((result) => {
        if (result.data.status === 'success') {
          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'deleteEvent',
            adminId: UID,
            adminEmail,
            adminName,
            eventId: selectEventId.toString(),
            data: null,
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
          resolve({ status: 'success' });
        } else {
          resolve({ status: 'error', message: result.data.error });
        }
      })
        .catch(err => resolve({ status: 'error', message: err.message }));
    }
  }));
}

export function getAllCampaigns(filterType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());

  FirebaseRef.child('campaigns').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('campaigns')
    .on('value', (snapshot) => {
      const allCampaigns = snapshot.val() || {};
      const array = [];
      Object.keys(allCampaigns).map((key) => {
        if (filterType === 'PAST') {
          if (allCampaigns[key].endDate < filterDate) {
            array.push({ key, ...allCampaigns[key], filterType: 'past' });
            // return null;
          }
        } else if (filterType === 'CURRENT') {
          if (allCampaigns[key].startDate <= filterDate && allCampaigns[key].endDate >= filterDate) {
            array.push({ key, ...allCampaigns[key], filterType: 'current' });
            // return null;
          }
        } else if (filterType === 'PLAN') {
          if (allCampaigns[key].startDate > filterDate) {
            array.push({ key, ...allCampaigns[key], filterType: 'plan' });
            // return null;
          }
        } else {
          const type = allCampaigns[key].endDate < filterDate ? 'past' : (allCampaigns[key].startDate <= filterDate && allCampaigns[key].endDate >= filterDate) ? 'current' : (allCampaigns[key].startDate > filterDate) ? 'plan' : ''
          array.push({ key, ...allCampaigns[key], filterType: type });
          // return null;
        }
        return null;
      });

      array.sort((a, b) => (b.endDate - a.endDate));
      return resolve(dispatch({ type: 'ADMIN_CAMPAIGNS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function getCampaignHistory() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child('campaignHistory')
      .once('value', (snapshot) => {
        const campaignHistory = snapshot.val() || {};

        console.log(campaignHistory, "checkCampaignHistory")
        return resolve(dispatch({ type: 'ADMIN_CAMPAIGN_HISTORY_REPLACE', data: campaignHistory }));
    })
      .catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function getCampaignShowedHistory() {
  console.log('call getCampaignShowedHistory');
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child('campaignShowedHistory')
      .once('value', (snapshot) => {
        const campaignShowedHistory = snapshot.val() || {};

        console.log(campaignShowedHistory, "checkCampaignShowedHistory")
        return resolve(dispatch({ type: 'ADMIN_CAMPAIGN_SHOWED_HISTORY_REPLACE', data: campaignShowedHistory }));
    })
      .catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function getSingleCampaignShowedHistory(id) {
  console.log('call getSingleCampaignShowedHistory', id);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return (dispatch) => new Promise((resolve) => {
    /* FirebaseRef.child(`campaignHistory/${id}`)
      .on('value', (snapshot) => {
        const campaignHistory = snapshot.val() || {};

        console.log(campaignHistory, "campaignHistory")
        dispatch({ type: 'ADMIN_CAMPAIGN_HISTORY_REPLACE_SINGLE', id, data: campaignHistory });
      }); */

    FirebaseRef.child(`campaignShowedHistory/${id}`)
      .once('value', (snapshot) => {
        const campaignShowedHistory = snapshot.val() || {};

        console.log(campaignShowedHistory, "checkCampaignShowedHistory")
        return resolve(dispatch({ type: 'ADMIN_CAMPAIGN_SHOWED_HISTORY_REPLACE_SINGLE', id, data: campaignShowedHistory }));
      });
  });
}

export function getSingleCampaignShowedHistory2(id) {
  console.log('call getSingleCampaignShowedHistory2', id);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`campaignHistory/${id}`)
      .once('value', (snapshot) => {
        const campaignHistory = snapshot.val() || {};

        console.log(campaignHistory, "test data campaignHistory");
        dispatch({ type: 'ADMIN_CAMPAIGN_HISTORY_SINGLE', id, data: campaignHistory });

        FirebaseRef.child(`campaignHistory/${id}`)
          .on('child_added', (snapshot) => {
            const key = snapshot.key;
            if (!campaignHistory[key]) {
              const change = snapshot.val() || {};

              console.log(change, "test data campaignHistory")
              dispatch({ type: 'ADMIN_CAMPAIGN_HISTORY_SINGLE_ADDED', id, key, data: change });
            } else {
              // console.log('ignore campaignHistory', key);
            }
          });
      });

    FirebaseRef.child(`campaignShowedHistory/${id}`)
      .once('value', (snapshot) => {
        const campaignShowedHistory = snapshot.val() || {};

        console.log(Object.keys(campaignShowedHistory).length, "test data checkCampaignShowedHistory");

        FirebaseRef.child(`campaignShowedHistory/${id}`)
          .on('child_added', (snapshot) => {
            const key = snapshot.key;
            if (!campaignShowedHistory[key]) {
              const change = snapshot.val() || {};

              console.log(change, "test data checkCampaignShowedHistory");
              dispatch({ type: 'ADMIN_CAMPAIGN_SHOWED_HISTORY_SINGLE_ADDED', key, id, data: change });
            } else {
              // console.log('ignore checkCampaignShowedHistory', key);
            }
          });

  return resolve(dispatch({ type: 'ADMIN_CAMPAIGN_SHOWED_HISTORY_SINGLE', id, data: campaignShowedHistory }));
      });




  });
}

export function closeSingleCampaignShowedHistory(id) {
  console.log('call closeSingleCampaignShowedHistory', id);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return (dispatch) => new Promise((resolve) => {
    FirebaseRef.child(`campaignHistory/${id}`).off();
    FirebaseRef.child(`campaignShowedHistory/${id}`).off();
  });
}

export function deleteCampaign(selectCampaignId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve) => {
    FirebaseRef.child(`campaigns/${selectCampaignId}`).remove().then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deleteCampaign',
        adminId: UID,
        adminEmail,
        adminName,
        campaignId: selectCampaignId.toString(),
        data: null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      resolve({ status: 'success', message: 'success' });
    })
      .catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function editSelectCampaign(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    selectCampaignId,
    startDate,
    endDate,
    selectedLocations,
    selectedDesigns,
    selectedEnvs,
    selectedPlatforms,
    imageUrl,
    imageUrlEn,
    imageUrlRu,
    pageToOpen,
    oldData,
    adFormMeasure,
    externalBanner,
    overflowBackgroundColor,
    title,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!selectCampaignId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!selectedLocations || !selectedLocations.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLocations })); }
  if (!selectedDesigns || !selectedDesigns.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.selectedDesigns })); }
  if (!selectedEnvs || !selectedEnvs.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.selectedEnvs })); }
  if (!selectedPlatforms || !selectedPlatforms.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.selectedPlatforms })); }
  if ((!externalBanner) && (!imageUrl || !imageUrlEn || !imageUrlRu)) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  // if (!title) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.title })); }

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`campaigns/${selectCampaignId}`).update({
      startDate: startParsedDate,
      endDate: endParsedDate,
      selectedLocations,
      selectedPlatforms,
      selectedDesigns,
      selectedEnvs,
      imageUrl,
      imageUrlEn,
      imageUrlRu,
      externalBanner,
      overflowBackgroundColor: overflowBackgroundColor || null,
      pageToOpen,
      adFormMeasure,
      title,
    })
      .then(() => {
        if (startParsedDate !== Date.parse(oldData.startDate) || endParsedDate !== Date.parse(oldData.endDate) || !isEqual(selectedLocations, oldData.selectedLocations) || !isEqual(selectedDesigns, oldData.selectedDesigns) || !isEqual(selectedPlatforms, oldData.selectedPlatforms) || !isEqual(selectedEnvs, oldData.selectedEnvs) || pageToOpen !== oldData.pageToOpen || adFormMeasure !== oldData.adFormMeasure) {
          const dataToEdit = {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            selectedDesigns,
            selectedLocations,
            selectedPlatforms,
            selectedEnvs,
            imageUrl,
            imageUrlEn,
            imageUrlRu,
            externalBanner,
            overflowBackgroundColor: overflowBackgroundColor || null,
            title: title || null,
            pageToOpen,
            adFormMeasure,
          };
          const changesObj = {};
          Object.keys(dataToEdit).map((key) => {
            if (oldData[key] !== dataToEdit[key]) {
              changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
            }
            return null;
          });

          let changedlog = '';
          Object.keys(changesObj).map((key, index) => {
            changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

            if ((index + 1) !== Object.keys(changesObj).length) {
              changedlog += ', ';
            }
            return null;
          });

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'editSelectCampaign',
            adminId: UID,
            adminEmail,
            adminName,
            campaignId: selectCampaignId,
            data: {
              ...dataToEdit,
              changedlog,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }
        return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.error });
      })
      .catch(() => reject({ status: constants.SERVER_STATUS_CODE.error, message: constants.SERVER_STATUS_CODE.error }));
  });
}

export function addNewCampaign(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  console.log('addNewCampaign', data);

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    startDate, endDate, selectedLocations, selectedPlatforms, selectedDesigns, selectedEnvs, imageUrl, imageUrlEn, imageUrlRu, pageToOpen, adFormMeasure, externalBanner, overflowBackgroundColor, title,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }

  if (!selectedLocations || !selectedLocations.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLocations })); }
  if (!selectedPlatforms || !selectedPlatforms.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPlatforms })); }
  if (!selectedDesigns || !selectedDesigns.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingDesigns })); }
  if (!selectedEnvs || !selectedEnvs.length) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingEnvs })); }

  console.log('addNewCampaign test 1');

  if (!externalBanner) {
    if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
    if (!imageUrlEn) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
    if (!imageUrlRu) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto }));
  }

  console.log('addNewCampaign test 2');

  return () => new Promise((resolve, reject) => {
    // return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });

    FirebaseRef.child('campaigns').push({
      startDate: startParsedDate,
      endDate: endParsedDate,
      selectedLocations,
      selectedPlatforms,
      selectedDesigns,
      selectedEnvs,
      imageUrl,
      imageUrlEn,
      imageUrlRu,
      externalBanner,
      overflowBackgroundColor: overflowBackgroundColor || null,
      pageToOpen,
      adFormMeasure,
      title: title || null,
    })
      .then(() => {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'addNewCampaign',
          adminId: UID,
          adminEmail,
          adminName,
          data: { ...data },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
      })
      .catch(() => reject({ status: constants.SERVER_STATUS_CODE.error, message: constants.SERVER_STATUS_CODE.error }));
  });
}

export function addShopDiscount(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, type, value, showInBanner, devMode, specialPrice, discountPrice, selectedDiscountPackage,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (type === constants.SPECIALPRICE) {
    map(constants.SHOP_PRODUCT_TYPES, (item) => {
      if (specialPrice[item.label] || type === constants.BONUS) {
        return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue }));
      }
      return null;
    });
  }
  if ((type === constants.DISCOUNTPRICE) && (!discountPrice && discountPrice !== 0)) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue }));
  }

  return () => new Promise((resolve) => {
    const createDiscountFunction = Firebase.app().functions().httpsCallable('createDiscount');

    createDiscountFunction({
      startDate: startParsedDate,
      endDate: endParsedDate,
      titleEN: enTitle,
      titleLV: lvTitle,
      titleRU: ruTitle,
      descriptionEN: enDescription,
      descriptionLV: lvDescription,
      descriptionRU: ruDescription,
      imageUrl,
      type,
      value: 10,
      showInBanner,
      devMode,
      specialPrice,
      discountPrice,
      selectedDiscountPackage,
    }).then((result) => {
      if (result.data.status === 'success') {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'createDiscount',
          adminId: UID,
          adminEmail,
          adminName,
          data: {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            imageUrl,
            type,
            value,
            showInBanner,
            devMode,
            specialPrice,
            discountPrice,
            selectedDiscountPackage: selectedDiscountPackage || [],
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });

        return resolve({ status: 'success' });
      } else {
        return resolve({ status: 'error', message: result.data.error });
      }
    }).catch((err) => {
      resolve({ status: 'error', message: err.message });
    });
  });
}

export function editShopDiscount(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    selectEventId, startDate, endDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl, type, value, showInBanner, oldData, devMode, discountPrice, specialPrice, selectedDiscountPackage,
  } = data;

  const startParsedDate = Date.parse(startDate);
  const endParsedDate = Date.parse(endDate);

  if (startParsedDate > endParsedDate) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.wrongEventDate }));
  }
  if (!selectEventId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }

  if (type === constants.SPECIALPRICE || type === constants.BONUS) {
    map(constants.SHOP_PRODUCT_TYPES, (item) => {
      if (specialPrice[item.label]) {
        return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue }));
      }
      return null;
    });
  }
  if ((type === constants.DISCOUNTPRICE) && (!discountPrice && discountPrice !== 0)) {
    return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingValue }));
  }

  return () => new Promise((resolve) => {
    const editEventFunction = Firebase.app().functions().httpsCallable('editDiscount');

    editEventFunction({
      discountId: selectEventId.toString(),
      startDate: startParsedDate,
      endDate: endParsedDate,
      titleEN: enTitle,
      titleLV: lvTitle,
      titleRU: ruTitle,
      descriptionEN: enDescription,
      descriptionLV: lvDescription,
      descriptionRU: ruDescription,
      imageUrl,
      type,
      value: 10,
      showInBanner,
      devMode,
      specialPrice,
      discountPrice,
      selectedDiscountPackage,
    }).then((result) => {
      if (result.data.status === 'success') {
        if (startParsedDate !== Date.parse(oldData.startDate) || endParsedDate !== Date.parse(oldData.endDate) || enTitle !== oldData.titleEN || enDescription !== oldData.descriptionEN || type !== oldData.type || value !== oldData.value || showInBanner !== oldData.showInBanner || devMode !== oldData.devMode) {
          const dataToEdit = {
            startDate: dateFormat(startDate),
            endDate: dateFormat(endDate),
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            imageUrl,
            type,
            value,
            showInBanner,
            devMode,
            specialPrice: (type === constants.SPECIALPRICE || type === constants.BONUS) ? specialPrice : null,
            discountPrice,
            selectedDiscountPackage: selectedDiscountPackage || [],
          };
          const changesObj = {};
          Object.keys(dataToEdit).map((key) => {
            if (oldData[key] !== dataToEdit[key]) {
              changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
            }
            return null;
          });

          let changedlog = '';
          Object.keys(changesObj).map((key, index) => {
            changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

            if ((index + 1) !== Object.keys(changesObj).length) {
              changedlog += ', ';
            }
            return null;
          });

          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'editDiscount',
            adminId: UID,
            adminEmail,
            adminName,
            eventId: selectEventId,
            data: {
              ...dataToEdit,
              changedlog,
            },
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
        }
        return resolve({ status: 'success' });
      }
      return resolve({ status: 'error', message: result.data.error });
    }).catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function getAllShopDiscounts(filterType) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());

  FirebaseRef.child('discounts').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('discounts')
    .on('value', (snapshot) => {
      const allShopDiscount = snapshot.val() || {};
      const array = [];
      Object.keys(allShopDiscount).map((key) => {
        if (filterType === 'PAST') {
          if (allShopDiscount[key].endDate < filterDate) {
            array.push({ key, ...allShopDiscount[key] });
            // return null;
          }
        } else if (filterType === 'CURRENT') {
          if (allShopDiscount[key].startDate <= filterDate && allShopDiscount[key].endDate >= filterDate) {
            array.push({ key, ...allShopDiscount[key] });
            // return null;
          }
        } else if (filterType === 'PLAN') {
          if (allShopDiscount[key].startDate > filterDate) {
            array.push({ key, ...allShopDiscount[key] });
            // return null;
          }
        } else {
          array.push({ key, ...allShopDiscount[key] });
          // return null;
        }
        return null;
      });

      array.sort((a, b) => (b.endDate - a.endDate));
      return resolve(dispatch({ type: 'ADMIN_DISCOUNTS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function deleteShopDiscount(selectEventId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      const deleteEventFunction = Firebase.app().functions().httpsCallable('deleteDiscount');

      deleteEventFunction({
        discountId: selectEventId.toString(),
      }).then((result) => {
        if (result.data.status === 'success') {
          AdminLogsRef().child('adminLogs/adminActions').push({
            type: 'deleteDiscount',
            adminId: UID,
            adminEmail,
            adminName,
            discountId: selectEventId,
            data: null,
            date: Firebase.database.ServerValue.TIMESTAMP,
          });
          resolve({ status: 'success' });
        } else {
          resolve({ status: 'error', message: result.data.error });
        }
      })
        .catch(err => resolve({ status: 'error', message: err.message }));
    }
  }));
}

export function editBan(uid, endDate, reason) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  const endDate2 = new Date(endDate);

  const endDateParsed = Date.parse(endDate2);

  if (!uid) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav id' })); }
  if (!reason) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav iemesla' })); }
  if (!endDateParsed || !(endDate2 instanceof Date)) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Nav beigu laika' })); }

  let blocked = false;
  if (endDate > Date.now()) {
    blocked = true;
  }

  return () => new Promise(resolve => FirebaseRef.child(`bans/${uid.toString()}`).update({
    endDate: endDateParsed,
    reason,
    blocked,
  }).then(() => {
    FirebaseRef.child(`users/${uid}`).update({
      banEndDate: endDateParsed,
      banReason: reason,
      blocked,
    });

    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'editBan',
      adminId: UID,
      adminEmail,
      adminName,
      userId: uid.toString(),
      data: {
        endDate: endDateParsed,
        reason,
        blocked,
      },
      date: Firebase.database.ServerValue.TIMESTAMP,
    });

    resolve({ status: 'success' });
  }).catch((err) => {
    resolve({ status: 'error', message: err });
  }));
}

const isdigit = (value) => {
  const val = !!Number(value);
  return val;
};

export function addTournament(data) {
  console.log('addTournament', data);
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve({ status: 'error', message: 'Nav UID' }));

  const {
    name,
    titleLV,
    titleEN,
    titleRU,
    entryFee,
    bonus,
    startDate,
    registerTime,
    winnerPercent,
    parasta,
    G,
    atra,
    lightning,
    unlimited,
    pro,
    maxPlayers,
    maza,
    rounds,
    roundLength,
    newRoundPause,
    bet,
    privateTournament,
    devTournament,
    password,
    sponsorImageUrl,
    prizeImageUrl,
    playerPerRoomLimit,
    bannerPrizeChecked,
    bannerPrizeImageUrl,
    bannerShowChecked,
    bannerStartDate,
    bannerEndDate,
    specialPrizeLV,
    specialPrizeEN,
    specialPrizeRU,
  } = data;
  const startDateParsed = Date.parse(startDate);

  if (privateTournament && (!password || (typeof password !== 'string') || (password && password.length !== 4) || (!isdigit(password)))) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Wrong password' })); }

  if (!titleLV || !titleEN || !titleRU) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing title' })); }
  if (!entryFee) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing entry fee' })); }
  if (!rounds) { return () => new Promise(resolve => resolve({ status: 'error', message: 'No starting tokens' })); }
  if (!roundLength) { return () => new Promise(resolve => resolve({ status: 'error', message: 'No round length' })); }
  if (!winnerPercent) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing winning percent' })); }
  if (!maxPlayers) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing maximum players' })); }

  if (Number.isNaN(Number(entryFee)) || parseInt(entryFee, 10) <= 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Entry fee has to be positive number' })); }
  if (Number.isNaN(Number(rounds)) || parseInt(rounds, 10) <= 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Rounds per tournament has to be positive number' })); }
  if (Number.isNaN(Number(roundLength)) || parseInt(roundLength, 10) < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Round length has to be positive number' })); }
  if (!startDateParsed || !(startDate instanceof Date)) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing date' })); }
  if (Number.isNaN(Number(winnerPercent)) || parseInt(winnerPercent, 10) <= 0 || parseInt(winnerPercent, 10) > 100) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Winning percent has to be positive number' })); }

  let gameType = 'P';
  if (parasta) {
    gameType = 'P';
  } else if (G) {
    gameType = 'G';
  }

  let newState;

  if (startDateParsed && registerTime && startDateParsed - (1000 * 60 * registerTime) > Date.now()) {
    newState = 'pending';
  } else if (startDateParsed && registerTime && startDateParsed - (1000 * 60 * registerTime) < Date.now() && startDateParsed > Date.now()) {
    newState = 'registration';
  } else {
    return () => new Promise(resolve => resolve({ status: 'error', message: 'Start time cannot be in the past' }));
  }

  newState = 'pending';

  /*
  let roundLengthTime = 20;

  if (roundLength > 24) {
    roundLengthTime = 60;
  } else if (roundLength > 12) {
    roundLengthTime = 40;
  } */

  let speed = 'normal';

  if (atra) {
    speed = 'atra';
  } else if (lightning) {
    speed = 'lightning';
  }

  let roundLengthTime = constants.CALC_TOURNAMENT_ROUND_LENGTH(roundLength, speed);

  const maxLengthTime = (roundLengthTime * rounds) + ((newRoundPause || 0) * (rounds - 1));
  const maxLengthTimeRounded = Math.ceil(maxLengthTime / 10) * 10;

  console.log('maxLengthTimeRounded', maxLengthTimeRounded, maxLengthTime);

  const bannerStartDateParsed = Date.parse(bannerStartDate);
  const bannerEndDateParsed = Date.parse(bannerEndDate);

  return () => new Promise(resolve => TournamentsRef().child('newTournaments').push({
    params: {
      name: titleLV,
      titleEN: titleEN,
      titleLV: titleLV,
      titleRU: titleRU,
      bet: bet || '1:10',
      currentState: newState,
      currentRound: 1,
      entryFee: parseInt(entryFee, 10),
      bonus: parseInt(bonus, 10) || 0,
      rounds: parseInt(rounds, 10),
      roundLength: parseInt(roundLength, 10),
      roundLengthTime: roundLengthTime || null,
      startDate: startDateParsed,
      registerTime,
      winnerPercent,
      maxPlayers,
      maxLengthTime: maxLengthTimeRounded || null,
      gameType,
      fastGame: atra || null,
      lightningGame: lightning || null,
      unlimitedGame: unlimited || null,
      smallGame: maza,
      proGame: pro,
      newRoundPause,
      totalBank: 0,
      open: true,
      privateTournament: privateTournament || null,
      devTournament: devTournament || null,
      password: password || null,
      sponsorImg: sponsorImageUrl,
      prizeImg: prizeImageUrl,
      playerPerRoomLimit: playerPerRoomLimit || null,
      bannerPrizeImageUrl: bannerPrizeImageUrl || null,
      bannerPrizeChecked: bannerPrizeChecked || null,
      bannerShowChecked: bannerShowChecked || null,
      bannerStartDate: bannerStartDateParsed || null,
      bannerEndDate: bannerEndDateParsed || null,
      specialPrizeLV: bannerPrizeChecked ? (specialPrizeLV || null) : null,
      specialPrizeEN: bannerPrizeChecked ? (specialPrizeEN || null) : null,
      specialPrizeRU: bannerPrizeChecked ? (specialPrizeRU || null) : null,
    },
  }).then(async (res) => {
    const { key } = res;

    const promises = [];

    if (key) {
      if (bannerShowChecked) {
        const createEventFunction = Firebase.app().functions().httpsCallable('createEvent');

        console.log('call create banner', bannerShowChecked, bannerPrizeChecked, bannerPrizeImageUrl, bannerStartDate, bannerEndDate, startDateParsed)

        console.log("bannerPrizeChecked", bannerPrizeChecked, bannerPrizeImageUrl);

        await createEventFunction({
          startDate: bannerStartDateParsed,
          endDate: bannerEndDateParsed,
          tournamentStartDate: startDateParsed,
          name: titleLV,
          titleEN: titleEN,
          titleLV: titleLV,
          titleRU: titleRU,
          descriptionEN: titleEN,
          descriptionLV: titleLV,
          descriptionRU: titleRU,
          descriptionTournamentId: key,
          bannerPrizeChecked,
          imageUrl: (bannerPrizeChecked && bannerPrizeImageUrl) ? bannerPrizeImageUrl : null,
          pageToOpen: 'plannedTournaments',
          showInBanner: bannerShowChecked,
          devMode: devTournament,
          bannerImageType: null,
          externalLink: null,
          specialPrizeLV: bannerPrizeChecked ? (specialPrizeLV || null) : null,
          specialPrizeEN: bannerPrizeChecked ? (specialPrizeEN || null) : null,
          specialPrizeRU: bannerPrizeChecked ? (specialPrizeRU || null) : null,
        });
      }

      const joinTournamentFunction = Firebase.app().functions('europe-west1').httpsCallable('joinTournament');
      const leaveTournamentFunction = Firebase.app().functions().httpsCallable('leaveTournament');
      const pauseTournamentFunction = Firebase.app().functions().httpsCallable('pauseTournament');
      const returnToTournamentFunction = Firebase.app().functions().httpsCallable('returnToTournament');

      joinTournamentFunction({ tournamentId: 'init' });
      leaveTournamentFunction({ tournamentId: 'init' });
      pauseTournamentFunction({ tournamentId: 'init' });
      returnToTournamentFunction({ tournamentId: 'init' });

      promises.push(TournamentsRef().child(`newTournamentsList/${key}`).set({
        currentState: newState,
        currentRound: 1,
        name: titleLV,
        titleEN: titleEN,
        titleLV: titleLV,
        titleRU: titleRU,
        bet: bet || '1:10',
        entryFee: parseInt(entryFee, 10),
        bonus: parseInt(bonus, 10) || 0,
        rounds: parseInt(rounds, 10),
        roundLength: parseInt(roundLength, 10),
        roundLengthTime: roundLengthTime || null,
        startDate: startDateParsed,
        registerTime,
        winnerPercent,
        sponsorImg: sponsorImageUrl || null,
        prizeImg: prizeImageUrl || null,
        maxPlayers,
        maxLengthTime: maxLengthTime || null,
        gameType,
        fastGame: atra || null,
        lightningGame: lightning || null,
        unlimitedGame: unlimited || null,
        smallGame: maza,
        proGame: pro,
        newRoundPause,
        totalBank: 0,
        open: true,
        privateTournament: privateTournament || null,
        devTournament: devTournament || null,
        playerPerRoomLimit: playerPerRoomLimit || null,
        bannerPrizeImageUrl: bannerPrizeImageUrl || null,
        bannerPrizeChecked: bannerPrizeChecked || null,
        bannerShowChecked: bannerShowChecked || null,
        bannerStartDate: bannerStartDateParsed || null,
        bannerEndDate: bannerEndDateParsed || null,
        specialPrizeLV: bannerPrizeChecked ? (specialPrizeLV || null) : null,
        specialPrizeEN: bannerPrizeChecked ? (specialPrizeEN || null) : null,
        specialPrizeRU: bannerPrizeChecked ? (specialPrizeRU || null) : null,
      }));

      promises.push(AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'createdTournament',
        adminId: UID,
        adminEmail,
        adminName,
        tournamentId: key,
        data: {
          name: titleLV,
          titleEN: titleEN,
          titleLV: titleLV,
          titleRU: titleRU,
          bet: bet || '1:10',
          currentState: newState,
          currentRound: 1,
          entryFee: parseInt(entryFee, 10),
          bonus: parseInt(bonus, 10) || 0,
          rounds: parseInt(rounds, 10),
          roundLength: parseInt(roundLength, 10),
          roundLengthTime: roundLengthTime || null,
          startDate: startDateParsed,
          registerTime,
          winnerPercent,
          sponsorImg: sponsorImageUrl || null,
          prizeImg: prizeImageUrl || null,
          maxPlayers,
          gameType,
          fastGame: atra || null,
          lightningGame: lightning || null,
          unlimitedGame: unlimited || null,
          smallGame: maza,
          proGame: pro,
          newRoundPause,
          totalBank: 0,
          open: true,
          privateTournament: privateTournament || null,
          devTournament: devTournament || null,
          password: password || null,
          playerPerRoomLimit: playerPerRoomLimit || null,
          bannerPrizeImageUrl: bannerPrizeImageUrl || null,
          bannerPrizeChecked: bannerPrizeChecked || null,
          bannerShowChecked: bannerShowChecked || null,
          bannerStartDate: bannerStartDateParsed || null,
          bannerEndDate: bannerEndDateParsed || null,
          specialPrizeLV: bannerPrizeChecked ? (specialPrizeLV || null) : null,
          specialPrizeEN: bannerPrizeChecked ? (specialPrizeEN || null) : null,
          specialPrizeRU: bannerPrizeChecked ? (specialPrizeRU || null) : null,
        },
        date: Firebase.database.ServerValue.TIMESTAMP,
      }));
    }

    Promise.allSettled(promises).then(() => {
      resolve({ status: 'success' });
    }).catch((err) => {
      resolve({ status: 'success' });
    });
  })
    .catch((err) => {
      resolve({ status: 'error', message: err });
    }));
}


export function editTournament({ data, oldData }) {
  console.log('editTournament', data, oldData);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

//  const {
//    prevState, tournamentToEdit, name, entryFee, bonus, startDate, registerTime, winnerPercent, parasta, G, atra, lightning, unlimited, pro, maxPlayers, maza, rounds, roundLength, newRoundPause, bet, privateTournament, devTournament, password, sponsorImageUrl, prizeImageUrl, playerPerRoomLimit,
//  } = data;

  const {
    prevState,
    tournamentToEdit,
    sponsorImageUrl,
    prizeImageUrl,
    titleLV,
    titleEN,
    titleRU,
    entryFee,
    bonus,
    startDate,
    registerTime,
    winnerPercent,
    parasta,
    G,
    atra,
    lightning,
    unlimited,
    pro,
    maxPlayers,
    maza,
    rounds,
    roundLength,
    newRoundPause,
    bet,
    password,
    privateTournament,
    devTournament,
    playerPerRoomLimit,
    bannerPrizeChecked,
    bannerPrizeImageUrl,
    bannerShowChecked,
    bannerStartDate,
    bannerEndDate,
    specialPrizeLV,
    specialPrizeEN,
    specialPrizeRU,
  } = data;

  const startDateParsed = Date.parse(startDate);

  if (!tournamentToEdit) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Error editing tournament' })); }
  if (!titleLV || !titleEN || !titleRU) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing title' })); }
  if (!entryFee) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing entry fee' })); }
  if (!rounds) { return () => new Promise(resolve => resolve({ status: 'error', message: 'No starting tokens' })); }
  if (!roundLength) { return () => new Promise(resolve => resolve({ status: 'error', message: 'No round length' })); }
  if (!winnerPercent) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing winning percent' })); }
  if (!maxPlayers) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing maximum players' })); }

  if (Number.isNaN(Number(entryFee)) || parseInt(entryFee, 10) <= 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Entry fee has to be positive number' })); }
  if (Number.isNaN(Number(rounds)) || parseInt(rounds, 10) <= 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Rounds per tournament has to be positive number' })); }
  if (Number.isNaN(Number(roundLength)) || parseInt(roundLength, 10) < 0) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Round length has to be positive number' })); }
  if (!startDateParsed || !(startDate instanceof Date)) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Missing date' })); }
  if (Number.isNaN(Number(winnerPercent)) || parseInt(winnerPercent, 10) <= 0 || parseInt(winnerPercent, 10) > 100) { return () => new Promise(resolve => resolve({ status: 'error', message: 'Winning percent has to be positive number' })); }

  let gameType = 'P';
  if (parasta) {
    gameType = 'P';
  } else if (G) {
    gameType = 'G';
  }

  let newState;

  if (startDateParsed && registerTime && startDateParsed - (1000 * 60 * registerTime) > Date.now()) {
    newState = 'pending';
  } else if (startDateParsed && registerTime && startDateParsed - (1000 * 60 * registerTime) < Date.now() && startDateParsed > Date.now()) {
    newState = 'registration';
  } else {
    return () => new Promise(resolve => resolve({ status: 'error', message: 'Start time cannot be in the past' }));
  }

  newState = 'pending';

  /*
  let roundLengthTime = 20;

  if (roundLength > 24) {
    roundLengthTime = 60;
  } else if (roundLength > 12) {
    roundLengthTime = 40;
  } */

  let speed = 'normal';

  if (atra) {
    speed = 'atra';
  } else if (lightning) {
    speed = 'lightning';
  }

  let roundLengthTime = constants.CALC_TOURNAMENT_ROUND_LENGTH(roundLength, speed);

  const bannerStartDateParsed = Date.parse(bannerStartDate);
  const bannerEndDateParsed = Date.parse(bannerEndDate);

  // const maxLengthTime = roundLengthTime * rounds;

  const maxLengthTime = (roundLengthTime * rounds) + ((newRoundPause || 0) * (rounds - 1));
  const maxLengthTimeRounded = Math.ceil(maxLengthTime / 10) * 10;

  console.log('maxLengthTimeRounded', maxLengthTimeRounded, maxLengthTime);

  const dataToEdit = {
    currentState: newState,
    name: titleLV,
    titleEN: titleEN,
    titleLV: titleLV,
    titleRU: titleRU,
    bet: bet || '1:10',
    entryFee: parseInt(entryFee, 10),
    bonus: parseInt(bonus, 10) || 0,
    rounds: parseInt(rounds, 10),
    roundLength: parseInt(roundLength, 10),
    roundLengthTime: roundLengthTime || null,
    startDate: startDateParsed,
    registerTime,
    winnerPercent,
    sponsorImg: sponsorImageUrl || null,
    prizeImg: prizeImageUrl || null,
    maxPlayers,
    maxLengthTime: maxLengthTime || null,
    gameType,
    fastGame: atra || null,
    lightningGame: lightning || null,
    unlimitedGame: unlimited || null,
    smallGame: maza,
    proGame: pro,
    newRoundPause,
    open: true,
    privateTournament: privateTournament || null,
    devTournament: devTournament || null,
    playerPerRoomLimit: playerPerRoomLimit || null,
    bannerPrizeImageUrl: bannerPrizeImageUrl || null,
    bannerPrizeChecked: bannerPrizeChecked || null,
    bannerShowChecked: bannerShowChecked || null,
    bannerStartDate: bannerStartDateParsed || null,
    bannerEndDate: bannerEndDateParsed || null,
    specialPrizeLV: bannerPrizeChecked ? (specialPrizeLV || null) : null,
    specialPrizeEN: bannerPrizeChecked ? (specialPrizeEN || null) : null,
    specialPrizeRU: bannerPrizeChecked ? (specialPrizeRU || null) : null,
  };

  if (!privateTournament) {
    dataToEdit.password = null;
  }

  if (privateTournament && password) {
    dataToEdit.password = password;
  }

  console.log('UID 0', { UID, tournamentToEdit, dataToEdit });

  return () => new Promise(resolve => FirebaseRef.child('events').orderByChild('descriptionTournamentId').equalTo(tournamentToEdit).limitToLast(1).once('value', (snapshot) => {
    const eventData = snapshot.val() || null;

    console.log('eventData', eventData);

    TournamentsRef().child(`newTournaments/${tournamentToEdit}/params`).update(dataToEdit).then(async () => {
      const promises = [];

      if (eventData) {
        const eventKey = (eventData && Object.keys(eventData).length > 0) ? Object.keys(eventData)[0] : null;

        console.log('already has an event, update exisitng one', eventKey);


        if (eventKey) {
          const editEventFunction = Firebase.app().functions().httpsCallable('editEvent');

          console.log('call edit banner', bannerShowChecked, bannerPrizeChecked, bannerPrizeImageUrl, bannerStartDate, bannerEndDate, startDateParsed)

          console.log("bannerPrizeChecked", bannerPrizeChecked, bannerPrizeImageUrl);

          await editEventFunction({
            eventId: eventKey,
            startDate: bannerStartDateParsed,
            endDate: bannerEndDateParsed,
            tournamentStartDate: startDateParsed,
            name: titleLV,
            titleEN: titleEN,
            titleLV: titleLV,
            titleRU: titleRU,
            descriptionEN: titleEN,
            descriptionLV: titleLV,
            descriptionRU: titleRU,
            descriptionTournamentId: tournamentToEdit,
            bannerPrizeChecked,
            imageUrl: (bannerPrizeChecked && bannerPrizeImageUrl) ? bannerPrizeImageUrl : null,
            pageToOpen: 'plannedTournaments',
            showInBanner: bannerShowChecked,
            devMode: devTournament,
            bannerImageType: null,
            externalLink: null,
            specialPrizeLV: bannerPrizeChecked ? (specialPrizeLV || null) : null,
            specialPrizeEN: bannerPrizeChecked ? (specialPrizeEN || null) : null,
            specialPrizeRU: bannerPrizeChecked ? (specialPrizeRU || null) : null,
          });

        }/* else {
          // Delete event
          const deleteEventFunction = Firebase.app().functions().httpsCallable('deleteEvent');

          if (eventKey) {
            await deleteEventFunction({
              eventId: eventKey,
            });
          }
        }*/

      } else {
        console.log('has no event, add a new one');

        if (bannerShowChecked) {
          const createEventFunction = Firebase.app().functions().httpsCallable('createEvent');

          console.log('call create banner', bannerShowChecked, bannerPrizeChecked, bannerPrizeImageUrl, bannerStartDate, bannerEndDate, startDateParsed)

        //  const bannerStartDateParsed = Date.parse(bannerStartDate);
        //  const bannerEndDateParsed = Date.parse(bannerEndDate);

          console.log("bannerPrizeChecked", bannerPrizeChecked, bannerPrizeImageUrl);

          await createEventFunction({
            startDate: bannerStartDateParsed,
            endDate: bannerEndDateParsed,
            tournamentStartDate: startDateParsed,
            name: titleLV,
            titleEN: titleEN,
            titleLV: titleLV,
            titleRU: titleRU,
            descriptionEN: titleEN,
            descriptionLV: titleLV,
            descriptionRU: titleRU,
            descriptionTournamentId: tournamentToEdit,
            bannerPrizeChecked,
            imageUrl: (bannerPrizeChecked && bannerPrizeImageUrl) ? bannerPrizeImageUrl : null,
            pageToOpen: 'plannedTournaments',
            showInBanner: bannerShowChecked,
            devMode: devTournament,
            bannerImageType: null,
            externalLink: null,
            specialPrizeLV: bannerPrizeChecked ? (specialPrizeLV || null) : null,
            specialPrizeEN: bannerPrizeChecked ? (specialPrizeEN || null) : null,
            specialPrizeRU: bannerPrizeChecked ? (specialPrizeRU || null) : null,
          });
        }
      }

      /* if (bannerShowChecked) {
        const createEventFunction = Firebase.app().functions().httpsCallable('createEvent');

        console.log('call create banner', bannerShowChecked, bannerPrizeChecked, bannerPrizeImageUrl, bannerStartDate, bannerEndDate, startDateParsed)

        const bannerStartDateParsed = Date.parse(bannerStartDate);
        const bannerEndDateParsed = Date.parse(bannerEndDate);

        console.log("bannerPrizeChecked", bannerPrizeChecked, bannerPrizeImageUrl);

        await createEventFunction({
          startDate: bannerStartDateParsed,
          endDate: bannerEndDateParsed,
          tournamentStartDate: startDateParsed,
          name: titleLV,
          titleEN: titleEN,
          titleLV: titleLV,
          titleRU: titleRU,
          descriptionEN: titleEN,
          descriptionLV: titleLV,
          descriptionRU: titleRU,
          descriptionTournamentId: tournamentToEdit,
          bannerPrizeChecked,
          imageUrl: (bannerPrizeChecked && bannerPrizeImageUrl) ? bannerPrizeImageUrl : null,
          pageToOpen: 'plannedTournaments',
          showInBanner: bannerShowChecked,
          devMode: devTournament,
          bannerImageType: null,
          externalLink: null,
        });
      } */

      if (newState === 'pending') {
        promises.push(TournamentsRef().child(`newTournaments/${tournamentToEdit}/players`).once('value', (snapshot) => {
          const players = snapshot.val();

          TournamentsRef().child(`newTournamentsList/${tournamentToEdit}/roomsResolved`).remove();

          TournamentsRef().child(`newTournaments/${tournamentToEdit}/params/roomsResolved`).remove();

          if (players) {
            Object.keys(players).map((key) => {
              FirebaseRef.child(`users/${key.toString()}/activeTournament`).remove();
              return null;
            });
          }
        }));
      } else if (newState === 'registration') {
        if (startDateParsed && startDateParsed - (1000 * 60 * 5) > Date.now()) {
          promises.push(TournamentsRef().child(`newTournaments/${tournamentToEdit}/players`).once('value', (snapshot) => {
            const players = snapshot.val();

            TournamentsRef().child(`newTournamentsList/${tournamentToEdit}/roomsResolved`).remove();

            TournamentsRef().child(`newTournaments/${tournamentToEdit}/params/roomsResolved`).remove();

            if (players) {
              Object.keys(players).map((key) => {
                FirebaseRef.child(`users/${key.toString()}/activeTournament`).remove();
                return null;
              });
            }
          }));
        }
      }

      if (newState && prevState === 'closed') {
        TournamentsRef().child(`newTournaments/${tournamentToEdit}/players`).remove();
        TournamentsRef().child(`newTournaments/${tournamentToEdit}/playersStaticData`).remove();
        TournamentsRef().child(`newTournaments/${tournamentToEdit}/params`).update({
          registeredPlayers: 0,
          totalBank: 0,
          currentRound: 1,
        });
        TournamentsRef().child(`newTournamentsList/${tournamentToEdit}`).update({
          registeredPlayers: 0,
          totalBank: 0,
          currentRound: 1,
        });
      }

      promises.push(TournamentsRef().child(`newTournamentsList/${tournamentToEdit}`).update({
        currentState: newState,
        name: titleLV,
        titleEN: titleEN,
        titleLV: titleLV,
        titleRU: titleRU,
        bet: bet || '1:10',
        entryFee: parseInt(entryFee, 10),
        bonus: parseInt(bonus, 10) || 0,
        rounds: parseInt(rounds, 10),
        roundLength: parseInt(roundLength, 10),
        roundLengthTime: roundLengthTime || null,
        startDate: startDateParsed,
        registerTime,
        winnerPercent,
        sponsorImg: sponsorImageUrl || null,
        prizeImg: prizeImageUrl || null,
        maxPlayers,
        maxLengthTime: maxLengthTime || null,
        gameType,
        fastGame: atra || null,
        lightningGame: lightning || null,
        unlimitedGame: unlimited || null,
        smallGame: maza,
        proGame: pro,
        newRoundPause,
        open: true,
        privateTournament: privateTournament || null,
        devTournament: devTournament || null,
        playerPerRoomLimit: playerPerRoomLimit || null,
        bannerPrizeImageUrl: bannerPrizeImageUrl || null,
        bannerPrizeChecked: bannerPrizeChecked || null,
        bannerShowChecked: bannerShowChecked || null,
        bannerStartDate: bannerStartDateParsed || null,
        bannerEndDate: bannerEndDateParsed || null,
        specialPrizeLV: bannerPrizeChecked ? (specialPrizeLV || null) : null,
        specialPrizeEN: bannerPrizeChecked ? (specialPrizeEN || null) : null,
        specialPrizeRU: bannerPrizeChecked ? (specialPrizeRU || null) : null,
      }));

      const changesObj = {};

      Object.keys(dataToEdit).map((key) => {
        if (oldData[key] !== dataToEdit[key] && key !== 'open') {
          changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
        }
        return null;
      });

      console.log('changesObj', { changesObj, length: Object.keys(changesObj).length });

      let changedlog = '';

      Object.keys(changesObj).map((key, index) => {
        changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

        console.log('index', { index, length: Object.keys(changesObj).length });

        if ((index + 1) !== Object.keys(changesObj).length) {
          changedlog += ', ';
        }
        return null;
      });

      console.log('changedlog', { changedlog });

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'editedTournament',
        adminId: UID,
        adminEmail,
        adminName,
        tournamentId: tournamentToEdit,
        data: { ...dataToEdit, changedlog },
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      Promise.all(promises).then(() => resolve({ status: 'success' }));
    }).catch((err) => resolve({ status: 'error', message: err }));
  }));
}

export function deleteTournament(tournamentId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  // return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {

  return () => new Promise(resolve => FirebaseRef.child('events').orderByChild('descriptionTournamentId').equalTo(tournamentId).limitToLast(1).once('value', async (snapshot) => {
    const eventData = snapshot.val() || null;

    console.log('eventData', eventData);

    const eventKey = (eventData && Object.keys(eventData).length > 0) ? Object.keys(eventData)[0] : null;

    console.log('already has an event, update exisitng one', eventKey);

    if (eventKey) {
      const deleteEventFunction = Firebase.app().functions().httpsCallable('deleteEvent');

      console.log('call delete banner');

      await deleteEventFunction({
        eventId: eventKey,
      });
    }

    if (UID) {
      TournamentsRef().child(`newTournamentsList/${tournamentId}`).remove();

      TournamentsRef().child(`tournamentPlayerHistory/${tournamentId}`).remove();

      TournamentsRef().child(`newTournaments/${tournamentId}`).remove().then(() => {
        resolve({ status: 'success' });
      });

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deletedTournament',
        adminId: UID,
        adminEmail,
        adminName,
        tournamentId,
        data: null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });
    }
  }));
}

export function getRoomLog(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    const promise1 = AdminLogsRef().child(`adminLogs/rooms/${roomId}`).once('value');
    const promise2 = RoomsRef.child(`roomsPoints/${roomId}/points`).once('value');

    return Promise.all([promise1, promise2]).then((promisesRes) => {
      let data;
      let roomsPoints;

      promisesRes.map((resp, index) => {
        if (index === 0) {
          data = resp.val();
        } else if (index === 1) {
          roomsPoints = resp.val();
        }
        return null;
      });

      return resolve(dispatch({
        type: 'ADMIN_ROOM_LOGS', data, roomId, roomsPoints,
      }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function getUserGamesHistory(userId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const promises = [];
  const stats = {};

  return dispatch => new Promise((resolve) => {
    for (let i = 1; i < 30; i += 1) {
      const today = new Date();
      today.setDate(today.getDate() - i);

      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let date = today.getDate();

      if (month < 10) {
        month = `0${month}`;
      }
      if (date < 10) {
        date = `0${date}`;
      }

      const dateString = `${year}-${month}-${date}`;

      promises.push(UserStatsRef().child(`userRoomsResults/${dateString}/${userId.toString()}`)
        .once('value', (snapshot) => {
          const history = snapshot.val() || {};
          stats[dateString] = history;
        }));
    }

    const today2 = new Date();

    const year2 = today2.getFullYear();
    let month2 = today2.getMonth() + 1;
    let date2 = today2.getDate();

    if (month2 < 10) {
      month2 = `0${month2}`;
    }
    if (date2 < 10) {
      date2 = `0${date2}`;
    }

    const dateString2 = `${year2}-${month2}-${date2}`;

    Promise.all(promises).then(() => {
      const promises2 = [];
      promises2.push(new Promise((resolve2) => {
        // todayUserResults
        UserStatsRef().child(`todayUserResults/${userId.toString()}`)
          .once('value', (todayUserResultsSnapshot) => {
            const todayUserResults = todayUserResultsSnapshot.val() || {};

            console.log('total result todayUserResults', todayUserResults);

        UserStatsRef().child(`todayRoomResults/playerRooms/${userId.toString()}`)
          .once('value', (snapshot) => {
            const history = snapshot.val() || {};
            let todayStats = {};
            const uid = userId;

            Object.keys(history).map((key) => {
              const { totalResults, totalBalChange, totalPointsChange } = history[key];
              if (totalResults && totalResults[uid]) {
                todayStats = {
                  ...todayStats,
                  wins: (todayStats.wins || 0) + ((totalResults[uid] && totalResults[uid].wins) ? totalResults[uid].wins : 0),
                  largeWins: (todayStats.largeWins || 0) + ((totalResults[uid] && totalResults[uid].largeWins) ? totalResults[uid].largeWins : 0),
                  smallWins: (todayStats.smallWins || 0) + ((totalResults[uid] && totalResults[uid].smallWins) ? totalResults[uid].smallWins : 0),
                  zoleWins: (todayStats.zoleWins || 0) + ((totalResults[uid] && totalResults[uid].zoleWins) ? totalResults[uid].zoleWins : 0),
                  loses: (todayStats.loses || 0) + ((totalResults[uid] && totalResults[uid].loses) ? totalResults[uid].loses : 0),
                  largeLoses: (todayStats.largeLoses || 0) + ((totalResults[uid] && totalResults[uid].largeLoses) ? totalResults[uid].largeLoses : 0),
                  smallLoses: (todayStats.smallLoses || 0) + ((totalResults[uid] && totalResults[uid].smallLoses) ? totalResults[uid].smallLoses : 0),
                  zoleLoses: (todayStats.zoleLoses || 0) + ((totalResults[uid] && totalResults[uid].zoleLoses) ? totalResults[uid].zoleLoses : 0),
                  galdinsLoses: (todayStats.galdinsLoses || 0) + ((totalResults[uid] && totalResults[uid].galdinsLoses) ? totalResults[uid].galdinsLoses : 0),
                  galdinsWins: (todayStats.galdinsWins || 0) + ((totalResults[uid] && totalResults[uid].galdinsWins) ? totalResults[uid].galdinsWins : 0),


                  roomsPlayed: (todayStats.roomsPlayed || 0) + 1,
                  roundsPlayed: (todayStats.roundsPlayed || 0) + ((totalResults[uid] && totalResults[uid].roundsPlayed) ? totalResults[uid].roundsPlayed : 0),

                  balChange: (todayStats.balChange || 0) + (totalBalChange[uid] || 0),
                  pointsChange: (todayStats.pointsChange || 0) + (totalPointsChange[uid] || 0),
                };
              } else {
                todayStats = {
                  ...todayStats,
                  balChange: ((todayStats && todayStats.balChange) ? todayStats.balChange : 0) + (totalBalChange[uid] || 0),
                  pointsChange: ((todayStats && todayStats.pointsChange) ? todayStats.pointsChange : 0) + (totalPointsChange[uid] || 0),
                };
              }

              return null;
            });

            if (todayUserResults.bonusSpin) {
              todayStats.bonusSpin = todayUserResults.bonusSpin;
            }

            console.log('dateString2', dateString2, todayStats);

            stats[dateString2] = todayStats;

            return resolve2();
          });
        });
      }));
      console.log('total result', stats);

      Promise.all(promises2).then(() => {
        resolve(dispatch({
          type: 'USER_GAMES_HISTORY',
          data: stats,
        }));
      });
    });
  });
}

export function getUserBalanceHistory(userId, startAt, endAt) {
  console.log('getUserBalanceHistory', { userId, startAt, endAt });
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  console.log('test 1');

  if (!UID) return () => new Promise(resolve => resolve());

  console.log('test 2');

  return dispatch => new Promise(resolve => UserStatsRef().child(`userBalHistory/${userId.toString()}`)
    .orderByChild('time')
    .startAt(startAt)
    .endAt(endAt)
    .once('value', (snapshot) => {
      const history = snapshot.val() || {};
      console.log('history', { history });

      resolve(dispatch({
        type: 'USER_BAL_HISTORY',
        data: history,
      }));
    })
    .catch((err) => { console.log(err); }));
}

export function getUserPointsHistory(userId, startAt, endAt) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => UserStatsRef().child(`userPointsHistory/${userId.toString()}`)
    .orderByChild('time')
    .startAt(startAt)
    .endAt(endAt)
    .once('value', (snapshot) => {
      const history = snapshot.val() || {};

      resolve(dispatch({
        type: 'USER_POINTS_HISTORY',
        data: history,
      }));
    }, ((err) => {
      console.log(err);
      return resolve(null);
    })));
}

export function setNextDealCards(roomId, cards) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  console.log('setNextDealCards', cards);

  return () => new Promise((resolve) => {
    const setNextDealCardsFunction = Firebase.app().functions().httpsCallable('setNextDealCards');

    setNextDealCardsFunction({
      roomId,
      cards,
      playerCards: {
        ...cards,
      },
    }).then((result) => {
      resolve(result);
    });
  });
}


export function setUserBal(roomId, userId, userPos, newBal) {
  if (Firebase === null || !roomId || !userId || !userPos || !newBal) return () => new Promise(resolve => resolve());

  if (Number.isNaN(newBal)) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${userId}`).update({
      bal: parseInt(newBal, 10),
    });

    resolve('success');
  });
}


export function setUserTournamentBal(roomId, tournamentId, userId, userPos, newBal) {
  if (Firebase === null || !roomId || !userId || !userPos || !newBal) return () => new Promise(resolve => resolve());

  if (Number.isNaN(newBal)) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    RoomsRef.child(`rooms/${roomId}/players/${userPos}`).update({
      bal: parseInt(newBal, 10),
    });

    RoomsRef.child(`rooms/${roomId}/playersList/${userPos}`).update({
      bal: parseInt(newBal, 10),
    });

    FirebaseRef.child(`tourPlayerData/${userId}/${tournamentId}`).update({
      bal: parseInt(newBal, 10),
    });

    FirebaseRef.child(`tourPlayers/${tournamentId}/${userId}`).update({
      bal: parseInt(newBal, 10),
    });

    resolve('success');
  });
}

export function disableTimer(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => RoomsRef.child(`rooms/${roomId}/globalParams/disableTimer`).once('value', (disabledSnapshot) => {
    const disabled = disabledSnapshot.val() || false;
    RoomsRef.child(`rooms/${roomId}/globalParams`).update({
      disableTimer: !disabled,
    });

    resolve('success');
  }));
}

export function toggleAutomation(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => RoomsRef.child(`rooms/${roomId}/globalParams/automated`).once('value', (disabledSnapshot) => {
    const disabled = disabledSnapshot.val() || false;
    RoomsRef.child(`rooms/${roomId}/globalParams`).update({
      automated: !disabled,
    });

    resolve('success');
  }));
}

export function getSmartLookEnabled() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  FirebaseRef.child('smartLookEnabled').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('smartLookEnabled')
    .on('value', (snapshot) => {
      const smartLookEnabled = snapshot.val() || false;

      resolve(dispatch({
        type: 'SMARTLOOK_STATUS',
        data: smartLookEnabled,
      }));
    }));
}

export function changeSmartLook(value) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return () => new Promise((resolve) => {
    FirebaseRef.child('smartLookEnabled').set(value);

    return resolve();
  });
}


export function getAdmins() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('users').off();

    const promise1 = FirebaseRef.child('users').orderByChild('role').equalTo('admin').once('value');
    const promise2 = FirebaseRef.child('users').orderByChild('role').equalTo('tester').once('value');

    Promise.all([promise1, promise2]).then((promisesRes) => {
      let admins;
      let testers;

      promisesRes.map((resp, index) => {
        if (index === 0) {
          admins = resp.val();
        } else if (index === 1) {
          testers = resp.val();
        }
        return null;
      });

      const users = { ...admins, ...testers };

      return resolve(dispatch({ type: 'ADMIN_LIST_REPLACE', data: users }));
    });
  }).catch((err) => { console.log(err.message); });
}


export function getBotUsers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());


  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('users').off();

    console.log('users fetch getBotUsers');

    const promise1 = FirebaseRef.child('users').orderByChild('botUser').equalTo(true).once('value');

    Promise.all([promise1]).then((promisesRes) => {
      let bots;

      promisesRes.map((resp, index) => {
        if (index === 0) {
          bots = resp.val();
        }
        return null;
      });

      return resolve(dispatch({ type: 'BOTS_LIST_REPLACE', data: bots }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function getAdminActionLog(start, end) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    AdminLogsRef().child('adminLogs/adminActions')
      .orderByChild('index')
      .startAt(start)
      .endAt(end)
      .once('value', async (snapshot) => {
        const adminActionLogs = snapshot.val() || {};
        console.log('Admin Actioin Logs', adminActionLogs);


        //>>>>>>>>>>>>>>>>>>
        const updatedLogs = {};

        const fetchAdminName = async (adminId) => {
          const nameSnapshot = await FirebaseRef.child(`users/${adminId}/name`).once('value');
          return nameSnapshot.val();
        };

        for (const [id, log] of Object.entries(adminActionLogs)) {
          try {
            const adminName = await fetchAdminName(log.adminId);
            updatedLogs[id] = {
              ...log,
              adminName
            };
          } catch (error) {
            updatedLogs[id] = {
              ...log,
              adminName: log.adminName
            };
            console.error(`Failed to fetch name for adminId ${log.adminId}: `, error);
          }
        }
        //<<<<<<<<<<<<<<<<<<<<<<

        return resolve(dispatch({
          type: 'ADMIN_ACTION_LOGS',
          data: Object.keys(updatedLogs).sort().reverse().reduce((obj, key2) => {
            obj[key2] = updatedLogs[key2];
            return obj;
          }, {}),
        }));
      }, (err) => { console.log(err); });
  })
    .catch((err) => { console.log(err); });
}

export function getAdminActionLogCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('statistics/adminActionsCount')
    .once('value', (snapshot) => {
      const logsCount = snapshot.val() || {};

      AdminLogsRef().child('adminLogs/adminActions').off();

      AdminLogsRef().child('adminLogs/adminActions')
        .orderByChild('index')
        .startAt(logsCount)
        .on('child_added', (snapshot2) => {
          const adminActionLogs = snapshot2.val() || {};
          const { key } = snapshot2;
          FirebaseRef.child('statistics/adminActionsCount')
            .once('value', (snapshot3) => {
              const logsCount2 = snapshot3.val() || {};
              dispatch({ type: 'ADMIN_ACTION_LOGS_COUNT', data: logsCount2 });
            });
          dispatch({
            type: 'ADMIN_ACTION_LOG',
            data: { log: adminActionLogs, key },
          });
        });
      console.log('logsCount!', logsCount);
      return resolve(dispatch({ type: 'ADMIN_ACTION_LOGS_COUNT', data: logsCount }));
    })).catch((err) => { console.log(err.message); });
}

export function updateRole(editUid, selectedRole) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!editUid || !selectedRole || selectedRole !== 'admin' || selectedRole !== 'tester' || selectedRole !== 'player') {
    return () => new Promise((resolve) => {
      FirebaseRef.child(`users/${editUid}/role`).set(selectedRole);

      AdminLogsRef().child(`admins/${editUid}/role`).set(selectedRole);
      LeaderboardRef.child(`admins/${editUid}/role`).set(selectedRole);
      RoomsRef.child(`admins/${editUid}/role`).set(selectedRole);
      UserStatsRef().child(`admins/${editUid}/role`).set(selectedRole);
      TournamentsRef().child(`admins/${editUid}/role`).set(selectedRole);
      StatusRef().child(`admins/${editUid}/role`).set(selectedRole);

      return resolve();
    });
  }

  return () => new Promise(resolve => resolve());
}


export function getStatistic(id, time, roundStart, roundTo) {

  if (Firebase === null) return () => new Promise(resolve => resolve());
  if (roundStart === '') { roundStart = constants.STATISTICS_RANGE.start; }
  if (roundTo === '') { roundTo = constants.STATISTICS_RANGE.end; }
  let idStrings = '';

  idStrings = statisticsConst.statisticsTypes.filter(type => type.id === id)[0].link;

  console.log('getStatistic', id, time, roundStart, roundTo, idStrings);

  // let endString;
  // let startString;
  // let endDate;
  // let startDate;
  // let startBalance;
  // let endBalance;

  const endString = new Date(Date.now()).toLocaleString('en-US', { timeZone: 'Europe/Riga' });
  const startString = new Date(constants.CALC_ACTIVATE_DATE_BY_TIME(time)).toLocaleString('en-US', { timeZone: 'Europe/Riga' });

  const yyyy = new Date(endString).getFullYear().toString();
  let MM = (new Date(endString).getMonth() + 1).toString();
  let dd = new Date(endString).getDate().toString();
  let hh = new Date(endString).getHours().toString();

  if (MM < 10) { MM = `0${MM}`; }
  if (dd < 10) { dd = `0${dd}`; }
  if (hh < 10) { hh = `0${hh}`; }

  const endDate = `${yyyy}-${MM}-${dd}-${hh}`;

  const yyyy2 = new Date(startString).getFullYear().toString();
  let MM2 = (new Date(startString).getMonth() + 1).toString();
  let dd2 = new Date(startString).getDate().toString();
  let hh2 = new Date(startString).getHours().toString();

  if (MM2 < 10) { MM2 = `0${MM2}`; }
  if (dd2 < 10) { dd2 = `0${dd2}`; }
  if (hh2 < 10) { hh2 = `0${hh2}`; }

  const startDate = `${yyyy2}-${MM2}-${dd2}-${hh2}`;

  return dispatch => new Promise((resolve) => {
    const promises = [];
    if (id === statisticsConst.LASTONLINEPLAYERS) {
      idStrings.map((idString) => {
        let endAt;
        let startAt;
        if (time === '1') {
          endAt = Date.now();
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(7);
        } else if (time === '2') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(7);
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(30);
        } else if (time === '3') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(30);
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(90);
        } else if (time === '4') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(90);
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(180);
        } else if (time === '5') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(180);
          startAt = constants.CALC_ACTIVATE_DATE_BY_TIME(360);
        } else if (time === '6') {
          endAt = constants.CALC_ACTIVATE_DATE_BY_TIME(360);
          startAt = '';
        }

        let firebaseProm;

        console.log('idString', idString);

        firebaseProm = UserStatsRef().child(`${idString}`)
          .orderByValue();

        if (time !== '6') {
          firebaseProm = firebaseProm.startAt(startAt).endAt(endAt);
        } else {
          firebaseProm = firebaseProm.endAt(endAt);
        }

        firebaseProm = firebaseProm.once('value', (snapshot) => {
          const statistics = snapshot.val() || {};
          const split = idString.split('/');
          dispatch({ type: 'ADMIN_STATISTICS_REPLACE', data: statistics, key: split[1] });
        });

        promises.push(firebaseProm);
        return null;
      });
    } else {
      idStrings.map((idString) => {
        let firebaseProm;
        if (time.toString() === statisticsConst.STATISTICS_PERIOD_TIME.month.toString()) {
          if (id === statisticsConst.USERSCOINANALYSIS) {
            if (idString === 'players/activePlayersDay') {
              firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey();
            } else {
              firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByChild('date');
            }
          } else {
            firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey();
          }
        } else {
          if (id === statisticsConst.USERSCOINANALYSIS) {
            if (idString === filter(statisticsConst.statisticsTypes, ['id', statisticsConst.ACTIVEPLAYERDAY])[0].link) {
              firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey()
            } else {
              if (idString === 'players/activePlayersDay') {
                firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey();
                firebaseProm = firebaseProm.startAt(startDate).endAt(endDate);
              } else {
                firebaseProm = AdminLogsRef().child(`statistics/${idString}`)
                  .orderByChild('date')
                  .startAt(constants.CALC_ACTIVATE_DATE_BY_TIME(time))
                  .endAt(Date.now());
              }
            }
          } else {
            firebaseProm = AdminLogsRef().child(`statistics/${idString}`).orderByKey();

            if (idString.slice(-7) !== statisticsConst.STATISTICS_FILTER_TYPE.history) {
              firebaseProm = firebaseProm.startAt(startDate).endAt(endDate);
            }
          }
        }

        console.log('USERSCOINANALYSIS test4');

        firebaseProm = firebaseProm.once('value', (snapshot) => {
          const statistics = snapshot.val() || {};
          const split = idString.split('/');

          console.log('firebaseProm statistics');


          if (idString.slice(-12) === statisticsConst.STATISTICS_FILTER_TYPE.roundsPlayed) {
            Object.keys(statistics).map((key) => {
              let filtered = statistics[key];
              filtered = Object.keys(filtered).filter(key1 => (parseInt(key1, 10) >= parseInt(roundStart, 10) && parseInt(key1, 10) <= parseInt(roundTo, 10)))
                .reduce((obj, key2) => {
                  obj[key2] = filtered[key2];
                  return obj;
                }, {});
              statistics[key] = filtered;
              return null;
            });
          }
          console.log(time, "statistics test", statistics, idString, split);

          dispatch({ type: 'ADMIN_STATISTICS_REPLACE', data: statistics, key: split[split.length - 1] });
        });

        promises.push(firebaseProm);
        return null;
      });
    }

    Promise.all(promises).then(() => resolve());
  }).catch((err) => { console.log(err.message); });
}

export function getAllGifts() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('gifts2')
    .once('value', (snapshot) => {
      const gifts = snapshot.val() || {};
      resolve(dispatch({
        type: 'GET_ALL_GIFTS',
        data: gifts,
      }));
    }));
}

export function setMobileVersion(version, dev) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!version) {
    return () => new Promise(resolve => resolve());
  }

  if (dev) {
    return () => new Promise((resolve) => {
      FirebaseRef.child('settings/mobileVersionDev').set(version);

      return resolve();
    });
  }
  return () => new Promise((resolve) => {
    FirebaseRef.child('settings/mobileVersion').set(version);

    return resolve();
  });
}

export function getMobileVersion(dev) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (dev) {
    return () => new Promise(resolve => FirebaseRef.child('settings/mobileVersionDev').once('value', (snapshot) => {
      const mobileVersion = snapshot.val() || null;

      return resolve({ data: mobileVersion });
    }));
  }
  return () => new Promise(resolve => FirebaseRef.child('settings/mobileVersion').once('value', (snapshot) => {
    const mobileVersion = snapshot.val() || null;

    return resolve({ data: mobileVersion });
  }));
}


export function setTreasureChestEnabled(value) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    if (value) {
      FirebaseRef.child('settings/treasureChestEnabled').set(true);
    } else {
      FirebaseRef.child('settings/treasureChestEnabled').remove();
    }

    return resolve();
  });
}

export function getTreasureChestEnabled() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise(resolve => FirebaseRef.child('settings/treasureChestEnabled').once('value', (snapshot) => {
    const treasureChestEnabled = snapshot.val() || null;

    return resolve({ data: treasureChestEnabled });
  }));
}


export function getUserAchievements(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    let userAchievements;
    let claimedAchievements;
    let claimedAchievementsHistory;

    let gamesPlayed;
    let gamesWon;

    const promises = [];

    promises.push(new Promise(resolve2 => FirebaseRef.child(`users/${uid}/gPlayed`)
      .once('value', (snapshot) => {
        gamesPlayed = snapshot.val() || 0;

        return resolve2();
      }, (err) => {
        console.log(err);
        gamesPlayed = 0;
        return resolve2();
      })));

    promises.push(new Promise(resolve2 => FirebaseRef.child(`users/${uid}/gWon`)
      .once('value', (snapshot) => {
        gamesWon = snapshot.val() || 0;

        return resolve2();
      }, (err) => {
        console.log(err);
        gamesWon = 0;
        return resolve2();
      })));

    promises.push(new Promise(resolve2 => FirebaseRef.child(`userAchievements/${uid}`)
      .once('value', (snapshot) => {
        userAchievements = snapshot.val() || {};

        return resolve2();
      }, (err) => {
        console.log(err);
        userAchievements = {};
        return resolve2();
      })));

    promises.push(new Promise(resolve2 => FirebaseRef.child(`claimedAchievements/${uid}`)
      .once('value', (snapshot) => {
        claimedAchievements = snapshot.val() || {};

        return resolve2();
      }, (err) => {
        console.log(err);
        claimedAchievements = {};
        return resolve2();
      })));

    promises.push(new Promise(resolve2 => FirebaseRef.child(`claimedAchievementsHistory/${uid}`)
      .once('value', (snapshot) => {
        claimedAchievementsHistory = snapshot.val() || {};

        return resolve2();
      }, (err) => {
        console.log(err);
        claimedAchievementsHistory = {};
        return resolve2();
      })));

    return Promise.all(promises).then(() => resolve(dispatch({
      type: 'USER_ACHIEVEMENTS',
      data: {
        userAchievements,
        claimedAchievements,
        claimedAchievementsHistory,
        gamesPlayed,
        gamesWon,
      },
    })));
  });
}

export function getUserSettings(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    let usersSettings;

    const promises = [];

    promises.push(new Promise((resolve2) => FirebaseRef.child(`usersSettings/${uid}`)
      .once('value', (snapshot) => {
        usersSettings = snapshot.val() || null;

        return resolve2();
      }, (err) => {
        usersSettings = null;
        return resolve2();
      })));

    return Promise.all(promises).then(() => resolve(usersSettings));
  });
}

export function getAutoPlayOtherUser(uid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve) => {
    let disabledAutoPlay;

    const promises = [];

    promises.push(new Promise((resolve2) => FirebaseRef.child(`users/${uid}/disableAutoPlay`)
      .once('value', (snapshot) => {
        disabledAutoPlay = snapshot.val() || false;

        return resolve2();
      }, (err) => {
        disabledAutoPlay = false;
        return resolve2();
      })));

    return Promise.all(promises).then(() => resolve(disabledAutoPlay));
  });
}

export function updateProfile(formData) {
  const {
    // userId,
    firstName,
    lastName,
    photo,
  } = formData;

  return () => new Promise(async (resolve, reject) => {
    // Are they a user?
    const UID = Firebase.auth().userId;

    if (!UID) {
      return reject({
        // message: errorMessages.missingFirstName
      });
    }

    // Validation rules
    if (!firstName) {
      return reject({
        // message: errorMessages.missingFirstName
      });
    }
    if (!lastName) {
      return reject({
        // message: errorMessages.missingLastName
      });
    }

    const updateProfileFunction = Firebase.app()
      .functions()
      .httpsCallable('updateProfile');

    return updateProfileFunction({
      firstName,
      lastName,
      photo,
    })
      .then(() => {
        // getUserData(dispatch);
        // console.log('###############', res);
        // if (res.data.message == 'maxNameChangeLimit' && res.data.status == 'error') {
        //   return resolve({ status: 'error', message: 'maxNameChangeLimit' });
        // }
        // return resolve({ status: 'success' });
      })
      .catch(() => reject({
        // message: errorMessages.errorUpdating
      }));
  }).catch((err) => {
    console.log(err);
    throw err;
  });
}

export function fetchFeedbackHistory(selectedId, design, platform) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  // FirebaseRef.child(`feedbackHistory/${selectedId}`).off();

  return dispatch => new Promise(resolve => FirebaseRef.child(`feedbackHistory/${selectedId}`)
    .once('value', (snapshot) => {
      const allFeedbackHistory = snapshot.val() || {};
      const array = [];
      let totalBal = 0;
      forEach(allFeedbackHistory, (item, key) => {
        if (item.design === design.value && item.platform === platform.value && parseFloat(item.rating) > 0) {
          array.push({ key, selectedId, ...item });
          totalBal += parseFloat(item.rating) > 0 ? parseFloat(item.rating) : 0;
        }
      });
      array.sort((a, b) => (b.lastFeedback - a.lastFeedback));
      return resolve(dispatch({ type: 'ADMIN_FEEDBACKS_HISTORY_REPLACE', data: array, totalRating: totalBal }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllFeedbacks() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  FirebaseRef.child('feedbackHistory').off();

  return dispatch => new Promise(resolve => FirebaseRef.child(`feedbackHistory`)
    .on('value', (snapshot) => {
      const allFeedbackHistory = snapshot.val() || {};
      const array = [];
      let totalBal = 0;
      forEach(allFeedbackHistory, (items, key) => {
        forEach(items, (item, idx) => {
          if (item.rating && parseFloat(item.rating) > 0) {
            array.push({ key, feedbackId: idx, ...item });
            totalBal += parseFloat(item.rating) > 0 ? parseFloat(item.rating) : 0;
          }
        });
      });
      array.sort((a, b) => (b.lastFeedback - a.lastFeedback));
      return resolve(dispatch({ type: 'ADMIN_FEEDBACKS_REPLACE', data: array, totalRating: totalBal }));
    })).catch((err) => { console.log(err.message); });
}

export function getFeedbacks() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => {
    dispatch({ type: 'ADMIN_MULTIPLE_FEEDBACKS_REPLACE', data: {}, feedbackLoading: true });

    FirebaseRef.child('feedback').off();

    FirebaseRef.child('feedback')
    .on('value', (snapshot) => {
      const allFeedbacks = snapshot.val() || {};
      console.log(allFeedbacks, "check===========")
      return resolve(dispatch({ type: 'ADMIN_MULTIPLE_FEEDBACKS_REPLACE', data: allFeedbacks, feedbackLoading: false }));
    })
  }).catch((err) => { console.log(err.message); });
}

export function getFeedbacksResults() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => {
    dispatch({ type: 'ADMIN_MULTIPLE_FEEDBACKS_RESULTS_REPLACE', data: {}, feedbackResultsLoading: true });

    FirebaseRef.child('feedbackResults').off();

    FirebaseRef.child('feedbackResults')
    .on('value', (snapshot) => {
      const allFeedbackResults = snapshot.val() || {};
      return resolve(dispatch({ type: 'ADMIN_MULTIPLE_FEEDBACKS_RESULTS_REPLACE', data: allFeedbackResults, feedbackResultsLoading: false }));
    })
  }).catch((err) => { console.log(err.message); });
}


export function getEditorData() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => FirebaseRef.child('versionHistory')
    .orderByChild('versionDate')
    .once('value', (snapshot) => {
      const data = snapshot.val() || null;

      const array = [];

      Object.keys(data).map((key) => {
        array.push({ key, ...data[key] });
        return null;
      });

      array.sort((a, b) => (b.versionDate - a.versionDate));

      return resolve(dispatch({ type: 'ADMIN_VERSION_REPLACE', data: array }));
    }).catch((err) => {
      console.log(err);
      return resolve(null);
    }));
}

export function updateEditorData(selectId, data, versionDate) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  if (!data || !versionDate) return () => new Promise(resolve => resolve());
  const versionFormatDate = Date.parse(versionDate);
  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`versionHistory/${selectId}`).update({
      versionDate: versionFormatDate,
      [constants.sendLanguage.lv]: data.lv,
      [constants.sendLanguage.en]: data.en,
      [constants.sendLanguage.ru]: data.ru,
    })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function addEditerData(data, versionDate) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  if (!data || !versionDate) return () => new Promise(resolve => resolve());
  const versionFormatDate = Date.parse(versionDate);
  return () => new Promise((resolve, reject) => {
    console.log('update path', { data, versionDate });
    //  return resolve();
    // const versionFormatDate = dateFormatMoment(versionDate, constants.FORMAT_DATE_TYPE.pointsfullDate);
    FirebaseRef.child('versionHistory').push({
      versionDate: versionFormatDate,
      [constants.sendLanguage.lv]: data.lv,
      [constants.sendLanguage.en]: data.en,
      [constants.sendLanguage.ru]: data.ru,
    })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function deleteEditor(selectId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`versionHistory/${selectId}`)
      .remove()
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function blogImageFileUpload(fileUpload) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (fileUpload !== '') {
    return () => new Promise((resolve) => {
      const uploadTask = FirebaseStorage.ref(`/blogImages/${(Date.now().toString())}`)
        .put(fileUpload);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            resolve({ status: 'success', url: downloadURL });
          });
        });
    });
  }
  return () => new Promise(resolve => resolve());
}

export function addBlogEditerData(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return () => new Promise(resolve => resolve());

  const {
    blogDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl,
  } = data;

  const blogFormatDate = Date.parse(blogDate);

  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('blogs').push({
      blogDate: blogFormatDate,
      titleLV: lvTitle,
      titleEN: enTitle,
      titleRU: ruTitle,
      descriptionLV: lvDescription,
      descriptionEN: enDescription,
      descriptionRU: ruDescription,
      imageUrl,
      urlString: toUrlString(enTitle),
    })
      .then(() => {
        return AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'addBlog',
          adminId: UID,
          adminEmail,
          adminName,
          date: Firebase.database.ServerValue.TIMESTAMP,
          data: { title: enTitle },
        });
      })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => {
        console.log(err);
        return reject({ status: constants.SERVER_STATUS_CODE.error });
      });
  });
}

export function getBlogEditorData() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  return dispatch => new Promise(resolve => FirebaseRef.child('blogs')
    .orderByKey()
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};
      const array = [];

      Object.keys(data).map((key) => {
        array.push({ key, ...data[key] });
        return null;
      });

      array.sort((a, b) => (b.blogDate - a.blogDate));

      return resolve(dispatch({ type: 'ADMIN_BLOGS_REPLACE', data: array }));
    }).catch((err) => {
      console.log(err);
      return resolve(null);
    }));
}

export function updateBlogEditorData(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return () => new Promise(resolve => resolve());
  const {
    selectId, blogDate, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, imageUrl,
  } = data;

  if (!selectId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!imageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }

  const blogFormatDate = Date.parse(blogDate);
  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`blogs/${selectId}`).update({
      blogDate: blogFormatDate,
      titleLV: lvTitle,
      titleEN: enTitle,
      titleRU: ruTitle,
      descriptionLV: lvDescription,
      descriptionEN: enDescription,
      descriptionRU: ruDescription,
      imageUrl,
      urlString: toUrlString(enTitle),
    })
      .then(() => {
        return AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'updateBlog',
          adminId: UID,
          adminEmail,
          adminName,
          date: Firebase.database.ServerValue.TIMESTAMP,
          data: { id: selectId },
        });
      })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => {
        console.log(err);
        reject({ status: constants.SERVER_STATUS_CODE.error });
      });
  });
}

export function deleteBlogEditor(selectId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`blogs/${selectId}`)
      .remove()
      .then(() => {
        return AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'deleteBlog',
          adminId: UID,
          adminEmail,
          adminName,
          date: Firebase.database.ServerValue.TIMESTAMP,
          data: { id: selectId },
        });
      })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => {
        console.log(err);
        return reject({ status: constants.SERVER_STATUS_CODE.error });
      });
  });
}

export function deleteUserFeedback(userId, selectId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child(`feedback/${userId}/${selectId}`)
      .remove()
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success }))
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function checkFeedbackTime() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('settings/feedback')
    .once('value', (snapshot) => {
      const feedbackSetting = snapshot.val() || {};

      console.log({ feedbackSetting });
      let feedbackStartTime = false;
      const nowDate = Date.parse(new Date());
      if (feedbackSetting && feedbackSetting.feedbackStart) {
        if (feedbackSetting.feedbackStartTime) {
          feedbackStartTime = nowDate > constants.CALC_ACTIVE_FEEDBACK_TIME(feedbackSetting.feedbackStartTime, constants.FEEDBACK_SHOW_TIME_RANGE.end);
        } else {
          feedbackStartTime = true;
        }
      } else {
        feedbackStartTime = true;
      }

      const previousFeedbackTime = feedbackSetting.feedbackStartTime || 0;
      return resolve(dispatch({ type: 'START_FEEDBACK_TIME', data: feedbackStartTime, previousFeedbackTime }));
    }).catch((err) => {
      console.log(err);
      return resolve(null);
    }));
}

/*
function generateRandom(min = 0, max = 100) {
  const difference = max - min;
  let rand = Math.random();
  rand = Math.floor(rand * difference);
  rand += min;
  return rand;
} */

export function startFeedback() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('settings/feedback').set({
      feedbackStartTime: Date.parse(new Date()),
      feedbackStart: true,
      devModal: true,
    })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success })).catch((err) => {
        console.log(err);
        return reject({ status: constants.SERVER_STATUS_CODE.error });
      });
  });
}

export function stopFeedback() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());
  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('settings/feedback').set({
      feedbackStart: false,
    })
      .then(() => resolve({ status: constants.SERVER_STATUS_CODE.success })).catch((err) => {
        console.log(err);
        return reject({ status: constants.SERVER_STATUS_CODE.error });
      });
  });
}

export function getSuspicisousTransactionsPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const endString = Date.now();
  const startString = constants.CALC_ACTIVATE_DATE_BY_TIME(constants.WEEKLY_VALUE.twoWeek);
  return dispatch => new Promise(resolve => FirebaseRef.child('completedPayments')
    .orderByChild('dateInitiated')
    .startAt(startString)
    .endAt(endString)
    .once('value', async (snapshot) => {
      const suspiciousTransactions = snapshot.val() || {};

      const dateTransactions = {};
      const dateSuspiciousPlayers = {};

      map(suspiciousTransactions, async (trans, key) => {
        const transDate = moment(new Date(trans.dateInitiated).toLocaleString(statisticsConst.STATISTICS_ADMIN_TIMEZONE.timelocal, { timeZone: statisticsConst.STATISTICS_ADMIN_TIMEZONE.timezone })).format(constants.FORMAT_DATE_TYPE.fullDayMonth);
        dateTransactions[key] = {
          ...trans,
          formatDate: transDate,
        };
      });

      if (dateTransactions) {
        await Promise.all(map(dateTransactions, async (item, key) => {
          const userFirebaseProm = await FirebaseRef.child(`users/${item.userUid}`).once('value');
          const user = userFirebaseProm.val();
          if (user) {
            if (!find(dateSuspiciousPlayers, findItem => findItem.formatDate === item.formatDate && findItem.userUid === item.userUid)) {
              const filterData = filter(dateTransactions, filterItem => filterItem.formatDate === item.formatDate && filterItem.userUid === item.userUid);
              if (size(filterData) >= 5) {
                const userName = user.name || '--';
                dateSuspiciousPlayers[key] = {
                  formatDate: item.formatDate,
                  count: size(filterData),
                  userUid: item.userUid,
                  name: userName,
                  dateInitiated: item.dateInitiated,
                };
              }
            }
          }
        }));
      }
      return resolve(dispatch({ type: 'ADMIN_SUSPICISOUS_TRANSACTIONS_REPLACE', data: dateSuspiciousPlayers, allSuspiciousTransactions: dateTransactions }));
    })).catch((err) => { console.log(err.message); });
}

export function getSuspiciousFoldPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const endString = Date.now();
  const startString = constants.CALC_ACTIVATE_DATE_BY_TIME(constants.WEEKLY_VALUE.twoWeek);

  console.log('getSuspiciousFoldPlayers', endString, startString)

  return dispatch => new Promise(resolve => AdminLogsRef().child('roomsTogether/quitRounds')
    .orderByChild('dateAdded')
    .startAt(startString)
    .endAt(endString)
    .once('value', async (snapshot) => {
      const data = snapshot.val() || {};
      const array = [];

      console.log('getSuspiciousFoldPlayers data', data);

      await Promise.all(Object.keys(data).map(async (key) => {
        const globalRoomData = await AdminLogsRef().child(`roomsTogether/rooms/${data[key].roomId}`).once('value');
        const userFirebaseProm = await FirebaseRef.child(`users/${data[key].uid}/name`).once('value');
        const userName = userFirebaseProm.val();
        const roomData = globalRoomData.val();

        console.log("roomData", roomData);

        const checkUser = userName ? userName : '--';
        let checkRoom = false;
        if (roomData && roomData.date) {
          checkRoom = true;
        }

        console.log('checkUser', checkUser, key);

        if (data[key].count >= 5) {
          array.push({ key, ...data[key], supiciousFold: true, userName: checkUser });
        }
      }));

      console.log('getSuspiciousFoldPlayers array', array);

      await array.sort((a, b) => (b.dateAdded - a.dateAdded));

      return resolve(dispatch({ type: 'SUSPICIOUS_FOLD_PLAYERS', data: filter(array, item => item.supiciousFold) }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function getGlobalRoomData(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    AdminLogsRef().child(`roomsTogether/rooms/${roomId}`).once('value', (snapShot) => {
      const data = snapShot.val() || {};
      if (data) {
        AdminLogsRef().child(`roomsTogether/playerRooms/${data.player1}/${data.player2}`)
          .orderByChild('roomId')
          .equalTo(roomId)
          .once('value', (snapshot) => {
            const rooms = snapshot.val() || {};
            return resolve(dispatch({ type: 'ADMIN_ROOM_GLOBAL_PARAMS', data: rooms }));
          });
      }
    });
  });
}

export function editUserDob(editUserId, dob) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (dob) {
    if (Date.parse(dob) < Date.parse(constants.LIMIT_DATE_RANGE.minDate) || Date.parse(dob) > Date.parse(constants.LIMIT_DATE_RANGE.maxDate)) {
      return () => new Promise(resolve => resolve({ status: constants.SERVER_STATUS_CODE.error, message: errorMessages.wrongDate }));
    }
  }

  const birthday = dob ? Date.parse(dob) : null;
  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${editUserId.toString()}`).update({
      birthday,
    }).then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'editDob',
        adminId: UID,
        adminEmail,
        adminName,
        userId: editUserId.toString(),
        birthday,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch((err) => {
      console.log(err);
      return resolve({ status: constants.SERVER_STATUS_ERROR.error });
    });
  });
}

export function editUserNote(editUserId, userNote, userName) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve) => {
    FirebaseRef.child(`users/${editUserId.toString()}`).update({
      userNote,
    }).then((res) => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'addNote',
        adminId: UID,
        adminEmail,
        adminName,
        userId: editUserId.toString(),
        userName,
        data: {
          userNote,
        },
        date: Firebase.database.ServerValue.TIMESTAMP,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch((err) => {
      console.log(err);
      return resolve({ status: constants.SERVER_STATUS_ERROR.error });
    });
  });
}

export function getUserLastIp(userUid) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  return dispatch => new Promise(resolve => FirebaseRef.child(`usersLastIp/${userUid}`)
    .once('value', (snapshot) => {
      const lastIpData = snapshot.val() ? { userUid: userUid, IP: snapshot.val() } : {};

      return resolve(dispatch({ type: 'ADMIN_LAST_IP_REPLACE', data: lastIpData }));
    })).catch((err) => { console.log(err.message); });
}

export function getNotifications() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;

  FirebaseRef.child('notifications').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('notifications')
    .on('value', (snapshot) => {
      const allNotifications = snapshot.val() || {};

      console.log(allNotifications, 'all notifications');

      return resolve(dispatch({ type: 'ADMIN_NOTIFICATIONS_REPLACE', data: allNotifications }));
    })).catch((err) => { console.log(err.message); });
}

export function addNotification(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const {
    lvTitle, enTitle, ruTitle, lvText, enText, ruText, notifyIdentifier, notificationDate, notificationType, showNotification,
  } = data;
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('notifications').push({
      lvTitle,
      enTitle,
      ruTitle,
      lvText,
      enText,
      ruText,
      notifyIdentifier,
      notificationDate: Date.parse(notificationDate),
      notificationType,
      showNotification,
    })
      .then(() => {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'addNotification',
          adminId: UID,
          adminEmail,
          adminName,
          data: { ...data },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      })
      .catch(() => reject({ status: constants.SERVER_STATUS_CODE.error }));
  });
}

export function editNotification(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const {
    selectNotificationId, lvTitle, enTitle, ruTitle, lvText, enText, ruText, notifyIdentifier, notificationDate, notificationType, showNotification,
  } = data;
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => FirebaseRef.child(`notifications/${selectNotificationId}`).update({
    lvTitle,
    enTitle,
    ruTitle,
    lvText,
    enText,
    ruText,
    notifyIdentifier,
    notificationDate: Date.parse(notificationDate),
    notificationType,
    showNotification,
  }).then(() => {
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'editNotification',
      adminId: UID,
      adminEmail,
      adminName,
      data: {
        ...data,
      },
      date: Firebase.database.ServerValue.TIMESTAMP,
    });
    return resolve({ status: constants.SERVER_STATUS_CODE.success });
  })).catch((err) => { console.log(err.message); });
}

export function deleteNotification(selectNotificationId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged(() => {
    FirebaseRef.child(`notifications/${selectNotificationId}`).remove().then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deleteNotification',
        adminId: UID,
        adminEmail,
        adminName,
        selectNotificationId,
        data: null,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    });
  }));
}


const getMultiAccountPairsFunction = (dispatch) => {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return new Promise(resolve => FirebaseRef.child('multiAccounts').once('value', (snapshot) => {
    const data = snapshot.val() || {};

    resolve(dispatch({
      type: 'MULTI_ACCOUNTS',
      data,
    }));
  }).catch(() => {
    resolve();
  }));
};

export function getMultiAccountPairs() {
  return dispatch => getMultiAccountPairsFunction(dispatch);
}

export function addMultiAccountPair(uid1, uid2, isNewGroup) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid1 || !uid2) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  const promise1 = FirebaseRef.child(`users/${uid1}/name`).once('value');
  const promise2 = FirebaseRef.child(`users/${uid2}/name`).once('value');
  const promise3 = FirebaseRef.child(`multiAccounts/${uid1}/${uid1}`).once('value');

  return dispatch => new Promise(resolve => Promise.all([promise1, promise2, promise3]).then(async (promisesRes) => {
    let name1;
    let name2;
    let exisitngMultiAccounts;

    promisesRes.map((resp, index) => {
      if (index === 0) {
        name1 = resp.val() || null;
      } else if (index === 1) {
        name2 = resp.val() || null;
      } else if (index === 2) {
        exisitngMultiAccounts = resp.val() || null;
      }
      return null;
    });

    console.log('exisitngMultiAccounts', exisitngMultiAccounts, uid1, uid2);

    await FirebaseRef.child(`multiAccounts/${uid1}/${uid2}`).update({
      uid: uid2,
      name: name2,
      date: Firebase.database.ServerValue.TIMESTAMP,
    }).then(async () => {
      if (exisitngMultiAccounts) {
        await FirebaseRef.child(`multiAccounts/${uid1}/${uid1}`).update({
          uid: uid1,
          name: name1,
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
      } else {
        await FirebaseRef.child(`multiAccounts/${uid1}/${uid1}`).update({
          uid: uid1,
          name: name1,
          date: Firebase.database.ServerValue.TIMESTAMP,
          dateCreated: Firebase.database.ServerValue.TIMESTAMP,
        });
      }

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: isNewGroup ? 'addMultiAccountPair' : 'addMultiAccount',
        adminId: UID,
        adminEmail,
        adminName,
        uid1: uid1 || null,
        uid2: uid2 || null,
        data: { uid1, uid2 },
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      await getMultiAccountPairsFunction(dispatch);

      return resolve();
    }).catch(() => {
      resolve();
    });
  }));
}


export function removeMultiAccountPair(uid1, uid2, length) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  if (!uid1 || !uid2) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (uid1 !== uid2) {
    console.log('removing not root');
    return dispatch => new Promise(resolve => FirebaseRef.child(`multiAccounts/${uid1}/${uid2}`).remove().then(async () => {
      if (length <= 2) {
        await FirebaseRef.child(`multiAccounts/${uid1}/${uid1}`).remove();
      }

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: length === 2 ? 'removeMultiAccountPair' : 'removeMultiAccount',
        adminId: UID,
        adminEmail,
        adminName,
        uid1: uid1 || null,
        uid2: uid2 || null,
        data: { uid1, uid2 },
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      await getMultiAccountPairsFunction(dispatch);
      return resolve();
    }).catch(() => {
      resolve();
    }));
  }
  const promise1 = FirebaseRef.child(`multiAccounts/${uid1}`).once('value');

  return dispatch => new Promise(resolve => Promise.all([promise1]).then(async (promisesRes) => {
    let multiAccountsData;

    promisesRes.map((resp, index) => {
      if (index === 0) {
        multiAccountsData = resp.val() || null;
      }
      return null;
    });

    let newId2 = Object.keys(multiAccountsData)[0];

    if (newId2 === uid1) {
      newId2 = Object.keys(multiAccountsData)[1];
    }

    delete multiAccountsData[uid1];

    FirebaseRef.child(`multiAccounts/${uid1}`).remove().then(async () => {
      if (length > 2) {
        await FirebaseRef.child(`multiAccounts/${newId2}`).set(multiAccountsData);
      }

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: length === 2 ? 'removeMultiAccountPair' : 'removeMultiAccount',
        adminId: UID,
        adminEmail,
        adminName,
        uid1: uid1 || null,
        uid2: uid2 || null,
        data: { uid1, uid2: uid2 || null },
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      await getMultiAccountPairsFunction(dispatch);
      return resolve();
    });
  }).catch(() => {
    resolve();
  }));
}

export function getAllHints() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  FirebaseRef.child('hints').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('hints')
    .on('value', (snapshot) => {
      const allHints = snapshot.val() || {};
      const array = [];
      Object.keys(allHints).map((key) => {
        array.push({ key, ...allHints[key] });
        return null;
      });
      return resolve(dispatch({ type: 'ADMIN_HINTS_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllHintsOld() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  FirebaseRef.child('hints').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('hints')
    .orderByChild('hintType')
    .equalTo(constants.OLD_DESIGN)
    .on('value', (snapshot) => {
      const allHints = snapshot.val() || {};
      const array = [];
      Object.keys(allHints).map((key) => {
        array.push({ key, ...allHints[key] });
        return null;
      });
      return resolve(dispatch({ type: 'ADMIN_HINTS_OLD_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllHintsNew() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  FirebaseRef.child('hints').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('hints')
    .orderByChild('hintType')
    .equalTo(constants.NEW_DESIGN)
    .on('value', (snapshot) => {
      const allHints = snapshot.val() || {};
      const array = [];
      Object.keys(allHints).map((key) => {
        array.push({ key, ...allHints[key] });
        return null;
      });
      return resolve(dispatch({ type: 'ADMIN_HINTS_NEW_REPLACE', data: array }));
    })).catch((err) => { console.log(err.message); });
}

export function addNewHint(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    showOptions, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, enImageUrl, lvImageUrl, ruImageUrl, devMode, hintType,
  } = data;

  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!enImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!lvImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!ruImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!hintType) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }

  return () => new Promise((resolve, reject) => {
    FirebaseRef.child('hints').push({
      showOptions,
      titleLV: lvTitle,
      titleEN: enTitle,
      titleRU: ruTitle,
      descriptionLV: lvDescription,
      descriptionEN: enDescription,
      descriptionRU: ruDescription,
      enImageUrl,
      lvImageUrl,
      ruImageUrl,
      devMode,
      hintType,
    })
      .then((res) => {
        console.log('addHint=', res);
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'createNewHint',
          adminId: UID,
          adminEmail,
          adminName,
          data: {
            showOptions,
            titleEN: enTitle,
            titleLV: lvTitle,
            titleRU: ruTitle,
            descriptionEN: enDescription,
            descriptionLV: lvDescription,
            descriptionRU: ruDescription,
            enImageUrl,
            lvImageUrl,
            ruImageUrl,
            devMode,
            hintType,
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      })
      .catch((err) => { console.log(err); return reject({ status: constants.SERVER_STATUS_CODE.error }); });
  });
}

export function editSelectHint(data) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  if (!UID) return new Promise(resolve => resolve());

  const {
    selectHintId, showOptions, enTitle, lvTitle, ruTitle, enDescription, lvDescription, ruDescription, enImageUrl, lvImageUrl, ruImageUrl, oldData, devMode, hintType,
  } = data;

  if (!selectHintId) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }
  if (!lvTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVTitle })); }
  if (!enTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENTitle })); }
  if (!ruTitle) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUTitle })); }
  if (!lvDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingLVDescription })); }
  if (!enDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingENDescription })); }
  if (!ruDescription) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingRUDescription })); }
  if (!enImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!lvImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!ruImageUrl) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingPhoto })); }
  if (!hintType) { return () => new Promise(resolve => resolve({ status: 'error', message: errorMessages.missingData })); }

  return () => new Promise((resolve) => {
    FirebaseRef.child(`hints/${selectHintId}`).update({
      showOptions,
      titleLV: lvTitle,
      titleEN: enTitle,
      titleRU: ruTitle,
      descriptionLV: lvDescription,
      descriptionEN: enDescription,
      descriptionRU: ruDescription,
      enImageUrl,
      lvImageUrl,
      ruImageUrl,
      devMode,
      hintType,
    }).then(() => {
      if (showOptions !== oldData.showOptions || enTitle !== oldData.titleEN || enDescription !== oldData.descriptionEN || devMode !== oldData.devMode || hintType !== oldData.hintType) {
        const dataToEdit = {
          showOptions,
          titleEN: enTitle,
          titleLV: lvTitle,
          titleRU: ruTitle,
          descriptionEN: enDescription,
          descriptionLV: lvDescription,
          descriptionRU: ruDescription,
          enImageUrl,
          ruImageUrl,
          lvImageUrl,
          devMode,
          hintType,
        };
        const changesObj = {};
        Object.keys(dataToEdit).map((key) => {
          if (oldData[key] !== dataToEdit[key]) {
            changesObj[key] = { new: dataToEdit[key], old: oldData[key] };
          }
          return null;
        });

        let changedlog = '';
        Object.keys(changesObj).map((key, index) => {
          changedlog += `${key} ${changesObj[key].old} to ${changesObj[key].new}`;

          if ((index + 1) !== Object.keys(changesObj).length) {
            changedlog += ', ';
          }
          return null;
        });

        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'editSelectHint',
          adminId: UID,
          adminEmail,
          adminName,
          eventId: selectHintId,
          data: {
            ...dataToEdit,
            changedlog,
          },
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
      }

      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(err => resolve({ status: 'error', message: err.message }));
  });
}

export function deleteHint(hindId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return () => new Promise(resolve => Firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      FirebaseRef.child(`hints/${hindId}`).remove().then(() => {
        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'deleteHint',
          adminId: UID,
          adminEmail,
          adminName,
          hindId,
          data: null,
          date: Firebase.database.ServerValue.TIMESTAMP,
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success });
      })
        .catch(err => resolve({ status: 'error', message: err.message }));
    }
  }));
}

export function getAllReportedPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  // const filterDate = Date.parse(new Date());

  FirebaseRef.child('reportPlayers').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('reportPlayers')
    .on('value', (snapshot) => {
      const allReportedPlayers = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_REPORTED_PLAYERS_REPLACE', data: allReportedPlayers }));
    })).catch((err) => { console.log(err.message); });
}

export function getAllReportedMessages() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  // const filterDate = Date.parse(new Date());

  FirebaseRef.child('reportMessages').off();

  return dispatch => new Promise(resolve => FirebaseRef.child('reportMessages')
    .on('value', (snapshot) => {
      const allReportedMessages = snapshot.val() || {};
      console.log('allReportedMessages', allReportedMessages);

      return resolve(dispatch({ type: 'ADMIN_REPORTED_MESSAGES_REPLACE', data: allReportedMessages }));
    })).catch((err) => { console.log(err.message); });
}

export function checkAdminReportPlayers(key, reporterId, reporterName, reportedId, reportedName, reason) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  console.log(key, 'checkAdminView');
  return () => new Promise(() => {
    FirebaseRef.child(`reportPlayers/${key.toString()}`)
      .update({
        adminView: true,
      });
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'reportPlayers',
      adminId: UID,
      adminEmail,
      userId: key,
      adminName,
      userName: reporterName,
      data: {
        reportedId,
        reportedName,
        reason,
        adminView: 'Done',
      },
      date: Firebase.database.ServerValue.TIMESTAMP,
    });
  });
}

export function checkAdminReportMessages(key, reporterId, reporterName, reportedId, reportedName, message) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  console.log(key, reporterId, reporterName, reportedId, reportedName, message, 'checkAdminView');
  return () => new Promise(() => {
    FirebaseRef.child(`reportMessages/${key.toString()}`)
      .update({
        adminView: true,
      });
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'reportMessages',
      adminId: UID,
      adminEmail,
      adminName,
      date: Firebase.database.ServerValue.TIMESTAMP,
      userId: key,
      userName: reporterName,
      data: {
        reportedId,
        reportedName,
        message,
        adminView: 'Done',
      },
    });
  });
}

export function getConfirmedPlayersCount() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .orderByChild('verifiedEmail')
    .equalTo(true)
    .once('value', (snapshot) => {
      const data = snapshot.val() || {};
      console.log(size(data), 'admin verified users action');

      const filteredUsers = data ? filter(data, item => item.verifiedEmailSent) : {};

      return resolve(dispatch({ type: 'ADMIN_VERIFIED_COUNT_REPLACE', data: size(filteredUsers) }));
    })).catch((err) => { console.log(err.message); });
}

export function checkAutoForCompensation(uid, name) {
  console.log('checkAutoForCompensation', uid, name);
  if (!uid) return () => new Promise(resolve => resolve());

  if (Firebase === null) return () => new Promise(resolve => resolve());

  /*
  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  const test = Date.now();

   let d = new Date(test);

   d.setHours(24, 0, 0, 0);
   d = Date.parse(d);

  const startAt = d - (1000 * 60 * 60 * 72);
  const endAt = d;
  const totalPenalty = 0;
  const percentPenalty = 0;
  const adminChangeAuto = false;
  const previousChangeAuto = {};
  console.log('adminPenaltyBonus3'); */

  return () => new Promise(() => {
    const delay = [1, 2, 3, 4, 5];
    const random = Math.floor(Math.random() * delay.length);

    FirebaseRef.child(`checkAutoForCompensation/${uid}`).set({ time: Firebase.database.ServerValue.TIMESTAMP, delay: delay[random] });
  });
}

// export function getRoomChats(filterType, filterVal) {
//   if (Firebase === null) return () => new Promise(resolve => resolve());

//   const d = Date.now();
//   /* let startAt;
//   let endAt;
//   let roomData;
//   const roomMsg = [];
//   let filterRoomData; */

//   const startAt = d - (1000 * 60 * 60 * 24 * constants.WEEKLY_VALUE.twoWeek);
//   const endAt = d;

//   return dispatch => new Promise(async (resolve) => {
//     dispatch({ type: 'PLAYER_ROOM_CHAT_MESSAGE', data: [], chatLoading: true });

//     FirebaseRef.child('playerChat')
//       .orderByChild('time')
//       .startAt(startAt)
//       .endAt(endAt)
//       .once('value', (snapshot) => {
//         const data = snapshot.val();
//         let roomChats = [];
//         if (filterType === constants.ROOMID && filterVal !== '') {
//           roomChats = filter(data, item => item.roomId === filterVal);
//         } else if (filterType === constants.USERID && filterVal !== '') {
//           roomChats = filter(data, item => item.userUid === filterVal);
//         } else {
//           Object.keys(data).map((key) => {
//             roomChats.push({ key, ...data[key] });
//             return null;
//           });
//         }
//         if (size(roomChats) > 0) {
//           roomChats.sort((a, b) => (b.time - a.time));
//         }
//         return resolve(dispatch({ type: 'PLAYER_ROOM_CHAT_MESSAGE', data: roomChats, chatLoading: false }));
//       });
//   });
// }

export function getRoomChats(filterType, filterVal) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const startAt = Date.now() - (1000 * 60 * 60 * 24 * constants.WEEKLY_VALUE.twoWeek);

  FirebaseRef.child('playerChat').off();

  return dispatch => {
    const unsubscribe = FirebaseRef.child('playerChat')
      .orderByChild('time')
      .startAt(startAt)
      .on('value', async (snapshot) => {
        const data = snapshot.val();
        let roomChats = [];

        if (filterType === constants.ROOMID && filterVal !== '') {
          roomChats = filter(data, item => item.roomId === filterVal);
        } else if (filterType === constants.USERID && filterVal !== '') {
          roomChats = filter(data, item => item.userUid === filterVal);
        } else {
          Object.keys(data).map((key) => {
            roomChats.push({ key, ...data[key] });
            return null;
          });
        }

        const updatedRoomChats = await Promise.all(roomChats.map(async (chat) => {
          const banSnapshot = await FirebaseRef.child(`bans/${chat.userUid}`).once('value');
          const banData = banSnapshot.val();
          return {
            ...chat,
            blocked: banData ? banData.blocked : false
          };
        }));

        if (size(updatedRoomChats) > 0) {
          updatedRoomChats.sort((a, b) => (b.time - a.time));
        }

        return dispatch({ type: 'PLAYER_ROOM_CHAT_MESSAGE', data: updatedRoomChats, chatLoading: false });
      });

    return () => unsubscribe();
  };
}

export function getLogRocketUsers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => FirebaseRef.child('users')
    .orderByChild('enableLogRocket')
    .equalTo(true)
    .once('value', (userSnapshot) => {
      const enableLogRocketUsers = userSnapshot.val() || {};

      return resolve(dispatch({ type: 'ENABLED_LOG_LOCKET_USERS', data: enableLogRocketUsers }));
    }));
}

export function addWarningMark(playerUid, curWarningMark) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  console.log(playerUid, curWarningMark || 0, 'checkAdminView');
  const updateWarningMark = curWarningMark ? curWarningMark + 1 : 1;
  const cursorExpireDate = Date.parse(moment(new Date()).add(30, 'days'));

  return () => new Promise((resolve) => {
    if (curWarningMark === 0 || !curWarningMark) {
      FirebaseRef.child(`warningPlayers/${playerUid.toString()}`)
        .update({
          warning: updateWarningMark,
          warningDate: Date.now(),
          adminActivated: true,
        });
    } else {
      FirebaseRef.child(`warningPlayers/${playerUid.toString()}`)
        .update({
          warning: updateWarningMark,
          warningDate: Date.now(),
          adminActivated: true,
          cursor: (updateWarningMark > 0 && updateWarningMark % 10 === 0) || null,
          cursorExpire: (updateWarningMark > 0 && updateWarningMark % 10 === 0) ? cursorExpireDate : null,
        });
    }

    FirebaseRef.child(`users/${playerUid.toString()}`)
      .update({
        warning: updateWarningMark,
        warningDate: Date.now(),
        cursor: (updateWarningMark > 0 && updateWarningMark % 10 === 0) || null,
        cursorExpire: (updateWarningMark > 0 && updateWarningMark % 10 === 0) ? cursorExpireDate : null,
      });
    AdminLogsRef().child('adminLogs/adminActions').push({
      type: 'warningMark',
      adminId: UID,
      adminEmail,
      adminName,
      date: Firebase.database.ServerValue.TIMESTAMP,
      userId: playerUid,
      data: {
        warning: updateWarningMark,
      },
    });
    return resolve({ status: constants.SERVER_STATUS_CODE.success });

  });
}

export function getWarningPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());

  FirebaseRef.child('warningPlayers').off();

  return dispatch => new Promise(async resolve => FirebaseRef.child('warningPlayers')
    .orderByKey()
    .on('value', (userSnapshot) => {
      const warningPlayers = userSnapshot.val() || {};
      const warningPlayersList = {};

      map(warningPlayers, (item, key) => {
        if (item?.cursor && Date.now() > item?.cursorExpire) {
          FirebaseRef.child(`users/${key.toString()}`).update({
            cursorExpire: null,
            cursor: null,
          });
          FirebaseRef.child(`warningPlayers/${key.toString()}`).update({
            cursorExpire: null,
            cursor: null,
          });
        } else {
          warningPlayersList[key] = {
            ...item,
          };
        }
      })

      return resolve(dispatch({ type: 'REPLACE_WARNING_PLAYERS', data: warningPlayersList }));
    }));
}

export function getDesignPlatforms() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (
    FirebaseRef
    && Firebase
    && Firebase.auth()
    && Firebase.auth().currentUser
    && Firebase.auth().currentUser.uid
  ) ? Firebase.auth().currentUser.uid : null;

  if (!UID) return () => new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: [], designPlatformsLoading: true });
    FirebaseRef.child('designPlatforms')
      .orderByKey()
      .once('value', (userSnapshot) => {
        const designPlatforms = userSnapshot.val() || {};
        return resolve(dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: designPlatforms, designPlatformsLoading: false }));
      }).catch(() => {
        return resolve(dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: {}, designPlatformsLoading: false }));
      });
  });
}

export function updateDesignPlatform(id, design, value) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: [], designPlatformsLoading: true });
    FirebaseRef.child(`designPlatforms/${id}`).update({
      [design]: value,
      setFrequency: design === 'frequency' && value > 0 ? Date.now() : null,
    }).then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'designPlatform',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          [design]: value,
        },
      });

      FirebaseRef.child('designPlatforms')
        .orderByKey()
        .once('value', (userSnapshot) => {
          const designPlatforms = userSnapshot.val() || {};

          dispatch({ type: 'GET_DESIGN_PLATFORM', data: designPlatforms });
          return resolve(dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: designPlatforms, designPlatformsLoading: false }));
        }).catch(() => {
          return resolve(dispatch({ type: 'REPLACE_DESIGN_PLATFORMS', data: {}, designPlatformsLoading: false }));
        });
    });
  });
};

export function getSurveys() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(async (resolve) => {
    await FirebaseRef.child('surveys').off();

    FirebaseRef.child('surveys')
      .on('value', (snapshot) => {
        const allSurveys = snapshot.val() || {};

        console.log('getSurveys allSurveys', allSurveys);

        return resolve(dispatch({ type: 'REPLACE_SURVEY_PLATFORMS', data: allSurveys }));
      });
  }).catch((err) => { console.log(err.message); });
  }

export function getSurveyHistory() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());

  return dispatch => new Promise(async (resolve) => {
    dispatch({ type: 'REPLACE_SURVEY_HISTORY_PLATFORMS', data: [], loading: true });
    await FirebaseRef.child('surveyHistory').off();

    FirebaseRef.child('surveyHistory')
      .on('value', (userSnapshot) => {
        const surveyHistory = userSnapshot.val() || {};

        console.log('getSurveysHistory surveyHistory', surveyHistory);

        return resolve(dispatch({ type: 'REPLACE_SURVEY_HISTORY_PLATFORMS', data: surveyHistory, loading: false }));
      });
    });
}

export function createSurveyFunc({startDate, endDate, question, description, questionType, devMode, answerList, selectedDesigns, disableAnswer}) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('surveys').push({
      startDate: Date.parse(startDate),
      endDate: Date.parse(endDate),
      question,
      description,
      questionType,
      devMode: devMode || null,
      answerList,
      selectedDesigns,
      disableAnswer
    }).then((res) => {

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'createSurvey',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          startDate: moment(startDate).format('DD-MM-YYYY HH:MM:SS'),
          endDate: moment(endDate).format('DD-MM-YYYY HH:MM:SS'),
          question,
          description,
          questionType,
          devMode,
          answerList,
          selectedDesigns,
          disableAnswer
        },
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function deleteFeedback(selectedId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`feedback/${selectedId}`).remove().then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deleteFeedback',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        selectedId,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function createFeedback({startDate, endDate, platforms, designs, devMode}) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child('feedback').startAt(Date.parse(startDate)).endAt(Date.parse(endDate)).once('value', (snapshot) => {
      const results = snapshot.val();
      console.log(results, "resultcheck===============")

      FirebaseRef.child('feedback').push({
        startDate: Date.parse(startDate),
        endDate: Date.parse(endDate),
        platforms,
        designs,
        devMode,
      }).then((res) => {
        console.log(res, "check===============111111111111")

        AdminLogsRef().child('adminLogs/adminActions').push({
          type: 'createFeedback',
          adminId: UID,
          adminEmail,
          adminName,
          date: Firebase.database.ServerValue.TIMESTAMP,
          data: {
            startDate: moment(startDate).format('DD-MM-YYYY HH:MM:SS'),
            endDate: moment(endDate).format('DD-MM-YYYY HH:MM:SS'),
            platforms,
            designs,
            devMode,
          },
        });
        return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
      }).catch(() => {
        return resolve({ status: constants.SERVER_STATUS_CODE.error });
      });
    })
  });
}

export function editFeedback({selectedId, startDate, endDate, platforms, designs, devMode,}) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`feedback/${selectedId}`).update({
      startDate: Date.parse(startDate),
      endDate: Date.parse(endDate),
      platforms,
      designs,
      devMode,
    }).then((res) => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'editFeedback',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          startDate: moment(startDate).format('DD-MM-YYYY HH:MM:SS'),
          endDate: moment(endDate).format('DD-MM-YYYY HH:MM:SS'),
          platforms,
          designs,
          devMode,
        },
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function deleteSurveyFunc(selectedId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`surveys/${selectedId}`).remove().then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deleteSurvey',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        selectedId,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function editSurveyFunc({selectedId, startDate, endDate, question, description, questionType, devMode, answerList, selectedDesigns, disableAnswer}) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`surveys/${selectedId}`).update({
      startDate: Date.parse(startDate),
      endDate: Date.parse(endDate),
      question,
      description,
      questionType,
      devMode: devMode || null,
      answerList,
      selectedDesigns,
      disableAnswer
    }).then((res) => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'editSurvey',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          startDate: moment(startDate).format('DD-MM-YYYY HH:MM:SS'),
          endDate: moment(endDate).format('DD-MM-YYYY HH:MM:SS'),
          question,
          description,
          questionType,
          devMode,
          answerList,
          selectedDesigns,
          disableAnswer
        },
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}




export function getTranslationsFiles(devMode) {
  console.log('getTranslationsFiles', devMode);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());

  return dispatch => new Promise((resolve) => {
    // dispatch({ type: 'REPLACE_SURVEY_PLATFORMS', data: [], loading: true });

    const promises = [];

    let lvReference = FirebaseStorage.ref(`/translations`).child(`lv.json`);
    let enReference = FirebaseStorage.ref(`/translations`).child(`en.json`);
    let ruReference = FirebaseStorage.ref(`/translations`).child(`ru.json`);

    if (devMode) {
      console.log('get dev translations');

      lvReference = FirebaseStorage.ref(`/translationsDev`).child(`lv.json`);
      enReference = FirebaseStorage.ref(`/translationsDev`).child(`en.json`);
      ruReference = FirebaseStorage.ref(`/translationsDev`).child(`ru.json`);
    }

    const languageMap = {
      'english': null,
      'latvian': null,
      'russian': null,
    };

    promises.push(new Promise((resolve2) => {
      lvReference.getDownloadURL().then((url) => {
        axios.get(url).then((response) => {
          let translationsDb = response.data;

          console.log('translations file lv', translationsDb);

          if (translationsDb.common) { i18n.addResourceBundle('lv', 'common', translationsDb.common); }
          if (translationsDb.holiday) { i18n.addResourceBundle('lv', 'holiday', translationsDb.holiday); }
          if (translationsDb.previousRound) { i18n.addResourceBundle('lv', 'previousRound', translationsDb.previousRound); }
          if (translationsDb.achievements) { i18n.addResourceBundle('lv', 'achievements', translationsDb.achievements); }
          if (translationsDb.game) { i18n.addResourceBundle('lv', 'game', translationsDb.game); }
          if (translationsDb.playerInfo) { i18n.addResourceBundle('lv', 'playerInfo', translationsDb.playerInfo); }
          if (translationsDb.chat) { i18n.addResourceBundle('lv', 'chat', translationsDb.chat); }
          if (translationsDb.notifications) { i18n.addResourceBundle('lv', 'notifications', translationsDb.notifications); }
          if (translationsDb.login) { i18n.addResourceBundle('lv', 'login', translationsDb.login); }
          if (translationsDb.help) { i18n.addResourceBundle('lv', 'help', translationsDb.help); }
          if (translationsDb.settings) { i18n.addResourceBundle('lv', 'settings', translationsDb.settings); }
          if (translationsDb.updateProfile) { i18n.addResourceBundle('lv', 'updateProfile', translationsDb.updateProfile); }
          if (translationsDb.nativeNotifications) { i18n.addResourceBundle('lv', 'nativeNotifications', translationsDb.nativeNotifications); }
          if (translationsDb.bonus) { i18n.addResourceBundle('lv', 'bonus', translationsDb.bonus); }
          if (translationsDb.admin) { i18n.addResourceBundle('lv', 'admin', translationsDb.admin); }
          if (translationsDb.rulesPage) { i18n.addResourceBundle('lv', 'rulesPage', translationsDb.rulesPage); }
          if (translationsDb.rules) { i18n.addResourceBundle('lv', 'rules', translationsDb.rules); }
          if (translationsDb.tournamentHelp) { i18n.addResourceBundle('lv', 'tournamentHelp', translationsDb.tournamentHelp); }

          languageMap.latvian = translationsDb;

          return resolve2(dispatch({ type: 'REPLACE_TRASLATIONS_FILES', data: translationsDb, language: 'lv' }));
        });
      });
    }));

    promises.push(new Promise((resolve2) => {
      enReference.getDownloadURL().then((url) => {
        axios.get(url).then((response) => {
          let translationsDb = response.data;

          console.log('translations file en', translationsDb);

          if (translationsDb.common) { i18n.addResourceBundle('en', 'common', translationsDb.common); }
          if (translationsDb.holiday) { i18n.addResourceBundle('en', 'holiday', translationsDb.holiday); }
          if (translationsDb.previousRound) { i18n.addResourceBundle('en', 'previousRound', translationsDb.previousRound); }
          if (translationsDb.achievements) { i18n.addResourceBundle('en', 'achievements', translationsDb.achievements); }
          if (translationsDb.game) { i18n.addResourceBundle('en', 'game', translationsDb.game); }
          if (translationsDb.playerInfo) { i18n.addResourceBundle('en', 'playerInfo', translationsDb.playerInfo); }
          if (translationsDb.chat) { i18n.addResourceBundle('en', 'chat', translationsDb.chat); }
          if (translationsDb.notifications) { i18n.addResourceBundle('en', 'notifications', translationsDb.notifications); }
          if (translationsDb.login) { i18n.addResourceBundle('en', 'login', translationsDb.login); }
          if (translationsDb.help) { i18n.addResourceBundle('en', 'help', translationsDb.help); }
          if (translationsDb.settings) { i18n.addResourceBundle('en', 'settings', translationsDb.settings); }
          if (translationsDb.updateProfile) { i18n.addResourceBundle('en', 'updateProfile', translationsDb.updateProfile); }
          if (translationsDb.nativeNotifications) { i18n.addResourceBundle('en', 'nativeNotifications', translationsDb.nativeNotifications); }
          if (translationsDb.bonus) { i18n.addResourceBundle('en', 'bonus', translationsDb.bonus); }
          if (translationsDb.admin) { i18n.addResourceBundle('en', 'admin', translationsDb.admin); }
          if (translationsDb.rulesPage) { i18n.addResourceBundle('en', 'rulesPage', translationsDb.rulesPage); }
          if (translationsDb.rules) { i18n.addResourceBundle('en', 'rules', translationsDb.rules); }
          if (translationsDb.tournamentHelp) { i18n.addResourceBundle('en', 'tournamentHelp', translationsDb.tournamentHelp); }

          languageMap.english = translationsDb;

          return resolve2(dispatch({ type: 'REPLACE_TRASLATIONS_FILES', data: translationsDb, language: 'en' }));
        });
      });
    }));

    promises.push(new Promise((resolve2) => {
      ruReference.getDownloadURL().then((url) => {
        axios.get(url).then((response) => {
          let translationsDb = response.data;

          console.log('translations file ru', translationsDb);

          if (translationsDb.common) { i18n.addResourceBundle('ru', 'common', translationsDb.common); }
          if (translationsDb.holiday) { i18n.addResourceBundle('ru', 'holiday', translationsDb.holiday); }
          if (translationsDb.previousRound) { i18n.addResourceBundle('ru', 'previousRound', translationsDb.previousRound); }
          if (translationsDb.achievements) { i18n.addResourceBundle('ru', 'achievements', translationsDb.achievements); }
          if (translationsDb.game) { i18n.addResourceBundle('ru', 'game', translationsDb.game); }
          if (translationsDb.playerInfo) { i18n.addResourceBundle('ru', 'playerInfo', translationsDb.playerInfo); }
          if (translationsDb.chat) { i18n.addResourceBundle('ru', 'chat', translationsDb.chat); }
          if (translationsDb.notifications) { i18n.addResourceBundle('ru', 'notifications', translationsDb.notifications); }
          if (translationsDb.login) { i18n.addResourceBundle('ru', 'login', translationsDb.login); }
          if (translationsDb.help) { i18n.addResourceBundle('ru', 'help', translationsDb.help); }
          if (translationsDb.settings) { i18n.addResourceBundle('ru', 'settings', translationsDb.settings); }
          if (translationsDb.updateProfile) { i18n.addResourceBundle('ru', 'updateProfile', translationsDb.updateProfile); }
          if (translationsDb.nativeNotifications) { i18n.addResourceBundle('ru', 'nativeNotifications', translationsDb.nativeNotifications); }
          if (translationsDb.bonus) { i18n.addResourceBundle('ru', 'bonus', translationsDb.bonus); }
          if (translationsDb.admin) { i18n.addResourceBundle('ru', 'admin', translationsDb.admin); }
          if (translationsDb.rulesPage) { i18n.addResourceBundle('ru', 'rulesPage', translationsDb.rulesPage); }
          if (translationsDb.rules) { i18n.addResourceBundle('ru', 'rules', translationsDb.rules); }
          if (translationsDb.tournamentHelp) { i18n.addResourceBundle('ru', 'tournamentHelp', translationsDb.tournamentHelp); }

          languageMap.russian = translationsDb;

          return resolve2(dispatch({ type: 'REPLACE_TRASLATIONS_FILES', data: translationsDb, language: 'ru' }));
        });
      });
    }));

    Promise.all(promises).then(() => {
      console.log('languageMap', languageMap);
      if (languageMap && languageMap.english && languageMap.latvian && languageMap.russian) {
        return resolve(languageMap);
      }

      return resolve(null);
    }).catch(err => {
      console.log(err);
      return resolve(null);
    })

  });
}

export function updateTranslationsFile(translationsJson, language, changes, devMode) {
  console.log("updateTranslationsFile", translationsJson, language, changes, devMode);

  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());

  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  let translationReference = FirebaseStorage.ref(`/translations`).child(`${language}.json`);

  if (devMode) {
    translationReference = FirebaseStorage.ref(`/translationsDev`).child(`${language}.json`);
  }

  return dispatch => new Promise((resolve) => {
    const jsonString = JSON.stringify(translationsJson);
    const blob = new Blob([jsonString], { type: "application/json" });

    translationReference.put(blob).then(() => {
      console.log('Uploaded a blob!');

      // dispatch(getTranslationsFiles());

      console.log('test 7');
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'updateTranslations',
        adminId: UID,
        adminEmail,
        adminName,
        devMode,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {...changes},
      });

      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch((err) => {
      console.log('test 8');
      console.log('err', err);
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function getPossibleBotPlayers() {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());

  return dispatch => new Promise(resolve => {
    dispatch({ type: 'ADMIN_POSSIBLE_BOT_PLAYERS_REPLACE', data: {}, possibleBotLoading: true });

    FirebaseRef.child('possibleBotUsers/latest')
    .orderByChild('date')
    .on('value', (snapshot) => {
      const possibleBotPlayers = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_POSSIBLE_BOT_PLAYERS_REPLACE', data: possibleBotPlayers, possibleBotLoading: false  }));
    });
  }).catch((err) => { console.log(err.message); });
}

export function getPossibleBotPlayerData(playerId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());
  const filterDate = Date.parse(new Date());

  return dispatch => new Promise(resolve => FirebaseRef.child(`possibleBotUsers/history/${playerId}`)
    .on('value', (snapshot) => {
      const possibleBotPlayerData = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_POSSIBLE_BOT_PLAYER_DATA_REPLACE', data: possibleBotPlayerData }));
    })).catch((err) => { console.log(err.message); });
}

export function getRoomOptions(roomId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => AdminLogsRef().child(`adminLogs/roomIds/${roomId}`)
    .once('value', async (snapshot) => {
      const roomOptions = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_ROOM_OPTIONS_REPLACE', data: roomOptions }));
    }, (err) => { console.log(err); })).catch((err) => { console.log(err); });
}

export function updateHelpEditor(helpSection, lang, actionType, actionOrderId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  const convertString = JSON.stringify(helpSection);

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`helpEditor/${lang}`).update({
      helpSection: convertString,
    }).then((res) => {

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'updateHelpSection',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {type: actionType, orderId: actionOrderId}
      });

      return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function updateHelpEditorDevMode(devMode) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`helpEditor`).update({
      devMode,
    }).then((res) => {

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'updateHelpSection',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
      });

      return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}

export function getHelpEditor() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    dispatch({ type: 'ADMIN_HELP_EDITOR_REPLACE', data: [], helpEditorLoading: true });
    FirebaseRef.child('helpEditor').on('value', (snapshot) => {
      const helpSections = snapshot.val();
      console.log(helpSections,  "parsedData=============")

      return resolve(dispatch({ type: 'ADMIN_HELP_EDITOR_REPLACE', data: helpSections, helpEditorLoading: false }));

    });
  });
}

export function getAnnouncements() {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  return dispatch => new Promise(resolve => {
    dispatch({ type: 'ADMIN_ANNOUNCEMENTS_REPLACE', data: {}, loading: true });

    FirebaseRef.child('announcements')
    .on('value', (snapshot) => {
      const allAnnouncements = snapshot.val() || {};

      return resolve(dispatch({ type: 'ADMIN_ANNOUNCEMENTS_REPLACE', data: allAnnouncements, loading: false }));
    });

  }).catch((err) => { console.log(err.message); });
}

export function addNewAnnouncement({ title, announcement, imageUrl, platforms, designs, environments, receivers }) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  const date = new Date();

  return () => new Promise((resolve) => {
    FirebaseRef.child('announcements').push({
      date: Date.parse(date),
      title,
      announcement,
      imageUrl,
      platforms,
      designs,
      environments,
      receivers,
    }).then((res) => {

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'addNewAnnouncement',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          date,
          title,
          announcement,
          imageUrl,
          platforms,
          designs,
          environments,
          receivers,
        },
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  })
};

export function editAnnouncement({ selectedId, title, announcement, imageUrl, platforms, designs, environments, receivers }) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;
  const date = new Date();

  return () => new Promise((resolve) => {
    FirebaseRef.child(`announcements/${selectedId}`).update({
      date: Date.parse(date),
      title,
      announcement,
      imageUrl,
      platforms,
      designs,
      environments,
      receivers,
    }).then((res) => {

      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'editAnnouncement',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        data: {
          date,
          title,
          announcement,
          imageUrl,
          platforms,
          designs,
          environments,
          receivers,
        },
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success, message: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  })
};

export function deleteAnnouncement(selectedId) {
  if (Firebase === null) return () => new Promise(resolve => resolve());

  const UID = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.uid) ? Firebase.auth().currentUser.uid : null;
  if (!UID) return new Promise(resolve => resolve());
  const adminEmail = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.email) ? Firebase.auth().currentUser.email : null;
  const adminName = (FirebaseRef && Firebase && Firebase.auth() && Firebase.auth().currentUser && Firebase.auth().currentUser.displayName) ? Firebase.auth().currentUser.displayName : null;

  return dispatch => new Promise((resolve) => {
    FirebaseRef.child(`announcements/${selectedId}`).remove().then(() => {
      AdminLogsRef().child('adminLogs/adminActions').push({
        type: 'deleteAnnouncement',
        adminId: UID,
        adminEmail,
        adminName,
        date: Firebase.database.ServerValue.TIMESTAMP,
        selectedId,
      });
      return resolve({ status: constants.SERVER_STATUS_CODE.success });
    }).catch(() => {
      return resolve({ status: constants.SERVER_STATUS_CODE.error });
    });
  });
}
