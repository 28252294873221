import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';

import ReactGA from 'react-ga';
import { gsap } from 'gsap';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Media from 'reactstrap/lib/Media';
import Button from 'reactstrap/lib/Button';
import NavLink from 'reactstrap/lib/NavLink';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import Countdown from 'react-countdown';
import { size } from 'lodash';

// import styled, { keyframes } from 'styled-components';

import CustomDate from '../UI/CustomDate';
import ScrollAreaWrapper from '../UI/ScrollAreaWrapper';

import wheel from '../../../images/BonusPage/bonusu_rats_3_min.png';
import wheelVip from '../../../images/BonusPage/bonusu_rats_3_vip_min.png';
import stand from '../../../images/BonusPage/bonusu_rats_stand_min.png';
import wheelArrow from '../../../images/BonusPage/bonus_wheel_arrow_min.png';

// import glow from '../../../images/redesign/bonus-page/wheel-glow.png';

import bonusPageImg from '../../../images/icons/gift.svg';
import coinImg from '../../../images/coin.svg';
import closeImg from '../../../images/icons/close.png';

import buttonClickedSound from '../../../sounds/click_feedback.flac';

// import InviteFriend from './InviteFriend';

import {
  spinBonusWheel,
  claimSpinResults,
  getTimeOffset,
  getDailyBonusHistory,
} from '../../../actions/member';
import { FONT_MODE, MENU_NAVIGATION } from '../../../constants/constants';

/*
const rotate = r => keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(${r}deg);
  }
`; */

/*
const Wheel = styled.div`
  display: inline-block;
  animation: ${(props) => rotate(props.rotation)} 3.25s ease-in-out 1 forwards;
`;

const WheelFullscreen = styled.div`
  display: block;
  animation: ${(props) => rotate(props.rotation)} 3.25s ease-in-out 1 forwards;
`; */

class BonusPage extends React.Component {
  static propTypes = {
    spinWheel: PropTypes.func.isRequired,
    claimSpin: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    lastBonusSpin: PropTypes.number,
    offset: PropTypes.number,
    t: PropTypes.func.isRequired,
    getOffset: PropTypes.func.isRequired,
    getBonusHistory: PropTypes.func.isRequired,
    doRender: PropTypes.bool,
    activeTab: PropTypes.string,
    isFullscreen: PropTypes.bool,
  //  userSettings: PropTypes.shape({
  //    soundOn: PropTypes.bool,
  //  }),
    fontMode: PropTypes.string,
  };

  static defaultProps = {
    lastBonusSpin: null,
    offset: 0,
    doRender: true,
    isFullscreen: false,
    activeTab: null,
  //  userSettings: {},
    fontMode: FONT_MODE.normal,
  };

  constructor(props) {
    super(props);
    this.state = {
      rotation: 0,
      spinResult: 0,
      stopRotationAngle: 0,
      spinComplete: false,
      spinning: false,
      openHistory: false,
      historyData: null,
      latestWheelSpin: null,
    };

    //  this.spinWheel = this.spinWheel.bind(this);
    //  this.confirmResult = this.confirmResult.bind(this);

    this.buttonClickedAudio = new Audio(buttonClickedSound);
    this.wheelRef = React.createRef();
  }

  componentDidMount = () => {
    const { getBonusHistory } = this.props;

    getBonusHistory().then((res) => {
      if (res && res.history) {
        const latestSpin = res.history[Object.keys(res.history).reverse()[Object.keys(res.history).length - 1]];

        if (latestSpin) {
          this.setState({
            historyData: res.history,
            latestWheelSpin: latestSpin.time,
          });
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.lastBonusSpin !== prevProps.lastBonusSpin) {
      const { getBonusHistory } = this.props;

      getBonusHistory().then((res) => {
        if (res && res.history) {
          const latestSpin = res.history[Object.keys(res.history)[Object.keys(res.history).length - 1]];

          this.setState({
            historyData: res.history,
            latestWheelSpin: latestSpin && latestSpin?.time,
          });
        }
      });
    }
  }

  spinWheel = () => {
    const { spinWheel, claimSpin } = this.props;

    console.log('spinWheel start');

    this.playButtonSound();
    console.log('spinWheel rotation 0');
    this.setState({ rotation: 0, spinning: true, stopRotationAngle: null });

    let spinToFinish = false;
    const minRotationIncrement = 0.5;
    const maxRotationIncrement = 6.0;
    let rotationIncrement = 1;
    let rotationIncrementReached = 0;
    spinWheel()
      .then((res) => {
        console.log('spinWheel res', res);

        if (!res.data.error) {
          const { spinResult } = res.data;
          let stopRotationAngle;
          switch (spinResult) {
            case 0:
              stopRotationAngle = 1065;
              break;
            case 10:
              stopRotationAngle = 1085;
              break;
            case 25:
              stopRotationAngle = 1125;
              break;
            case 50:
              stopRotationAngle = 1105;
              break;
            case 100:
              stopRotationAngle = 1285;
              break;
            case 300:
              stopRotationAngle = 1265;
              break;
            case -10:
              stopRotationAngle = 1045;
              break;
            case -25:
              stopRotationAngle = 1225;
              break;
            case -50:
              stopRotationAngle = 1345;
              break;
            case -75:
              stopRotationAngle = 1185;
              break;
            case -100:
              stopRotationAngle = 1145;
              break;
            case 75:
              stopRotationAngle = 1185;
              break;
            default:
              stopRotationAngle = 1065;
          }

          console.log('spinWheel set stopRotationAngle', spinResult, stopRotationAngle);
          this.setState({ stopRotationAngle, spinResult });

        } else {
          this.setState({ stopRotationAngle: 0 }); //, spininng: false
          const { getOffset } = this.props;
          getOffset();
        }

      })
      .catch((error) => {
        console.log('bonus wheel request error', error);
        this.setState({ stopRotationAngle: 0 }); //, spininng: false
      });
  
    // Start spinning the wheel continuously until the stop rotation angle is received
    const spinInterval = setInterval(() => {
      const { rotation, stopRotationAngle } = this.state;
  
      let newRotation = rotation + rotationIncrement;
      if (!spinToFinish) {
        if (rotationIncrement < maxRotationIncrement) {
          rotationIncrement += 0.1;
        } else { rotationIncrement = maxRotationIncrement; }
        if (newRotation >= 360) { newRotation = 0; }
      } else {
        const distanceToFinish = stopRotationAngle - rotation;
        const percentTowardsFinish = distanceToFinish / stopRotationAngle;
        rotationIncrement = minRotationIncrement + (rotationIncrementReached - minRotationIncrement) * Math.pow(percentTowardsFinish, 0.4);
      }


      console.log('spinWheel update rotation', newRotation);

      this.setState({ rotation: newRotation });
      gsap.to('.bonus-page-wheel-old', { rotation: newRotation, duration: 0.01 });

      if (stopRotationAngle !== null) {
        if (!spinToFinish && newRotation === 0) {
          spinToFinish = true;
          rotationIncrementReached = rotationIncrement;
        } else if (spinToFinish && newRotation >= stopRotationAngle) {
          clearInterval(spinInterval);
          this.setState({
            spinComplete: true, spinning: false, rotation: stopRotationAngle,
          });
          claimSpin();
        }
      }
    }, 10);
  };

  confirmResult = () => {
    const { claimSpin } = this.props;

    claimSpin();

    //  changeTab('1');
    this.setState({ spinResult: 0, spinComplete: false, rotation: 0 });
  };

  playButtonSound = () => {
    const { soundOn } = this.props;

  //  if (userSettings) {
      //  const userSettings2 = userSettings[uid] || userSettings.default || {}

      if (soundOn) {
        const playPromise = this.buttonClickedAudio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Automatic playback started!
              // Show playing UI.
              console.log('audio played auto');
            })
            .catch(() => {
              // Auto-play was prevented
              // Show paused UI.
              console.log('playback prevented');
            });
        }
      }
  //  }
  };

  getBonusHistory = () => {
    ReactGA.event({
      category: 'Menu',
      action: 'Open Bonus History',
    });

    this.setState({ openHistory: true });
  };

  closeHistory = () => {
    this.setState({ openHistory: false });
  };

  render() {
    const {
      offset, lastBonusSpin, t, doRender, activeTab, isFullscreen, fontMode, vipUntil, changeTab, devMode,
    } = this.props;

    const {
      rotation,
      spinComplete,
      spinResult,
      spinning,
      openHistory,
      historyData,
      // latestWheelSpin,
    } = this.state;

    if (!doRender) {
      return null;
    }

    console.log('rotation', rotation, openHistory, offset);

    const notSpined = (lastBonusSpin && (new Date(lastBonusSpin).getDate() !== new Date(Date.now() + offset).getDate() || new Date(lastBonusSpin).getMonth() !== new Date(Date.now() + offset).getMonth())) || !lastBonusSpin;

    if (isFullscreen) {
      return (
        <div className="bonus-page">
          {activeTab && activeTab === '4' ? (
            <Helmet>
              <title>
                {`Zole - ${t('route.bonusPage')}`}
              </title>
            </Helmet>
          ) : null}
          <Row>
            {/*  <Col sm="12" className="bonus-page-title">
              {t('bonusPage.dailyBonus')}
            </Col> */}
            <Col xs="12" className="bonus-page-block">
              <Row>
                <Col className="bonus-page-header">
                  <Media
                    src={bonusPageImg}
                    className="bonus-page-header-image"
                  />
                  <div className="bonus-page-header-text">
                    {t('bonusPage.dailyBonus')}
                  </div>
                  {/*  <Button onClick={this.getBonusHistory}>
                    {t('common.history')}
                  </Button> */}
                  <div className="bonus-page-history-wrapper">
                    <NavLink
                      className="bonus-page-history-link"
                      onClick={this.getBonusHistory}
                    >
                      {t('common.bonusHistory')}
                    </NavLink>
                  </div>
                </Col>
              </Row>
              <div className="bonus-page-block-container">
                <div className="bonus-page-block-background" />
                {!spinComplete ? (
                  <Fragment>
                    <Row>
                      <Col xs="5" className="bonus-page-block-wheel">
                        <div style={{ display: 'block', height: 1 }}>
                          <Media className="wheel-bg" src={stand} alt="" />
                        </div>
                        <div className="wheel-wrapper" style={{ position: 'relative' }}>
                          {/*  <WheelFullscreen className="bonus-page-wheel-old" style={{ transform: `rotate(${rotation}deg)` }}>
                            <Media className="wheel" src={wheel} alt="" />
                          </WheelFullscreen> */}

                          <Media ref={this.wheelRef} id="bonus-page-wheel" className="bonus-page-wheel-old" src={(vipUntil && vipUntil >= (Date.now() + offset)) ? wheelVip : wheel} alt="" />
                        </div>
                        {/*  <Media className="triangle-wrapper" src={wheelArrow} alt="" />  */}
                      </Col>
                      <Col xs="2">
                        <Media
                          className="triangle-wrapper"
                          src={wheelArrow}
                          alt=""
                        />
                      </Col>
                      <Col xs="5" className="bonus-page-block-button">
                        <Row className="spin-button-container">
                          <Col>
                            <Button
                              className="spin-button"
                              onClick={this.spinWheel}
                              disabled={spinning || !notSpined}
                            >
                              {t('bonusPage.spinWheel')}
                            </Button>
                          </Col>
                        </Row>
                        <Row className="bonus-page-block-left">
                          <Col xs="12" className="bonus-page-block-left-text">
                            {t('bonusPage.spinAndWin')}
                          </Col>
                          <Col xs="12" className="bonus-page-block-left-money">
                            <Media
                              className="bonus-page-block-left-money-image"
                              src={coinImg}
                              alt=""
                            />
                            <div className="bonus-page-block-left-money-text">
                              300
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    {(!notSpined && !spinning) ? (
                      <Row className="bonus-page-block-warn">
                        <Col sm="xs" className="bonus-page-block-left-text">
                          <div>{t('bonusPage.hoursLimitInfo1')}</div>
                          <div
                            style={{
                              marginTop: 5,
                              display: 'flex',
                              height: 20,
                              alignItems: 'center',
                            }}
                          >
                            <span>
                              {t('bonusPage.hoursLimitInfo2')}
                            </span>
                            <Countdown
                              date={new Date(Date.now() + offset).setHours(
                                23,
                                59,
                                59,
                              )}
                              now={() => Date.now() + offset}
                              renderer={props => (
                                <div className="spin-timer">
                                  {`${
                                    props.hours < 10
                                      ? `0${props.hours}`
                                      : props.hours
                                  }:${
                                    props.minutes < 10
                                      ? `0${props.minutes}`
                                      : props.minutes
                                  }:${
                                    props.seconds < 10
                                      ? `0${props.seconds}`
                                      : props.seconds
                                  }`}
                                </div>
                              )}
                              onComplete={() => {
                                this.forceUpdate();
                              }}
                            />
                          </div>
                          <div
                            style={{
                              marginTop: 5,
                              display: 'flex',
                              height: 20,
                              alignItems: 'center',
                            }}
                          >
                            <span style={{ marginRight: 3 }}>
                              {t('bonusPage.lastTimeSpinned')}
                            </span>
                            {lastBonusSpin && (
                              <CustomDate
                                format="DD.MM.YYYY hh:mm"
                                date={lastBonusSpin}
                              />
                            )}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                        <>
                          {devMode ? (
                            <Row className="bonus-page-block-warn bonus-page-block-button">
                              <Col xs="12" className="bonus-page-block-left">
                                <div className="bonus-page-block-left-text">
                                  {t('bonusPage.buyVIPDescription')}
                                </div>
                              </Col>

                              <Col xs="12" className="bonus-page-block-left">
                                <Button
                                  className="spin-button"
                                  onClick={() => changeTab(MENU_NAVIGATION.buyPagePremiumPacks)}
                                  disabled={spinning}
                                >
                                  {t('bonusPage.buyVIP')}
                                </Button>
                              </Col>
                            </Row>
                          ) : (null)}
                          </>
                    )}
                  </Fragment>
                ) : (
                  <Row>
                    <Col xs="5" className="bonus-page-block-complete-wheel">
                      <div style={{ display: 'block', height: 1 }}>
                        <Media className="wheel-bg" src={stand} alt="" />
                      </div>
                      {/*  <Media className="wheel-bg" src={stand} alt="" /> */}
                      <div className="wheel-wrapper" style={{ position: 'relative' }}>
                        <Media
                          className="wheel"
                            src={(vipUntil && vipUntil >= (Date.now() + offset)) ? wheelVip : wheel}
                          alt=""
                          style={{ transform: `rotate(${rotation}deg)` }}
                        />
                      </div>
                      {/*  <Media className="triangle-wrapper" src={wheelArrow} alt="" />  */}
                    </Col>
                    <Col xs="2">
                      <Media
                        className="triangle-wrapper"
                        src={wheelArrow}
                        alt=""
                      />
                    </Col>
                    <Col xs="5" className="bonus-page-block-complete">
                      <Row>
                        <Col
                          xs={{ size: 8, offset: 3 }}
                          className="bonus-page-block-complete-text"
                        >
                          {t('bonusPage.youReceivedBonus')}
                        </Col>
                        <Col
                          xs={{ size: 12, offset: 3 }}
                          className="bonus-page-block-complete-money"
                        >
                          <Media
                            className="bonus-page-block-complete-money-image"
                            src={coinImg}
                            alt=""
                          />
                          <div className="bonus-page-block-complete-money-text">
                            {spinResult}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          xs={{ size: 6, offset: 3 }}
                          className="bonus-page-block-complete-button"
                        >
                          <div className="accept-button-container">
                            <Button
                              className="accept-button"
                              onClick={this.confirmResult}
                            >
                              {t('bonusPage.okButton')}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>

          <Modal
            container={'div > div'}
            className={`friends-modal root-font-${fontMode} old-modal`}
            isOpen={openHistory}
            toggle={this.closeHistory}
          >
            <ModalHeader
              className="friends-modal-header"
              toggle={this.closeHistory}
              close={
                <Media
                  src={closeImg}
                  className="notification-header-close"
                  alt="X"
                  onClick={this.closeHistory}
                />
              }
            >
              {t('common.bonusHistory')}
            </ModalHeader>
            <ModalBody className="friends-modal-body">
              <Row className="money-history-table-header">
                <Col sm="1" />
                <Col sm="6" className="money-history-table-header-col">
                  {t('moneyHistory.time')}
                </Col>
                <Col sm="4" className="money-history-table-header-col">
                  {t('moneyHistory.bonus')}
                </Col>
                <Col sm="1" />
              </Row>
              <ScrollAreaWrapper
                className="money-history-table-scrollarea"
                contentClassName="money-history-table-body"
                show={historyData && size(historyData) > 8 ? true : null}
                rightOffset={12}
                topOffset={23}
                bottomOffset={-3}
                disableAutoHeight={historyData && size(historyData) === 0}
              >
                {console.log('bonushistory offset', offset)}
                {(historyData && Object.keys(historyData).length > 0) ? Object.keys(historyData).map((key, index) => (
                  <Row
                    key={key}
                    className={`top-table-row ${
                      index % 2 === 0 ? 'odd' : 'even'
                    }`}
                  >
                    <Col sm="1" />
                    <Col sm="6" className="money-history-table-col">
                      <CustomDate
                        format="DD.MM.YYYY hh:mm"
                        date={historyData[key].time}
                      />
                    </Col>
                    <Col sm="4" className="money-history-table-col">
                      {historyData[key].change}
                    </Col>
                    <Col sm="1" />
                  </Row>
                )) : <div className="bonus-page-no-entries d-flex text-center align-items-center">{t('bonusPage.dailyBonusHistoryEmptyEntries')}</div>}
              </ScrollAreaWrapper>
            </ModalBody>
            <ModalFooter className="friends-modal-footer">
              <Button
                color="link"
                className="my-info-header-button"
                style={{ width: '30%' }}
                onClick={this.closeHistory}
              >
                {t('common.close')}
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }

    return (
      <div className="bonus-page">
        {activeTab && activeTab === '4' ? (
          <Helmet>
            <title>{`Zole - ${t('route.bonusPage')}`}</title>
          </Helmet>
        ) : null}
        <Row>
          {/*  <Col sm="12" className="bonus-page-title">
            {t('bonusPage.dailyBonus')}
          </Col> */}
          <Col xs="12" className="bonus-page-block">
            <Row>
              <Col className="bonus-page-header">
                <Media src={bonusPageImg} className="bonus-page-header-image" />
                <div className="bonus-page-header-text">
                  {t('bonusPage.dailyBonus')}
                </div>
                {/*  <Button onClick={this.getBonusHistory}>
                  {t('common.history')}
                </Button> */}
                <div className="bonus-page-history-wrapper">
                  <NavLink
                    className="bonus-page-history-link"
                    onClick={this.getBonusHistory}
                  >
                    {t('common.bonusHistory')}
                  </NavLink>
                </div>
              </Col>
            </Row>
            <div className="bonus-page-block-container">
              <div className="bonus-page-block-background" />
              {!spinComplete ? (
                <Fragment>
                  <Row>
                    {!isFullscreen ? (
                      <Col xs="1" className="bonus-page-block-left" />
                    ) : null}
                    <Col xs="6" className="bonus-page-block-wheel">
                      <Media className="wheel-bg" src={stand} alt="" />
                      {/*  <Wheel rotation={rotation}>
                        <Media className="wheel" src={wheel} alt="" />
                      </Wheel> */}
                      <Media ref={this.wheelRef} id="bonus-page-wheel" className="bonus-page-wheel-old wheel" src={(vipUntil && vipUntil >= (Date.now() + offset)) ? wheelVip : wheel} alt="" />
                      <Media
                        className="triangle-wrapper"
                        src={wheelArrow}
                        alt=""
                      />
                      {/*  <div className="triangle-wrapper">
                      <div className="triangle" />
                      <div className="triangle2" />
                      <div className="triangle3" />
                    </div> */}
                    </Col>
                    <Col
                      xs={`${isFullscreen ? '6' : '4'}`}
                      className="bonus-page-block-button"
                    >
                      <Row className="spin-button-container">
                        <Col>
                          <Button
                            className="spin-button"
                            onClick={this.spinWheel}
                            disabled={spinning || !notSpined}
                          >
                            {t('bonusPage.spinWheel')}
                          </Button>
                        </Col>
                      </Row>
                      <Row className="bonus-page-block-left">
                        <Col xs="12" className="bonus-page-block-left-text">
                          {t('bonusPage.spinAndWin')}
                        </Col>
                        <Col xs="12" className="bonus-page-block-left-money">
                          <Media
                            className="bonus-page-block-left-money-image"
                            src={coinImg}
                            alt=""
                          />
                          <div className="bonus-page-block-left-money-text">
                            300
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {(!notSpined && !spinning) ? (
                    <Row className="bonus-page-block-warn">
                      <Col sm="xs" className="bonus-page-block-left-text">
                        {t('bonusPage.hoursLimit')}
                        {/*  Dienas bonusu varat saņemt ne biežāk kā reizi 24 stundās. Lai iegrieztu ratu atkārtoti, ir jāgaida vēl  */}
                        <Countdown
                          date={new Date(Date.now() + offset).setHours(
                            23,
                            59,
                            59,
                          )}
                          now={() => Date.now() + offset}
                          //  zeroPadTime={2}
                          renderer={props => (
                            <div className="spin-timer">
                              {`${
                                props.hours < 10
                                  ? `0${props.hours}`
                                  : props.hours
                              }:${
                                props.minutes < 10
                                  ? `0${props.minutes}`
                                  : props.minutes
                              }:${
                                props.seconds < 10
                                  ? `0${props.seconds}`
                                  : props.seconds
                              }`}
                            </div>
                          )}
                          onComplete={() => {
                            this.forceUpdate();
                          }}
                        />
                      </Col>
                    </Row>
                  ) : (
                      <>
                        {devMode ? (
                          <Row className="bonus-page-block-warn bonus-page-block-button">
                            <Col xs="12" className="bonus-page-block-left">
                              <div className="bonus-page-block-left-text">
                                {t('bonusPage.buyVIPDescription')}
                              </div>
                            </Col>

                            <Col xs="12" className="bonus-page-block-left">
                              <Button
                                className="spin-button"
                                onClick={() => changeTab(MENU_NAVIGATION.buyPagePremiumPacks)}
                                disabled={spinning}
                              >
                                {t('bonusPage.buyVIP')}
                              </Button>
                            </Col>
                          </Row>
                        ) : (null)}
                      </>
                  )}
                </Fragment>
              ) : (
                <Row>
                  {!isFullscreen ? (
                    <Col xs="1" className="bonus-page-block-left" />
                  ) : null}
                  <Col xs="6" className="bonus-page-block-complete-wheel">
                    <Media className="wheel-bg" src={stand} alt="" />
                    <Media
                      className="wheel"
                      src={(vipUntil && vipUntil >= (Date.now() + offset)) ? wheelVip : wheel}
                      alt=""
                      style={{ transform: `rotate(${rotation}deg)` }}
                    />
                    <Media className="triangle-wrapper" src={wheelArrow} alt="" />
                    {/*  <div className="triangle-wrapper">
                      <div className="triangle" />
                      <div className="triangle2" />
                      <div className="triangle3" />
                    </div> */}
                  </Col>
                  <Col xs="4" className="bonus-page-block-complete">
                    <Row>
                      <Col
                        xs={{ size: 8, offset: 3 }}
                        className="bonus-page-block-complete-text"
                      >
                        {t('bonusPage.youReceivedBonus')}
                      </Col>
                      <Col
                        xs={{ size: 12, offset: 3 }}
                        className="bonus-page-block-complete-money"
                      >
                        <Media
                          className="bonus-page-block-complete-money-image"
                          src={coinImg}
                          alt=""
                        />
                        <div className="bonus-page-block-complete-money-text">
                          {spinResult}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs={{ size: 6, offset: 3 }}
                        className="bonus-page-block-complete-button"
                      >
                        <div className="accept-button-container">
                          <Button
                            className="accept-button"
                            onClick={this.confirmResult}
                          >
                            {t('bonusPage.okButton')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {/*  <Col sm="3" className="bonus-page-block-complete-button">
                  <div className="accept-button-container">
                    <Button className="accept-button" onClick={this.confirmResult}>
                      {t('bonusPage.okButton')}
                    </Button>
                  </div>
                  </Col> */}
                </Row>
              )}
            </div>
          </Col>
        </Row>
        {/*! spinComplete && (
          <Row style={{ position: 'absolute', width: '100%' }}>
            <Col>
              <InviteFriend member={member} />
            </Col>
          </Row>
        ) */}
        <Modal
          container={'div > div'}
          className={`friends-modal root-font-${fontMode} old-modal`}
          isOpen={openHistory}
          toggle={this.closeHistory}
        >
          <ModalHeader
            className="friends-modal-header"
            toggle={this.closeHistory}
            close={
              <Media
                src={closeImg}
                className="notification-header-close"
                alt="X"
                onClick={this.closeHistory}
              />
            }
          >
            {t('common.bonusHistory')}
          </ModalHeader>
          <ModalBody className="friends-modal-body">
            <Row className="money-history-table-header">
              <Col sm="1" />
              <Col sm="6" className="money-history-table-header-col">
                {t('moneyHistory.time')}
              </Col>
              <Col sm="4" className="money-history-table-header-col">
                {t('moneyHistory.bonus')}
              </Col>
              <Col sm="1" />
            </Row>
            {historyData
              ? Object.keys(historyData).map((key, index) => (
                <Row
                  key={key}
                  className={`top-table-row ${
                    index % 2 === 0 ? 'odd' : 'even'
                  }`}
                >
                  <Col sm="1" />
                  <Col sm="6" className="money-history-table-col">
                    <CustomDate
                      format="DD.MM.YYYY hh:mm"
                      date={historyData[key].time}
                    />
                  </Col>
                  <Col sm="4" className="money-history-table-col">
                    {historyData[key].change}
                  </Col>
                  <Col sm="1" />
                </Row>
              )) : null}
          </ModalBody>
          <ModalFooter className="friends-modal-footer">
            <Button
              color="link"
              className="my-info-header-button"
              style={{ width: '30%' }}
              onClick={this.closeHistory}
            >
              {t('common.close')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  //  userSettings: state.userSettings || {},
  // userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  soundOn: state.userSettings.soundOn,
  // rooms: state.rooms.rooms || {},
  offset: state.member.offset,
  lastBonusSpin: state.member.lastBonusSpin,
  uid: state.member.uid || '',
  vipUntil: state.member.vipUntil,
});

const mapDispatchToProps = {
  spinWheel: spinBonusWheel,
  claimSpin: claimSpinResults,
  getOffset: getTimeOffset,
  getBonusHistory: getDailyBonusHistory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('common')(BonusPage));
