import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Collapse, Nav, NavItem, Button } from 'reactstrap';
import NavLink from 'reactstrap/lib/NavLink';
import Media from 'reactstrap/lib/Media';
import * as constants from '../../../../../../constants/constants';

import LogoCompact from '../../../../../../images/redesign/mobile/icons/logo-compact.svg';
import LogoExpanded from '../../../../../../images/redesign/mobile/icons/logo-expanded.svg';

import CreateRoomIcon from '../../../../../../images/redesign/mobile/icons/create-room-button-icon.svg';
import FilterIcon from '../../../../../../images/redesign/rooms-table/filterIcon.svg';
import FilterLightIcon from '../../../../../../images/redesign/light-mode/rooms-table/filterIcon.png';
import InfoIcon from '../../../../../../images/redesign/icons/info.svg';
import InfoLightIcon from '../../../../../../images/redesign/light-mode/icons/info.png';


import roomsTabIcon from '../../../../../../images/redesign/mobile/icons/rooms-menu-icon.svg';
import roomsActiveTabIcon from '../../../../../../images/redesign/mobile/icons/rooms-menu-active-icon.svg';
import TopTabIcon from '../../../../../../images/redesign/mobile/icons/top-menu-icon.svg';
import TopActiveTabIcon from '../../../../../../images/redesign/mobile/icons/top-menu-active-icon.svg';
import PowerRankingsTabIcon from '../../../../../../images/redesign/mobile/icons/power-ranking-menu-icon.svg';
import PowerRankingsActiveTabIcon from '../../../../../../images/redesign/mobile/icons/power-ranking-menu-active-icon.svg';
import MyInfoTabIcon from '../../../../../../images/redesign/mobile/icons/my-info-menu-icon.svg';
import MyInfoActiveTabIcon from '../../../../../../images/redesign/mobile/icons/my-info-menu-active-icon.svg';

import MyInfoMenuMainIcon from '../../../../../../images/redesign/mobile/icons/my-info-menu-main-icon.svg';
import MyInfoMenuMainLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/my-info-menu-main-icon.svg';
import MyInfoMenuMainActive from '../../../../../../images/redesign/mobile/icons/my-info-menu-main-active-icon.svg';
import MyInfoMenuMainActiveLight from '../../../../../../images/redesign/mobile/light-mode/icons/my-info-menu-main-active-icon.svg';

import MyFriendIcon from '../../../../../../images/redesign/mobile/icons/my-friend-icon.svg';
import MyFriendLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/my-friend-icon.svg';
import MyFriendActiveIcon from '../../../../../../images/redesign/mobile/icons/my-friend-active-icon.svg';
import MyFriendActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/my-friend-active-icon.svg';

import IgnoreIcon from '../../../../../../images/redesign/mobile/icons/ignore-icon.svg';
import IgnoreLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/ignore-icon.svg';
import IgnoreActiveIcon from '../../../../../../images/redesign/mobile/icons/ignore-active-icon.svg';
import IgnoreActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/ignore-active-icon.svg';

import AchievementsIcon from '../../../../../../images/redesign/mobile/icons/achievements-icon.svg';
import AchievementsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/achievements-icon.svg';
import AchievementsActiveIcon from '../../../../../../images/redesign/mobile/icons/achievements-active-icon.svg';
import AchievementsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/achievements-active-icon.svg';

import GameResultsIcon from '../../../../../../images/redesign/mobile/icons/game-results-icon.svg';
import GameResultsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/game-results-icon.svg';
import GameResultsActiveIcon from '../../../../../../images/redesign/mobile/icons/game-results-active-icon.svg';
import GameResultsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/game-results-active-icon.svg';

import WeeklyStatisticsIcon from '../../../../../../images/redesign/mobile/icons/weekly-statistics-icon.svg';
import WeeklyStatisticsActiveIcon from '../../../../../../images/redesign/mobile/icons/weekly-statistics-active-icon.svg';
import WeeklyStatisticsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/weekly-statistics-icon.svg';
import WeeklyStatisticsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/weekly-statistics-active-icon.svg';

import GameLogsIcon from '../../../../../../images/redesign/mobile/icons/game-logs-icon.svg';
import GameLogsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/game-logs-icon.svg';
import GameLogsActiveIcon from '../../../../../../images/redesign/mobile/icons/game-logs-active-icon.svg';
import GameLogsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/game-logs-active-icon.svg';

import MoneyHistoryIcon from '../../../../../../images/redesign/mobile/icons/money-history-icon.svg';
import MoneyHistoryLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/money-history-icon.svg';
import PointsHistoryIcon from '../../../../../../images/redesign/mobile/icons/points-history-icon.svg';
import PointsHistoryLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/points-history-icon.svg';

import TournamentsTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-menu-icon.svg';
import TournamentsActiveSubTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-active-menu-icon.svg';//
import TournamentsFutureSubTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-future-menu-icon.svg';
import TournamentsHistorySubTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-history-menu-icon.svg';
import tutorialImage from '../../../../../../images/icons/help2.svg';

import TournamentsActiveTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-menu-active-icon.svg';
import TournamentsHistorySubActiveTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-history-active-menu-icon.svg';
import TournamentsFutureSubActiveTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-future-active-menu-icon.svg';
import TournamentsActiveSubActiveTabIcon from '../../../../../../images/redesign/mobile/icons/tournaments-active-active-menu-icon.svg';

import PowerRankingsCoinsIcon from '../../../../../../images/redesign/mobile/icons/pw-coins-icon.svg';
import PowerRankingsCoinsActiveIcon from '../../../../../../images/redesign/mobile/icons/pw-coins-active-icon.svg';
import PowerRankingsCoinsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-coins-icon.svg';
import PowerRankingsCoinsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-coins-active-icon.svg';

import PowerRankingsPointsIcon from '../../../../../../images/redesign/mobile/icons/pw-points-icon.svg';
import PowerRankingsPointsActiveIcon from '../../../../../../images/redesign/mobile/icons/pw-points-active-icon.svg';
import PowerRankingsPointsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-points-icon.svg';
import PowerRankingsPointsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-points-active-icon.svg';

import PowerRankingsRatingIcon from '../../../../../../images/redesign/mobile/icons/pw-rating-icon.svg';
import PowerRankingsRatingActiveIcon from '../../../../../../images/redesign/mobile/icons/pw-rating-active-icon.svg';
import PowerRankingsRatingLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-rating-icon.svg';
import PowerRankingsRatingActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-rating-active-icon.svg';

import PowerRankingsWinPercentIcon from '../../../../../../images/redesign/mobile/icons/pw-win-percent-icon.svg';
import PowerRankingsWinPercentActiveIcon from '../../../../../../images/redesign/mobile/icons/pw-win-percent-active-icon.svg';
import PowerRankingsWinPercentLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-win-percent-icon.svg';
import PowerRankingsWinPercentActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-win-percent-active-icon.svg';

import PowerRankingsWinsIcon from '../../../../../../images/redesign/mobile/icons/pw-wins-icon.svg';
import PowerRankingsWinsActiveIcon from '../../../../../../images/redesign/mobile/icons/pw-wins-active-icon.svg';
import PowerRankingsWinsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-wins-icon.svg';
import PowerRankingsWinsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/pw-wins-active-icon.svg';

import roomsTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/rooms-menu-icon.svg';
import TopTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-menu-icon.svg';
import PowerRankingsTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/power-ranking-menu-icon.svg';
import MyInfoTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/my-info-menu-icon.svg';
import TournamentsTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/tournaments-menu-icon.svg';
import humanSmallImg from '../../../../../../images/redesign/tutorial/human-small.png';
import gearIcon from '../../../../../../images/icons/gear.svg';
import gearIconLight from '../../../../../../images/redesign/mobile/light-mode/icons/gear light.png';

import { AiOutlineMore } from "react-icons/ai";
import lightInfoImg from '../../../../../../images/redesign/light-mode/player/info.svg';

import TournamentsActiveSubTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/tournaments-active-menu-icon.svg';
import TournamentsFutureSubTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/tournaments-future-menu-icon.svg';
import TournamentsHistorySubTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/tournaments-history-menu-icon.svg';

import TournamentsHistorySubActiveTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/tournaments-history-active-menu-icon.svg';
import TournamentsFutureSubActiveTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/tournaments-future-active-menu-icon.svg';
import TournamentsActiveSubActiveTabLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/tournaments-active-active-menu-icon.svg';

import TopFilterIcon from '../../../../../../images/redesign/mobile/icons/top-filter-dark-icon.svg';
import TopFilterActiveIcon from '../../../../../../images/redesign/mobile/icons/top-filter-active-dark-icon.svg';
import TopFilterLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-filter-light-icon.svg';
import TopFilterActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-filter-active-light-icon.svg';

import TopTimeIcon from '../../../../../../images/redesign/mobile/icons/top-time-dark-icon.svg';
import TopTimeActiveIcon from '../../../../../../images/redesign/mobile/icons/top-time-active-dark-icon.svg';
import TopTimeLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-time-light-icon.svg';
import TopTimeActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-time-active-light-icon.svg';

import TopMillionairesIcon from '../../../../../../images/redesign/mobile/icons/top-millionaires-dark-icon.svg';
import TopMillionairesActiveIcon from '../../../../../../images/redesign/mobile/icons/top-millionaires-active-dark-icon.svg';
import TopMillionairesLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-millionaires-light-icon.svg';
import TopMillionairesActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-millionaires-active-light-icon.svg';

import TopNoOlderThanMeIcon from '../../../../../../images/redesign/mobile/icons/top-no-older-than-me-dark-icon.svg';
import TopNoOlderThanMeActiveIcon from '../../../../../../images/redesign/mobile/icons/top-no-older-than-me-active-dark-icon.svg';
import TopNoOlderThanMeLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-no-older-than-me-light-icon.svg';
import TopNoOlderThanMeActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-no-older-than-me-active-light-icon.svg';

import TopActiveIcon from '../../../../../../images/redesign/mobile/icons/top-active-players-dark-icon.svg';
import TopActiveActiveIcon from '../../../../../../images/redesign/mobile/icons/top-active-players-active-dark-icon.svg';
import TopActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-active-players-light-icon.svg';
import TopActiveActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-active-players-active-light-icon.svg';

import TopSearchIcon from '../../../../../../images/redesign/mobile/icons/top-search-dark-icon.svg';
import TopSearchActiveIcon from '../../../../../../images/redesign/mobile/icons/top-search-active-dark-icon.svg';
import TopSearchLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-search-light-icon.svg';
import TopSearchActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/top-search-active-light-icon.svg';

import MoneyPacksIcon from '../../../../../../images/redesign/mobile/icons/money-packs-icon.svg';//
import MoneyPacksActiveIcon from '../../../../../../images/redesign/mobile/icons/money-packs-active-dark-icon.svg';//
import MoneyPacksLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/money-packs-icon.svg';//
import MoneyPacksActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/money-packs-active-light-icon.svg';//

import PremiumPacksIcon from '../../../../../../images/redesign/mobile/icons/premium-packs-icon.svg';//
import PremiumPacksActiveIcon from '../../../../../../images/redesign/mobile/icons/premium-packs-active-dark-icon.svg';//
import PremiumPacksLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/premium-packs-icon.svg';//
import PremiumPacksActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/premium-packs-active-light-icon.svg';//

import RedeemCodeIcon from '../../../../../../images/redesign/mobile/icons/redeem-code-icon.svg';//
import RedeemCodeActiveIcon from '../../../../../../images/redesign/mobile/icons/redeem-code-active-dark-icon.svg';//
import RedeemCodeLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/redeem-code-icon.svg';//
import RedeemCodeActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/redeem-code-active-light-icon.svg';//

import ShopHistoryIcon from '../../../../../../images/redesign/mobile/icons/shop-history-icon.svg';//
import ShopHistoryActiveIcon from '../../../../../../images/redesign/mobile/icons/shop-history-active-dark-icon.svg';//
import ShopHistoryLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/shop-history-icon.svg';//
import ShopHistoryActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/shop-history-active-light-icon.svg';//

import AboutUsIcon from '../../../../../../images/redesign/mobile/icons/about-us-icon.svg';
//import AboutUsActiveIcon from '../../../../../../images/redesign/mobile/icons/about-us-active-dark-icon.svg';
import AboutUsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/about-us-icon.svg';
//import AboutUsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/about-us-active-light-icon.svg';
import BlockedPlayersIcon from '../../../../../../images/redesign/mobile/icons/blocked-players-icon.svg';
//import BlockedPlayersActiveIcon from '../../../../../../images/redesign/mobile/icons/blocked-players-active-dark-icon.svg';
import BlockedPlayersLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/blocked-players-icon.svg';
//import BlockedPlayersActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/blocked-players-active-light-icon.svg';
import BlogIcon from '../../../../../../images/redesign/mobile/icons/blog-icon.svg';
//import BlogActiveIcon from '../../../../../../images/redesign/mobile/icons/blog-active-dark-icon.svg';
import BlogLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/blog-icon.svg';
//import BlogActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/blog-active-light-icon.svg';
import ContactsIcon from '../../../../../../images/redesign/mobile/icons/contacts-icon.svg';
//import ContactsActiveIcon from '../../../../../../images/redesign/mobile/icons/contacts-active-dark-icon.svg';
import ContactsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/contacts-icon.svg';
//import ContactsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/contacts-active-light-icon.svg';
import GameHelpIcon from '../../../../../../images/redesign/mobile/icons/game-help-icon.svg';
//import GameHelpActiveIcon from '../../../../../../images/redesign/mobile/icons/game-help-active-dark-icon.svg';
import GameHelpLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/game-help-icon.svg';
//import GameHelpActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/game-help-active-light-icon.svg';
import TermsIcon from '../../../../../../images/redesign/mobile/icons/terms-icon.svg';
//import TermsActiveIcon from '../../../../../../images/redesign/mobile/icons/terms-active-dark-icon.svg';
import TermsLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/terms-icon.svg';
//import TermsActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/terms-active-light-icon.svg';
import VersionHistoryIcon from '../../../../../../images/redesign/mobile/icons/version-history-icon.svg';
//import VersionHistoryActiveIcon from '../../../../../../images/redesign/mobile/icons/version-history-active-dark-icon.svg';
import VersionHistoryLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/version-history-icon.svg';
//import VersionHistoryActiveLightIcon from '../../../../../../images/redesign/mobile/light-mode/icons/version-history-active-light-icon.svg';

import dailyBonusButton from '../../../../../../images/redesign/Daily bonus button small.svg';
import shopButton from '../../../../../../images/redesign/Shop button small.png';
import chatButton from '../../../../../../images/redesign/Chat button small.svg';
import chatButtonLight from '../../../../../../images/redesign/mobile/light-mode/icons/Chat button small light.png';

import defaultImage from '../../../../../../images/redesign/common/default_image.svg';
import lightDefaultImage from '../../../../../../images/redesign/light-mode/common/default_image.svg';
import coinImg from '../../../../../../images/redesign/common/coin.svg';
import ratingImg from '../../../../../../images/redesign/common/rating.svg';

import UpdateProfile from '../../../User/UpdateProfile';
import { capitalizeFirstLetter } from '../../../../../../utils/stringUtils';
import { setOpenRoomsTab, setOpenTopTab, setOpenTournamentsTutorial, setPrepareForJoyride } from '../../../../../../actions/state';
import config from '../../../../../../constants/config';
import { getTournamentsWithOpenRegistration } from '../../../../../../utils/tournamentUtils';
import { getMostRecentSubObject } from '../../../../../../utils/objUtils';

import { isMobileDevice } from '../../../../../../utils/deviceUtils';
import { useNavBarHeight } from '../../../../Hooks/UseNavBarHeight';
import { addPaymentInfo } from '../../../../../../actions/shop';

const LayoutMobileHeader = React.memo(({
  activeTab: activeTabParent,
  //collapsedTab,
  toggle: toggleParent,
  //activeTabNoContent,
  //toggleNoContent,
  t,
  achievementsNotification,
  hasActiveRegistration,
  showUserSettingsModal,
  screenMode,
  openCreate,
  disableCreate,
  openFilter,
  openInfo,
  handleClickStart,
  OpenMobileDropdownModal,
  toggleShowTournamentTutorial,
  supportChatStatus,
  toggleTypeFilter,
  typeFilterOpen,
  activeType,
  topActiveTab,
  toggleType,
  showFields,
  toggleMillionaires,
  millionairesOpen,
  showOnlyActive,
  toggleShowActive,
  toggleSearch,
  searchOpen,
  filters,
  handleSearchValue,
  handleKeyPress,
  searchValue,
  toggleTopFilter,
  toggleTopTime,
  typeTimeOpen,
  toggleTime,
  fetchLeaderboardSearchFunc,
  member,
  activeTournament,
  openCreateRoom,
  setOpenRoomsTabFunc,
  setOpenTopTabFunc,
  setOpenTournamentsTutorialFunc,
  openTopTab,
  toggleBottomBarTab,
  lastBonusSpin,
  offset,
  joyrideActive,
  i18n,
  tournaments,
  pwOption,
  setSupportAsRead,
  playButtonSound,
  prepareForJoyride,
  setPrepareForJoyrideFunc,
  closeNoContentTabs,
  setCloseNoContentTabsFunc,
  toggleSupport,
  gifts,
  activeGifts,
  expandedLandscapeSidebar,
  uid,
  name,
  setAddPaymentInfo
}) => {
  const navBarHeight = useNavBarHeight();
  const lastGiftId = getMostRecentSubObject(member.giftsHistory, 'date')?.giftId;
  const lowBalance = member.balance >= constants.PLAYER_ZOLE_RANGE.maxmin && member.balance <= constants.PLAYER_ZOLE_RANGE.min;
  const negativeBalance = member.balance < constants.PLAYER_ZOLE_RANGE.maxmin;

  const [createRoomHover, setCreateRoomHover] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);


  const [activeTabNoContent, setActiveTabNoContent] = useState('');
  const [collapsedTab, setCollapsedTab] = useState(false);

  const isInAppFrame = config.isInAppFrame();
  const menuRef = useRef(null);

  const joyrideMenuOverlayStyle = { position: 'absolute', left: -6, top: 0, right: -6, bottom: 0, pointerEvents: 'none' };

  useEffect(() => {
    if (prepareForJoyride) {
      setActiveTabNoContent('');
      setPrepareForJoyrideFunc(false);
      setCollapsedTab(false);
    }
  }, [prepareForJoyride]);

  useEffect(() => {
    if (menuRef.current) {
      if (activeTabNoContent === constants.MENU_NAVIGATION.more) {
        menuRef.current.scrollTop = menuRef.current.scrollHeight; // scroll to bottom, when 'More...' submenu is opened
      }
    }
  }, [activeTabNoContent]);

  const openLink = (link, isInAppFrame) => {
    const langQuery = `?lang=${i18n.language}`;

    const devMode = window.location.hostname == 'fb.dev.spelezoli.lv' || window.location.hostname == 'dra.dev.spelezoli.lv' || window.location.hostname == 'inbox.dev.spelezoli.lv';
    window.open(!isInAppFrame
        ? link + langQuery
        : devMode ? ('https://dev.spelezoli.lv' + link + langQuery) : ('https://spelezoli.lv' + link + langQuery),
        '_blank'
    );
  }

  const notSpinedLuckyWheel = (lastBonusSpin && new Date(lastBonusSpin).getDate() !== new Date(Date.now() + offset).getDate()) || (!lastBonusSpin);

  const [tournamentsTutorialPopoverOpen, setTournamentsTutorialPopoverOpen] = useState(false);

  const toggle = (tab) => {
    if(activeTabParent !== tab) {
      toggleParent(tab);
      setCollapsedTab(false);
      setActiveTabNoContent('');
    } else {
      setCollapsedTab(!collapsedTab);
      setActiveTabNoContent('');
    }
  }

  const toggleNoContent = (tab) => {
    setActiveTabNoContent(tab);
    if(activeTabNoContent !== tab) {
      setActiveTabNoContent(tab);
      setCollapsedTab(true);
    } else {
      setActiveTabNoContent('');
    }
  }

  const handleToBuyPage = () => {
    toggle(constants.MENU_NAVIGATION.buyPageMoneyPacks);

    setAddPaymentInfo({
      uid,
      name,
      source: constants.PAYMENT_SOURCE.shop,
    });
  };

  return (
    <div className="landscape-layout-mobile-header d-none" style={{top: 'unset'}} onMouseEnter={() => setSidebarOpen(true)} onMouseLeave={() => setSidebarOpen(false)}>
      {/** style={{width: 50, overflow: 'hidden'}} keeps joyride from glitching siderbar */}
      <div ref={menuRef} className={`landscape-layout-mobile-header-wrapper ${joyrideActive && 'landscape-layout-mobile-header-wrapper-joyride'} ${expandedLandscapeSidebar && 'landscape-layout-mobile-header-wrapper-expanded'}`} style={joyrideActive ? {width: 50, overflow: 'hidden', pointerEvents: 'none'} : {}}>
        <div className="background-fade" />
        <div className="logo-wrapper" onClick={() => openLink('/', isInAppFrame)}>
          <Media src={LogoCompact} className='logo' alt='logo' />
          <Media src={LogoExpanded} className='logo-expanded' alt='logo' />
        </div>
        <div className='landscape-layout-mobile-header-links'>
          <div className={`landscape-layout-mobile-header-link-item ${(activeTabParent === constants.MENU_NAVIGATION.rooms && !activeTabNoContent) ? 'landscape-layout-mobile-header-link-item-active' : ''}`}>
            <NavLink className='layout-header-link' onClick={() => { toggle(constants.MENU_NAVIGATION.rooms); }}>
              <div className="item-wrapper">
                <div className={`item-img item-img-rooms-${screenMode} ${(activeTabParent === constants.MENU_NAVIGATION.rooms && !activeTabNoContent) && `item-img-rooms-active-${screenMode}`}`} />
                <div className={`item-text item-text-${screenMode} ${(activeTabParent === constants.MENU_NAVIGATION.rooms && !activeTabNoContent) && 'item-text-active'}`}>{(t('common:menu.rooms')).toUpperCase()}</div>
              </div>
            </NavLink>
            { !collapsedTab && (activeTabParent === constants.MENU_NAVIGATION.rooms && !activeTabNoContent) &&
              <Collapse className='rooms-tab-collapse' isOpen={(activeTabParent === constants.MENU_NAVIGATION.rooms && !activeTabNoContent)} navbar>
                <Nav navbar>
                  <NavItem>
                    <div
                      onMouseOver={() => setCreateRoomHover(true)} onMouseOut={() => setCreateRoomHover(false)} id="create-room-landscape" className="create-room-landscape-wrapper"
                    >
                      <Button
                        type='button'
                        className='btn create-room-button'
                        id="create-room-button-landscape-id"
                        onClick={() => setOpenRoomsTabFunc(constants.ROOMS_NAVIGATION.createRoom)}
                        disabled={!!activeTournament || (Object.keys(member.joinedRooms || {})).length > 0}
                      >
                        <Media src={CreateRoomIcon} className={`create-room-button-icon create-room-button-icon-${screenMode}`} alt='create-room-button-icon' />
                        <div className="create-room-button-text">{t('common:roomsTable.createRoom')}</div>
                      </Button>

                      {activeTournament && (
                        <div className="create-room-popover">
                          <div className="create-room-popover-body">
                            {t('common.inTournamentMessage')}
                          </div>
                          <div className="create-room-popover-arrow" />
                        </div>
                      )}
                    </div>
                  </NavItem>

                  <NavItem>
                    <div className="nav-item-wrapper" onClick={() => setOpenRoomsTabFunc(constants.ROOMS_NAVIGATION.filterRooms)}>
                      <Media src={screenMode == constants.SCREEN_MODE.light ? FilterLightIcon : FilterIcon} className='filter-icon nav-item-img-14' alt='filter-icon' />
                      <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:roomsTable.filterRooms')}</div>
                    </div>
                  </NavItem>
                  <NavItem>
                    <div className="nav-item-wrapper" onClick={() => setOpenRoomsTabFunc(constants.ROOMS_NAVIGATION.roomLegends)}>
                      <Media src={screenMode == constants.SCREEN_MODE.light ? InfoLightIcon : InfoIcon} className='info-icon nav-item-img-14' alt='info' />
                      <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:roomsTable.roomInfo')}</div>
                    </div>
                  </NavItem>
                </Nav>
              </Collapse>
            }
          </div>
        </div>
        <div className="landscape-layout-mobile-header-links">
          <div className={`landscape-layout-mobile-header-link-item ${(activeTabParent === constants.MENU_NAVIGATION.top && !activeTabNoContent) ? 'landscape-layout-mobile-header-link-item-active' : ''}`}>
            <NavLink className="layout-header-link" onClick={() => { toggle(constants.MENU_NAVIGATION.top); }} style={{ position: 'relative '}}>
              <div className="top-menu-item-wrapper-overlay" id="top-menu-item-wrapper-overlay-landscape-id" style={joyrideMenuOverlayStyle} />
              <div className="item-wrapper">
                <div className={`item-img item-img-top-${screenMode} ${(activeTabParent === constants.MENU_NAVIGATION.top && !activeTabNoContent) && `item-img-top-active-${screenMode}`}`} />
                <div className={`item-text item-text-${screenMode} ${(activeTabParent === constants.MENU_NAVIGATION.top && !activeTabNoContent) && 'item-text-active'}`}>{t('common:top.top').toUpperCase()}</div>
              </div>
            </NavLink>
            { !collapsedTab && (activeTabParent === constants.MENU_NAVIGATION.top && !activeTabNoContent) &&
              <Collapse className="top-tab-collapse" isOpen={(activeTabParent === constants.MENU_NAVIGATION.top && !activeTabNoContent)} navbar>
                <Nav navbar>
                  <NavItem onClick={() => setOpenTopTabFunc(constants.TOP_NAVIGATION.filter)} style={{ position: 'relative '}}>
                    <div className="top-filter-menu-item-wrapper-overlay" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper top-filter-menu-item-wrapper">
                      <Media src={screenMode === constants.SCREEN_MODE.light ? TopFilterLightIcon : TopFilterIcon} className="top-filter nav-item-img-22" alt="top-filter" />
                      <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:top.filter')}</div>
                    </div>
                  </NavItem>

                  {(!isInAppFrame && (isMobileDevice())) &&
                    <Fragment>
                      <NavItem onClick={() => setOpenTopTabFunc(constants.TOP_NAVIGATION.timePeriod)}>
                        <div className="nav-item-wrapper">
                          <Media src={screenMode === constants.SCREEN_MODE.light ? TopTimeLightIcon : TopTimeIcon} className="top-time nav-item-img-22" alt="top-time" />
                          <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:top.timePeriod2')}</div>
                        </div>
                      </NavItem>
                      <NavItem onClick={() => setOpenTopTabFunc(constants.TOP_NAVIGATION.onlyMillionaires)} style={{ position: 'relative '}}>
                        <div className="top-millionaires-menu-item-wrapper-overlay" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                        <div className="nav-item-wrapper top-millionaires-joyride">
                          <Media
                            src={screenMode === constants.SCREEN_MODE.light ? (
                              openTopTab === constants.TOP_NAVIGATION.onlyMillionaires ? TopMillionairesActiveLightIcon : TopMillionairesLightIcon
                            ) : (
                              openTopTab === constants.TOP_NAVIGATION.onlyMillionaires ? TopMillionairesActiveIcon : TopMillionairesIcon
                            )}
                            className="top-millionaires nav-item-img-22"
                            alt="top-millionaires"
                          />
                          <div className={`nav-item-text nav-item-text-${screenMode} ${openTopTab === constants.TOP_NAVIGATION.onlyMillionaires && 'nav-item-text-active'}`}>{t('common:top.onlyMillionaires')}</div>
                        </div>
                      </NavItem>
                      <NavItem onClick={() => setOpenTopTabFunc(constants.TOP_NAVIGATION.onlyActive)}>
                        <div className="nav-item-wrapper">
                          <Media
                            src={screenMode === constants.SCREEN_MODE.light ? (
                              openTopTab === constants.TOP_NAVIGATION.onlyActive ? TopActiveActiveLightIcon : TopActiveLightIcon
                            ) : (
                              openTopTab === constants.TOP_NAVIGATION.onlyActive ? TopActiveActiveIcon : TopActiveIcon
                            )}
                            className="top-active nav-item-img-22"
                            alt="top-active"
                          />
                          <div className={`nav-item-text nav-item-text-${screenMode} ${openTopTab === constants.TOP_NAVIGATION.onlyActive && 'nav-item-text-active'}`}>{t('common:top.onlyActive')}</div>
                        </div>
                      </NavItem>
                      <NavItem onClick={() => setOpenTopTabFunc(constants.TOP_NAVIGATION.onlyNoOlderThanMe)}>
                        <div className="nav-item-wrapper">
                          <Media
                            src={screenMode === constants.SCREEN_MODE.light ? (
                              openTopTab === constants.TOP_NAVIGATION.onlyNoOlderThanMe ? TopNoOlderThanMeActiveLightIcon : TopNoOlderThanMeLightIcon
                            ) : (
                              openTopTab === constants.TOP_NAVIGATION.onlyNoOlderThanMe ? TopNoOlderThanMeActiveIcon : TopNoOlderThanMeIcon
                            )}
                            className="top-active nav-item-img-22"
                            alt="top-active"
                          />
                          <div className={`nav-item-text nav-item-text-${screenMode} ${openTopTab === constants.TOP_NAVIGATION.onlyNoOlderThanMe && 'nav-item-text-active'}`}>{t('common:top.showNoOlderMe')}</div>
                        </div>
                      </NavItem>
                    </Fragment>
                  }

                  <NavItem onClick={() => setOpenTopTabFunc(constants.TOP_NAVIGATION.search)} style={{ position: 'relative '}}>
                    <div className="top-search-menu-item-wrapper-overlay" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper top-search-menu-item-wrapper" id="top-search-menu-item-wrapper-id">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          filters?.searchValue ? TopSearchActiveLightIcon : TopSearchLightIcon
                        ) : (
                          filters?.searchValue ? TopSearchActiveIcon : TopSearchIcon
                        )}
                        className="top-search nav-item-img-top-search"
                        alt="top-search"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:top.search')}</div>
                    </div>
                  </NavItem>
                </Nav>
              </Collapse>
            }
          </div>
        </div>
        <div className='landscape-layout-mobile-header-links'>
          <div className={`landscape-layout-mobile-header-link-item ${(constants.POWER_RANKINGS_TABS.includes(activeTabParent) && !activeTabNoContent) ? 'landscape-layout-mobile-header-link-item-active' : ''}`}>
            <NavLink className='layout-header-link' onClick={() => { toggle(constants.MENU_NAVIGATION.powerRankings); }} style={{ position: 'relative' }}>
              <div className="power-rank-menu-item-wrapper-overlay" id="power-rank-menu-item-wrapper-overlay-id" style={joyrideMenuOverlayStyle} />

              <div className="item-wrapper power-rank-menu-item-wrapper" id="power-rank-menu-item-wrapper-id">
                <div className={`item-img item-img-powerRankings-${screenMode} ${(constants.POWER_RANKINGS_TABS.includes(activeTabParent) && !activeTabNoContent) && `item-img-powerRankings-active-${screenMode}`}`} />
                <div className={`item-text item-text-${screenMode} ${(constants.POWER_RANKINGS_TABS.includes(activeTabParent) && !activeTabNoContent) && 'item-text-active'}`}>{(t('common:menu.powerRankings')).toUpperCase()}</div>
              </div>
            </NavLink>
            { !collapsedTab && (constants.POWER_RANKINGS_TABS.includes(activeTabParent) && !activeTabNoContent) &&
              <Collapse className='power-rankings-tab-collapse' isOpen={(constants.POWER_RANKINGS_TABS.includes(activeTabParent) && !activeTabNoContent)} navbar>
                <Nav navbar>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.powerRankings)/*() => toggle(constants.MENU_NAVIGATION.powerRankings)*/}>
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.powerRankings ? PowerRankingsRatingActiveLightIcon : PowerRankingsRatingLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.powerRankings ? PowerRankingsRatingActiveIcon : PowerRankingsRatingIcon
                        )}
                        className="power-rankings nav-item-img-21"
                        alt="power-rankings"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.powerRankings && 'nav-item-text-active'}`}>{capitalizeFirstLetter(t('common:powerRankings.rating'))}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.powerRankingsPoints)/* () => toggle(constants.MENU_NAVIGATION.powerRankingsPoints)*/}>
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.powerRankingsPoints ? PowerRankingsPointsActiveLightIcon : PowerRankingsPointsLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.powerRankingsPoints ? PowerRankingsPointsActiveIcon : PowerRankingsPointsIcon
                        )}
                        className="power-rankings nav-item-img-21"
                        alt="power-rankings"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.powerRankingsPoints && 'nav-item-text-active'}`}>{capitalizeFirstLetter(t('common:powerRankings.points'))}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.powerRankingsCoins)/*() => toggle(constants.MENU_NAVIGATION.powerRankingsCoins)*/}>
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.powerRankingsCoins ? PowerRankingsCoinsActiveLightIcon : PowerRankingsCoinsLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.powerRankingsCoins ? PowerRankingsCoinsActiveIcon : PowerRankingsCoinsIcon
                        )}
                        className="power-rankings nav-item-img-21"
                        alt="power-rankings"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.powerRankingsCoins && 'nav-item-text-active'}`}>{capitalizeFirstLetter(t('common:powerRankings.coins'))}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(pwOption ? constants.MENU_NAVIGATION.powerRankingsLossCount : constants.MENU_NAVIGATION.powerRankingsWinsCount)/*() => toggle(constants.MENU_NAVIGATION.powerRankingsWinsCount)*/}>
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          (activeTabParent === constants.MENU_NAVIGATION.powerRankingsWinsCount || activeTabParent === constants.MENU_NAVIGATION.powerRankingsLossCount)  ? PowerRankingsWinsActiveLightIcon : PowerRankingsWinsLightIcon
                        ) : (
                          (activeTabParent === constants.MENU_NAVIGATION.powerRankingsWinsCount || activeTabParent === constants.MENU_NAVIGATION.powerRankingsLossCount) ? PowerRankingsWinsActiveIcon : PowerRankingsWinsIcon
                        )}
                        className="power-rankings nav-item-img-21"
                        alt="power-rankings"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${(activeTabParent === constants.MENU_NAVIGATION.powerRankingsWinsCount || activeTabParent === constants.MENU_NAVIGATION.powerRankingsLossCount) && 'nav-item-text-active'}`}>{capitalizeFirstLetter(t(pwOption ? 'common:powerRankings.lossCount' : 'common:powerRankings.winsCount'))}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(pwOption ? constants.MENU_NAVIGATION.powerRankingsLossPercentage : constants.MENU_NAVIGATION.powerRankingsWinPercentage)/*() => toggle(constants.MENU_NAVIGATION.powerRankingsWinPercentage)*/}>
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          (activeTabParent === constants.MENU_NAVIGATION.powerRankingsWinPercentage || activeTabParent === constants.MENU_NAVIGATION.powerRankingsLossPercentage) ? PowerRankingsWinPercentActiveLightIcon : PowerRankingsWinPercentLightIcon
                        ) : (
                          (activeTabParent === constants.MENU_NAVIGATION.powerRankingsWinPercentage || activeTabParent === constants.MENU_NAVIGATION.powerRankingsLossPercentage) ? PowerRankingsWinPercentActiveIcon : PowerRankingsWinPercentIcon
                        )}
                        className="power-rankings nav-item-img-21"
                        alt="power-rankings"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${(activeTabParent === constants.MENU_NAVIGATION.powerRankingsWinPercentage || activeTabParent === constants.MENU_NAVIGATION.powerRankingsLossPercentage) && 'nav-item-text-active'}`}>{capitalizeFirstLetter(t(pwOption ? 'common:powerRankings.lossPercentage' : 'common:powerRankings.winPercentage'))}</div>
                    </div>
                  </NavItem>
                </Nav>
              </Collapse>
            }
          </div>
        </div>
        <div className='landscape-layout-mobile-header-links'>
          <div className={`landscape-layout-mobile-header-link-item ${(constants.MY_INFO_TABS.includes(activeTabParent) && !activeTabNoContent) ? 'landscape-layout-mobile-header-link-item-active' : ''}`}>
            <NavLink className='layout-header-link' onClick={() => { toggle(constants.MENU_NAVIGATION.myInfo); }} style={{ position: 'relative' }}>
              <div className="my-info-menu-item-wrapper-overlay" id="my-info-menu-item-wrapper-overlay-id" style={joyrideMenuOverlayStyle} />
              <div className="item-wrapper my-info-menu-item-wrapper" id="my-info-menu-item-wrapper-id">
                <div className={`item-img item-img-myInfo-${screenMode} ${(constants.MY_INFO_TABS.includes(activeTabParent) && !activeTabNoContent) && `item-img-myInfo-active-${screenMode}`}`} />
                <div className={`item-text item-text-${screenMode} ${(constants.MY_INFO_TABS.includes(activeTabParent) && !activeTabNoContent) && 'item-text-active'}`}>{(t('common:menu.myInfo')).toUpperCase()}</div>
              </div>
            </NavLink>
            { !collapsedTab && (constants.MY_INFO_TABS.includes(activeTabParent) && !activeTabNoContent) &&
              <Collapse className='my-info-tab-collapse' isOpen={(constants.MY_INFO_TABS.includes(activeTabParent) && !activeTabNoContent)} navbar>
                <Nav navbar>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.myInfo)}>
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.myInfo ? MyInfoMenuMainActiveLight : MyInfoMenuMainLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.myInfo ? MyInfoMenuMainActive : MyInfoMenuMainIcon
                        )}
                        className="my-info nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.myInfo && 'nav-item-text-active'}`}>{t('common:myInfo.myInfo')}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.friends)} style={{position: 'relative'}}>
                    <div className="my-info-friends-menu-item-wrapper-overlay" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper my-info-friends-menu-item-wrapper" id="my-info-friends-menu-item-wrapper-id" >
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.friends ? MyFriendActiveLightIcon : MyFriendLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.friends ? MyFriendActiveIcon : MyFriendIcon
                        )}
                        className="my-info my-info-friends-joyride nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.friends && 'nav-item-text-active'}`}>{t('common:myInfo.friends')}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.ignoredUsers)} style={{position: 'relative'}}>
                    <div className="my-info-ignored-menu-item-wrapper-overlay" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.ignoredUsers ? IgnoreActiveLightIcon : IgnoreLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.ignoredUsers ? IgnoreActiveIcon : IgnoreIcon
                        )}
                        className="my-info my-info-ignored-joyride nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.ignoredUsers && 'nav-item-text-active'}`}>{t('common:myInfo.ignoredPlayers')}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.archievements)} style={{position: 'relative'}}>
                    <div className="my-info-achievements-menu-item-wrapper-overlay" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.archievements ? AchievementsActiveLightIcon : AchievementsLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.archievements ? AchievementsActiveIcon : AchievementsIcon
                        )}
                        className="my-info my-info-achievements-joyride nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.archievements && 'nav-item-text-active'}`}>{t('common:myInfo.achievements')}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.gameHistory)} style={{position: 'relative'}}>
                    <div className="my-info-results-menu-item-wrapper-overlay" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.gameHistory ? GameResultsActiveLightIcon : GameResultsLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.gameHistory ? GameResultsActiveIcon : GameResultsIcon
                        )}
                        className="my-info my-info-results-joyride nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.gameHistory && 'nav-item-text-active'}`}>{t('common:myInfo.results')}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.weeklyStatus)} style={{position: 'relative'}}>
                    <div className="my-info-weekly-stats-menu-item-wrapper-overlay" id="my-info-weekly-stats-menu-item-wrapper-overlay-id" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.weeklyStatus ? WeeklyStatisticsActiveLightIcon : WeeklyStatisticsLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.weeklyStatus ? WeeklyStatisticsActiveIcon : WeeklyStatisticsIcon
                        )}
                        className="my-info my-info-weekly-stats-joyride nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.weeklyStatus && 'nav-item-text-active'}`}>{t('common:myInfo.weeklyStatus2')}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.gameLogs)} style={{position: 'relative'}}>
                    <div className="my-info-game-logs-menu-item-wrapper-overlay" id="my-info-game-logs-menu-item-wrapper-overlay-id" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.gameLogs ? GameLogsActiveLightIcon : GameLogsLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.gameLogs ? GameLogsActiveIcon : GameLogsIcon
                        )}
                        className="my-info my-info-game-logs-joyride nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.gameLogs && 'nav-item-text-active'}`}>{t('common:myInfo.gameLogs')}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.myInfoGifts)} style={{position: 'relative'}}>
                    <div className="my-info-gifts-menu-item-wrapper-overlay" id="my-info-gifts-menu-item-wrapper-overlay-id" style={{position: 'absolute', left: 0, top: 0, width: '90%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.myInfoGifts ? GameLogsActiveLightIcon : GameLogsLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.myInfoGifts ? GameLogsActiveIcon : GameLogsIcon
                        )}
                        className="my-info my-info-gifts-joyride nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.myInfoGifts && 'nav-item-text-active'}`}>{t('common:myInfo.gifts')}</div>
                    </div>
                  </NavItem>
                </Nav>
              </Collapse>
            }
          </div>
        </div>
        <div className='landscape-layout-mobile-header-links'>
          <div className={`landscape-layout-mobile-header-link-item ${(constants.TOURNAMENTS_TABS.includes(activeTabParent) && !activeTabNoContent) ? 'landscape-layout-mobile-header-link-item-active' : ''}`}>
            <NavLink className={`layout-header-link ${getTournamentsWithOpenRegistration(tournaments).length > 0 && 'incoming-mWeb-landscape'}`} onClick={() => { toggle(hasActiveRegistration ? constants.MENU_NAVIGATION.futureTournaments : constants.MENU_NAVIGATION.tournaments); }}>
              <div className="item-wrapper tournaments-menu-item-wrapper" id="tournaments-menu-item-wrapper-id">
                <div className={`item-img item-img-tournaments-${screenMode} ${(constants.TOURNAMENTS_TABS.includes(activeTabParent) && !activeTabNoContent) && `item-img-tournaments-active-${screenMode}`}`} />
                <div className={`item-text item-text-${screenMode} ${(constants.TOURNAMENTS_TABS.includes(activeTabParent) && !activeTabNoContent) && 'item-text-active'}`}>{(t('common:menu.tournaments').toUpperCase())}</div>
              </div>
            </NavLink>
            { !collapsedTab && (constants.TOURNAMENTS_TABS.includes(activeTabParent) && !activeTabNoContent) &&
              <Collapse className='tournaments-tab-collapse' isOpen={(constants.TOURNAMENTS_TABS.includes(activeTabParent) && !activeTabNoContent)} navbar>
                <Nav navbar>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.tournaments)} style={{ position: 'relative' }}>
                    <div className="tournament-active-menu-item-wrapper-overlay" id="tournament-active-menu-item-wrapper-overlay-id" style={{position: 'absolute', left: 0, top: 0, width: '100%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.tournaments ? TournamentsActiveSubActiveTabLightIcon : TournamentsActiveSubTabLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.tournaments ? TournamentsActiveSubActiveTabIcon : TournamentsActiveSubTabIcon
                        )}
                        className="tournament-active nav-item-img-20"
                        alt="tournament-active"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.tournaments && 'nav-item-text-active'}`}>{t('common:tournaments.activeTournaments')}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.futureTournaments)} style={{ position: 'relative' }}>
                    <div className="tournament-future-menu-item-wrapper-overlay" style={{position: 'absolute', left: 0, top: 0, width: '100%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.futureTournaments ? TournamentsFutureSubActiveTabLightIcon : TournamentsFutureSubTabLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.futureTournaments ? TournamentsFutureSubActiveTabIcon : TournamentsFutureSubTabIcon
                        )}
                        className="tournament-future nav-item-img-20"
                        alt="tournament-future"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.futureTournaments && 'nav-item-text-active'}`}>{t('common:tournaments.futureTournaments')}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.tournamentsHistory)} style={{ position: 'relative' }}>
                    <div className="tournament-history-menu-item-wrapper-overlay" style={{position: 'absolute', left: 0, top: 0, width: '100%', height: 36, pointerEvents: 'none'}} />
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? (
                          activeTabParent === constants.MENU_NAVIGATION.tournamentsHistory ? TournamentsHistorySubActiveTabLightIcon : TournamentsHistorySubTabLightIcon
                        ) : (
                          activeTabParent === constants.MENU_NAVIGATION.tournamentsHistory ? TournamentsHistorySubActiveTabIcon : TournamentsHistorySubTabIcon
                        )}
                        className="tournament-history nav-item-img-20"
                        alt="tournament-history"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.tournamentsHistory && 'nav-item-text-active'}`}>{t('common:tournaments.tournamentHistory')}</div>
                    </div>
                  </NavItem>

                  <NavItem onClick={() => setOpenTournamentsTutorialFunc(true)/*toggleShowTournamentTutorial*/} style={{ overflow: "hidden" }} /*onMouseEnter={() => setTournamentsTutorialPopoverOpen(true)} onMouseLeave={() => setOpenTournamentsTutorialFunc(false)}*/>
                    <div className="nav-item-wrapper">
                      <Media src={(screenMode == constants.SCREEN_MODE.light) ? lightInfoImg : tutorialImage} style={{marginTop: 13}} className='tournament-tutorial nav-item-img-20' alt='tournament-tutorial' id="tournament-tutorial-popover-target" />
                      <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:tournaments.tutorial2')}</div>
                    </div>
                  </NavItem>
                </Nav>
              </Collapse>
            }
          </div>
        </div>
        <div className='landscape-layout-mobile-header-links'>
          <div className={`landscape-layout-mobile-header-link-item ${activeTabNoContent === constants.MENU_NAVIGATION.moneyAndCoins ? 'landscape-layout-mobile-header-link-item-active' : ''}`}>
            <NavLink className='layout-header-link' onClick={() => { toggleNoContent(constants.MENU_NAVIGATION.moneyAndCoins); }}>
              <div className="item-wrapper">
                <div className={`item-img item-img-myInfo-${screenMode} ${activeTabNoContent === constants.MENU_NAVIGATION.moneyAndCoins && `item-img-myInfo-active-${screenMode}`}`} />
                <div className={`item-text item-text-${screenMode} ${activeTabNoContent === constants.MENU_NAVIGATION.moneyAndCoins && 'item-text-active'}`}>{(t('common:menu.moneyAndCoins')).toUpperCase()}</div>
              </div>
            </NavLink>
            { activeTabNoContent === constants.MENU_NAVIGATION.moneyAndCoins &&
              <Collapse className='my-info-tab-collapse' isOpen={activeTabNoContent === constants.MENU_NAVIGATION.moneyAndCoins} navbar>
                <Nav navbar>
                  <NavItem onClick={() => toggleBottomBarTab('moneyHistory')}>
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? MoneyHistoryLightIcon : MoneyHistoryIcon}
                        className="my-info nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode}`}>{capitalizeFirstLetter(t('common:menu.moneyHistory'))}</div>
                    </div>
                  </NavItem>
                  <NavItem onClick={() => toggleBottomBarTab('pointsHistory')}>
                    <div className="nav-item-wrapper">
                      <Media
                        src={screenMode === constants.SCREEN_MODE.light ? PointsHistoryLightIcon : PointsHistoryIcon}
                        className="my-info nav-item-img-21"
                        alt="my-info"
                      />
                      <div className={`nav-item-text nav-item-text-${screenMode}`}>{capitalizeFirstLetter(t('common:menu.pointsHistory'))}</div>
                    </div>
                  </NavItem>
                </Nav>
              </Collapse>
            }
          </div>
        </div>
        <div className="landscape-layout-mobile-header-links-wrapper-bottom">
          <div className='landscape-layout-mobile-header-links'>
            <div className="landscape-layout-mobile-header-link-item">
              <NavLink className={`layout-header-link layout-header-link-medium ${notSpinedLuckyWheel && 'incoming-mWeb-landscape'}`} onClick={() => toggle(constants.MENU_NAVIGATION.bonusPage)}>
                <div className="item-wrapper daily-bonus-menu-item-wrapper">
                  <Media src={dailyBonusButton} alt='X' className='daily-bonus-link-image' />
                  <div className={`item-text item-text-${screenMode} item-text-medium ${(activeTabParent === constants.MENU_NAVIGATION.bonusPage && !activeTabNoContent) && 'item-text-active'}`}>{t('common:route.bonusPage')}</div>
                </div>
              </NavLink>
            </div>
          </div>
          {/* <div className='landscape-layout-mobile-header-links'>
            <div className="landscape-layout-mobile-header-link-item">
              <NavLink className='layout-header-link layout-header-link-medium' onClick={() => toggle(constants.MENU_NAVIGATION.buyPage)}>
                <div className="item-wrapper">
                  <Media src={shopButton} alt='X' className='daily-bonus-link-image' />
                  <div className={`item-text item-text-${screenMode} item-text-medium ${activeTabParent === constants.MENU_NAVIGATION.buyPage && 'item-text-active'}`}>{t('common:route.buyPage')}</div>
                </div>
              </NavLink>
            </div>
          </div> */}


          <div className='landscape-layout-mobile-header-links'>
            <div className={`landscape-layout-mobile-header-link-item ${(constants.BUY_PAGE_TABS.includes(activeTabParent) && !activeTabNoContent) ? 'landscape-layout-mobile-header-link-item-active' : ''}`}>
              <NavLink className='layout-header-link layout-header-link-medium' onClick={() => handleToBuyPage()}>
                <div className="item-wrapper shop-menu-item-wrapper">
                  <Media src={shopButton} alt='X' className='shop-link-image' />
                  {/* <div className={`item-img item-img-myInfo-${screenMode} ${activeTabNoContent === constants.MENU_NAVIGATION.buyPage && `item-img-myInfo-active-${screenMode}`}`} /> */}
                  <div className={`item-text item-text-medium item-text-${screenMode} ${(constants.BUY_PAGE_TABS.includes(activeTabParent) && !activeTabNoContent) && 'item-text-active'}`}>{t('common:route.buyPage')}</div>
                </div>
              </NavLink>
              { !collapsedTab && (constants.BUY_PAGE_TABS.includes(activeTabParent) && !activeTabNoContent) &&
                <Collapse className='my-info-tab-collapse' isOpen={(constants.BUY_PAGE_TABS.includes(activeTabParent) && !activeTabNoContent)} navbar>
                  <Nav navbar>
                    <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.buyPageMoneyPacks)}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            activeTabParent === constants.MENU_NAVIGATION.buyPageMoneyPacks ? MoneyPacksActiveLightIcon : MoneyPacksLightIcon
                          ) : (
                            activeTabParent === constants.MENU_NAVIGATION.buyPageMoneyPacks ? MoneyPacksActiveIcon : MoneyPacksIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.buyPageMoneyPacks && 'nav-item-text-active'}`}>{capitalizeFirstLetter(t('common:buyPage.moneyPacks'))}</div>
                      </div>
                    </NavItem>
                    <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.buyPagePremiumPacks)}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            activeTabParent === constants.MENU_NAVIGATION.buyPagePremiumPacks ? PremiumPacksActiveLightIcon : PremiumPacksLightIcon
                          ) : (
                            activeTabParent === constants.MENU_NAVIGATION.buyPagePremiumPacks ? PremiumPacksActiveIcon : PremiumPacksIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.buyPagePremiumPacks && 'nav-item-text-active'}`}>{capitalizeFirstLetter(t('common:buyPage.premiumPacks'))}</div>
                      </div>
                    </NavItem>
                    <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.buyPageHistory)}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            activeTabParent === constants.MENU_NAVIGATION.buyPageHistory ? ShopHistoryActiveLightIcon : ShopHistoryLightIcon
                          ) : (
                            activeTabParent === constants.MENU_NAVIGATION.buyPageHistory ? ShopHistoryActiveIcon : ShopHistoryIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.buyPageHistory && 'nav-item-text-active'}`}>{capitalizeFirstLetter(t('common:buyPage.history'))}</div>
                      </div>
                    </NavItem>
                    <NavItem onClick={() => toggle(constants.MENU_NAVIGATION.buyPageRedeemCode)}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            activeTabParent === constants.MENU_NAVIGATION.buyPageRedeemCode ? RedeemCodeActiveLightIcon : RedeemCodeLightIcon
                          ) : (
                            activeTabParent === constants.MENU_NAVIGATION.buyPageRedeemCode ? RedeemCodeActiveIcon : RedeemCodeIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode} ${activeTabParent === constants.MENU_NAVIGATION.buyPageRedeemCode && 'nav-item-text-active'}`}>{capitalizeFirstLetter(t('common:common.redeemGiftCode'))}</div>
                      </div>
                    </NavItem>
                  </Nav>
                </Collapse>
              }
            </div>
          </div>

          <div className='landscape-layout-mobile-header-links'>
            <div className="landscape-layout-mobile-header-link-item">
              <NavLink className={`layout-header-link layout-header-link-medium ${supportChatStatus && supportChatStatus.read === false && 'incoming-mWeb-landscape'}`} onClick={() => toggleSupport()}>
                <div className="item-wrapper">
                  <Media src={screenMode === constants.SCREEN_MODE.light ? chatButtonLight : chatButton} alt='X' className='chat-link-image' />
                  <div className={`item-text item-text-${screenMode} item-text-medium`}>{t('common:menu.chat')}</div>
                </div>
              </NavLink>
            </div>
          </div>

          <div className='landscape-layout-mobile-header-links'>
            <div className="landscape-layout-mobile-header-link-item">
              <NavLink className='layout-header-link layout-header-link-medium' onClick={(e) => handleClickStart(e)}>
                <div className="item-wrapper">
                  <Media src={humanSmallImg} alt='X' className='tutorial-link-image' />
                  <div className={`item-text item-text-${screenMode} item-text-medium`}>{t('common:menu.tutorial2')}</div>
                </div>
              </NavLink>
            </div>
          </div>
          <div className='landscape-layout-mobile-header-links'>
            <div className="landscape-layout-mobile-header-link-item">
              <NavLink className='layout-header-link layout-header-link-medium' onClick={showUserSettingsModal}>
                <div className="item-wrapper settings-menu-item-wrapper">
                  {/* <div className="settings-link-wrapper"> */}
                    <Media className="settings-link-image" src={screenMode === constants.SCREEN_MODE.light ? gearIconLight : gearIcon} alt={t('menu.settings')} />
                  {/* </div> */}
                  <div className={`item-text item-text-${screenMode} item-text-medium`}>{t('common:home.settings')}</div>
                </div>
              </NavLink>
            </div>
          </div>
          <div className='landscape-layout-mobile-header-links'>
            <div className="landscape-layout-mobile-header-link-item">

            </div>
          </div>

          <div className='landscape-layout-mobile-header-links'>
            <div className={`landscape-layout-mobile-header-link-item ${activeTabNoContent === constants.MENU_NAVIGATION.more ? 'landscape-layout-mobile-header-link-item-active' : ''}`}>
              <NavLink className="layout-header-link layout-header-link-medium" onClick={() => toggleNoContent(constants.MENU_NAVIGATION.more)}>
                <div className="item-wrapper more-menu-item-wrapper">
                  <div className="settings-link-wrapper">
                    <AiOutlineMore style={screenMode === constants.SCREEN_MODE.light ? {color: 'black'} : {}} />
                  </div>
                  <div className={`item-text item-text-medium item-text-${screenMode} ${activeTabNoContent === constants.MENU_NAVIGATION.more && 'item-text-active'}`}>
                    {t(activeTabNoContent === constants.MENU_NAVIGATION.more ? 'common:menu.less' : 'common:menu.more')}
                  </div>

                </div>
              </NavLink>
              { activeTabNoContent === constants.MENU_NAVIGATION.more &&
                <Collapse className='my-info-tab-collapse' isOpen={activeTabNoContent === constants.MENU_NAVIGATION.more} navbar>
                  <Nav navbar>
                    <NavItem onClick={() => toggleBottomBarTab('helpPage')}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            GameHelpLightIcon
                          ) : (
                            GameHelpIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:menu.helpPage')}</div>
                      </div>
                    </NavItem>
                    <NavItem onClick={() => openLink('/version-history', isInAppFrame)}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            VersionHistoryLightIcon
                          ) : (
                            VersionHistoryIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:menu.versionHistory')}</div>
                      </div>
                    </NavItem>
                    <NavItem onClick={() => toggleBottomBarTab('bannedUsers')}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            BlockedPlayersLightIcon
                          ) : (
                            BlockedPlayersIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:menu.bannedUsers')}</div>
                      </div>
                    </NavItem>
                    <NavItem onClick={() => toggleBottomBarTab('aboutPage')}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            AboutUsLightIcon
                          ) : (
                            AboutUsIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:home.aboutUs')}</div>
                      </div>
                    </NavItem>
                    <NavItem onClick={() => toggleBottomBarTab('termsOfUse')}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            TermsLightIcon
                          ) : (
                            TermsIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:route.termsOfUse')}</div>
                      </div>
                    </NavItem>
                    <NavItem onClick={() => openLink('/blog', isInAppFrame)}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            BlogLightIcon
                          ) : (
                            BlogIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:route.blog')}</div>
                      </div>
                    </NavItem>
                    <NavItem onClick={() => openLink('/contacts', isInAppFrame)}>
                      <div className="nav-item-wrapper">
                        <Media
                          src={screenMode === constants.SCREEN_MODE.light ? (
                            ContactsLightIcon
                          ) : (
                            ContactsIcon
                          )}
                          className="my-info nav-item-img-21"
                          alt="my-info"
                        />
                        <div className={`nav-item-text nav-item-text-${screenMode}`}>{t('common:menu.contacts')}</div>
                      </div>
                    </NavItem>
                  </Nav>
                </Collapse>
              }
            </div>
          </div>
        </div>
        <div className="bottom-part-overlay" style={{position: 'fixed', left: 0, bottom: 22, width: 213, height: 50, pointerEvents: 'none'}} />

        <div className="bottom-part">
          <div className='landscape-layout-mobile-header-links'>
            <div className="landscape-layout-mobile-header-link-item">
              <NavLink className='layout-header-link layout-header-link-medium'>
                {/* <Media className="settings-button-image" src={gearIcon} alt={t('menu.settings')} /> */}
                <div className="item-wrapper">
                  <div className="medium-icon-wrapper">
                    <div style={{ backgroundImage: member.photo ? `url(${member.photo})` : (screenMode === constants.SCREEN_MODE.light ? `url(${lightDefaultImage})` : `url(${defaultImage})`), backgroundPosition: 'center', backgroundSize: member.photo ? '100%, 100%' : '80%, 93%' }} className="profile-link-image">
                    </div>
                  </div>
                  <div className={`item-text item-text-${screenMode} item-text-medium`}>{t('common:menu.myProfile')}</div>
                  {(gifts && activeGifts && activeGifts.giftId && gifts[activeGifts.giftId]) &&
                    <Media src={gifts[activeGifts.giftId].image} className="gift-img" />
                  }
                </div>
                <UpdateProfile key={member.uid} overlayMode t={t} member={member} screenMode={screenMode} isMobile={true} />

              </NavLink>
              <div className="coins-and-rating">
                <div className="value-wrapper">
                  <Media src={coinImg} className={`value-image ${(lowBalance || negativeBalance) && 'blink'}`} />
                  <span className={`value-text value-text-${screenMode} ${lowBalance && 'value-text-orange'} ${negativeBalance && 'value-text-red'}`}>{member.balance}</span>
                </div>
                <div className="value-wrapper">
                  <Media src={ratingImg} className="value-image" />
                  <span className={`value-text value-text-${screenMode}`}>{member.rating}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )

});

LayoutMobileHeader.propTypes = {
  activeTab: PropTypes.string,
  t: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  showUserSettingsModal: PropTypes.func.isRequired,
  achievementsNotification: PropTypes.bool,
  hasActiveRegistration: PropTypes.bool,
  supportChatStatus: PropTypes.shape({}),
  member: PropTypes.shape(),
  toggleSupport: PropTypes.func.isRequired,
  supportModalOpen: PropTypes.bool,
  toggleBottomBarTab: PropTypes.func,
  pwOption: PropTypes.bool,
  setSupportAsRead: PropTypes.func,
  playButtonSound: PropTypes.func,
  name: PropTypes.string,
  uid: PropTypes.string,
  setAddPaymentInfo: PropTypes.func.isRequired,
};

LayoutMobileHeader.defaultProps = {
  activeTab: '1',
  achievementsNotification: false,
  hasActiveRegistration: false,
  member: null,
  supportModalOpen: false,
  pwOption: false,
  name: '',
  uid: '',
};

const mapStateToProps = state => ({
  supportChatStatus: state.member.supportChatStatus,
  member: state.member,
  userSettings: state.userSettings[state.member.uid] || state.userSettings.default || {},
  filters: state.userSettings[state.member.uid]?.roomsFilter || {},
  rooms: state.rooms.rooms || {},
  uid: state.member.uid || '',
  activeTournament: state.member.activeTournament || null,
  activeRoom: state.member.activeRoom || null,
  joinedRooms: state.member.joinedRooms || null,
  joinedRoomPassword: state.member.joinedRoomPassword || state.rooms.joinedRoomPassword || null,
  joinRoomError: state.member.joinRoomError || null,
  openTournamentsTutorial: state.state.openTournamentsTutorial || false,
  openTopTab: state.state.openTopTab || null,
  lastBonusSpin: state.member.lastBonusSpin,
  offset: state.member.offset,
  tournaments: (state.tournaments && state.tournaments.tournaments) ? state.tournaments.tournaments : [],
  prepareForJoyride: state.state.prepareForJoyride || false,
  expandedLandscapeSidebar: state.state.expandedLandscapeSidebar || false,
  gifts: state.rooms.gifts || {},
  activeGifts: state.member.activeGifts || null,
  name: state.member.name,
});

const mapDispatchToProps = {
  setOpenRoomsTabFunc: setOpenRoomsTab,
  setOpenTopTabFunc: setOpenTopTab,
  setOpenTournamentsTutorialFunc: setOpenTournamentsTutorial,
  setPrepareForJoyrideFunc: setPrepareForJoyride,
  setAddPaymentInfo: addPaymentInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutMobileHeader);
